<template>
  <div >
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense class="my-0 compact-rows">
        <!-- <pre>{{newEquipamento.equipamentosSelecionados}}</pre> -->
        <v-col>
          <!-- Botão de Kit Básico -->
          <v-row dense>
            <v-col >
              <v-btn
                v-for="kit in kitEquipamento"
                :key="kit.id"
                class="blod_color mb-3 mt-3 mr-2"
                @click="selectKit(kit)"
                small dense
              >
                {{ kit.nome }}
              </v-btn>
            </v-col>
            <v-col class="text-end mb-3 mt-3 ml-3">
              <v-btn small dense style="color: red; font-weight: bold;" @click="resetSelectedEquipamentos">Limpar Seleção</v-btn>

            </v-col>
          </v-row>
          <v-expansion-panels multiple>
            <!-- Apoio Respiratório -->
            <v-expansion-panel>
              <v-expansion-panel-header class="expansion-header text-center blod_color">
                <span v-if="selectedCount('apoioRespiratorioEquipamentos') > 0">
                  APOIO RESPIRATÓRIO ( {{ selectedCount('apoioRespiratorioEquipamentos') }} equipamento(s) selecionado(s) )
                </span>
                <span v-else>
                  APOIO RESPIRATÓRIO
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="equipamento-row">
                  <v-col cols="12" class="text-center" v-if="loadingApoioRespiratorio">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="text-center" v-else-if="apoioRespiratorioEquipamentos.length === 0">
                    <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                  </v-col>
                  <template v-else>
                    <v-col v-for="(equipamento, index) in apoioRespiratorioEquipamentos" :key="index" class="equipamento-col" cols="3">
                      <v-checkbox
                        v-model="newEquipamento.equipamentosSelecionados"
                        :value="equipamento.id"
                        :label="equipamento.displayText"
                      ></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Oxigenoterapia -->
            <v-expansion-panel>
              <v-expansion-panel-header class="expansion-header text-center blod_color">
                <span v-if="selectedCount('oxigenoterapiaEquipamentos') > 0">
                  OXIGENOTERAPIA  ( {{ selectedCount('oxigenoterapiaEquipamentos') }} equipamento(s) selecionado(s) )
                </span>
                <span v-else>
                  OXIGENOTERAPIA 
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="equipamento-row">
                  <v-col cols="12" class="text-center" v-if="loadingOxigenoterapia">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="text-center" v-else-if="oxigenoterapiaEquipamentos.length === 0">
                    <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                  </v-col>
                  <template v-else>
                    <v-col v-for="(equipamento, index) in oxigenoterapiaEquipamentos" :key="index" class="equipamento-col" cols="3">
                      <v-checkbox
                        v-model="newEquipamento.equipamentosSelecionados"
                        :value="equipamento.id"
                        :label="equipamento.displayText"
                      ></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Mobiliário -->
            <v-expansion-panel>
              <v-expansion-panel-header class="expansion-header text-center blod_color">
                <span v-if="selectedCount('mobiliarioEquipamentos') > 0">
                  MOBILIÁRIO ( {{ selectedCount('mobiliarioEquipamentos') }} equipamento(s) selecionado(s) )
                </span>
                <span v-else>
                    MOBILIÁRIO 
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="equipamento-row">
                  <v-col cols="12" class="text-center" v-if="loadingMobiliario">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="text-center" v-else-if="mobiliarioEquipamentos.length === 0">
                    <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                  </v-col>
                  <template v-else>
                    <v-col v-for="(equipamento, index) in mobiliarioEquipamentos" :key="index" class="equipamento-col" cols="3">
                      <v-checkbox
                        v-model="newEquipamento.equipamentosSelecionados"
                        :value="equipamento.id"
                        :label="equipamento.displayText"
                      ></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Demais Equipamentos -->
            <v-expansion-panel>
              <v-expansion-panel-header class="expansion-header text-center blod_color">
                <span v-if="selectedCount('demaisEquipamentos') > 0">
                    DEMAIS EQUIPAMENTOS  ( {{ selectedCount('demaisEquipamentos') }} equipamento(s) selecionado(s) )
                </span>
                <span v-else>
                    DEMAIS EQUIPAMENTOS 
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="equipamento-row">
                  <v-col cols="12" class="text-center" v-if="loadingDemaisEquipamentos">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-col>
                  <v-col cols="12" class="text-center" v-else-if="demaisEquipamentos.length === 0">
                    <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                  </v-col>
                  <template v-else>
                    <v-col v-for="(equipamento, index) in demaisEquipamentos" :key="index" class="equipamento-col" cols="3">
                      <v-checkbox
                        v-model="newEquipamento.equipamentosSelecionados"
                        :value="equipamento.id"
                        :label="equipamento.displayText"
                      ></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Esterilizáveis -->
           <v-expansion-panel v-if="newEquipamento.disponibilidade === 3">
            <v-expansion-panel-header class="expansion-header text-center blod_color">
              <span v-if="newEquipamento.esterilizaveisSelecionados.length > 0">
                  ESTERILIZÁVEIS  ( {{ newEquipamento.esterilizaveisSelecionados.length }} esterilizáve(s) selecionado(s) )
              </span>
              <span v-else>
                  ESTERILIZÁVEIS 
              </span>
            
            </v-expansion-panel-header>
            <v-expansion-panel-content>
             <v-row class="mb-4">
                <v-col cols="8">
                  <v-text-field v-model="searchTerm" label="Pesquisar esterilizável" outlined dense hide-details clearable placeholder="Digite o nome do esterilizável..." ></v-text-field>
                </v-col>
                <v-col class="mt-2">
                  <v-btn small dense style="color: red; font-weight: bold;" @click="limpar">Limpar Seleção de Esterilizáveis</v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" v-if="filteredEsterilizavel.length === 0" class="text-center">
                  <p class="error-message">Sem esterilizáveis cadastrados</p>
                </v-col>
                
                <template v-else>
                  <v-col v-for="(item) in filteredEsterilizavel" :key="item.id" cols="12" md="3" class="d-flex align-center">
                    <v-card outlined class="pa-2 w-100 standard-card">
                      <v-row align="center" dense>
                        <!-- Checkbox para selecionar o esterilizável -->
                        <v-col cols="1" class="d-flex justify-center mt-2">
                          <v-checkbox
                            dense
                            v-model="newEquipamento.esterilizaveisSelecionados"
                            :value="item.id"
                          ></v-checkbox>
                        </v-col>

                        <!-- Nome do esterilizável -->
                        <v-col >
                          <span class="font-weight-bold">{{ item.nome }}</span>
                        </v-col>

                        <!-- Seleção de frequência -->
                        <v-col class="mt-5">
                          <v-select
                            dense
                            outlined
                            :items="frequencia"
                            item-text="nome"
                            item-value="id"
                            v-model="item.frequencia"
                            label="Frequência"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <!-- Espaço adicional antes do restante dos campos -->
          <div class="spacer"></div>
          <v-row dense>
            <v-col cols="3" >
              <v-select dense :items="disponibilidadeOptions" item-text="nome" item-value="id" v-model="newEquipamento.disponibilidade" outlined >
                <template v-slot:label>
                  <span>Disponibilidade <span style="color: red;">*</span></span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" v-if="newEquipamento.disponibilidade === 3" >
              <v-text-field
                v-model="hojeData"
                label="Dt Requisição"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="newEquipamento.disponibilidade === 3" >
              <v-text-field
                v-model="hojeDataPrevista"
                label="Dt Prev. Implant."
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="newEquipamento.disponibilidade === 2" >
              <v-text-field
                v-model="hojeData"
                label="Dt Inclusão no Orçamento"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-end" dense>
       <v-col class="text-end">
            <v-tooltip top :disabled="isFormValid">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <ConfirmButton
                    :color="isFormValid ? 'success' : 'grey'"
                    :loading="isSubmitting"
                    :disabled="!isFormValid || isSubmitting"
                  >
                    Salvar
                  </ConfirmButton>
                </span>
              </template>
              <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
            </v-tooltip>
          </v-col>
      </v-row>
    </v-form>
  </div>

</template>

<script>
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";


export default {
  name: "GenerateProceduresFormEquipamento",
  props: {
    operadoraId: Number,
    onSubmit: Function,
    paciente: Number,
  },
  data: () => UtilsFunc.withCRUDUtils({
    searchTerm: '',
    isSubmitting: false,
    validating: false,
    equipamentos: [],
    loadingEquipamentos: false,
    aditivo:[],
    pacoteId:[],
    nomeequipamentos:[],
    equipamentodependente: [],
    kitEquipamento:[],
    loadingApoioRespiratorio: false,
    loadingOxigenoterapia: false,
    loadingMobiliario: false,
    loadingDemaisEquipamentos: false,
    loadingDisponibilidade: false,
    disponibilidadeOptions: [],
    frequencia: [],
    esterilizavel: [],
    esterilizaveisSelecionados: [],
    newEquipamento: {
      equipamentosSelecionados: [],
      equipamento: null,
      preco: '',
      data_inicio_equipamentos: '',
      data_fim_equipamentos: '',
      quantidade_equipamentos: '',
      valor_cobranca_final_equipamentos: '',
      aditivo_equipamentos: false,
      nr_aditivo: null,
      data_aditivo: null,
      operadora: null,
      equipamento_ref: null,
      esterilizaveisSelecionados: [],
    },
    isLoading: true,
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
  }),
  methods: {
    async getEquipamentos() {
      this.loadingApoioRespiratorio = true;
      this.loadingOxigenoterapia = true;
      this.loadingMobiliario = true;
      this.loadingDemaisEquipamentos = true;

      try {
        const operadoraId = this.operadoraId;
        // console.log(operadoraId)
        const { data } = await api.get(`operadoras/operadora-equipamentos/?operadora=${operadoraId}`);
        this.equipamentos = data.filter(equipamento => equipamento.ativo === true);
        // console.log(this.equipamentos)

        // Filtra e para cada tipo de equipamento, desativa o loading após carregar os dados
        this.loadingApoioRespiratorio = false;
        this.loadingOxigenoterapia = false;
        this.loadingMobiliario = false;
        this.loadingDemaisEquipamentos = false;

      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      }
    },
    async getEquipamentoDependencia() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`operadoras/operadora-equipamentos-dependencias-novo/`);
        this.equipamentodependente = data.filter(item => item.ativo)
        // console.log('this.equipamentodependente', this.equipamentodependente)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getKitEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/kitequipamentos/`);
        this.kitEquipamento = data.filter(item => item.ativo)
        // console.log('kitEquipamento -> ', this.kitEquipamento)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getDisponibilidade() {
      this.loadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidadeOptions = data.filter(item => [3, 2].includes(item.id));
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingDisponibilidade = false;
      }
    },
    async getNomeEsterilizavel() {
      try {
        const { data } = await api.get('/equipamentos/nomeesterelizavels/');
        this.esterilizavel = data
          .filter(item => item.ativo)
          .sort((a, b) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          })
          .map(item => ({ ...item, frequencia: 11 }))
          ;
        // console.log("Esterilizáveis carregados:", this.esterilizavel);
      } catch (error) {
        this.$toast.error(`Erro ao carregar esterilizáveis: ${error.message}`);
      }
    },

    async getFrequencia() {
      this.loadingFrequencia = true
      try {
        const { data } = await api.get("/atendimentos/frequencias/");
        this.frequencia = data;
        // console.log(this.frequencia)
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.loadingFrequencia = false
      }
    },
    async localOnSubmit() {
      this.validating = true;
      let isValid = true;

      if (!this.allFieldsValid()) {
        this.$toast.error('Por favor, escolha pelo menos um equipamento e a disponibilidade!');
        this.validating = false;
        return;
      }
      if (!isValid) {
        this.validating = false;
        return;
      }

      for (const equipamentoId of this.newEquipamento.equipamentosSelecionados) {
        const selectedEquipamentoObj = this.equipamentos.find(equipamento => equipamento.id === equipamentoId);
        const selectedNomeEquipamento = this.nomeequipamentos.find(nomeequipamento => nomeequipamento.id === (selectedEquipamentoObj ? selectedEquipamentoObj.equipamento.id : null));

        this.isSubmitting = true; 

        const fields = {
          paciente: this.paciente,
          nome_equipamento: selectedNomeEquipamento ? selectedNomeEquipamento.id : null,
          operadora: this.operadoraId,
        };
          if (this.newEquipamento.disponibilidade === 3) { // Requisitar
              fields.data_requisicao = this.hojeData || null;
              fields.data_prevista_implantacao = this.hojeDataPrevista || null;
              fields.requisicao = true;
              fields.disponibilidade = 3  
          }
          if (this.newEquipamento.disponibilidade === 2) { // Orçamento
              fields.data_inclusao_orcamento = this.hojeData || null;
              fields.disponibilidade = 2  
          }
        const fieldsArray = [fields]; // Transforma fields em um array

        await this.onSubmit(fieldsArray).finally(() => {
          this.isSubmitting = false;
        });

      }

       // Verificar e salvar esterilizáveis, se houver selecionados
      if (this.newEquipamento.esterilizaveisSelecionados.length > 0) {
        try {
          const esterilizaveisFields = this.newEquipamento.esterilizaveisSelecionados.map((esterilizavelId) => {
            const selectedEsterilizavel = this.esterilizavel.find(item => item.id === esterilizavelId);

            return {
              paciente: this.paciente,
              nome_esterilizavel: esterilizavelId,
              frequencia: selectedEsterilizavel?.frequencia || null,
              data_requisicao: this.hojeData || null,
              data_prevista_implantacao: this.hojeDataPrevista || null,
              disponibilidade: 3,
              requisicao: true,
            };
          });

          for (const field of esterilizaveisFields) {
            await api.post(`/pacientes/esterilizaveis/`, field);
          }

          this.$toast.success('Esterilizáveis cadastrados com sucesso!');
        } catch (error) {
          this.$toast.error(`Erro ao salvar esterilizáveis: ${error.message}`);
        }
      }
      this.validating = false;
    },
    allFieldsValid() {
      return (
        this.newEquipamento.equipamentosSelecionados.length > 0 && 
        this.newEquipamento.disponibilidade
      );
    },
    calculateDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end - start);
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    },
    updateQuantityAndTotal() {
      if (this.newEquipamento.data_inicio_equipamentos && this.newEquipamento.data_fim_equipamentos) {
        const days = this.calculateDaysBetweenDates(this.newEquipamento.data_inicio_equipamentos, this.newEquipamento.data_fim_equipamentos);
        this.newEquipamento.quantidade_equipamentos = days;
        this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * days).toFixed(2);
      }
    },

    updateTotal() {
      this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * this.newEquipamento.quantidade_equipamentos).toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1); 
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    selectedCount(group) {
      // Conta quantos itens do grupo estão selecionados
      return this[group].filter(equipamento => 
        this.newEquipamento.equipamentosSelecionados.includes(equipamento.id)
      ).length;
    },
    selectKit(kit) {
      // Limpa os equipamentos selecionados antes de aplicar o novo kit
      // this.resetSelectedEquipamentos();

      let itensMarcados = 0;

      // Para cada equipamento no kit, verifica se existe na lista de equipamentos disponíveis
      kit.equipamento.forEach(equipamentoId => {
        const equipamentoEncontrado = this.equipamentos.find(equipamento => equipamento.equipamento.id === equipamentoId);

        if (equipamentoEncontrado) {
          // Adiciona o equipamento à lista de selecionados
          this.newEquipamento.equipamentosSelecionados.push(equipamentoEncontrado.id);
          itensMarcados++;
        }
      });

      // Exibe uma mensagem com a quantidade de itens marcados
      if (itensMarcados > 0) {
        this.$toast.success(`${kit.nome} foi selecionado.`);
      } else {
        this.$toast.warning(`Nenhum equipamento encontrado para o ${kit.nome}.`);
      }
    },
    resetSelectedEquipamentos() {
      // Limpa todos os equipamentos selecionados
      this.newEquipamento.equipamentosSelecionados = [];
      this.newEquipamento.esterilizaveisSelecionados = [];
      this.newEquipamento.disponibilidade = null;
    },
    limpar() {
      this.newEquipamento.esterilizaveisSelecionados = [];
    },
  },
  components: { ConfirmButton },
  computed: {
    filteredEsterilizavel() {
      if (!this.searchTerm) {
        return this.esterilizavel;
      }
      return this.esterilizavel.filter(item =>
        item.nome.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    procedureTotalValue() {
     const basePrice = parseFloat(this.newEquipamento.valor_cobranca_equipamento);
      const discount = this.newEquipamento.desconto_equipamento ? parseFloat(this.newEquipamento.desconto_equipamento) / 100 : 0;
      const addition = this.newEquipamento.acrescimo_equipamento ? parseFloat(this.newEquipamento.acrescimo_equipamento) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.newEquipamento.quantidade_equipamento;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    formattedEquipamentos() {
      return this.equipamentos.map(equipamento => {
        return {
          ...equipamento,
          // displayText: equipamento.nomecomercial ? `${equipamento.equipamento.nome} - "${equipamento.nomecomercial}" - Cód.: ${equipamento.codigo}` : (`${equipamento.equipamento.nome} - Cód.: ${equipamento.codigo}`)
          displayText: equipamento.equipamento ? `${equipamento.equipamento.nome}` : ''
        };
      });
    },
    apoioRespiratorioEquipamentos() { 
      return this.formattedEquipamentos.filter(equipamento => [11,12,34,68,67,46, 57,47,49,52,53,65].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    oxigenoterapiaEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => [27, 31, 28, 29, 30, 75,51, 66, 50, 54, 55].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    mobiliarioEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => [3, 14,15, 16, 17, 19, 18,20, 22, 21,23, 73, 33, 37, 73, 32].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    demaisEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => ![34, 67, 57,47,48, 49, 52,53,65, 17,68, 12, 11, 23, 46, 3,14,15,16, 19, 18,20,22, 21,73,33,51,66,50,54,55,27, 31,28, 29, 30,75, 37,73, 32 ].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    isFormValid() {
      // Verifica se há pelo menos um equipamento selecionado
      const hasSelectedEquipment = this.newEquipamento.equipamentosSelecionados.length > 0;

      // Verifica se a disponibilidade foi definida
      const hasDisponibilidade = !!this.newEquipamento.disponibilidade;

      // Verifica se pelo menos uma das datas foi preenchida
      const hasDate =
        !!this.hojeData || !!this.hojeDataPrevista;

      // O botão será habilitado apenas se todas as condições forem verdadeiras
      return hasSelectedEquipment && hasDisponibilidade && hasDate;
    },
  },
  watch: {
   'newEquipamento.equipamentosSelecionados': function(newVal, oldVal) {
      // Itera sobre os equipamentos que têm dependentes
      this.equipamentodependente.forEach(dependente => {
        const paiId = dependente.equipamentopai.id;
        const filhosIds = dependente.equipamentofilhos.map(filho => filho.id);

        // Itera sobre os equipamentos selecionados
        newVal.forEach(selectedId => {
          // Busca o equipamento selecionado no array de equipamentos disponíveis
          const equipamentoSelecionado = this.equipamentos.find(equip => equip.id === selectedId);

          if (equipamentoSelecionado) {
            const equipamentoId = equipamentoSelecionado.equipamento.id; // Pega o equipamento.equipamento.id

            // Verifica se o equipamento.equipamento.id corresponde ao paiId
            if (equipamentoId === paiId && !oldVal.includes(selectedId)) {
              // Se o equipamento selecionado corresponde ao pai, sugere a seleção dos filhos
              filhosIds.forEach(filhoId => {
                // Busca o equipamento filho no getEquipamentos para encontrar o ID correto a ser marcado
                const equipamentoFilho = this.equipamentos.find(equip => equip.equipamento.id === filhoId);
                
                if (equipamentoFilho && !newVal.includes(equipamentoFilho.id)) {
                  newVal.push(equipamentoFilho.id); // Marca os filhos automaticamente
                }
              });
            }
          }
        });
      });
    },
  },
  mounted() {
    this.getEquipamentos();
    this.getNomeEquipamento();
    this.getEquipamentoDependencia();
    this.getKitEquipamento();
    this.getDisponibilidade();
    this.getNomeEsterilizavel();
    this.getFrequencia();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');

    const dataFormatadaAmanha = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate() +1).padStart(2, '0');

    this.hojeData = dataFormatada
    this.hojeDataPrevista = dataFormatadaAmanha
  },
}
</script>

<style scoped>
.expansion-header {
  justify-content: center;
  font-size: 18px;
}
.disponibilidade-col {
  display: flex;
  align-items: center;
}
.spacer {
  margin-top: 20px; /* Ajusta o espaçamento entre os painéis de expansão e o restante dos campos */
}

.equipamento-row {
  margin-bottom: 2px;
}

.equipamento-col {
  margin-bottom: 2px;
  padding: 0px;
}

.error-message {
  color: red;
  font-weight: bold;
}

.standard-card {
  min-height: 120px; /* Altura mínima */
  max-height: 120px; /* Altura máxima */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


</style>
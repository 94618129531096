<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-data-table dense height="400" :loading="isLoadingEsterilizavel" :headers="esterelizavelHeaders" :items="esterelizavel" item-value="id" show-select v-model="selectedEsterelizaveis" class="elevation-1">
          <template v-slot:[`item.nome`]="{ item }">
            <span>{{ item.nome.nome }}</span>
          </template>
          <template v-slot:[`item.disponibilidade`]="{ item }">
            <v-chip :color="item.disponibilidade?.id === 7 ? 'green' : 'grey'" dark>
              {{ item.disponibilidade?.nome || 'Indefinido' }}
            </v-chip>
          </template>
          <template v-slot:[`item.data_validade_esterilizacao`]="{ item }">
            <span>
              {{ item.data_validade_esterilizacao | formatDatabr }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="3" class="ml-5">
        <v-autocomplete :loading="isLoadingFornecedor" dense label="Fornecedor" clearable v-model="data_validade_esterilizacao"   :items="fornecedores" item-value="id" item-text="nome_fantasia"  outlined :rules="requiredField" />
      </v-col>
      <v-col cols="3">
        <v-text-field dense clearable outlined type="date" label="Data de envio para Esterilização" v-model="esterilizacao_data_envio" ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-end align-center mt-0" dense>
      <v-btn  class="mr-4 elevation-0" text  @click="atualizarDados" > <v-icon left>mdi-refresh</v-icon> Atualizar </v-btn>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <div class="align-self-top py-4" left top  :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" :disabled="!isSaveEnabled" > 
          <v-menu eager offset-x left offset-y top v-model="menu">
            <!-- <template v-slot:activator="{ on, attrs }" >
              <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on" :disabled="selectedEsterelizaveis.length === 0"> mdi-printer </v-icon>
            </template> -->
            <v-container class="container">
              <template >
                <div > 
                  <Relatorio ref="relatorioComponent" :dados="selectedEsterelizaveis" :fornecedor="selectedFornecedor" :dataEnvio="esterilizacao_data_envio" > </Relatorio>
                </div>
              </template>
            </v-container>
          </v-menu>
        </div>
      </v-col>
      
      <v-col cols="auto">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton 
                :loading="loading" 
                color="success"
                :disabled="!isSaveEnabled"
                :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
              >
                Salvar
              </ConfirmButton>
            </span>
          </template>
          <span>Selecione o fornecedor, a data de envio e pelo menos um esterilizável</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import Relatorio from '../../relatorios/entregaSuprimento/EntregaEsterilizacao.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      fornecedores: [],
      ativo: true,
      isLoadingFornecedor: false,
      isLoadingEsterilizavel: false,
      isLoadingDados: false, 
      esterelizavel: [],
      selectedEsterelizaveis: [],
      selectedFornecedor: null,
      data_validade_esterilizacao: '',
      esterilizacao_data_envio: this.getFormattedCurrentDate(),
      requiredField: [e => (e ? true : 'Obrigatório')],
      menu: false,
      
      // isSaveEnabled: false, // Controla se o botão está habilitado
      isReportTriggered: false,
      esterelizavelHeaders: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome.nome" },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Data da Validade da Esterilização", value: "data_validade_esterilizacao" },
      ],
    });
  },
  components: { ConfirmButton, Relatorio },
  methods: {
    getFormattedCurrentDate() {
      const currentDate = new Date();
      return currentDate.toISOString().substr(0, 10);
    },
    async getEsterelizavel() {
      this.isLoadingEsterilizavel = true;
      try {
        const response = await api.get("equipamentos/esterelizavels/");
        this.esterelizavel = response.data
          .filter(item => ![1, 2, 3, 4, 5, 7, 9, 11, 12, 13, 14, 15, 16].includes(item.disponibilidade?.id) && item.ativo)
          .sort((a, b) => {
            // Primeiro ordena por disponibilidade.id = 10
            if (a.disponibilidade.id === 10 && b.disponibilidade.id !== 10) {
              return -1;
            }
            if (a.disponibilidade.id !== 10 && b.disponibilidade.id === 10) {
              return 1;
            }

            // Depois ordena por data_validade_esterilizacao
            if (a.data_validade_esterilizacao && b.data_validade_esterilizacao) {
              return new Date(a.data_validade_esterilizacao) - new Date(b.data_validade_esterilizacao);
            }
            if (a.data_validade_esterilizacao) {
              return -1;
            }
            if (b.data_validade_esterilizacao) {
              return 1;
            }

            // Por fim, ordena por nome.nome
            const nomeA = a.nome?.nome?.toLowerCase() || '';
            const nomeB = b.nome?.nome?.toLowerCase() || '';
            return nomeA.localeCompare(nomeB);
          });
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao carregar esterelizáveis: ${error}`);
      } finally {
        this.isLoadingEsterilizavel = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true;
      try {
        const response = await api('fornecedores/fornecedores/');
        this.fornecedores = response.data.filter(item => item.ativo && item.tipo.some(tipo => tipo.id === 8));
        if (this.fornecedores.length === 1) {
          this.data_validade_esterilizacao = this.fornecedores[0].id;
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedores. ${error}`);
      } finally {
        this.isLoadingFornecedor = false;
      }
    },
    localOnSubmit() {
        const validation = this.$refs.formRef.validate();
        if (!validation) return;
      
        const Itens = this.selectedEsterelizaveis.flatMap(item => {
            return Array.from({ length: item.quantidade || 1 }).map(() => ({
                id: item.id,
                disponibilidade: 7, // esterilizavel
                data_validade_esterilizacao: null,
                esterilizacao_data_envio: this.esterilizacao_data_envio,
                esterilizacao_fornecedor: this.selectedFornecedor?.id,
                esterilizando: 1

            }));
        });
        for (const fields of Itens) {
            this.onSubmit(fields);
            this.atualizarDados()
        }
        this.isReportTriggered = true;
    },
    generateReport() {
      if (this.selectedEsterelizaveis.length > 0) {
        this.$toast.success("Gerando relatório...");
        // Chama o componente ou função de geração do relatório
        this.$refs.relatorioComponent.generatePDF(this.selectedEsterelizaveis);
      } else {
        this.$toast.error("Nenhum item selecionado para o relatório.");
      }
    },
    atualizarDados() {
      this.getEsterelizavel();
      this.getFornecedor();
    },
  },
  computed: {
    isSaveEnabled() {
      // Verifica se tem itens selecionados na tabela
      if (!this.selectedEsterelizaveis || this.selectedEsterelizaveis.length === 0) {
        return false;
      }
      // console.log(this.selectedEsterelizaveis)   // vizualiza em tenmpo real o que foi selecionado na tela
      // Verifica se fornecedor está selecionado
      if (!this.data_validade_esterilizacao) {
        return false;
      }

      // Verifica se a data de envio está preenchida
      if (!this.esterilizacao_data_envio) {
        return false;
      }

      // Verifica se a data de envio não é maior que hoje
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0);
      const dataEnvio = new Date(this.esterilizacao_data_envio);
      if (dataEnvio > hoje) {
        return false;
      }

      // Se chegou até aqui, todas as validações passaram
      return true;
    }
  },
  mounted() {
    this.atualizarDados();
  },
  watch: {

    data_validade_esterilizacao(newValue) {
      this.selectedFornecedor = this.fornecedores.find(f => f.id === newValue);
    },
    isReportTriggered(newValue) {
      if (newValue) {
        this.generateReport();
        this.isReportTriggered = false; // Reseta o flag
      }
    }

  },
};
</script>

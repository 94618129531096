<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef" :loading="loading">
    <v-container>
      <!-- Nome do Kit -->
      <v-row dense>
        <v-col>
          <v-text-field dense label="Nome do Kit" v-model="currentItem.nome" outlined :rules="requiredField" />
        </v-col>
      </v-row>

      <!-- Equipamentos Já Selecionados e Novos -->
      <v-row>
        <v-col cols="6">
          <h3>Equipamentos Já Selecionados:</h3>
          <ul>
            <li v-for="equip in currentSelectedEquipamentos" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>
        <v-col cols="6">
          <h3>Equipamentos Selecionados Agora:</h3>
          <ul>
            <li v-for="equip in newlySelectedEquipamentos" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <!-- Autocomplete para Seleção de Novos Equipamentos -->
      <v-row>
        <v-col cols="10">
          <v-autocomplete
            :loading="isLoading('get:equipamento')"
            v-model="selectedEquipamentos"
            :items="equipamentos"
            item-value="id"
            item-text="nome"
            label="Selecione os Equipamentos"
            multiple
            chips
            outlined
            clearable
            @change="updateNewlySelectedEquipamentos"
          />
        </v-col>
      </v-row>

      <!-- Status do Kit -->
      <v-row dense>
        <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
          <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
          <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
        </v-btn-toggle>
      </v-row>

      <!-- Botão de Salvar -->
      <v-row class="justify-end mt-0" dense>
        <v-col class="text-end">
          <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>


<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "EditarKit",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [
        (value) => !!value || "O nome do kit é obrigatório.",
        (value) => value.trim().length >= 3 || "O nome do kit deve ter pelo menos 3 letras.",
      ],
      equipamentos: [], // Lista de todos os equipamentos disponíveis
      selectedEquipamentos: [], // IDs dos equipamentos selecionados pelo usuário
      currentSelectedEquipamentos: [], // Equipamentos filhos já associados
      newlySelectedEquipamentos: [], // Novos equipamentos selecionados pelo usuário
    });
  },
  components: { ConfirmButton },
  methods: {
    async getEquipamento() {
      const LOADING_NAME = "get:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/equipamentos/nomeequipamentos/");
        this.equipamentos = data.filter((item) => item.ativo); // Carregar apenas os equipamentos ativos
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    initializeCurrentSelectedEquipamentos() {
      if (this.currentItem && this.currentItem.equipamento && this.currentItem.equipamentoNomes) {
        const ids = this.currentItem.equipamento; // IDs dos equipamentos
        const nomes = this.currentItem.equipamentoNomes.split(", "); // Divide os nomes

        // Mapeia IDs para objetos
        this.currentSelectedEquipamentos = ids.map((id, index) => ({
          id,
          nome: nomes[index] || "Equipamento indefinido",
        }));

        // Inicializa os IDs selecionados no autocomplete
        this.selectedEquipamentos = this.currentSelectedEquipamentos.map((equip) => equip.id);
      }
    },
    updateNewlySelectedEquipamentos() {
      // Mapeia todos os itens atualmente selecionados no autocomplete
      const allSelected = this.selectedEquipamentos
        .map((id) => this.equipamentos.find((equip) => equip.id === id))
        .filter(Boolean); // Remove itens indefinidos ou nulos

      // Atualiza os itens já selecionados que permanecem no autocomplete
      this.currentSelectedEquipamentos = allSelected.filter((equip) =>
        this.currentItem.equipamento.includes(equip.id)
      );

      // Identifica os novos itens selecionados que ainda não estão no kit
      this.newlySelectedEquipamentos = allSelected.filter(
        (equip) => !this.currentItem.equipamento.includes(equip.id)
      );

      // Atualiza o estado do `currentItem.equipamento` para refletir as mudanças
      this.currentItem.equipamento = this.selectedEquipamentos;
    },

    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      if (!this.currentItem.nome) {
        this.$toast.error("O KIT tem que ter um nome.");
        return;
      }
      if (!this.selectedEquipamentos.length) {
        this.$toast.error("O KIT tem que ter pelo menos um equipamento associado.");
        return;
      }

      // Combine os equipamentos existentes com os selecionados no combobox
      const combinedEquipamentos = [
        ...this.currentItem.equipamento, // IDs dos equipamentos já existentes
        ...this.selectedEquipamentos, // IDs dos equipamentos recém-selecionados
      ];

      // Remover duplicatas
      const uniqueEquipamentos = [...new Set(combinedEquipamentos)];

      // Cria o objeto fields com os IDs únicos dos equipamentos
      const fields = {
        id: this.currentItem.id,
        nome: this.currentItem.nome,
        ativo: this.currentItem.ativo,
        equipamento: uniqueEquipamentos, // Envia IDs únicos no payload
      };

      // Verifica os dados que serão enviados
      // console.log("Enviando --> ", fields);

      // Envia os dados ao backend
      this.onSubmit(fields);
    },

  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.getEquipamento(); // Carrega os equipamentos disponíveis
    this.initializeCurrentSelectedEquipamentos();
  },

};
</script>

<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Requisição" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Previsão da Implantação" disabled v-model="dataPrevista" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
    <v-data-table
        dense
        :loading="isLoadingPacientes"
        :headers="ItemPacientelHeaders"
        :items="sortedFilterPaciente"
        item-value="id"
        show-select
        v-model="selectedPacienteItems"
        class="elevation-1"
        :items-per-page="5"
        :selectable="isItemSelectable"
      >
        <template v-slot:[`item.esterilizavel`]="{ item }">
          {{ item.esterilizavel }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
        <template v-slot:[`item.data_validade`]="{ item }">
          {{ item.data_validade | formatDatabr }}
        </template>
        <template v-slot:[`item.qtdMarcada`]="{ item }">
          <span>{{ item.qtdMarcada !== null && item.qtdMarcada !== undefined ? item.qtdMarcada : 0 }}</span>
        </template>
        <template v-slot:[`item.correspondente`]="{ item }">
          <v-icon v-if="hasCorrespondingEsterilizavel(item)" color="green">mdi-check-circle</v-icon>
        </template>
      </v-data-table>

      </v-col>
    </v-row>

    <!-- Esterilizáveis -->
    <v-row dense>
      <v-card-title class="blod_color">Esterilizáveis Disponíveis:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-row dense>
  <v-col>
    <v-data-table
      dense
      :loading="isLoadingEsterilizavel"
      :headers="esterelizavelHeaders"
      :items="sortedEsterelizavel"
      item-value="id"
      class="elevation-1"
      :items-per-page="5"
      v-model="selectedEsterelizaveis"
    >
      <template v-slot:[`item.data_validade_esterilizacao`]="{ item }">
        <span>{{ item.data_validade_esterilizacao | formatDatabr }}</span>
      </template>
      <template v-slot:[`item.selecionado`]="{ item }">
        <v-icon v-if="isItemChecked(item)" color="green">mdi-check-circle</v-icon>
      </template>
    </v-data-table>
  </v-col>
</v-row>

      </v-col>
    </v-row>
    <!-- <v-row dense>
       <v-col cols="3">
        <v-text-field dense outlined label="Data da Troca"  v-model="dataTrocaEfetivada" type="date" :rules="requiredField" ></v-text-field>
      </v-col>
    </v-row> -->

    <v-row class="justify-end align-center mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar </v-btn>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="d-flex align-center">
        <div class="align-self-top py-4" left top  :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" :disabled="!isSaveEnabled" > 
          <v-menu eager offset-x left offset-y top v-model="menu">
            <!-- <template v-slot:activator="{ on, attrs }" >
              <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on" :disabled="selectedEsterelizaveis.length === 0"> mdi-printer </v-icon>
            </template> -->
            <v-container class="container">
              <template >
                <div > 
                  <Relatorio ref="relatorioComponent" :paciente="this.selectedPacienteItems" :esterilizaveis="this.selectedEsterelizaveis" :pacienteSelecionado="resumoPacientes.find(p => p.id === pacienteSelecionado)" :dataEntrega="dataPrevista"> </Relatorio>
                </div>
              </template>
            </v-container>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="auto" class="d-flex align-center">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton 
                :loading="loading" 
                color="success"
                :disabled="!isSaveEnabled"
                :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
              >
                Salvar
              </ConfirmButton>
            </span>
          </template>
          <span>Selecione os itens correspondentes nas duas tabelas para habilitar o salvamento</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import Relatorio from '../../relatorios/entregaSuprimento/RequisicaoEsterilizacao.vue';

export default {
  name: "Requisicao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingEsterilizavel: false,
      isLoadingPacientes: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPrevista: "",
      dataTroca: "",
      // dataTrocaEfetivada: "",
      esterelizavel: [],
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      isLoadingDados: false,
      requiredField: [e => (e ? true : 'Obrigatório')],
      menu: false,
      isReportTriggered: false,
      ItemPacientelHeaders: [
       {
        text: "Selecionado",
        value: "correspondente",
        sortable: false,
        width: 30,
        align: 'center',
        render: (item) => {
            // Verifica se o item é selecionável
            const selecionavel = this.isItemSelectable(item);

            // Exibe "Sim" apenas se o item é selecionável, caso contrário "Não"
            return selecionavel ? "Sim" : "Não";
        }
    },
        { text: "Id Solicitação", value: "idEsterelizavelNovo" },
        { text: "Esterilizável", value: "esterilizavel" },
        // { text: "Validade da Esterilização", value: "data_validade" },
        // { text: "Quantidade Solicitada", value: "quantidade" },
        // { text: "Selecionado p Troca", value: "qtdMarcada" },
        
      ],
      esterelizavelHeaders: [
        { text: "Selecionado", value: "selecionado", sortable: false, width: 30, align: 'center'  },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome.nome" },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Deposito", value: "deposito.nome" },
        { text: "Validade da Esterilização", value: "data_validade_esterilizacao" },
      ],
    });
  },
  components: { ConfirmButton, Relatorio },
  methods: {
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        this.pacientes = response.data.filter(item => 
          item.data_requisicao && 
          item.data_prevista_implantacao && 
          item.ativo && 
          !item.data_implantacao &&
          item.disponibilidade.id === 3 &&
          item.requisicao
        );
        // console.log(this.pacientes)
        // Improved grouping logic
        const agrupamento = this.pacientes.reduce((acc, atual) => {
          const chave = `${atual.paciente.id}-${atual.data_requisicao.split('T')[0]}`;
          
          if (!acc[chave]) {
            // Initialize the group with the first item's data
            const data = new Date(atual.data_requisicao);
            data.setDate(data.getDate() + 1);
            const dataFormatada = data.toLocaleDateString('pt-BR');
            
            acc[chave] = {
              id: chave,
              pacienteId: atual.paciente.id,
              nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
              nomePaciente: atual.paciente.nome,
              data_nascimento: atual.paciente.data_nascimento,
              esterilizavel: [],
              data_requisicao: atual.data_requisicao,
              data_prevista_implantacao: atual.data_prevista_implantacao,
              _contagem: {}
            };
          }
          
          // Add the current item to the esterilizavel array
          const itemEsterilizavel = {
            id: atual.nome_esterilizavel.id,
            nome: atual.nome_esterilizavel.nome,
            IdEsterelizavel: atual.id,
            quantidade: 1
          };
          
          // Update count and add item if not already present
          const nomeEsterilizavel = atual.nome_esterilizavel.nome;
          acc[chave]._contagem[nomeEsterilizavel] = 
            (acc[chave]._contagem[nomeEsterilizavel] || 0) + 1;
          
          // Check if the item already exists in the array
          const itemExistente = acc[chave].esterilizavel.find(
            item => item.nome === nomeEsterilizavel
          );
          
          if (!itemExistente) {
            itemEsterilizavel.quantidade = acc[chave]._contagem[nomeEsterilizavel];
            acc[chave].esterilizavel.push(itemEsterilizavel);
          }
          
          return acc;
        }, {});

        // Convert to final array
        this.resumoPacientes = Object.values(agrupamento).map(item => ({
          id: item.id,
          pacienteId: item.pacienteId,
          nomePaciente: item.nomePaciente,
          data_nascimento: item.data_nascimento,
          nome: item.nome,
          esterilizavel: item.esterilizavel,
          data_requisicao: item.data_requisicao,
          data_prevista_implantacao: item.data_prevista_implantacao,
        }))
        .sort((a, b) => {
          const dataA = new Date(a.data_requisicao);
          const dataB = new Date(b.data_requisicao);
          return dataA - dataB;
        });

      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
    updatePacienteData() {
    this.selectedPacienteItems = [];
    
    const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
    );
    if (paciente) {
        this.dataRequisicao = paciente.data_requisicao || "";
        // this.dataTrocaEfetivada = paciente.data_implantacao || "";
        this.dataPrevista = paciente.data_prevista_implantacao || "";
        this.IDPaciente = paciente.pacienteId
      
        // Transformar os itens nome_esterilizavel em um formato adequado para a tabela
        // Adicionando um índice único para cada item
        // console.log(paciente.esterilizavel)
        this.filterPaciente = paciente.esterilizavel.map((item, index) => ({
            id: `${item.id}_${index}`, // Chave única combinando id e índice
            originalId: item.id,
            pacienteId: paciente.pacienteId,
            idEsterelizavelNovo: item.IdEsterelizavel,
            idEsterelizavel: item.IdEsterelizavel,
            esterilizavel: item.nome,
            quantidade: item.quantidade,
            qtdMarcada: 0
        }));
        // console.log('Paciente -> ', this.filterPaciente)
    } else {
        this.dataRequisicao = "";
        // this.dataTrocaEfetivada = "",
        this.dataPrevista = "";
        this.filterPaciente = [];
    }
    },
    async getEsterelizavel() {
      this.isLoadingEsterilizavel = true;
      try {
        const response = await api.get("equipamentos/esterelizavels/");
        const hoje = new Date();
        hoje.setHours(0, 0, 0, 0);
        this.esterelizavel = response.data
          .filter(item => {
            // Primeiro verifica disponibilidade
            if (item.disponibilidade?.id !== 6) return false;

            // Verifica se tem data de validade
            if (!item.data_validade_esterilizacao) return false;

            // Converte a data de validade para comparação
            const dataValidade = new Date(item.data_validade_esterilizacao);
            dataValidade.setHours(0, 0, 0, 0);

            // Retorna true apenas se a data de validade for maior que hoje
            return dataValidade > hoje;
          })
          .sort((a, b) => {
          // Primeiro comparamos os nomes dos itens
          const nomeA = a.nome?.nome?.toLowerCase() || '';
          const nomeB = b.nome?.nome?.toLowerCase() || '';
          
          if (nomeA !== nomeB) {
            return nomeA.localeCompare(nomeB);
          }

          // Se os nomes forem iguais, ordenamos por data de validade
          const dataA = a.data_validade_esterilizacao ? new Date(a.data_validade_esterilizacao) : new Date(9999, 11, 31);
          const dataB = b.data_validade_esterilizacao ? new Date(b.data_validade_esterilizacao) : new Date(9999, 11, 31);
          
          return dataA - dataB;
        });
        // console.log('Esterelizaveis -> ',  this.esterelizavel)
      } catch (error) {
        this.$toast.error(`Erro ao carregar esterilizáveis: ${error}`);
      } finally {
        this.isLoadingEsterilizavel = false;
      }
    },
    autoSelectEsterilizaveis(selectedItems) {
      if (!selectedItems || !selectedItems.length) return;

      this.selectedEsterelizaveis = []; // Limpa seleções anteriores

      selectedItems.forEach(pacienteItem => {
          const esterilizavelDisponivel = this.esterelizavel.find(
              item => item.nome.id === pacienteItem.originalId &&
              !this.selectedEsterelizaveis.some(selected => selected.id === item.id)
          );

          if (esterilizavelDisponivel) {
              this.selectedEsterelizaveis.push(esterilizavelDisponivel);
          }
      });
    },
    compareItems() {
      this.selectedPacienteItems.forEach(pacienteItem => {
          const itensCorrespondentes = this.selectedEsterelizaveis.filter(
              esterilizavel => esterilizavel.nome.id === pacienteItem.originalId
          );

          const quantidade = itensCorrespondentes.length;

          // Atualiza a quantidade marcada no paciente
          const itemToUpdate = this.filterPaciente.find(item => item.id === pacienteItem.id);
          if (itemToUpdate) {
              itemToUpdate.qtdMarcada = quantidade;
          }
      });
    },
    async atualizarDados() {
      this.isLoadingDados = true;
      try {
        // Limpar seleções e dados
        this.selectedPacienteItems = [];
        this.selectedEsterelizaveis = [];
        this.pacienteSelecionado = null;
        this.dataRequisicao = "";
        // this.dataTrocaEfetivada = "",
        this.dataPrevista = "",
        this.dataTroca = "";
        this.filterPaciente = [];

        // Recarregar todos os dados
        await Promise.all([
          this.getEsterelizavel(),
          this.getPacientes()
        ]);
      } catch (error) {
        this.$toast.error('Erro ao atualizar os dados');
        console.error(error);
      } finally {
        this.isLoadingDados = false;
      }
    },
    // async ItensRetiradosPacienteNoEsterelizavel() {
    //   try {
    //     // console.log('Dados coletados->',this.selectedPacienteItems)
    //     for (const item of this.selectedPacienteItems) {
    //       // const hoje = new Date();
    //       // const dataFormatada = hoje.getFullYear() + '-' + 
    //       //   String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
    //       //   String(hoje.getDate()).padStart(2, '0');
    //       const fields = {
    //         id: item.idEsterelizavelNovo,
    //         disponibilidade: 10, // necessita esterelizacao
    //         // data_troca: dataFormatada,
    //         // data_validade_esterilizacao: dataFormatada,
    //         // ativo: false
    //       };

    //       await this.onSubmit(fields);
    //     }
    //   } catch (error) {
    //     console.error('Erro ao atualizar itens do paciente:', error);
    //     this.$toast.error('Erro ao atualizar itens do paciente.');
    //   }
    // },
    async MudarDisponibilidadeEsterilizavel() {
      try {
        for (const item of this.selectedEsterelizaveis) {
          await this.onSubmit({
            id: item.id,
            disponibilidade: 13, // em trasito para entrega 
          });
        }
      } catch (error) {
        console.error('Erro ao atualizar esterilizáveis:', error);
        this.$toast.error('Erro ao atualizar esterilizáveis.');
      }
    }, 
    // async AdicionarEsterelizavelAoPaciente() {
    //   // console.log('entrou');
    //   try {
    //     const promises = this.selectedPacienteItems.map((item) =>
    //       api.put(`/pacientes/esterilizavel/${item.idEsterelizavel}/`, {
    //         id: item.idEsterelizavel,
    //         ativo: true,
    //         data_requisicao: this.dataRequisicao,
    //         data_prevista_implantacao: this.dataPrevista,
    //         data_implantacao: this.dataTrocaEfetivada,
    //       })
    //     );
    //     await Promise.all(promises);

    //     this.$toast.success('Itens do paciente atualizados com sucesso.');
    //   } catch (error) {
    //     console.error('Erro ao atualizar itens do paciente:', error);
    //     this.$toast.error('Erro ao atualizar itens do paciente.');
    //   }
    // },
    async AdicionarEsterelizavelAoPaciente() {
      try {
        const promises = [];
        const alreadyProcessed = new Set(); // Mantém um rastreamento dos esterilizáveis já processados

        // Itera pelos itens selecionados na tabela de pacientes
        this.selectedPacienteItems.forEach((pacienteItem) => {
          // Filtra os esterilizáveis correspondentes ao paciente atual
          const esterilizaveisCorrespondentes = this.selectedEsterelizaveis.filter(
            (esterilizavel) => esterilizavel.nome.id === pacienteItem.originalId
          );

          esterilizaveisCorrespondentes.forEach((esterilizavel) => {
            const uniqueKey = `${pacienteItem.pacienteId}-${esterilizavel.id}`;
            if (!alreadyProcessed.has(uniqueKey)) {
              // Adiciona ao conjunto de itens processados
              alreadyProcessed.add(uniqueKey);

              const payload = {
                paciente: pacienteItem.pacienteId,
                // nome_esterilizavel: pacienteItem.originalId,
                disponibilidade: 13,
                esterilizavel: esterilizavel.id,
                data_requisicao: this.dataRequisicao,
                data_prevista_implantacao: this.dataPrevista,
                // data_implantacao: this.dataTrocaEfetivada,
              };
              // Adiciona a chamada da API à lista de promessas
              promises.push(api.put(`/pacientes/esterilizavel/${pacienteItem.idEsterelizavel}/`, payload));
            }
          });
        });

        // Aguarda todas as promessas serem resolvidas
        await Promise.all(promises);

        this.$toast.success("Itens do paciente atualizados com sucesso.");
      } catch (error) {
        console.error("Erro ao atualizar itens do paciente:", error);
        this.$toast.error("Erro ao atualizar itens do paciente.");
      }
    },
    isItemSelectable(item) {
      // Permite desmarcar itens já selecionados
      if (this.selectedEsterelizaveis.some(selected => selected.id === item.id)) {
          return true;
      }

      // Certifica-se de que o item está na lista de "Esterilizáveis Disponíveis"
      const itemDisponivel = this.esterelizavel.find(esterilizavel => esterilizavel.id === item.id);

      if (!itemDisponivel) {
          return false;
      }

      // Certifica-se de que há pelo menos um paciente selecionado
      if (!this.selectedPacienteItems.length) {
          return false;
      }

      // Verifica se o esterilizável corresponde a um item requisitado pelo paciente
      const itemPacienteCorrespondente = this.selectedPacienteItems.find(
          pacienteItem => pacienteItem.originalId === item.nome.id
      );

      if (!itemPacienteCorrespondente) {
          return false;
      }

      // Conta quantos itens deste tipo já estão selecionados
      const itensSelecionadosDesseGrupo = this.selectedEsterelizaveis.filter(
          esterilizavel => esterilizavel.nome.id === item.nome.id
      ).length;

      // Só permite selecionar se a quantidade não exceder a requisitada
      return itensSelecionadosDesseGrupo < itemPacienteCorrespondente.quantidade;
    },
    isItemChecked(item) {
      return this.selectedEsterelizaveis.some(selected => selected.id === item.id);
    },
     hasCorrespondingEsterilizavel(item) {
      // Verifica se o item está selecionado na tabela de pacientes
      const itemEstaSelecionado = this.selectedPacienteItems.some(
          selected => selected.id === item.id
      );

      if (!itemEstaSelecionado) {
          return false;
      }

      // Verifica se existe um esterilizável correspondente selecionado
      const temEsterilizavelCorrespondente = this.selectedEsterelizaveis.some(
          esterilizavel => esterilizavel.nome.id === item.originalId
      );

      // Retorna true somente se o item estiver selecionado e tiver correspondente
      return itemEstaSelecionado && temEsterilizavelCorrespondente;
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      this.isReportTriggered = true;
      await this.MudarDisponibilidadeEsterilizavel();
      await this.AdicionarEsterelizavelAoPaciente();
      this.$toast.success('Pacientes atualizados com sucesso!');
      
    },
    generateReport() {
      if (this.selectedPacienteItems.length > 0 ) {
        this.$toast.success("Gerando relatório...");
        // Chama o componente ou função de geração do relatório
        this.$refs.relatorioComponent.generatePDF(this.selectedPacienteItems );
      } else {
        this.$toast.error("Nenhum item selecionado para o relatório.");
      }
    },
  },
  computed: {
    isSaveEnabled() {
      if (!this.selectedPacienteItems.length || !this.selectedEsterelizaveis.length) {
          return false;
      }
      // if (!this.dataTrocaEfetivada) {
      //     return false;
      // }

      for (const pacienteItem of this.selectedPacienteItems) {
          const temEsterilizavelCorrespondente = this.selectedEsterelizaveis.some(
              esterilizavel => esterilizavel.nome.id === pacienteItem.originalId
          );

          if (!temEsterilizavelCorrespondente) {
              return false;
          }
      }

      return true;
    },
    sortedEsterelizavel() {
        if (!this.esterelizavel) return [];
        
        return [...this.esterelizavel].sort((a, b) => {
            // Verifica se os itens estão selecionados
            const aSelected = this.selectedEsterelizaveis.some(item => item.id === a.id);
            const bSelected = this.selectedEsterelizaveis.some(item => item.id === b.id);

            // Se um está selecionado e outro não, o selecionado vem primeiro
            if (aSelected && !bSelected) return -1;
            if (!aSelected && bSelected) return 1;

            // Se ambos estão selecionados ou não selecionados, mantém a ordem original
            // Primeiro por nome
            const nomeA = a.nome?.nome?.toLowerCase() || '';
            const nomeB = b.nome?.nome?.toLowerCase() || '';
            
            if (nomeA !== nomeB) {
                return nomeA.localeCompare(nomeB);
            }

            // Se os nomes forem iguais, ordena por data de validade
            const dataA = a.data_validade_esterilizacao ? new Date(a.data_validade_esterilizacao) : new Date(9999, 11, 31);
            const dataB = b.data_validade_esterilizacao ? new Date(b.data_validade_esterilizacao) : new Date(9999, 11, 31);
            
            return dataA - dataB;
        });
    },
    sortedFilterPaciente() {
      if (!this.filterPaciente) return [];
      
      return [...this.filterPaciente]
      .filter( item => item.disponibilidade !== 13)
      
      .sort((a, b) => {
        // Verifica se os itens estão selecionados
        const aSelected = this.selectedPacienteItems.some(item => item.id === a.id);
        const bSelected = this.selectedPacienteItems.some(item => item.id === b.id);

        // Se um está selecionado e outro não, o selecionado vem primeiro
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;

        // Se ambos estão selecionados ou não selecionados, mantém a ordem alfabética
        return a.esterilizavel.localeCompare(b.esterilizavel);
      });
    },
  },
  watch: {
    selectedPacienteItems: {
      handler(newValue, oldValue) {
          if (!newValue || !oldValue) return;

          // Identifica os itens que foram desmarcados
          const desmarcados = oldValue.filter(old => 
              !newValue.some(current => current.id === old.id)
          );

          // Para os itens desmarcados, remove os esterilizáveis correspondentes
          if (desmarcados.length > 0) {
              desmarcados.forEach(itemDesmarcado => {
                  this.selectedEsterelizaveis = this.selectedEsterelizaveis.filter(
                      esterilizavel => esterilizavel.nome.id !== itemDesmarcado.originalId
                  );
                  
              });
          }

          // Se houver novos itens selecionados, faz a seleção automática
          if (newValue.length > 0) {
              this.autoSelectEsterilizaveis(newValue);
          }

          // Atualiza as quantidades marcadas
          this.filterPaciente.forEach(item => {
              const isSelected = newValue.some(selected => selected.id === item.id);
              item.qtdMarcada = isSelected ? 1 : 0;
          });
      },
      deep: true
    },
     isReportTriggered(newValue) {
      if (newValue) {
        this.generateReport();
        this.isReportTriggered = false; // Reseta o flag
      }
    }
  },
  mounted() {
    this.getEsterelizavel();
    this.getPacientes();
    // const hoje = new Date();
    // const dataFormatada = hoje.getFullYear() + '-' + 
    //     String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
    //     String(hoje.getDate()).padStart(2, '0');
    // this.dataTrocaEfetivada = dataFormatada
  },
};
</script>

<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >


    <v-row dense>
      <v-col>
        <v-data-table  dense  :loading="isLoadingUsuarios"  :headers="headers"  :items="sortedUsuarios"  item-value="id"  v-model="selectedUsuarioItems"  class="elevation-1"  :items-per-page="5"   >
        <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-badge left bordered overlap :content="activeFilters.length">
                <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                <v-icon left>
                    mdi-filter
                </v-icon>
                Filtros
                </v-btn>
            </v-badge>
            <v-btn class="mr-4 elevation-0" text @click="atualizar" :loading="isLoadingDados"> <v-icon left>mdi-reload</v-icon> Atualizar  </v-btn>
            <v-btn color="success" class="elevation-0" @click="abrirModalNovo" > <v-icon left> mdi-plus </v-icon>  Novo </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card outlined v-show="showFilters" dense>
          <h4 class="ml-4 mt-4">Filtrar por</h4>
          <v-row dense class="py-2 px-4">
              <v-col cols="2">
              <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                  v-model="filters.is_active.value" outlined dense />
              </v-col>
          </v-row>
          <v-card-actions>
              <v-row dense>
              <v-col class="text-end">
                  <v-btn color="error" text elevation="0" @click="clearFilters">
                  Limpar filtros
                  </v-btn>
                  <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                  Aplicar filtros
                  </v-btn>
              </v-col>
              </v-row>
          </v-card-actions>
          </v-card>
      </v-expand-transition>
      </template>
          <template v-slot:[`item.data_entrega`]="{ item }">
              {{ item.data_entrega | formatDatabr }}
          </template>
          <template v-slot:[`item.data_devolucao`]="{ item }">
              {{ item.data_devolucao | formatDatabr }}
          </template>
          <template v-slot:[`item.especialidade`]="{ item }">
              {{ item.usuario.especialidade ? item.usuario.especialidade.nome : ''}}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
              <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                  <span class="white--text">{{ item.ativo ? 'Em Uso' : 'Devolvido' }}</span>
              </v-chip>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRows(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
          </template>
        </v-data-table>
        
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton  :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" >  Salvar  </ConfirmButton>
            </span>
          </template>
          <span>Selecione pelo menos um item e uma disponibilidade para salvar</span>
        </v-tooltip>
      </v-col>
    </v-row>

<!-- Edição -->
    <v-dialog v-model="dialog.update" max-width="1200px">
      <v-card>
        <v-card-title class="blod_color">
          Devolução de Equipamento do Usuário: ID - {{selectedItem?.usuario?.id}}, Nome - {{selectedItem?.usuario?.apelido}} - {{selectedItem?.usuario?.especialidade?.nome }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="1">
                <v-text-field  dense  outlined  :value="selectedItem?.id || ''"  label="Id"  disabled readonly/>
              </v-col>
              <v-col cols="2">
                <v-text-field  dense  outlined  :value="selectedItem?.equipamento?.id || ''"  label="Equipamento"  disabled readonly/>
              </v-col>
              <v-col cols="4">
                <v-text-field   dense  outlined  :value="selectedItem?.equipamento?.descricao?.nome || ''"  label="Equipamento"  readonly />
              </v-col>
              <v-col cols="3">
                <v-text-field  dense  type="date"  outlined  label="Data de Devolução"  v-model="formUpdate.data_devolucao"  required  clearable/>
              </v-col>
              <v-col cols="2">
                <v-btn-toggle v-model="formUpdate.ativo" mandatory class="white--text">
                  <v-btn :value="false" :color="!formUpdate.ativo ? 'error' : ''">
                    Devolver
                  </v-btn>
                  <v-btn :value="true" :color="formUpdate.ativo ? 'green' : ''">
                    Em Uso
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="success" @click="updateRegistro" :loading="loadingUpdate" :disabled="!formUpdate.data_devolucao" > Salvar </v-btn> -->
           <v-btn   color="success"    @click="showConfirmDialog2"   :loading="loadingUpdate"   > Salvar <v-dialog  persistent   max-width="290"  v-model="confirmDialogOpen2" >
              <v-card>
                <v-card-title>Atenção</v-card-title>
                <v-card-text>
                  Tem certeza que deseja salvar esse novo registro?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="confirmDialogOpen2 = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="green" text @click="confirmSalvarupdateRegistro">
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!-- Novo Registro -->
    <v-dialog v-model="dialog.novo" max-width="1200px">
      <v-card>
        <v-card-title class="blod_color">
          Nova Entrega de Equipamento:
           <v-spacer></v-spacer>
           <v-btn icon @click="dialog.novo = false">
                <v-icon>
                mdi-close
                </v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5">
                <v-autocomplete :loading="isLoadingUsuarioLista" v-model="novoRegistro.usuario" :items="usuarioFormatados" item-text="nome_formatado_usuario" item-value="id" label="Usuário" required dense outlined ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete :loading="isLoadingEquipamentoLista" v-model="novoRegistro.equipamento" :items="equipamentoFormatados" item-text="nome_formatado" item-value="id" label="Equipamento" required dense outlined ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field dense  label="Data da Entrega" type="date" outlined clearable v-model="novoRegistro.data_entrega"/>  
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <div class="align-self-top py-4" left top   > 
              <v-menu eager offset-x left offset-y top v-model="menu">
                <!-- <template v-slot:activator="{ on, attrs }"  >
                  <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on" :disabled="!novoRegistro.usuario || !novoRegistro.equipamento || !novoRegistro.data_entrega"> mdi-printer </v-icon>
                </template> -->
                <v-container class="container">
                  <template >
                    <div > 
                      <Relatorio ref="relatorioComponent" :usuario="novoRegistro.usuario" :equipamento="novoRegistro.equipamento" :dataEnvio="novoRegistro.data_entrega" > </Relatorio>
                    </div>
                  </template>
                </v-container>
              </v-menu>
            </div>
          </v-col>
          <v-btn   color="success"    @click="showConfirmDialog"   :loading="loadingSave"   :disabled="!novoRegistro.usuario || !novoRegistro.equipamento || !novoRegistro.data_entrega" > Salvar <v-dialog  persistent   max-width="290"  v-model="confirmDialogOpen" >
              <v-card>
                <v-card-title>Atenção</v-card-title>
                <v-card-text>
                  Tem certeza que deseja salvar esse novo registro?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="confirmDialogOpen = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="green" text @click="confirmSalvarNovoRegistro">
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import Relatorio from "../../relatorios/entregaSuprimento/EntregaUsuarioEquipamento.vue";

export default {
  name: "UsuarioEquipamento",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingUsuarios: false,
      isLoadingDisponibilidade: false, 
      isLoadingUsuarioLista: false, 
      isLoadingEquipamentoLista: false, 
      isLoadingDados: false,
      isSaveEnabled: false,
      loadingUpdate: false,
      selectedUsuarioItems: [], // Alterado de string para array
      usuarios: [],
      disponibilidade: [],
      dialog: { update: false, novo: false,  },
      novoRegistro: {
        usuario: null,
        equipamento: null
      },
      equipamentoLista: [],
      usuaioFormatados: [],
      usuariosLista: [],
      loadingSave: false,
      selectedItem: null,
      formUpdate: {
        data_devolucao: null,
        ativo: true
      },
      dataOriginal: null,
      confirmDialogOpen: false,
      confirmDialogOpen2: false,
      menu: false,
      isReportTriggered: false,
      filters: {
      is_active: { value: true, compareType: 'equal', prop: 'ativo' }},
      activeFilters: [],
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false }
      ],
      showFilters: false,
      search: '',
      headers: [
        { text: "Editar", value: "edit" },
        { text: "ID", value: "id" },
        { text: "ID do Usuário", value: "usuario.id" },
        { text: "Usuário", value: "usuario.apelido" },
        { text: "Especialidade", value: "especialidade" },
        { text: "ID do Equipamento", value: "equipamento.id" },
        { text: "Equipamento", value: "equipamento.descricao.nome" },
        { text: "Data Entrega", value: "data_entrega" },
        { text: "Data Devolução", value: "data_devolucao" },
        { text: "Status", value: "ativo" },
      ],
    });
  },
  components: { ConfirmButton , Relatorio },

  methods: {
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async getUsuario() {
      this.isLoadingUsuarios = true;
      try {
        const response = await api("equipamentos/equipamento_usuarios/");
        this.usuarios = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        this.$toast.error(`Erro ao carregar usuários. ${error}`);
      } finally {
        this.isLoadingUsuarios = false;
      }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      
      if (!Array.isArray(this.selectedPacienteItems)) {
        this.$toast.error("Nenhum item selecionado");
        return;
      }

      const Itens = this.selectedPacienteItems.map(item => {
        const fields = {
          id: item.equipamento.id,
          disponibilidade: 6,
          idequipamentoPaciente: item.id
        };

        // const hoje = new Date();
        // const dataFormatada = hoje.getFullYear() + '-' + 
        //   String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        //   String(hoje.getDate()).padStart(2, '0');
          
        // if (this.currentItem.disponibilidade === 10) {
        //   fields.data_validade_esterilizacao = dataFormatada;
        // }
          
        return fields;
      });

      for (const fields of Itens) {
        try {
          await this.onSubmit(fields);
          await this.updatePacienteEsterelizavel([fields.idequipamentoPaciente]);
        } catch (error) {
          console.error("Erro ao salvar os dados principais:", error);
          this.$toast.error("Erro ao salvar os dados principais.");
        }
      }
    },
    atualizar() {
      this.getUsuario();
      this.getDisponibilidade();
      this.clearFilters();
      this.showFilters = false;
      this.search = '';

    },
    async getUsuariosLista() {
      this.isLoadingUsuarioLista = true
      try {
        const response = await api.get('/contas/usuarios/?ativo=True&pesquisar=false');  
        // console.log(response)
        this.usuariosLista = response.data
        // .filter(item => item.especialidade && item.especialidade.id !== 10)
          .sort((a, b) => {
                    const nomeA = a.apelido?.apelido?.toLowerCase() || '';
                    const nomeB = b.apelido?.apelido?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
        // console.log(this.usuariosLista)
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de usuários: ${error}`);
      } finally {
        this.isLoadingUsuarioLista = false
      }
    },
    async getEquipamentoLista() {
      this.isLoadingEquipamentoLista = true
      try {
        const response = await api.get('equipamentos/equipamentos/');  // Ajuste a rota conforme sua API
        this.equipamentoLista = response.data
          .filter(item => item.ativo && item.disponibilidade?.id === 6)
          .sort((a, b) => {
                    const nomeA = a.descricao?.nome?.toLowerCase() || '';
                    const nomeB = b.descricao?.nome?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
        // console.log( this.equipamentoLista)
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de equipamento: ${error}`);
      } finally {
        this.isLoadingEquipamentoLista = false
      }
    },
    async abrirModalNovo() {
      this.dialog.novo = true;
      
      // Atualiza as listas
      await Promise.all([
        this.getUsuariosLista(),
        this.getEquipamentoLista()
      ]);
    },
    fecharModal() {
      this.dialog.novo = false;
      this.novoRegistro = {
        usuario: null,
        equipamento: null
      };
    },
    async applyFilters() {
        this.search = '';
        const filters = JSON.parse(JSON.stringify(this.filters));
        this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
    },
    clearFilters() {
        Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '');
        this.filters = {
            is_active: { value: true, compareType: 'equal', prop: 'ativo' }
        };
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
    },
    async salvarNovoRegistro() {
      if (!this.novoRegistro.usuario || !this.novoRegistro.equipamento) {
        this.$toast.error("Preencha todos os campos obrigatórios");
        return;
      }

      // Encontra o esterilizável selecionado na lista
      const equipamentoSelecionado = this.equipamentoLista.find(
        item => item.id === this.novoRegistro.equipamento
      );
      const hoje = new Date();
      const dataFormatada = hoje.getFullYear() + '-' + 
          String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
          String(hoje.getDate()).padStart(2, '0');

      this.loadingSave = true;
      // Primeiro altera Paciente
      try {
        await api.post('/equipamentos/equipamento_usuarios_criar/', {
          usuario: this.novoRegistro.usuario,
          equipamento: this.novoRegistro.equipamento,
          nome_equipamento: equipamentoSelecionado?.descricao?.id,
          disponibilidade: 1,
          data_requisicao: dataFormatada,
          data_entrega: dataFormatada,
          ativo: true
        });
        // Agora altera equipamento
        await api.put(`/equipamentos/equipamento_editar/${this.novoRegistro.equipamento}/`, {
          // id: this.novoRegistro.equipamento,
          disponibilidade: 1,
        });

        this.$toast.success("Registro criado com sucesso!");
        this.dialog.novo = false
        this.fecharModal();
        this.getUsuario(); // Atualizando a lista após salvar
      } catch (error) {
        this.$toast.error(`Erro ao salvar: ${error}`);
      } finally {
        this.loadingSave = false;
      }
    },
    async updateRegistro() {
      if (!this.formUpdate.data_devolucao && this.formUpdate.ativo === false) {
        this.$toast.error("Informe a data de devolução e o status");
        return;
      }

      this.loadingUpdate = true;
      try {
        // Altera o usuario
        await api.put(`/equipamentos/equipamento_usuario/${this.selectedItem.id}/`, {
          data_devolucao: this.formUpdate.data_devolucao,
          ativo: this.formUpdate.ativo
        });
        
        // Agora altera equipamento
        const status = this.formUpdate.ativo ? 1 : 6 // o status 1 disponivel e 10 necessita esterelizar sem alterara a data de validade da esterelizacao
        await api.put(`/equipamentos/equipamento_editar/${this.selectedItem.equipamento.id}/`, {
          id:this.selectedItem.equipamento.id,
          disponibilidade: status,
        });

        this.$toast.success("Registro alterado com sucesso!");
        this.dialog.update = false
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar: ${error}`);
      } finally {
        this.loadingUpdate = false;
      }
    },
    onSelectRows(item, type) {
      if (type === 'update') {
        this.selectedItem = item;
        this.dataOriginal = item.data_devolucao;  // Guarda a data original
        this.formUpdate = {
          data_devolucao: item.data_devolucao || null,
          ativo: item.ativo
        };
        this.dialog.update = true;
      }
    },
    showConfirmDialog() {
      if (this.novoRegistro.usuario && this.novoRegistro.equipamento) {
        this.confirmDialogOpen = true;
      }
    },
    showConfirmDialog2() {
      if ((this.formUpdate.ativo && !this.formUpdate.data_devolucao) || (!this.formUpdate.ativo && this.formUpdate.data_devolucao)) {
        this.confirmDialogOpen2 = true;
      }
    },
    confirmSalvarNovoRegistro() {
      this.confirmDialogOpen = false;
      this.salvarNovoRegistro();
      this.isReportTriggered = true;
    },
    confirmSalvarupdateRegistro() {
      this.confirmDialogOpen2 = false;
      this.updateRegistro();
    },
    generateReport() {
      if (this.novoRegistro.usuario > 0 ) {
        this.$toast.success("Gerando relatório...");
        // Chama o componente ou função de geração do relatório
        this.$refs.relatorioComponent.generatePDF(this.novoRegistro.usuario);
      } else {
        this.$toast.error("Nenhum item selecionado para o relatório.");
      }
    },
  },
    computed: {
      sortedUsuarios() {
        if (!this.usuarios || !Array.isArray(this.usuarios)) {
          return [];
        }

        // Aplica filtros ativos
        let usuariosFiltrados = this.usuarios;
        if (this.activeFilters.length > 0) {
          const filterBy = UtilsFunc.createFilter(this.activeFilters);
          usuariosFiltrados = usuariosFiltrados.filter(filterBy);
        }

        // Aplica o termo de busca
        if (this.search) {
          const searchTerm = this.search.toLowerCase().trim();
          usuariosFiltrados = usuariosFiltrados.filter(item => {
            const usuarioNome = item.usuario?.apelido?.toLowerCase() || '';
            const especialidadeNome = item.usuario?.especialidade?.nome?.toLowerCase() || '';
            const equipamentoNome = item.equipamento?.nome?.nome?.toLowerCase() || '';
            const id = item.id?.toString() || '';
                  const equipamentoId = item.equipamento?.id?.toString() || '';

            return (
              usuarioNome.includes(searchTerm) ||
              especialidadeNome.includes(searchTerm) ||
              equipamentoNome.includes(searchTerm) ||
              id.includes(searchTerm) ||
              equipamentoId.includes(searchTerm)
            );
          });
        }

        // Separa usuários ativos e inativos
        const usuariosAtivos = usuariosFiltrados.filter(user => user.ativo);
        const usuariosInativos = usuariosFiltrados.filter(user => !user.ativo);

        // Ordenação por apelido
        const ordenarPorApelido = (a, b) => {
          const apelidoA = (a.usuario?.apelido || '').toLowerCase();
          const apelidoB = (b.usuario?.apelido || '').toLowerCase();
          return apelidoA.localeCompare(apelidoB);
        };

        // Ordena os dois arrays
        const usuariosAtivosOrdenados = usuariosAtivos.sort(ordenarPorApelido);
        const usuariosInativosOrdenados = usuariosInativos.sort(ordenarPorApelido);

        // Concatena usuários ativos seguidos pelos inativos
        return [...usuariosAtivosOrdenados, ...usuariosInativosOrdenados];
    },
    equipamentoFormatados() {
      return this.equipamentoLista.map(item => ({
        ...item,
        nome_formatado: `ID: ${item.id} - ${item.descricao.nome}`
      }));
    },
    usuarioFormatados() {
      if (!this.usuariosLista) return [];
      
      return this.usuariosLista.map(item => {
        // Ajuste estes campos de acordo com a estrutura real dos seus dados
        const id = item.id || '';
        const nome = item.apelido || '';
        const especialidade = item.especialidade?.nome || 'Sem especialidade';
        
        return {
          ...item,
          nome_formatado_usuario: `ID: ${id} - ${nome} - ${especialidade}`
        };
      });
    }
  },
  mounted() {
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.novoRegistro.data_entrega = dataFormatada
    this.applyFilters();
    this.getDisponibilidade();   
    this.getUsuario();
    this.getUsuariosLista();
    this.getEquipamentoLista();
  },
  watch: {
    selectedUsuarioItems: {
      handler(newVal) {
        this.isSaveEnabled = Array.isArray(newVal) && newVal.length > 0;
      },
      immediate: true
    },
    'formUpdate.ativo': {
      handler(newValue) {
        if (newValue === false) {  // Mudou para Devolver
          if (this.dataOriginal) {  // Se tinha uma data original
            this.formUpdate.data_devolucao = this.dataOriginal;  // Restaura a data original
          } else {  // Se não tinha data
            const hoje = new Date();
            const dataFormatada = hoje.getFullYear() + '-' + 
              String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
              String(hoje.getDate()).padStart(2, '0');
            this.formUpdate.data_devolucao = dataFormatada;
          }
        } else {  // Mudou para Em Uso
          this.formUpdate.data_devolucao = null;  // Limpa a data
        }
      }
    },
    isReportTriggered(newValue) {
      if (newValue) {
        this.generateReport();
        this.isReportTriggered = false; // Reseta o flag
      }
    }
  }
};
</script>
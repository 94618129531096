<template>
  <div class="px-4">
    <!-- Parte Superior: Local para Carregar o documento com o tipo -->
    <h2 class="blod_color">Carregar documentos:</h2>
    <v-row dense class="my-3">
      <v-col md="3">
        <v-autocomplete outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id" >
          <template v-slot:label>
            <span>Tipo de documento <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="4">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput" >
          <template v-slot:label>
            <span>Anexar exame / Parecer / Relatório externo / ETC <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
      <v-col md="3">
        <v-tooltip top :disabled="isFormValid">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading" :disabled="!isFormValid || isUploading">
                <v-icon left>mdi-upload</v-icon>
                Enviar documento
              </v-btn>
            </span>
          </template>
          <span>Escolha o "Tipo de documento" e carregue o arquivo para habilitar este botão.</span>
        </v-tooltip>
      </v-col>
      <v-col md="2" >
        <v-btn class="mr-4 elevation-0" text @click="atualizar" >
          <v-icon left>
            mdi-reload
          </v-icon> 
          atualizar página
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-progress-linear v-if="isUploading" :indeterminate="true" color="blue"></v-progress-linear> -->
    <!-- Parte inferior: listar e baixar documentos -->
    <h2 class="blod_color mt-5 mb-5">Documentos carregados:</h2>
        <v-list dense>
          <v-progress-linear v-if="isUploading" :indeterminate="true" color="blue" loading-text="Carregando Informações...."></v-progress-linear>
          <v-list-item-group v-for="(exame, index) in uploadedDocuments" :key="index">
            <v-list-item class="my-2">
              <v-list-item-content>
                <v-list-item-title class="mb-2"> 
                  <span class="font-weight-bold text-primary blod_color ">{{exame.tipodoc.nome}}</span> 
                  <br>
                  <span>{{ extractFileName(exame.arquivo) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Carregado em: {{ formatDate(exame.data_carregado) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small color="green" class="white--text" :href="exame.arquivo" target="_blank">
                  <v-icon left>mdi-download</v-icon>
                  Baixar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
      </v-list>
  </div>
</template>


<script>
import '../../../../assets/css/styles.css';
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
  data: () =>  withCRUDUtils({
      selectedDocumentType: null,
      documentTypes: [],
      uploadedDocuments: [],
      isUploading: false,
      isActive: true,
      selectedFile: null,
  }),
  props:{
    pacienteId:{type:Number}
  },
  methods: {
    //Para pegar os nomes dos documentos ativos 
    async getDocumentTypes() {
      const LOADING_NAME = "get:documentTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tiposdocs/");
        this.documentTypes = data.filter(doctype => doctype.ativo); 
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Metodo para fazer o uploading do documento
    async uploadDocument() {

      if (!this.selectedDocumentType || !this.selectedFile) {
          this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
          return;
      }

      this.isUploading = true;

      let formData = new FormData();
      formData.append("arquivo", this.selectedFile);
      formData.append("tipodoc", this.selectedDocumentType);
      formData.append("ativo", this.isActive);
      formData.append("paciente", this.pacienteId);
      const currentDate = new Date().toISOString().split('T')[0];
      formData.append("data_carregado", currentDate);
      this.ativo = true

      try {
          await api.post("/pacientes/exames/", formData);
          this.$toast.success("Documento carregado com sucesso!");
          this.selectedFile = null; 
          this.selectedDocumentType = null;
          this.fetchUploadedDocuments();
          
      } catch (error) {
          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
          this.isUploading = false;
      }
    },
  // Pegar os documentos do back
    async fetchUploadedDocuments() {
      this.isUploading = true;
      try {
        const { data } = await api.get(`/pacientes/exames/?paciente=${this.pacienteId}`);
        this.uploadedDocuments = data;
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os documentos. Erro: ${error}`);
      } finally {
          this.isUploading = false;
      } 
    },
    // Pega os arquivos e mostra mna parte inferior da tela os arquivos
    async loadUploadedDocuments() {
      try {
        const { data } = await api.get(`/pacientes/exames/?paciente=${this.pacienteId}`);
        this.uploadedDocuments = data;
      } catch (error) {
        this.$toast.error(`Erro ao carregar documentos: ${error}`);
      }
    },
    // Retira o caminho do nome do arquivo 
    extractFileName(url) {
      if (url) {
        return url.split('/').pop();
      }
      return "";
    },
    // Verifica o tamnaho do doc se ultrapasa 1MB 
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 2000000) { // maior que 2MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (2MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
    atualizar() {
      this.fetchUploadedDocuments();
      this.loadUploadedDocuments();
    },
  },
  created() {
    this.getDocumentTypes();
  },
  mounted(){
    this.fetchUploadedDocuments();
    this.loadUploadedDocuments();
  },
  computed: {
    isFormValid() {
      return this.selectedDocumentType && this.selectedFile;
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
};
</script>

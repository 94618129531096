<template>
  <v-container class="container">
    <v-btn color="success" depressed size="small" @click="generatePDF">
      <v-icon left>mdi-printer</v-icon> Rel. Devolução do Equipamento para Locadora
    </v-btn>
    <!-- {{equipamento}} -->
  </v-container>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Requisicao",
  data: () => withCRUDUtils({
    loading: false,
  }),
  props: {
    // usuario: { Number },
    equipamento: { Object }, 
    dataEnvio: {Date},
  },

  methods: {
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
        
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-91):", error);
      }

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Devolução de Equipamento Locado`, 105, 28, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);
    
    },

    async generatePDF() {
      if (!this.dataEnvio) {
        this.$toast.error("Data de envio não foi fornecida. O relatório não pode ser gerado.");
        return;
      }
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      yPosition += lineHeight * 2;
      const blueColor = [57, 106, 159];
      doc.setFontSize(14);
      
      yPosition += lineHeight * 2;
      
      doc.setFont("helvetica", "bold");
      const currentDate = new Date();
      const dataentrega = this.dataEnvio ? this.dataEnvio : currentDate
      doc.text(`Empresa Locadora: ${this.equipamento?.fornecedor.nome_fantasia}`, 100, 45, { align: "center" });
      
      yPosition += lineHeight * 2;
      doc.setFontSize(12);
      
      doc.text(`Data da Entrega: ${UtilsPDF.formatDate(dataentrega)}`, 100, 55, { align: "center" });

      yPosition += lineHeight * 2;
      doc.setFont("helvetica", "bold");
      const equipamentos = Array.isArray(this.equipamento) ? this.equipamento : [this.equipamento];
      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
        },
        head: [['Id', 'Equipamento', 'Patrimônio', 'Data Validade Calibração',  'Data Início Locação', 'Entregue?']],
        body: equipamentos.map(item => [
          item.equipamento?.id,
          item.equipamento?.descricao?.nome,
          item.equipamento?.patrimonio,
          UtilsPDF.formatDate(item.equipamento?.data_validade_calibracao),
          UtilsPDF.formatDate(item.dataContratacao)
        ]),
        columnStyles: {
          0: { cellWidth: 10 }, // Define a largura da primeira coluna (Profissional)
        },
      });

      const tableHeight2 = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight2 + lineHeight + 12;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Recebedor: ___________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Data do Recebimento: ____ / ____ / ______", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Nome: _______________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("CPF ou RG: __________________________________", 15, yPosition);
      yPosition += lineHeight * 2;   
    

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório de Devolução de Equipamento Locado.pdf`;
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
   
    

  },
};
</script>
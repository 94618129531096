<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef" :loading="loading">
    <v-container>
      <v-row>
        <!-- Coluna para equipamentos já associados -->
        <v-col cols="6">
          <h3>Equipamentos Dependentes Já Selecionados:</h3>
          <ul>
            <li v-for="equip in currentSelectedEquipamentos" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>

        <!-- Coluna para novos equipamentos selecionados -->
        <v-col cols="6">
          <h3>Equipamentos Dependetes Selecionados Agora:</h3>
          <ul>
            <li v-for="equip in newlySelectedEquipamentos" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <v-autocomplete
            :loading="isLoading('get:equipamento')"
            v-model="selectedEquipamentos"
            :items="equipamentos"
            item-value="id"
            item-text="nome"
            label="Selecione os Equipamentos"
            multiple
            chips
            outlined
            clearable
            @change="updateNewlySelectedEquipamentos"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <!-- Coluna para esterilizável já associados -->
        <v-col cols="6">
          <h3>Esterilizáveis Dependentes Já Selecionados:</h3>
          <ul>
            <li v-for="equip in currentSelectedEsterilizáveis" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>

        <!-- Coluna para novos esterilizáveis selecionados -->
        <v-col cols="6">
          <h3>Esterilizáveis Dependetes Selecionados Agora:</h3>
          <ul>
            <li v-for="equip in newlySelectedEsterilizáveis" :key="equip.id">
              {{ equip.nome }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10">
          <v-autocomplete
            :loading="isLoading('get:esterilizavel')"
            v-model="selectedEsterilivaeis"
            :items="esterilizavel"
            item-value="id"
            item-text="nome"
            label="Selecione os Esterelizável"
            multiple
            chips
            outlined
            clearable
            @change="updateNewlySelectedEsterilizaveis"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end mt-3">
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text mr-10">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
          </v-btn-toggle>
          <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
        </v-col>

      </v-row>

    <v-row class="justify-end mt-0" dense>
    </v-row>
    </v-container>
  </v-form>
</template>





<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "EditarCadastrarEquipamentoDependente",
  components: { ConfirmButton },
  props: {
    currentItem: Object, // Dados do equipamento pai com filhos
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      equipamentos: [], // Lista de todos os equipamentos disponíveis
      esterilizavel: [],
      selectedEquipamentos: [], // IDs dos equipamentos selecionados pelo usuário
      selectedEsterilivaeis: [], // IDs dos equipamentos selecionados pelo usuário
      currentSelectedEquipamentos: [], // Equipamentos filhos já associados
      currentSelectedEsterilizáveis: [], // Equipamentos filhos já associados
      newlySelectedEquipamentos: [], // Novos equipamentos selecionados pelo usuário
      newlySelectedEsterilizáveis: [], // Novos equipamentos selecionados pelo usuário
      // isLoadingEquipamentos: false,
    });
  },
  methods: {
    // Busca todos os equipamentos disponíveis
    async getEquipamentos() {
      const LOADING_NAME = "get:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/equipamentos/nomeequipamentos/");
        // Remove o equipamento pai da lista
        const equipamentosFiltrados = data.filter(equip => equip.id !== this.currentItem.equipamentopai.id);
        equipamentosFiltrados.sort((a, b) => {
          // Ordena por ativo (true vem antes de false)
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
          // Se ambos são ativos ou inativos, ordena por nome
          return a.nome.localeCompare(b.nome);
        });
        this.equipamentos = equipamentosFiltrados;
        // console.log('Equipamentos disponíveis -> ', this.equipamentos);
      } catch (error) {
        this.$toast.error(`Erro ao buscar equipamento: ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getEsterilizavel() {
      const LOADING_NAME = "get:esterilizavel";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/equipamentos/nomeesterelizavels/");
        this.esterilizavel = data.filter(item => item.ativo);
        // console.log(this.esterilizavel)
      } catch (error) {
        this.$toast.error(`Erro ao buscar esterilizaveis: ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Inicializa os equipamentos filhos já associados
    initializeCurrentSelectedEquipamentos() {
      if (this.currentItem && this.currentItem.equipamentofilhos) {
        this.currentSelectedEquipamentos = this.currentItem.equipamentofilhos.map(equip => ({
          id: equip.id,
          nome: equip.nome
        }));

        // Inicializa os IDs selecionados no autocomplete
        this.selectedEquipamentos = this.currentSelectedEquipamentos.map(equip => equip.id);
      }
      // console.log(this.currentItem)
    },
    initializeCurrentSelectedEsterilizaveis() {
      if (this.currentItem && this.currentItem.esterilizavel) {
        // Certifique-se de que `esterilizavel` é um array de objetos
        this.currentSelectedEsterilizáveis = this.currentItem.esterilizavel.map(equip => ({
          id: equip.id,
          nome: equip.nome
        }));

        // Inicializa os IDs selecionados no autocomplete
        this.selectedEsterilivaeis = this.currentSelectedEsterilizáveis.map(equip => equip.id);
      }
    },
    // Atualiza os equipamentos recém-selecionados
    updateNewlySelectedEquipamentos() {
      const allSelected = this.selectedEquipamentos
        .map(id => this.equipamentos.find(equip => equip.id === id))
        .filter(Boolean); // Remove itens undefined ou null

      this.currentSelectedEquipamentos = allSelected.filter(equip =>
        this.currentItem.equipamentofilhos.some(filho => filho.id === equip.id)
      );

      this.newlySelectedEquipamentos = allSelected.filter(equip =>
        !this.currentItem.equipamentofilhos.some(filho => filho.id === equip.id)
      );
    },
   updateNewlySelectedEsterilizaveis() {
    const allSelected = this.selectedEsterilivaeis
      .map(id => this.esterilizavel.find(equip => equip.id === id))
      .filter(Boolean); // Remove itens undefined ou null

    this.currentSelectedEsterilizáveis = allSelected.filter(equip =>
      this.currentSelectedEsterilizáveis.some(filho => filho.id === equip.id)
    );

    this.newlySelectedEsterilizáveis = allSelected.filter(equip =>
      !this.currentSelectedEsterilizáveis.some(filho => filho.id === equip.id)
    );
  },
    // Salva a seleção
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      if (!this.selectedEquipamentos.length && !this.selectedEsterilivaeis.length) {
        this.$toast.error("Por favor, selecione pelo menos um Equipamento ou Esterilizável Dependente.");
        return;
      }

      const fields = {
          id: this.currentItem.id,
          equipamentofilhos: this.selectedEquipamentos,
          esterilizavel: this.selectedEsterilivaeis,
          ativo: Boolean(this.currentItem.ativo),
      };
      // console.log('fields enviado editar  -> ', fields)
      this.onSubmit(fields);
    },
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.getEsterilizavel().then(() => {
      this.initializeCurrentSelectedEsterilizaveis();
      this.updateNewlySelectedEsterilizaveis();
    }),
    this.getEquipamentos().then(() => {
      // Garantir que a inicialização aconteça após o carregamento
      this.updateNewlySelectedEquipamentos();
      this.initializeCurrentSelectedEquipamentos();
    });
  },
  watch: {
    selectedEquipamentos() {
      // console.log("Equipamentos selecionados atualizados:", newVal);
      this.updateNewlySelectedEquipamentos(); // Atualizar ao modificar os selecionados
    },
    equipamentos() {
      // console.log("Lista de equipamentos carregada:", newEquipamentos);
      this.updateNewlySelectedEquipamentos(); // Atualizar com base na lista carregada
    },
    selectedEsterilivaeis() {
       this.updateNewlySelectedEsterilizaveis();
    },
    esterilizavel() {
      this.updateNewlySelectedEsterilizaveis(); 
    }
  },
};
</script>

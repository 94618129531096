<template>
  <!-- Modal para selecionar operadora, mês e ano -->
  <v-dialog v-model="showModal" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="blod_color">Selecionar a locadora e as Datas Inicial e Final</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <!-- Mostrar um spinner enquanto as locadoras estão carregando -->
          <v-progress-circular
            v-if="loadingLocadora"
            indeterminate
            color="primary"
          ></v-progress-circular>

          <!-- Mostrar o select das locadoras quando estiverem disponíveis -->
          <v-row v-else>
            <v-col>
              <v-select
                v-model="selectedLocadora"
                :items="locadora"
                item-text="nome_fantasia"
                item-value="id"
                label="Locadora"
                required
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="dataInicial"
                label="Data Inicial"
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="dataFinal"
                label="Data Final"
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelModal">Cancelar</v-btn>
        <v-btn color="green" text @click="confirmModal">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from "@/http";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import { mapGetters } from "vuex";
  import UtilsPDF from "../../../service/utilsPDF";
  import logo from "../../../assets/logo.png";
  import ona from "../../../assets/ONA_Nivel_3.png";
  import gptw from "../../../assets/GPTW_2023-2024.png";

  // Converte "YYYY-MM-DD" em Date ou retorna null se inválido
  function parseISO(dateStr) {
    if (!dateStr) return null;
    const [yyyy, mm, dd] = dateStr.split("-");
    if (!yyyy || !mm || !dd) return null;
    return new Date(+yyyy, +mm - 1, +dd);
  }

  // Verifica se dateStr está entre minStr e maxStr (inclusive)
  function isBetween(dateStr, minStr, maxStr) {
    const d = parseISO(dateStr);
    const min = parseISO(minStr);
    const max = parseISO(maxStr);
    if (!d || !min || !max) return false;
    return d >= min && d <= max;
  }

  // Calcular diferença de dias (inclusiva: soma +1)
  function getDiffDays(dataIniStr, dataFimStr) {
    const dtIni = parseISO(dataIniStr);
    const dtFim = parseISO(dataFimStr);
    if (!dtIni || !dtFim) return 0;

    const diffMs = dtFim - dtIni;
    let diffDias = Math.floor(diffMs / (1000 * 60 * 60 * 24)) + 1;
    return diffDias < 0 ? 0 : diffDias;
  }

  // Formatar data "YYYY-MM-DD" em "DD/MM/YYYY"
  function formatDateBR(dateISO) {
    if (!dateISO) return "";
    const [year, month, day] = dateISO.split("-");
    return `${day}/${month}/${year}`;
  }

  // Formatar data para nome de arquivo "DD_MM_YYYY"
  function formatDateISOToBR(dateISO) {
    if (!dateISO) return "";
    const [year, month, day] = dateISO.split("-");
    return `${day}_${month}_${year}`;
  }

  function formatarMoeda(valor) {
    if (!valor && valor !== 0) return "";
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  export default {
    name: "IndicadorOperadora",
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        loadingLocadora: false,
        selectedLocadora: null,
        dataInicial: "",
        dataFinal: "",
        locadora: [],
        equipamentos: [],
      };
    },
    computed: {
      ...mapGetters(["userData"]),
    },
    methods: {
      openModal() {
        if (this.locadora.length === 0) {
          this.loadingLocadora = true;
          this.getLocadora();
        }
      },
      cancelModal() {
        this.$emit("update:showModal", false);
      },
      confirmModal() {
        if (!this.selectedLocadora || !this.dataInicial || !this.dataFinal) {
          this.$toast.error("Por favor, preencha todos os campos.");
          return;
        }
        if (this.dataInicial > this.dataFinal) {
          this.$toast.error("A Data Inicial não pode ser maior que a Data Final.");
          return;
        }
        this.$emit("update:showModal", false);
        this.getPDF();
      },
      async getLocadora() {
        try {
          const { data } = await api.get("fornecedores/fornecedores/");
          this.locadora = data
            .filter(
              (item) => item.ativo && item.tipo.some((tipo) => tipo.id === 4)
            )
            .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
          this.loadingLocadora = false;
        } catch (error) {
          this.$toast.error(`Erro ao buscar locadoras: ${error}`);
          this.loadingLocadora = false;
        }
      },
      async getPDF() {
        this.loading = true;
        this.$toast.success(
          `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
        );
        try {
          const { data } = await api.get("/equipamentos/equipamento_alugado/");
          // Filtra pela locadora
          this.equipamentos = data.filter(
            (item) => item.fornecedor?.id === this.selectedLocadora
          );

          if (!this.equipamentos || this.equipamentos.length === 0) {
            this.$toast.error(
              "Nenhum equipamento encontrado para a empresa selecionada."
            );
            this.loading = false;
            return;
          }

          const doc = this.generatePDF();

          // Monta o nome do arquivo
          const locadoraEscolhida = this.locadora.find(
            (loc) => loc.id === this.selectedLocadora
          );
          let locadoraNome = locadoraEscolhida?.nome_fantasia || "Empresa_nao_informada";
          locadoraNome = locadoraNome.replace(/\s+/g, "_");

          const dataIniFile = formatDateISOToBR(this.dataInicial);
          const dataFimFile = formatDateISOToBR(this.dataFinal);
          const filename = `Relatório_Faturamento_${locadoraNome}_Período_${dataIniFile}_até_${dataFimFile}.pdf`;
          doc.save(filename);

          this.loading = false;
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          this.loading = false;
        }
      },
      generatePDF() {
        const doc = new jsPDF({ compress: true });
        this.drawHeader(doc);

        const lineHeight = 6;
        let yPosition = 35;
        const blueColor = [57, 106, 159];

        // Cabeçalho
        const locadoraEscolhida = this.locadora.find(
          (loc) => loc.id === this.selectedLocadora
        );
        const locadoraNome =
          locadoraEscolhida?.nome_fantasia || "Empresa não informada";
        const dataIniBR = formatDateBR(this.dataInicial);
        const dataFimBR = formatDateBR(this.dataFinal);

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(
          `Empresa: ${locadoraNome}, período de ${dataIniBR} até ${dataFimBR}`,
          10,
          50
        );

        yPosition += lineHeight * 4;

        // ================================
        //     Gera corpo da tabela
        // ================================
        const bodyTable = this.equipamentos
          .filter((item) => {
            const eqIni = item?.equipamento?.data_inicio_locacao;
            const eqFim = item?.equipamento?.data_fim_locacao;

            // Regra solicitada:
            // 1) Se data_inicio_locacao está no [dataInicial, dataFinal], mantemos
            // 2) Senão, se NÃO TIVER data_fim_locacao => item "ativo" => mantemos
            // 3) Se tiver data_fim_locacao, verifica se data_fim_locacao está no [dataInicial, dataFinal]
            //    se sim => mantemos
            // 4) Caso contrário => descarta

            const iniNoIntervalo = isBetween(eqIni, this.dataInicial, this.dataFinal);
            if (iniNoIntervalo) return true;

            // eqIni NÃO está no intervalo, então olha eqFim
            if (!eqFim) {
              // Se não tiver eqFim => ativo => mantém
              return true;
            }
            // eqFim existe => vê se eqFim está no intervalo
            const fimNoIntervalo = isBetween(eqFim, this.dataInicial, this.dataFinal);
            return fimNoIntervalo;
          })
          .map((item) => {
            // Cálculo de valor diário
            const valorDiario = item.cobranca ? item.valor : item.valor / 30;

            // Precisamos definir as datas efetivas:
            // => Início efetivo: 
            //    se eqIni for nulo, use dataInicial 
            //    senão use a maior data entre eqIni e dataInicial
            // => Fim efetivo:
            //    se eqFim for nulo => dataFinal
            //    se eqFim < dataFinal => eqFim
            //    senão => dataFinal

            const eqIniDate = parseISO(item.equipamento?.data_inicio_locacao);
            const eqFimDate = parseISO(item.equipamento?.data_fim_locacao);
            const relIniDate = parseISO(this.dataInicial);
            const relFimDate = parseISO(this.dataFinal);

            let realStartDate = relIniDate;
            if (eqIniDate) {
              realStartDate = eqIniDate > relIniDate ? eqIniDate : relIniDate;
            }
            let realEndDate = relFimDate;
            if (!eqFimDate) {
              // se não tem data fim, usa dataFinal do relatório
              realEndDate = relFimDate;
            } else if (eqFimDate < relFimDate) {
              realEndDate = eqFimDate;
            } else {
              realEndDate = relFimDate;
            }

            // Converte Date => "YYYY-MM-DD"
            function toISO(d) {
              if (!d) return "";
              const yyyy = d.getFullYear();
              const mm = String(d.getMonth() + 1).padStart(2, "0");
              const dd = String(d.getDate()).padStart(2, "0");
              return `${yyyy}-${mm}-${dd}`;
            }

            const realStartStr = toISO(realStartDate);
            const realEndStr   = toISO(realEndDate);

            const diasLocacao = getDiffDays(realStartStr, realEndStr);
            const valorTotal  = valorDiario * diasLocacao;

            return [
              item.id,
              item.equipamento?.descricao?.nome || "-",
              item.equipamento?.patrimonio || "-",
              formatarMoeda(valorDiario), 
              UtilsPDF.formatDate(item.equipamento?.data_inicio_locacao),
              UtilsPDF.formatDate(item.equipamento?.data_fim_locacao),
              diasLocacao,
              formatarMoeda(valorTotal),
            ];
          });
          
        doc.autoTable({
          startY: yPosition,
          theme: "striped",
          headStyles: {
            fillColor: blueColor,
            halign: "center",
          },
          styles: {
            lineColor: blueColor,
            lineWidth: 0.1,
          },
          head: [
            [
              "ID",
              "Equipamento",
              "Patrimônio",
              "Valor Diário",
              "Dt. Início Loc.",
              "Dt. Fim Loc.",
              "Dias de Locação",
              "Valor Total",
            ],
          ],
          body: bodyTable,
          columnStyles: {
            0: { cellWidth: 10 },
            7: { cellWidth: 20 },
          },
        });

        const tableHeight2 = doc.autoTable.previous.finalY;
        yPosition = tableHeight2 + lineHeight + 2;

        function parseCurrencyBR(strBR) {
          if (!strBR) return 0;
          const num = parseFloat(
            strBR.replace(/[^\d,.-]/g, "").replace(",", ".")
          );
          return isNaN(num) ? 0 : num;
        }

        // 3) Soma todos os valores da coluna [7], que é "Valor Total"
        let somaGeral = 0;
        for (const row of bodyTable) {
          const valorTexto = row[7]; // Supondo que o valor total fique na coluna 7
          somaGeral += parseCurrencyBR(valorTexto);
        }
        const somaGeralFormatado = formatarMoeda(somaGeral);

        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(`Total: ${somaGeralFormatado}`, 195, yPosition, { align: "right" });

        yPosition = tableHeight2 + lineHeight + 2;


        UtilsPDF.addPageNumbers(doc);
        return doc;
      },

      drawHeader(doc) {
        try {
          doc.addImage(logo, 10, 8, 28, 28);
          doc.addImage(ona, 160, 10, 15, 15);
          doc.addImage(gptw, 185, 10, 15, 15);
        } catch (error) {
          this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
        }
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.text("SÃO LUÍS HOME CARE", 105, 22, { align: "center" });
        doc.setFontSize(12);
        doc.text("RELATÓRIO DE FATURAMENTO LOCAÇÃO EQUIPAMENTO", 105, 35, {
          align: "center",
        });
      },
      async download(dataurl, filename) {
        const httpsurl = !dataurl.includes("https")
          ? dataurl.replace("http", "https")
          : dataurl;
        const res = await fetch(httpsurl);
        const buffer = await res.blob();
        const url = URL.createObjectURL(buffer);
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      },
    },
    mounted() {
      this.getLocadora();
    },
    watch: {
      showModal(val) {
        if (val) {
          this.openModal();
        }
      },
      dataInicial(novoValor) {
        if (this.dataFinal && novoValor > this.dataFinal) {
          this.$toast.error("A data inicial não pode ser maior que a data final.");
        }
      },
      dataFinal(novoValor) {
        if (this.dataInicial && novoValor < this.dataInicial) {
          this.$toast.error("A data final não pode ser menor que a data inicial.");
        }
      },
    },
  };
</script>

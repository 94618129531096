<template>
    <div class="mr-5 ml-5">
        <v-data-table :loading="isLoading('get:notafiscal')" :items="notafiscal" :headers="headers" :search="search" :items-per-page="-1" height="600px" fixed-header
        item-value="id" show-expand single-expand >
                <template v-slot:top>
                    <v-row class="mt-4" dense>
                        <v-col cols="5">
                            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
                        </v-col>
                        <v-col class="text-end">
                            <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar  </v-btn>
                            <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left>  mdi-plus </v-icon> Novo </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <!-- Seta para expandir -->
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                    <!-- Subcomponente ou informações detalhadas -->
                    <DetalhesNotaFiscal :item="item" />
                    </td>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                    <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                    <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                    </v-chip>
                </template>
                 <template v-slot:[`item.edit`]="{ item }">
                    <v-btn icon @click="onSelectRow(item, 'update')">
                    <v-icon color="green">
                        mdi-pencil
                    </v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.dataAquisicao`]="{ item }">
                    {{ item.dataAquisicao | formatDatabr }}
                </template >
                <template v-slot:[`item.equipamento`]="{ item }">
                    {{ item.equipamento === true ? "SIM": "" }}
                </template >
                <template v-slot:[`item.esterilizavel`]="{ item }">
                    {{ item.esterilizavel === true ? "SIM": "" }}
                </template >
                <template v-slot:[`item.desconto`]="{ item }">
                    <span v-if="item.desconto > 0">
                        {{ `${item.desconto} % ` }}         
                    </span>
                </template >
        </v-data-table>
        <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="1100">
            <v-card>
                <v-card-title class="blod_color">
                Nova Nota Fiscal:
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog.create = false">
                    <v-icon>
                    mdi-close
                    </v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text>
                <NotaFiscalCriar :currentItem="newItem" :loading="isLoading('post:notafiscal')" :onSubmit="createNotaFiscal" />
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="900">
        <v-card>
            <v-card-title class="blod_color">
            Editar Nota Fiscal:
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.update = false">
                <v-icon>
                mdi-close
                </v-icon>
            </v-btn>
            </v-card-title>
            <v-card-text>
            <NotaFiscalEditar :currentItem="selectedRow" :loading="isLoading('put:notafiscal')" :onSubmit="updateeNotaFiscal" />
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
// import NotaFiscalEditar from '../notaFiscal/NotaFiscalEditar.vue';
import NotaFiscalEditar from './NotaFiscalEditar.vue';
import NotaFiscalCriar from './NotaFiscalCriar.vue';
import DetalhesNotaFiscal from './DetalhesNotaFiscal.vue';

const { withCRUDUtils } = UtilsFunc

export default {
    name: "CadastroAquisicaoEsterilizavel",
    components: { NotaFiscalEditar, NotaFiscalCriar, DetalhesNotaFiscal },
    data: () => withCRUDUtils ({
        notafiscal:[],
        newItem: { ativo:true },
        headers: [
            { text: "", value: "data-table-expand" },
            { text: "Editar", value: "edit", width: 100 },
            { text: "Id (Nota Fiscal)", align: "center", sortable: true, value: "id" },
            { text: "Nº Nota Fiscal / Recibo", align: "center", sortable: true, value: "nota_fiscal" },
            { text: "Fornecedor", align: "center", sortable: true, value: "fornecedor.nome_fantasia" },
            { text: "Razão Social", align: "center", sortable: true, value: "fornecedor.razao_social" },
            { text: "Data da NF", align: "center", sortable: true, value: "dataAquisicao" },
            { text: "Desconto da NF", align: "start", sortable: true, value: "desconto" },
            { text: "Equipamento", align: "center", sortable: true, value: "equipamento" },
            { text: "Esterilizável", align: "center", sortable: true, value: "esterilizavel" },
            { text: "Status", value: "ativo" },
        ],
    }),
    props: {
        onSubmit: Function,
        loading: Boolean,
        formRef: Object,
        currentItem: { type: Object, 
            default: () => ({
                ativo: null,
            })
        },
    },
    methods: {
        async getNotaFiscal() {
            const LOADING_NAME = "get:notafiscal"
            this.setLoading(LOADING_NAME)
            try {
                const response = await api.get('equipamentos/aquisicao/')
                this.notafiscal = response.data.sort((a, b) => {
                    // Primeiro compara `ativo` (ativo = true vem antes de ativo = false)
                    if (a.ativo === b.ativo) {
                        // Se `ativo` for igual, ordena por ID decrescente
                        return b.id - a.id;
                    }
                    return b.ativo - a.ativo; // Ordena `ativo` (true = 1, false = 0)
                });
                // console.log('Esterelizavel -> ', this.esterelizavel)
            } catch(error) {
                console.error(error)
            } finally {
                this.setLoading(LOADING_NAME, true)
            }
        },
        atualizar(){
            this.getNotaFiscal()
            this.search = '';
        },
        async createNotaFiscal(fields) {
            const LOADING_NAME = "post:notafiscal";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await api.post(`/equipamentos/aquisicao_criar/`, fields);
                this.getNotaFiscal()
                this.dialog.create = false
                this.$toast.success('Nota Fiscal criada com sucesso!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async updateeNotaFiscal(fields) {
            const LOADING_NAME = "put:notafiscal"; 
            this.setLoading(LOADING_NAME);
            // console.log('Recebido', fields)
            try {
                await api.put(`/equipamentos/aquisicao_editar/${fields.id}/`, fields );
                this.getNotaFiscal()
                this.dialog.update = false
                this.$toast.success('Nota Fiscal atualizada com sucesso!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
    },
    mounted() {
        this.getNotaFiscal()
    }
}
</script>

import { render, staticRenderFns } from "./RelatorioTelaPaciente.vue?vue&type=template&id=03210b58&scoped=true"
import script from "./RelatorioTelaPaciente.vue?vue&type=script&lang=js"
export * from "./RelatorioTelaPaciente.vue?vue&type=script&lang=js"
import style0 from "./RelatorioTelaPaciente.vue?vue&type=style&index=0&id=03210b58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03210b58",
  null
  
)

export default component.exports
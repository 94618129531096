<template>

    <v-row v-if="isLoading" class="d-flex justify-center py-8">
      <v-progress-circular indeterminate />
    </v-row>
    <v-row v-else dense>
      <v-col cols="6" v-if="equipamentoFornecedor && equipamentoFornecedor.length > 0">
        <h3><b>Equipamentos do Fornecedor:</b></h3>
        <v-simple-table dense>
          <thead>
            <tr class="grey lighten-2">
              <th class="text-left">ID</th>
              <th class="text-left">Nome do Equipamento</th>
              <th class="text-left">Valor Diário</th>
              <th class="text-left">Valor Mensal</th>
              <th class="text-left">Início da Vigência</th>
              <th class="text-left">Fim da Vigência</th>
              <th class="text-left">Ativo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in equipamentoFornecedor" :key="index" class="grey lighten-3">
              <td>{{ item.id }}</td>
              <td>{{ item.nomeEquipamento ? item.nomeEquipamento.nome : ''  }}</td>
              <td>{{ item.valordiario ? formatarReal(item.valordiario) : formatarReal(item.valormensal / 30)}}</td>
              <td>{{ item.valormensal ? formatarReal(item.valormensal) : formatarReal(item.valordiario * 30) }}</td>
              <td>{{ item.inicioVigencia ? formatDate(item.inicioVigencia) : '' }}</td>
              <td>{{ item.fimVigencia ? formatDate(item.fimVigencia) : '' }}</td>
              <td>{{ item.ativo ? 'Sim' : 'Não' }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" v-if="taxafornecedor && taxafornecedor.length > 0">
        <h3><b>Taxas do Fornecedor:</b></h3>
        <v-simple-table dense>
          <thead>
            <tr class="grey lighten-2">
              <th class="text-left">ID</th>
              <th class="text-left">Nome da Taxa</th>
              <th class="text-left">Valor</th>
              <th class="text-left">Início da Vigência</th>
              <th class="text-left">Fim da Vigência</th>
              <th class="text-left">Ativo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in taxafornecedor" :key="index" class="grey lighten-3">
              <td>{{ item.id }}</td>
              <td>{{ item.nomeTaxa ? item.nomeTaxa.nome : '' }}</td>
              <td>{{ item.valor ? formatarReal(item.valor) : ''}}</td>
              <td>{{ item.inicioVigencia ? formatDate(item.inicioVigencia) : '' }}</td>
              <td>{{ item.fimVigencia ? formatDate(item.fimVigencia) : '' }}</td>
              <td>{{ item.ativo ? 'Sim' : 'Não' }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <div v-else>
        <span><b>Sem itens cadastrados</b></span>
      </div>
    </v-row>

</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "OperadoraExpand",
  data: () => withCRUDUtils({
    equipamentoFornecedor: [],
    taxafornecedor: [],
    nomeequipamentos: [],
    nometaxas: [],
    fornecedor: [],
    isLoading: true,
  }),
  props: {
    currentItemId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getEquipamentoFornecedor() {
      try {
        const { data } = await api.get(`/equipamentos/equipamentos_fornecedores/`);
        this.equipamentoFornecedor = data.filter(item => item.fornecedor === this.currentItemId && item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-73). ${error}`);
      }
    },
    async getTaxaFornecedor() {
      try {
        const { data } = await api.get(`/taxas/taxas_fornecedores/`);
        this.taxafornecedor = data.filter(item => item.fornecedor === this.currentItemId && item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-73). ${error}`);
      }
    },
    async getNomeEquipamento() {
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-76). ${error}`);
      }
    },
    async getNomeTaxa() {
      try {
        const { data } = await api.get(`/taxas/taxas/`);
        this.nometaxas = data.filter(item => item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-76). ${error}`);
      }
    },
    async getFornecedor() {
      try {
        const { data } = await api('fornecedores/fornecedores/');
        data.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        this.fornecedor = data.filter(item => item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-92). ${error}`);
      }
    },
    formatarReal(valor) {
      return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      this.getEquipamentoFornecedor(),
      this.getTaxaFornecedor(),
      this.getNomeEquipamento(),
      this.getNomeTaxa(),
      this.getFornecedor(),
    ]);
    this.isLoading = false;
  },
};
</script>

<style scoped>

.text {
  color: red;
}
h3 {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center
}
</style>

<template>
  <v-container class="container">
    <v-btn color="success" depressed size="small" @click="generatePDF" :disabled="isAnyRowSelected">
      <v-icon left>mdi-printer</v-icon> Rel. Orçamentos Filtro (Realizado)
    </v-btn>
  </v-container>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Produtividade",
  data: () => withCRUDUtils({
    loading: false,
  }),
  props: {
    displayedData: { type: Array, default: () => [] },
    isAnyRowSelected: { type: Boolean, default: false },
    dataInicioOrcamento: String,
    dataFimOrcamento: String,
    username: String
  },
  methods: {
    formatarData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formatarValor(valor) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error('Falha ao adicionar a imagem ao PDF (L-43).', error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relação das Contas`, 105, 28, { align: "center" });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10); // Ajuste conforme necessário
      const periodoTexto = `Período: ${this.formatarData(this.dataInicioOrcamento)} à ${this.formatarData(this.dataFimOrcamento)}`;
      doc.text(periodoTexto, 105, 34, { align: "center" }); // Ajuste a posição Y conforme necessário

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28); 
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

    },
    generatePDF() {
      this.loading = true;
      this.$toast.success(`O documento está sendo preparado. Aguarde, por favor.`);

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      // Filtra os displayedData para incluir apenas os aceitos
      const displayedDataFiltrados = this.displayedData;

      // Calcula a soma total, tratando fatrealizado null como 0
      const somaTotal = displayedDataFiltrados.reduce((acc, item) => {
        return acc + (item.fatrealizado ? parseFloat(item.fatrealizado) : 0);
      }, 0);

      // Prepara os displayedData para a tabela
      const corpoTabela = displayedDataFiltrados.map(item => [
        item.id,
        item.convenio,
        item.paciente,
        item.modalidade_o.nome,
        this.formatarData(item.data_inicio),
        this.formatarData(item.data_fim),
        item.fatrealizado ? this.formatarValor(parseFloat(item.fatrealizado)) : 'R$ 0.00',
      ]);

      // Inclui a linha de total no corpo da tabela
      corpoTabela.push([
        '', '', '', '', '', 'Total:', this.formatarValor(somaTotal)
      ]);

      // Adiciona a tabela ao documento
      doc.autoTable({
        head: [['ID', 'Convênio', 'Paciente', 'Modalidade', 'Data Início', 'Data Fim', 'Valor da Conta']],
        body: corpoTabela,
        startY: 40,
        columnStyles: {
          6: { cellWidth: 30 } // Ajuste a largura da coluna do valor da conta conforme necessário
        },
        didParseCell: function (data) {
          // Estilizando a última linha (Total)
          if (data.row.index === corpoTabela.length - 1) {
            if (data.column.index === 6) { // Última coluna (Valor do Orçamento)
              data.cell.styles.halign = 'right'; // Alinha à direita
              data.cell.styles.fontStyle = 'bold';
            }
          }
        }
      });

      const quantidadeOrçamentos = displayedDataFiltrados.length;
      const fraseQuantidade = `Quantidade de Contas: ${quantidadeOrçamentos}`;

      // Posição Y: logo abaixo da última linha da tabela
      const posY = doc.autoTable.previous.finalY + 5; // Ajuste o espaçamento conforme necessário

      // Posição X: alinhado à direita
      const pageWidth = doc.internal.pageSize.getWidth();
      const textSize = doc.getStringUnitWidth(fraseQuantidade) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const posX = pageWidth - textSize - 25; // 10 é a margem direita, ajuste conforme necessário

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10); // Ajuste o tamanho da fonte conforme necessário
      doc.text(fraseQuantidade, posX, posY);

      UtilsPDF.addPageNumbers(doc);
      const filename = `Relação das Contas.pdf`;
      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },

  },
};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  padding: 0;
  flex-direction: column;
}
</style>
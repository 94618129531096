<template>
  <div>
    <div>
      <v-row dense>
        <v-col cols="2">
          <v-text-field v-model="currentItem.data_inicio" name="data_inicio" required outlined type="date"
            :rules="[(e) => e !== undefined || 'Obrigatório']" clearable>
            <template v-slot:label>
              <span>Data de início do orçamento<span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="currentItem.data_fim" name="data_fim" required outlined type="date"
            :rules="[(e) => e !== undefined || 'Obrigatório']" clearable>
            <template v-slot:label>
              <span>Data término do orçamento <span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field  outlined disabled label="QTD Dias Orçados" :value="calculatedDays" ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-data-table show-expand :items="selectedRowsDetails" :headers="headers" :loading="isLoading('get:budget-details')">
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 1" class="py-4 grey lighten-2">
          <h4 style="margin-bottom: 1rem;">
            Procedimentos
          </h4>
          <v-data-table hide-default-footer :headers="headersProcedures" :items="item.maxProcedures" item-key="index">
            <template v-slot:[`item.amountProceduresToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountProceduresToCreate" dense outlined hide-details v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>
          </v-data-table>


          <h4 style="margin: 1rem 0;">
            Taxas
          </h4>
          <v-data-table hide-default-footer :headers="headersTaxes" :items="item.maxTaxes" item-key="index">
            <template v-slot:[`item.amountTaxesToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountTaxesToCreate" dense outlined hide-details v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.taxa.preco`]="{ item }">
              {{
                formatCurrencyZero(item.taxa.preco)
              }}
            </template>
          </v-data-table>
          <h4 style="margin: 1rem 0;">
            Equiapmentos
          </h4>
          <v-data-table hide-default-footer :headers="headersEquipamentos" :items="item.maxEquipamentos" item-key="index">
            <template v-slot:[`item.amountEquipamentosToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountEquipamentosToCreate" dense outlined hide-details v-bind="attrs" 
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.equiapmentos.preco`]="{ item }">
              {{
                formatCurrencyZero(item.equiapmentos.preco)
              }}
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <v-form @submit.prevent="onBeforeSubmit" ref="formProrrogar">
      <v-row>
        <v-col class="d-flex align-start">
          <v-btn-toggle v-model="currentItem.ativo" mandatory>
            <v-btn :value="false" :class="{ purple: !currentItem.ativo, 'white--text': !currentItem.ativo }">
              Em análise
            </v-btn>
            <v-btn :value="true" :class="{ green: currentItem.ativo, 'white--text': currentItem.ativo }">
              Aprovado
            </v-btn>
          </v-btn-toggle>
        </v-col>

      </v-row>
      <v-row class="justify-end mt-2">
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading=loading :disabled="!isFormValid">
                  Prorrogar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import api from "../../../http";
import ConfirmButton from '../../ConfirmButton.vue'
export default {
  name: 'ExtendBudgetForm',
  props: {
    onSubmit: Function,
    selectedRows: Array,
    modalities: Array,
    loading: Boolean
  },
  components: {
    ConfirmButton
  },
  data: () => UtilsFunc.withCRUDUtils({
    currentItem: {},
    selectedRowsDetails: [],
    headers: [
      { text: 'Nrº Orçamento Principal', value: 'id' },
      { text: 'Paciente', value: 'paciente' },
      { text: 'Procedimentos', value: 'procedimentos' },
      { text: 'Taxas', value: 'taxas' },
    ],
    headersProcedures: [
      { text: 'Id', value: 'id' },
      { text: 'Procedimento', value: 'procedimento.procedimento' },
      { text: 'Frequência', value: 'frequencia.nome' },
      { text: 'Quantidade', value: 'amountProceduresToCreate', width: 250 },
    ],
    headersTaxes: [
      { text: 'Id', value: 'id' },
      { text: 'Taxa', value: 'taxa.taxa.nome' },
      { text: 'Valor', value: 'taxa.preco' },
      { text: 'Quantidade', value: 'amountTaxesToCreate', width: 250 },
    ],
    headersEquipamentos: [
      { text: 'Id', value: 'id' },
      { text: 'Taxa', value: 'equipamento.equipamento.nome' },
      { text: 'Valor', value: 'equipamento.preco' },
      { text: 'Quantidade Diárias', value: 'amountEquipamentosToCreate', width: 250 },
    ],
    modalidades: [],
  }),
  computed: {
    extendBudgetDiffDays() {
      const { data_fim, data_inicio } = this.currentItem
      if (!data_fim || !data_inicio) return 0
      const dateToMiliseconds = (date) => new Date(date).getTime()
      const difference = dateToMiliseconds(this.currentItem.data_fim) - dateToMiliseconds(this.currentItem.data_inicio)

      return Math.ceil(difference / 1000 / 3600 / 24) + 1
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!(this.currentItem.data_inicio && this.currentItem.data_fim);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
     calculatedDays() {
      if (this.currentItem.data_inicio && this.currentItem.data_fim) {
        const startDate = new Date(this.currentItem.data_inicio);
        const endDate = new Date(this.currentItem.data_fim);
        const timeDiff = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Inclui o dia final no cálculo
        return diffDays;
      }
      return 0; // Retorna 0 se as datas não estiverem definidas
    },
  },
  watch: {
    extendBudgetDiffDays: function () {
      const amountProceduresToCreate = this.extendBudgetDiffDays
      // console.log(amountProceduresToCreate)
      this.selectedRowsDetails = this.selectedRowsDetails.map(row => {
        row.maxProcedures = row.maxProcedures.map(procedure => ({
          ...procedure,
          amountProceduresToCreate: Math.ceil(amountProceduresToCreate * procedure.frequencia.fator),

        }))



        return { ...row }
      })

    },
    calculatedDays(newDays) {
      if (newDays) {
        // Atualiza os equipamentos com o valor de calculatedDays
        this.selectedRowsDetails = this.selectedRowsDetails.map(row => {
          row.maxEquipamentos = row.maxEquipamentos.map(equipamento => ({
            ...equipamento,
            amountEquipamentosToCreate: newDays // Atualiza o valor automaticamente
          }));
          return { ...row };
        });
        this.$emit("update-days", newDays);
      }
    }
  },
  methods: {

    formatCurrencyZero(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },
    isSameBudget(index) {
      if (index === 0) return false
      const previousRow = this.tableData[index - 1]
      const currentRow = this.tableData[index]

      return previousRow.id === currentRow.id
    },
    async onBeforeSubmit() {
      const isValid = this.$refs.formProrrogar.validate();
      // Verificar se a data de término é menor que a data de início
      if (this.currentItem.data_fim < this.currentItem.data_inicio) {
        this.$toast.error('A data de término não pode ser anterior à data de início.');
        return;
      }
      if (!isValid) return;

      const fields = { ...this.currentItem }
      console.log('this.selectedRowsDetails --> ',this.selectedRowsDetails)
      console.log('fields --> ',fields)
      await this.onSubmit(fields, this.selectedRowsDetails)
    },
    async getBudgetProceduresAndTaxes(id) {
      const { data: budget } = await api.get(`atendimentos/sessao-full/?orcamento=${id}`)
      const { data: taxes } = await api.get(`atendimentos/taxas/?orcamento=${id}`)
      const { data: equipamentos } = await api.get(`atendimentos/equipamentos/?orcamento=${id}`)

      return {
        budget: budget[0],
        taxes: taxes || [],
        equipamentos: equipamentos || [],
      }

    },
    async getBudgetDetails() {
      const LOADING_NAME = 'get:budget-details'
      this.setLoading(LOADING_NAME)
      try {
        const selectedRowsIds = this.selectedRows.map(row => row.id)

        const responses = await Promise.all(selectedRowsIds.map(this.getBudgetProceduresAndTaxes))

        const data = responses
        this.currentItem.modalidade = data[0].budget.modalidade

        const excludedProcedureIds = [43, 44, 45, 46];

        this.selectedRowsDetails = data.map(item => {
          const budget = item.budget
          const sortedProcedures = budget.evolucoes
            .filter(procedure => !excludedProcedureIds.includes(procedure.procedimento.procedimento_id)) // Exclusõa dos procedimentos de tec de enfermagem 6h, 12h e 24h
            .sort((a, b) => {
              return b.id - a.id
            })

          const maxProcedures = sortedProcedures.reduce((acc, procedure) => {
            if (acc.some(p => p.procedimento.procedimento_id === procedure.procedimento.procedimento_id)) return acc
            acc.push({
              ...procedure,
              procedimento: {
                ...procedure.procedimento,
                preco: Number(procedure.procedimento.preco)
              }
            })
            return acc
          }, [])
          
          const maxTaxes = item.taxes.reduce((acc, tax) => {
            if (acc.some(t => t.taxa.id === tax.taxa.id)) return acc
            
            acc.push({
              ...tax,
              taxa_ref: tax.taxa.taxa.id,
              valor: tax.valor_cobranca_taxa,
              amountTaxesToCreate: 1
            })

            return acc
          }, [])

          const maxEquipamentos = item.equipamentos
            .filter(equi => equi.ativo) // Filtra equipamentos ativos
            .map(equi => ({
              ...equi,
              equi_ref: equi.equipamento.equipamento.id,
              quant: this.calculatedDays,
              valor: equi.desconto_equipamento === 100 ? null : equi.valor_cobranca_equipamento,
              amountEquipamentosToCreate: 1,
            }));
            // console.log(maxEquipamentos)
          return {
            ...this.selectedRows.find(row => row.id === budget.id),
            ...budget,
            procedimentos: Array.from(new Set(budget.evolucoes
              .map(({ procedimento }) => procedimento.procedimento))).join(', '),
            maxProcedures,
            maxTaxes,
            maxEquipamentos,
          }
        })

      } catch (error) {
        console.error(error)
        this.$toast.error('Falha ao carregar orçamentos')
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    }
  },
  mounted() {
    this.getBudgetDetails()
  }
}
</script>

<template>
  <v-container class="container">
    <!-- {{dataEnvio}} -->
    <v-btn color="success" depressed size="small" @click="generatePDF">
      <v-icon left>mdi-printer</v-icon> Rel. Entraga Usuario Equipamento
    </v-btn>
  </v-container>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"
import UtilsPDF from '../../../service/utilsPDF';
import api from '../../../http';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Requisicao",
  data: () => withCRUDUtils({
    loading: false,
    endereco: [],
    usuarios: [],
    equipamentos: [],
    usuariosCarregados: false,
  }),
  props: {
    usuario: { Number },
    equipamento: { Number }, 
    dataEnvio: {Date},
  },

  methods: {
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
        // this.getUsuarios()
        
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-91):", error);
      }
      const lineHeight = 6;
      const xFixedPosition = 10;
      let initialYPosition = 45;
      let yPosition = 45;
      // const profissional = this.usuarios
      // const endereco = this.endereco[0]

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 15, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Entrega de Equipamento`, 105, 28, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      doc.setFontSize(12);
      if (this.usuarios && this.usuarios.apelido) {
        doc.text(`Usuário: ${this.usuarios.nome}`, xFixedPosition, initialYPosition);
      } else {
        doc.text("Usuário: Não informado", xFixedPosition, initialYPosition);
      }

      yPosition = initialYPosition + lineHeight
      const dataNascimento = `Data de Nascimento: ${UtilsPDF.formatDate(this.usuarios.nascimento)} `;
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });
      
      doc.text(`Especialidade: ${this.usuarios.especialidade.nome}  `, xFixedPosition, yPosition);
      doc.text(`CPF: ${this.usuarios.cpf} `, 200, yPosition, { align: "right", });
    
    },
    drawFooter(doc, yPosition) {
      const lineHeight = 6; // Altura entre as linhas
      const maxWidth = 190; // Largura máxima para o texto antes de quebrar a linha

      // Gerar o texto dos esterilizáveis
      const itensTexto = this.equipamentos
        .map(
          (item) =>
           `${item.descricao.nome} - R$${ (item.descricao.valor_cobranca ? item.descricao.valor_cobranca.toFixed(2).replace(".", ",") : 'Sem valor cadastrado')}`
        )
        .join("; ");

      // Texto completo com OBS
      const textoCompleto = `OBS: Valores de reposição dos itens são: ${itensTexto}. Caso não tenha a devolução dos itens  ou sejam entregues com avarias o valor de reposição será cobrado integralmente.`;

      // Adicionar o texto ao PDF com quebra automática
      yPosition += lineHeight; // Movendo para a próxima linha
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);

      const splitText = doc.splitTextToSize(textoCompleto, maxWidth);
      splitText.forEach((line) => {
        doc.text(line, 10, yPosition);
        yPosition += lineHeight;
      });

      return yPosition;
    },
    async generatePDF() {
      if (!this.dataEnvio) {
        this.$toast.error("Data de envio não foi fornecida. O relatório não pode ser gerado.");
        return;
      }
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      await this.getUsuarios();
      await this.getEquipamentos()

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      yPosition += lineHeight * 2;
      const blueColor = [57, 106, 159];
      doc.setFontSize(12);
      
      yPosition += lineHeight * 4;
      
      doc.setFont("helvetica", "bold");
      const currentDate = new Date();
      const dataentrega = this.dataEnvio ? this.dataEnvio : currentDate
      doc.text(`Data da Entrega: ${UtilsPDF.formatDate(dataentrega)}`, 100, 75, { align: "center" });

      yPosition += lineHeight * 2;


      doc.setFont("helvetica", "bold");
      doc.text(`Equipamentos - "ENTREGUE"`, 100, yPosition, { align: "center" });
      // doc.text(`DEIXAR NA RESIDENCIA`, 100, yPosition + 7, { align: "center" });
      yPosition += lineHeight * 4;
      
      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
        },
        head: [['Id', 'Equipamento', 'Validade da Calibracao', 'Entregue?']],
        body: this.equipamentos.map(item => [
          item.id,
          item.descricao.nome,
          UtilsPDF.formatDate(item.data_validade_calibracao
)
        ]),
        columnStyles: {
          0: { cellWidth: 10 }, // Define a largura da primeira coluna (Profissional)
        },
      });

      const tableHeight2 = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight2 + lineHeight + 12;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Recebedor: ___________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Data do Recebimento: ____ / ____ / ______", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Nome: _______________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("CPF ou RG: __________________________________", 15, yPosition);
      yPosition += lineHeight * 2;   
      
      // Desenhar o rodapé com os itens de esterilizáveis
      yPosition = this.drawFooter(doc, yPosition);

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório de Requisicao de Equipamento.pdf`;
      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
   
    async getUsuarios() {
      if (this.usuariosCarregados) {
        return;
      }
      try {
        const idUsuario = this.usuario;
        if (!idUsuario) {
          console.warn("ID do usuário não fornecido. Ignorando a chamada para getUsuarios.");
          return;
        }
        const response = await api.get(`/contas/usuarios/?ativo=True&pesquisa=true&id=${idUsuario}`);
        this.usuarios = response.data[0] || {};
        this.usuariosCarregados = true; // Marca como carregado
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de usuários: ${error}`);
      }
    },
    async getEquipamentos() {
      try {
        const id = this.equipamento
        const response = await api.get('equipamentos/equipamentos/');  // Ajuste a rota conforme sua API
        this.equipamentos = response.data
          .filter(item => item.ativo && item.id === id)
          
        // console.log( this.equipamentos)
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de equipamento: ${error}`);
      } 
    },

  },
};
</script>
<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" class="mt-5">
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" 
          :disabled="resumoPacientes.length === 1 && (resumoPacientes[0].id === 'no-patients' || resumoPacientes[0].id === 'error')"
          :messages="resumoPacientes.length === 1 && resumoPacientes[0].id === 'no-patients' ? 'Não há pacientes com solicitações de entrega pendente' : ''"
          class="custom-messages"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Troca" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Previsão da Troca" disabled v-model="dataPrevista" type="date"  ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-card-title class="blod_color">Itens Trocados:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" show-select item-value v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1" > 

        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field dense outlined label="Data da Troca"  v-model="dataTrocaEfetivada" type="date" :rules="requiredField" ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
      <v-col class="text-end">
        <ConfirmButton class="mr-4 ml-5 elevation-0" :onConfirm="cancelarEntrega" :loading="isLoadingaCancelar" color="red" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" > Cancelar Entrega  </ConfirmButton>
        <ConfirmButton :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"> Confirmar Entrega</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "CriarEquipamento",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingaCancelar: false,
      pacientes: [],
      pacientesEquipamento: [],
      itensParaCancelar: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPrevista: "",
      dataTroca: "",
      dataTrocaEfetivada: "",
      selectedPacienteItems: [],
      itensPacienteDesmarcados: [], 
      filterPaciente: [], 
      isLoadingDados: false,
      requiredField: [e => (e ? true : 'Obrigatório')],
      ItemPacientelHeaders: [
        { text: "Id", value: "idEquipamentoReferencia" },
        { text: "Equipamento", value: "equipamento" },
      ],

    });
  },
  components: { ConfirmButton },
  methods: {
    async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/equipamentos/");
        this.pacientes = response.data.filter(item => 
          item.data_solicitacao_troca !== null && 
          (item.data_implantacao !== null) &&
          item.ativo === true &&
          (item.equipamento.disponibilidade === 14 ) &&
          (!item.data_solicitacao_retirada || item.data_solicitacao_retirada === '') &&
          item.equipamento 
        );
        this.pacientesEquipamento = response.data.filter(item => 
          item.ativo === true &&
          item.disponibilidade.id === 13 &&
          item.data_implantacao === null &&
          item.troca === true
        );
        // console.log('this.pacientesEquipamento', this.pacientes)
        if (this.pacientes.length === 0) {
            this.resumoPacientes = [{
                id: 'no-patients',
                // nome: 'Não há pacientes com solicitações de troca pendentes'
            }];
        } else {
          // Primeiro agrupamento para juntar os materiais equipamentos
          const agrupamento = this.pacientes.reduce((acc, atual) => {
            // Ajustando a chave para considerar apenas a data sem a hora
            const dataReq = atual.data_solicitacao_troca.split('T')[0]; // Pega apenas a parte da data
            const chave = `${atual.paciente.id}-${dataReq}`;
            
            if (!acc[chave]) {
              // Formata a data de requisição
              const data = new Date(atual.data_solicitacao_troca);
              data.setDate(data.getDate() + 1);
              const dataFormatada = data.toLocaleDateString('pt-BR');
              
              // Inicia com um objeto que conta as ocorrências
              const contagem = {};
              contagem[atual.equipamento?.id] = 1;

              acc[chave] = {
                id: chave, // Usando a chave composta como ID
                pacienteId: atual.paciente.id, // Mantém o ID original do paciente
                nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
                equipamento: [{
                  id: atual.equipamento?.descricao?.id,
                  nome: atual.equipamento?.descricao?.nome,
                  idReferencia: atual.equipamento?.id,
                  idEntrega: atual.equipamentoIdCorrespondente,
                  IdEquipamento: atual.id,
                  dataValidade: atual.equipamento.data_validade_calibracao,
                  quantidade: 1
                }],
                data_solicitacao_troca: atual.data_solicitacao_troca,
                data_prevista_solicitacao_troca: atual.data_prevista_solicitacao_troca,
                data_prevista_implantacao: atual.data_prevista_implantacao,
                _contagem: contagem
              };
            } else {
              // Atualiza a contagem do material
              const nomeEquipamento = atual.equipamento?.descricao?.nome;
              acc[chave]._contagem[nomeEquipamento] = (acc[chave]._contagem[nomeEquipamento] || 0) + 1;
              
              // Verifica se o material já existe no array
              const materialExistente = acc[chave].equipamento.find(item => item.equipamento?.nome === nomeEquipamento);
              
              if (materialExistente) {
                materialExistente.quantidade = acc[chave]._contagem[nomeEquipamento];
              } else {
                acc[chave].equipamento.push({
                  id: atual.nome_equipamento.id,
                  nome: nomeEquipamento,
                  idReferencia: atual.equipamento?.id,
                  idEntrega: atual.equipamentoIdCorrespondente,
                  IdEquipamento: atual.id,
                  dataValidade: atual.equipamento.data_validade_calibracao,
                  quantidade: 1
                });
              }
            }
            return acc;
          }, {});

          // Converte para array final
          this.resumoPacientes = Object.values(agrupamento).map(item => ({
            id: item.id, // ID composto
            pacienteId: item.pacienteId, // ID original do paciente
            nome: item.nome,
            equipamento: item.equipamento,
            data_solicitacao_troca: item.data_solicitacao_troca,
            data_prevista_solicitacao_troca: item.data_prevista_solicitacao_troca,
            data_prevista_implantacao: item.data_prevista_implantacao
          }))
            .sort((a, b) => {
              // Converte as strings de data para objetos Date para comparação
              const dataA = new Date(a.data_solicitacao_troca);
              const dataB = new Date(b.data_solicitacao_troca);
              return dataA - dataB; // Ordem crescente (mais antigas primeiro)
            });
            // console.log('Resumo Pacientes', this.resumoPacientes)
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
   async updatePacienteData() {
    this.selectedPacienteItems = []; // Limpa seleções anteriores
    this.selectedEquipamentos = []; // Limpa seleções anteriores
    this.filterPaciente = [];

    const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
    );
    // console.log(paciente)
    if (paciente) {
        this.dataRequisicao = paciente.data_solicitacao_troca || "";
        this.dataPrevista = paciente.data_prevista_solicitacao_troca || "";
        this.IDPaciente = paciente.pacienteId;

        // Transforma os itens em formato para tabela
        this.filterPaciente = paciente.equipamento.map((item, index) => ({
            id: `${item.id}_${index}`,
            originalId: item.id,
            pacienteId: paciente.pacienteId,
            idEquipamentoReferencia: item.idReferencia,
            idEquipamentoEntregue: item.idEntrega,
            idEquipamento: item.IdEquipamento,
            data_validade: item.dataValidade || 'Não disponível',
            equipamento: item.nome,
            quantidade: item.quantidade,
        }));

        // Pré-seleciona todos os itens da tabela de pacientes
        this.selectedPacienteItems = [...this.filterPaciente];

        // Para cada item selecionado do paciente, procura e seleciona os equipamentos relacionados
        this.selectedPacienteItems.forEach((pacienteItem) => {
            const equipamentosRelacionados = this.pacientesEquipamento.filter(
                (equip) =>
                    equip.equipamento?.descricao?.id === pacienteItem.originalId && 
                    equip.disponibilidade.id === 13 // Verifica se está em trânsito de entrega
            );

            // Adiciona os equipamentos relacionados à lista
            this.selectedEquipamentos = [
                ...this.selectedEquipamentos,
                ...equipamentosRelacionados.map((equip) => ({
                    id: equip.equipamento?.id,
                    descricao: equip.equipamento?.descricao?.nome,
                    dataValidade: equip.equipamento?.data_validade_calibracao,
                    idEquipamento: equip.equipamento.descricao.id,
                    disponibilidade: equip.disponibilidade?.nome,
                })),
            ];
        });

        // console.log("Equipamentos Selecionados:", this.selectedEquipamentos);
        // console.log("Pacientes Selecionados:", this.selectedPacienteItems);
    } else {
        this.dataRequisicao = "";
        this.dataPrevista = "";
        this.filterPaciente = [];
    }
},


    async atualizarDados() {
      this.isLoadingDados = true;
      try {
        this.selectedPacienteItems = [];
        this.pacienteSelecionado = null;
        this.dataRequisicao = "";
        this.dataPrevista = ''
        this.filterPaciente = [];
        await Promise.all([
          this.getPacientes()
        ]);
      } catch (error) {
        this.$toast.error('Erro ao atualizar os dados');
        console.error(error);
      } finally {
        this.isLoadingDados = false;
      }
    },

    async ItensRetiradosPacienteNoEquipamentoCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/equipamento_editar/${item.idEquipamentoReferencia}/`, {
            id: item.idEquipamentoReferencia,
            disponibilidade: 4,
          }),          
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEquipamentoCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
        api.put(`/equipamentos/equipamento_editar/${item.idEquipamentoEntregue}/`, {
          id: item.idEquipamentoEntregue,
          disponibilidade: 6,
        })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar equipamento:', error);
        this.$toast.error('Erro ao atualizar equipamento.');
      }
    },
    async ItensAlteradosNoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/equipamento/${item.idEquipamento}/`, {
            id: item.idEquipamento,
            disponibilidade: 4
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/equipamento-id/${item.idEquipamentoEntregue}/`, {
            equipamento: item.idEquipamentoEntregue,
            disponibilidade: 16,
            ativo: false,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },



    async ItensRetiradosPacienteNoEquipamento() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/equipamento_editar/${item.idEquipamentoReferencia}/`, {
            id: item.idEquipamentoReferencia,
            disponibilidade: 6,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEquipamento() {

      try {
         const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/equipamento_editar/${item.idEquipamentoEntregue}/`, {
            id: item.idEquipamentoEntregue,
            disponibilidade: 1,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar equipamento:', error);
        this.$toast.error('Erro ao atualizar equipamento.');
      }
    }, 
    async ItensAlteradosNoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/equipamento/${item.idEquipamento}/`, {
            id: item.idEquipamento,
            ativo: false,
            data_troca: this.dataTrocaEfetivada,
            troca: null,
            disponibilidade: 17,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/equipamento-id/${item.idEquipamentoEntregue}/`, {
            equipamento: item.idEquipamentoEntregue,
            data_implantacao: this.dataTrocaEfetivada,
            // data_troca: this.dataTrocaEfetivada,
            disponibilidade: 1,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },

    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      await this.ItensRetiradosPacienteNoEquipamento();
      await this.ItensInluidosPacienteNoEquipamento();  
      await this.ItensAlteradosNoPaciente();
      await this.ItensInlucidosnoPaciente();
      this.$toast.success('Baixado com sucesso!');
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      
    },

    async cancelarEntrega() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      this.isLoadingaCancelar = true
      await this.ItensRetiradosPacienteNoEquipamentoCANCELAR(); // correto
      await this.ItensAlteradosNoPacienteCANCELAR(); // correto
      await this.ItensInluidosPacienteNoEquipamentoCANCELAR(); // correto
      await this.ItensInlucidosnoPacienteCANCELAR();
      this.$toast.success('Cancelada com sucesso!');
      this.isLoadingaCancelar = false
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
    }

  },
  computed: {
    isSaveEnabled() {
      // Se não houver nenhuma seleção em alguma das tabelas, retorna false
      if (!this.selectedPacienteItems.length ) {
          return false;
      }
      return true;
    },
  },
  mounted() {
    this.getPacientes();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.dataTrocaEfetivada = dataFormatada
  },
};
</script>

<style scoped>
.custom-messages ::v-deep .v-messages__message {
    color: red !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>
<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >
    <v-row dense>
      <v-col>
        <v-data-table  dense  :loading="isLoadingAlugados" show-expand :headers="headers"  :items="sortedAlugados"  item-value="id"  v-model="selectedUsuarioItems"  class="elevation-1"  :items-per-page="5"   >
        <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-badge left bordered overlap :content="activeFilters.length">
              <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                <v-icon left>
                  mdi-filter
                </v-icon>
                Filtros
              </v-btn>
            </v-badge>
            <v-btn class="mr-4 elevation-0" text @click="atualizar" :loading="isLoadingDados"> <v-icon left>mdi-reload</v-icon> Atualizar  </v-btn>
            <v-btn color="success" class="elevation-0" @click="abrirModalNovo" > <v-icon left> mdi-plus </v-icon>  Novo </v-btn>
          </v-col>
          <v-col cols="auto" class="pa-0 mr-4" 
              v-if="itemsStatus.vencidos > 0 || itemsStatus.proximosVencer > 0">
              <div class="d-flex align-center">
                  <v-icon small color="error" left>mdi-alert</v-icon>
                  <div class="d-flex flex-column ml-1 status-text">
                      <span class="text-caption text-xs">Calibração</span>
                      <span v-if="itemsStatus.vencidos > 0" class="error--text text-xs font-weight-medium">
                          {{ itemsStatus.vencidos }} {{ itemsStatus.vencidos === 1 ? 'Item' : 'Itens' }} vencido(s)
                      </span>
                      <span v-if="itemsStatus.proximosVencer > 0" class="warning--text text-xs font-weight-medium">
                          {{ itemsStatus.proximosVencer }} {{ itemsStatus.proximosVencer === 1 ? 'Item' : 'Itens' }} próx. venc.
                      </span>
                  </div>
              </div>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card outlined v-show="showFilters" dense>
          <h4 class="ml-4 mt-4">Filtrar por</h4>
          <v-row dense class="py-2 px-4">
              <v-col cols="2">
              <v-select hide-details :items="statusOptionsManutencao" label="Status" item-text="label" item-value="value"
                  v-model="filters.is_active.value" outlined dense />
              </v-col>
          </v-row>
          <v-card-actions>
              <v-row dense>
              <v-col class="text-end">
                  <v-btn color="error" text elevation="0" @click="clearFilters">
                  Limpar filtros
                  </v-btn>
                  <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                  Aplicar filtros
                  </v-btn>
              </v-col>
              </v-row>
          </v-card-actions>
          </v-card>
        </v-expand-transition>
        </template>
        <template v-slot:items="props">
          <tr>
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.equipamento.descricao.nome }}</td>
            <td>{{ props.item.usuario?.apelidoEspecialidade || "Não atribuído" }}</td>
            <td>{{ props.item.paciente?.nome || "" }}</td>
                <!-- Outros campos -->
              </tr>
          </template>
          <template v-slot:[`item.dataContratacao`]="{ item }">
              {{ item.dataContratacao | formatDatabr }}
          </template>
          <template v-slot:[`item.equipamento.data_validade_calibracao`]="{ item }">
              {{ item.equipamento?.data_validade_calibracao | formatDatabr }}
          </template>
          <template v-slot:[`item.data_envio`]="{ item }">
              {{ item.data_envio | formatDatabr }}
          </template>
          <template v-slot:[`item.data_recebimento`]="{ item }">
              {{ item.data_recebimento | formatDatabr }}
          </template>
          <template v-slot:[`item.usuario.data_devolucao`]="{ item }">
              {{ item.usuario?.data_devolucao | formatDatabr }}
          </template>
          <template v-slot:[`item.valor`]="{ item }">
              {{ item.valor | formatReal }}
          </template>
          <template v-slot:[`item.preventiva`]="{ item }">
              {{ item.preventiva ? 'Sim' : 'Não'}}
          </template>
          <template v-slot:[`item.corretiva`]="{ item }">
              {{ item.corretiva ? 'Sim' : 'Não'}}
          </template>
          <template v-slot:[`item.calibracao`]="{ item }">
              {{ item.calibracao ? 'Sim' : 'Não'}}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
              <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                  <span class="white--text">{{ item.ativo ? 'Em Manutenção' : 'Devolvido / Inativo' }}</span>
              </v-chip>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRows(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
          </template>
          <!-- Expanded Content -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <div class="expanded-content">
                <h4>Observação: {{ item.obs || "Nenhuma observação disponível" }}</h4>
              </div>
            </td>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog.create3" v-if="dialog.create3"  max-width="90%" @input="handleDialogClose" >
            <v-card>
                <v-card-title class="sticky-title title-border">
                    Cadastro de Novo Equipamento Alugado:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog"> <v-icon>  mdi-close  </v-icon> </v-btn>
                </v-card-title>
                  <v-card-text>
                      <EntradaEquipamentoAlugado :loading="isLoading('post:equipamento')" :onSubmit="createEquipamento" />
                  </v-card-text>
            </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton  :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" >  Salvar  </ConfirmButton>
            </span>
          </template>
          <span>Selecione pelo menos um item e uma disponibilidade para salvar</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <FaturamentoLocadora v-model="showModal" :showModal="showModal" @update:showModal="showModal = false"></FaturamentoLocadora>
<!-- Edição -->
    <v-dialog v-model="dialog.update" max-width="80%" >
      <v-card>
        <v-card-title class="blod_color">  
          Equipamento em Manutenção: ID - {{selectedItem?.id}}, ID do Equipamento - {{selectedItem?.equipamento?.id}}, Equipamento: {{selectedItem?.equipamento?.descricao?.nome}}, Fornecedor: {{selectedItem?.fornecedor?.nome_fantasia }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text >
          <v-container>
            <v-form @submit.prevent="localOnSubmit" ref="formRef">
              <v-row dense>
                <v-col >
                  <v-text-field  dense  outlined  :value="selectedItem?.id || ''"  label="Id"  disabled readonly/>
                </v-col>
                <v-col >
                  <v-text-field  dense  outlined  :value="selectedItem?.equipamento?.id || ''"  label="Equipamento"  disabled readonly/>
                </v-col>
                <v-col cols="4">
                  <v-text-field   dense  outlined  :value="selectedItem?.equipamento?.descricao?.nome || ''"  label="Equipamento"  readonly />
                </v-col>
                <v-col >
                  <v-text-field  dense  type="date"  outlined  label="Data Validade Calibração" :value="selectedItem?.equipamento?.data_validade_calibracao || ''" v-model="formUpdate.data_validade_calibracao" clearable/>
                </v-col>
              </v-row>
              <v-row dense >
                <v-col>
                  <v-text-field  label="Fornecedor"  :value="selectedItem?.fornecedor.nome_fantasia"  readonly  outlined  dense  ></v-text-field>
                </v-col>
                 <v-col >
                  <v-autocomplete :loading="isLoadingDeposito" dense label="Depósito"  outlined v-model="formUpdate.deposito" :value="selectedItem?.deposito?.id" :items="deposito"  item-text="nome" item-value="id" />
                </v-col>
                <v-col>
                  <v-text-field  label="Data de Envio" type="date" v-model="formUpdate.data_envio"  outlined  dense  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field  label="Data de Devolução" type="date" :value="selectedItem?.data_recebimento"  outlined  dense v-model="formUpdate.data_recebimento" ></v-text-field>
                </v-col>

              </v-row>
              <v-row dense>
                <v-col >
                  <vuetify-money  dense  label="Valor" outlined clearable v-model="formUpdate.valor"  :value="selectedItem?.valor || ''" :options="options" />  
                </v-col>
                <v-col >
                  <v-text-field  dense  label="Observações" outlined clearable v-model="formUpdate.obs"  :value="selectedItem?.obs || ''"  />  
                </v-col>
              </v-row>
              <v-col cols="auto">
          </v-col>
              <v-row dense>
                <v-col >
                  <v-btn-toggle v-model="formUpdate.ativo" mandatory class="white--text">
                    <v-btn :value="false" :color="!formUpdate.ativo ? 'green' : ''">
                      Receber Equipamento
                    </v-btn>
                    <v-btn :value="true" :color="formUpdate.ativo ? 'error' : ''">
                      Em Manutenção
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn   color="success"    @click="showConfirmDialog2"   :loading="loadingUpdate"   > Salvar <v-dialog  persistent   max-width="290"  v-model="confirmDialogOpen2" >
              <v-card>
                <v-card-title>Atenção</v-card-title>
                <v-card-text>
                  Tem certeza que deseja salvar esse novo registro?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="confirmDialogOpen2 = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="green" text @click="confirmSalvarupdateRegistro">
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- Novo Registro -->
    <v-dialog v-model="dialog.novo" max-width="1200px">
      <v-card>
        <v-card-title class="blod_color">
          Envio Equipamento para Manutenção:
           <v-spacer></v-spacer>
           <v-btn icon @click="dialog.novo = false">
                <v-icon>
                mdi-close
                </v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5">
                <v-autocomplete :loading="isLoadingFornecedor" v-model="novoRegistro.fornecedor" :items="fornecedor" item-text="nome_fantasia" item-value="id" label="Fornecedor" required dense outlined ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete :loading="isLoadingEquipamentoLista" v-model="novoRegistro.equipamento" :items="equipamentoFormatados" item-text="nome_formatado" item-value="id" label="Equipamento" required dense outlined ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field dense  label="Data do Envio" type="date" outlined clearable v-model="novoRegistro.data_envio"/>  
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-select v-model="novoRegistro.preventiva" :items="statusOptions" item-text="label" item-value="value" label="Manutenção Preventiva" dense outlined required></v-select>
              </v-col>
              <v-col cols="4">
                <v-select v-model="novoRegistro.corretiva" :items="statusOptions" item-text="label" item-value="value" label="Manutenção Corretiva" dense outlined required></v-select>
              </v-col>
              <v-col cols="4">
                <v-select v-model="novoRegistro.calibracao" :items="statusOptions" item-text="label" item-value="value" label="Necessita de Calibração" dense outlined required></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field dense  label="Observação"  outlined clearable v-model="novoRegistro.obs"/> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <div class="align-self-top py-4" left top   > 
              <v-menu eager offset-x left offset-y top v-model="menu">
                <!-- <template v-slot:activator="{ on, attrs }"  >
                  <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on" :disabled="!novoRegistro.fornecedor || !novoRegistro.equipamento || !novoRegistro.data_envio || novoRegistro.preventiva === null || novoRegistro.corretiva  === null || novoRegistro.calibracao  === null "> mdi-printer </v-icon>
                </template> -->
                <v-container class="container">
                  <template >
                    <div > 
                      <Relatorio ref="relatorioComponent"  :fornecedor="novoRegistro.fornecedor"  :equipamento="novoRegistro.equipamento"  :dataEnvio="novoRegistro.data_envio" :corretiva="novoRegistro.corretiva"  :preventiva="novoRegistro.preventiva"  :calibracao="novoRegistro.calibracao"  :obs="novoRegistro.obs" > </Relatorio>
                    </div>
                  </template>
                </v-container>
              </v-menu>
            </div>
          </v-col>
          <v-btn   color="success"    @click="showConfirmDialog"   :loading="loadingSave"   :disabled="!novoRegistro.fornecedor || !novoRegistro.equipamento || !novoRegistro.data_envio || novoRegistro.preventiva === null || novoRegistro.corretiva  === null || novoRegistro.calibracao  === null " > Salvar <v-dialog  persistent   max-width="290"  v-model="confirmDialogOpen" >
              <v-card>
                <v-card-title>Atenção</v-card-title>
                <v-card-text>
                  Tem certeza que deseja salvar esse novo registro?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="confirmDialogOpen = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="green" text @click="confirmSalvarNovoRegistro">
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import EntradaEquipamentoAlugado from './EntradaEquipamentoAlugado.vue';
import Relatorio from '../../relatorios/entregaSuprimento/EntregaManutencaoEquipamento.vue';
import FaturamentoLocadora from '../../relatorios/entregaSuprimento/FaturamentoLocadoraEquipamento.vue';

export default {
  name: "UsuarioEquipamento",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingAlugados: false,
      equipamentoManutencao: [],
      deposito: [],
      isLoadingFornecedor: false,
      isLoadingDisponibilidade: false, 
      isLoadingFornecedorLista: false, 
      isLoadingEquipamentoLista: false, 
      isLoadingDeposito: false,
      isLoadingDados: false,
      isSaveEnabled: false,
      loadingUpdate: false,
      selectedUsuarioItems: [], // Alterado de string para array
      fornecedor: [],
      disponibilidade: [],
      dialog: { update: false, novo: false, create3: false, },
      novoRegistro: {
        fornecedor: null,
        equipamento: null,
        data_envio: null,
        preventiva: null,
        corretiva: null,
        calibracao: null,
        obs: null
      },
      equipamentoLista: [],
      usuaioFormatados: [],
      fornecedorLista: [],
      loadingSave: false,
      selectedItem: null,
      formUpdate: {
        data_devolucao: null,
        data_validade_calibracao: null,
        data_inicio_locacao: null,
        ativo: true,
        deposito: null,
        obs: null,
      },
      dataOriginal: null,
      confirmDialogOpen: false,
      confirmDialogOpen2: false,
      menu: false,
      isReportTriggered: false,
      filters: {
      is_active: { value: true, compareType: 'equal', prop: 'ativo' }},
      activeFilters: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
      statusOptionsManutencao: [
        { label: "Em Manutenção", value: true },
        { label: "Disponivel / Inativo", value: false }
      ],
      options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2,
        },
      showFilters: false,
      search: '',
      showModal: false,
      // usuario: [],
      headers: [
        { text: "Editar", value: "edit" },
        { text: "ID", value: "id" },
        { text: "ID do Equipamento", value: "equipamento.id" },
        { text: "Equipamento", value: "equipamento.descricao.nome", width: 200 },
        { text: "Patrimônio", value: "equipamento.patrimonio" },
        { text: "Disponibilidade", value: "equipamento.disponibilidade.nome" },
        { text: "Data Envio", value: "data_envio" },
        { text: "Data Devolução", value: "data_recebimento" },
        { text: "Deposito", value: "equipamento.deposito.nome" },
        { text: "Data Validade da Calibração", value: "equipamento.data_validade_calibracao" },
        { text: "Fornecedor", value: "fornecedor.nome_fantasia" },
        { text: "Preventiva?", value: "preventiva" },
        { text: "Corretiva", value: "corretiva" },
        { text: "Calibração", value: "calibracao" },
        { text: "Valor", value: "valor" },
        { text: "Status", value: "ativo" },
      ],
    });
  },
  components: { ConfirmButton , EntradaEquipamentoAlugado, Relatorio , FaturamentoLocadora},

  methods: {
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true;
      try {
       const { data } = await api('fornecedores/fornecedores/')
        this.fornecedor = data
          .filter(item => 
            item.ativo && 
            item.tipo.some(tipo => tipo.id === 9)
          )
          .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia))
      
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedor. ${error}`);
      } finally {
        this.isLoadingFornecedor = false;
      }
    },
    async getDeposito() {
      this.isLoadingDeposito = true;
      try {
        const { data } = await api.get(`/uteis/deposito/`);
        this.deposito = data.filter(item => item.ativo)
        // console.log(this.deposito)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDeposito = false;
      }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      
      if (!Array.isArray(this.selectedPacienteItems)) {
        this.$toast.error("Nenhum item selecionado");
        return;
      }

      const Itens = this.selectedPacienteItems.map(item => {
        const fields = {
          id: item.equipamento.id,
          disponibilidade: 6,
          idequipamentoPaciente: item.id
        };

        // const hoje = new Date();
        // const dataFormatada = hoje.getFullYear() + '-' + 
        //   String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        //   String(hoje.getDate()).padStart(2, '0');
          
        // if (this.currentItem.disponibilidade === 10) {
        //   fields.data_validade_esterilizacao = dataFormatada;
        // }
          
        return fields;
      });

      for (const fields of Itens) {
        try {
          await this.onSubmit(fields);
          await this.updatePacienteEsterelizavel([fields.idequipamentoPaciente]);
        } catch (error) {
          console.error("Erro ao salvar os dados principais:", error);
          this.$toast.error("Erro ao salvar os dados principais.");
        }
      }
    },
    atualizar() {
      this.getEquipamentoAlugados();
      this.clearFilters();
      this.showFilters = false;
      this.search = '';

    },
    async getFornecedorsLista() {
      this.isLoadingFornecedorLista = true
      try {
        const response = await api.get('/contas/usuarios/?ativo=True&pesquisar=false');  
        // console.log(response)
        this.usuariosLista = response.data
        // .filter(item => item.especialidade && item.especialidade.id !== 10)
          .sort((a, b) => {
                    const nomeA = a.apelido?.apelido?.toLowerCase() || '';
                    const nomeB = b.apelido?.apelido?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
        // console.log(this.usuariosLista)
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de usuários: ${error}`);
      } finally {
        this.isLoadingFornecedorListaa = false
      }
    },
    async getEquipamentoAlugados() {
      this.isLoadingAlugados = true;
      try {
        const response = await api.get('/equipamentos/equipamento_manutencao/');
        this.equipamentoManutencao = response.data;

        // Ordenação por três parâmetros
        this.equipamentoManutencao.sort((a, b) => {
          // Ordenar por "ativo" (true > false)
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
          // Ordenar por "descricao.nome" (nome do equipamento)
          return a.equipamento.descricao.nome.localeCompare(b.equipamento.descricao.nome);
        });
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de equipamentos alugados: ${error}`);
      } finally {
        this.isLoadingAlugados = false;
      }
    },
    async getEquipamentoLista() {
      this.isLoadingEquipamentoLista = true
      try {
        const response = await api.get('equipamentos/equipamentos/');  // Ajuste a rota conforme sua API
        this.equipamentoLista = response.data
          .filter(item => item?.ativo && item?.disponibilidade?.id === 6 && item?.empresa_locadora === null)
          .sort((a, b) => {
                    const nomeA = a.descricao?.nome?.toLowerCase() || '';
                    const nomeB = b.descricao?.nome?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
        // console.log( this.equipamentoLista)
      } catch (error) {
        this.$toast.error(`Erro ao carregar lista de equipamento: ${error}`);
      } finally {
        this.isLoadingEquipamentoLista = false
      }
    },
    async abrirModalNovo() {
      this.dialog.novo = true;
      await Promise.all([
        this.getFornecedorsLista(),
        this.getEquipamentoLista()
      ]);
    },
    fecharModal() {
      this.dialog.novo = false;
      // this.showModal = false;
      this.novoRegistro = {
        usuario: null,
        equipamento: null
      };
    },
    async applyFilters() {
        this.search = '';
        const filters = JSON.parse(JSON.stringify(this.filters));
        this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
    },
    clearFilters() {
        Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '');
        this.filters = {
            is_active: { value: true, compareType: 'equal', prop: 'ativo' }
        };
        this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }];
    },
    async createEquipamento(fields) {
      const LOADING_NAME = "post:equipamento";
      this.setLoading(LOADING_NAME);

      try {
          // Criação do equipamento

          const response = await api.post('equipamentos/equipamento_criar/', fields);
          const equipamentoId = response.data?.id; // Supondo que o ID seja retornado neste campo

          if (equipamentoId) {
          this.$toast.success("Equipamento Criado com Sucesso");

          // Preparar os dados para o equipamento alugado
          const alugadoFields = {
              fornecedor: fields.empresa_locadora,
              nomeEquipamento: fields.nomeEquipamento,
              equipamento: equipamentoId,
              dataContratacao: fields.data_inicio_locacao,
              valor: fields.valordiario ? fields.valordiario : fields.valormensal,
              desconto: 0,
              cobranca: fields.valordiario > fields.valormensal,
              ativo: true,
          };

          // Criação da linha no endpoint de equipamento alugado
          try {
              await api.post('equipamentos/equipamento_alugado_criar/', alugadoFields);
              this.$toast.success("Equipamento Alugado Criado com Sucesso");
          } catch (error) {
              console.error("Erro ao criar Equipamento Alugado:", error);
              this.$toast.error("Erro ao criar Equipamento Alugado.");
          }
          } else {
          this.$toast.error("Equipamento criado, mas ID não retornado pela API.");
          }

          this.dialog.create3 = false;
          this.atualizar();
      } catch (error) {
          console.error("Erro ao criar Equipamento:", error);
          this.$toast.error(`Erro ao criar Equipamento: ${error}`);
      } finally {
          this.setLoading(LOADING_NAME, true);
      }
    },
    async updateRegistro() {
      if (!this.formUpdate.data_recebimento && this.formUpdate.ativo === false) {
        this.$toast.error("Informe a data de recebimento e o status");
        return;
      }

      this.loadingUpdate = true;
      try {
        // Altera o aluguel
        await api.put(`/equipamentos/equipamento_manutencao/${this.selectedItem.id}/`, {
          data_envio: this.formUpdate.data_envio,
          data_recebimento: this.formUpdate.data_recebimento ? this.formUpdate.data_recebimento : null,
          valor: this.formUpdate.valor ? this.formUpdate.valor : null,
          // desconto:this.formUpdate.desconto ? this.formUpdate.desconto : null,
          ativo: this.formUpdate.ativo,
          obs: this.formUpdate.obs ? this.formUpdate.obs : null,
        });
        

        // Agora altera equipamento
        const status = this.formUpdate.ativo === true ? 19 : 6
        
        await api.put(`/equipamentos/equipamento_editar/${this.selectedItem.equipamento.id}/`, {
          id:this.selectedItem.equipamento.id, 
          disponibilidade: status,
          deposito: this.formUpdate.deposito,
          data_validade_calibracao: this.formUpdate.data_validade_calibracao
        });

        this.$toast.success("Registro alterado com sucesso!");
        this.dialog.update = false
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar: ${error}`);
      } finally {
        this.loadingUpdate = false;
      }
    },
    onSelectRows(item, type) {
      if (type === 'update') {
        this.selectedItem = item;
        this.dataOriginal = item.data_devolucao;  // Guarda a data original
        this.formUpdate = {
          ativo: item.ativo,
          deposito: item.equipamento?.deposito?.id || null,
          data_validade_calibracao: item.equipamento?.data_validade_calibracao || null,
          data_envio: item.data_envio || null,
          data_recebimento: item.data_recebimento || null,
          valor: item.valor || null,
          desconto: item.desconto || null,
          obs: item.obs || null,
        };
        this.dialog.update = true;
      }
    },
    showConfirmDialog() {
      if (this.novoRegistro.fornecedor && this.novoRegistro.equipamento) {
        this.confirmDialogOpen = true;
      }
    },
    showConfirmDialog2() {
      if ((this.formUpdate.ativo && !this.formUpdate.data_recebimento) || (!this.formUpdate.ativo && this.formUpdate.data_recebimento)) {
        this.confirmDialogOpen2 = true;
      }
    },
    confirmSalvarNovoRegistro() {
      this.confirmDialogOpen = false;
      this.salvarNovoRegistro();
      this.isReportTriggered = true;
    },
    async salvarNovoRegistro() { 
      // this.confirmDialogOpen = false;
      this.loadingSave = true;
      try {
        const payload = {
          fornecedor: this.novoRegistro.fornecedor,
          equipamento: this.novoRegistro.equipamento, // Certifique-se de que é o ID
          data_envio: this.novoRegistro.data_envio,
          corretiva: this.novoRegistro.corretiva,
          preventiva: this.novoRegistro.preventiva,
          calibracao: this.novoRegistro.calibracao,
          obs: this.novoRegistro.obs,
          ativo: true,
        };

        // Envia o payload para criar a manutenção
        await api.post(`/equipamentos/equipamento_manutencao_criar/`, payload);

        // Atualiza a disponibilidade do equipamento após criar a manutenção
        await api.put(`/equipamentos/equipamento_editar/${this.novoRegistro.equipamento}/`, {
          disponibilidade: 19, // Exemplo: "Em Manutenção"
        });

        // Atualiza a lista de equipamentos na interface
        this.getEquipamentoAlugados();

        this.$toast.success("Manutenção criada com sucesso!");
        this.dialog.novo = false
        this.fecharModal();
      } catch (error) {
        console.error("Erro ao criar a manutenção:", error);
        this.$toast.error("Erro ao criar a manutenção.");
      } finally {
        this.confirmDialogOpen = false;
        this.loadingSave = false;
      }
    },
    confirmSalvarupdateRegistro() {
      this.confirmDialogOpen2 = false;
      this.updateRegistro();
    },
    generateReport() {
       if (this.novoRegistro.fornecedor > 0 ) {
        this.$toast.success("Gerando relatório...");
        // Chama o componente ou função de geração do relatório
        this.$refs.relatorioComponent.generatePDF(this.novoRegistro.fornecedor, this.novoRegistro.equipamento, this.novoRegistro.data_envio, this.novoRegistro.corretiva, this.novoRegistro.preventiva, this.novoRegistro.calibracao, this.novoRegistro.obs);
      } else {
        this.$toast.error("Nenhum item selecionado para o relatório.");
      }
      
    },
     handleDialogClose(val) {
      if (!val) { // quando o diálogo fecha, val será false
          this.atualizar();
      }
    },
    closeDialog() {
      this.dialog.create3 = false; //
      this.atualizar();
    },
  },
    computed: {  
    itemsStatus() {
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            
            // Initialize all counters
            let vencidos = 0;
            let proximosVencer = 0;

            this.equipamentoManutencao.forEach(item => {
                const naoEstaEmEsterilizacao = (item?.equipamento?.disponibilidade?.id === 6 || item?.equipamento?.disponibilidade?.id === 19);
                
                // calibracao
                if (item?.equipamento?.data_validade_calibracao && naoEstaEmEsterilizacao) {
                    const dataValidade = new Date(item?.equipamento?.data_validade_calibracao);
                    dataValidade.setHours(0, 0, 0, 0);
                    
                    if (dataValidade < hoje) {
                        vencidos++;
                    } else {
                        const diasAteVencer = Math.ceil((dataValidade - hoje) / (1000 * 60 * 60 * 24));
                        if (diasAteVencer <= 90) {
                            proximosVencer++;
                        }
                    }
                }
            });
            return {
                vencidos,
                proximosVencer,
            };
        },
    equipamentoFormatados() {
      return this.equipamentoLista.map(item => ({
        ...item,
        nome_formatado: `ID: ${item.id} - ${item.descricao.nome}`
      }));
    },
    usuarioFormatados() {
      if (!this.usuariosLista) return [];
      
      return this.usuariosLista.map(item => {
        // Ajuste estes campos de acordo com a estrutura real dos seus dados
        const id = item.id || '';
        const nome = item.apelido || '';
        const especialidade = item.especialidade?.nome || 'Sem especialidade';
        
        return {
          ...item,
          nome_formatado_usuario: `ID: ${id} - ${nome} - ${especialidade}`
        };
      });
    },
    sortedAlugados() {
        if (!this.equipamentoManutencao || !Array.isArray(this.equipamentoManutencao)) {
          return [];
        }

        // Aplica filtros ativos
        let alugadosFiltrados = this.equipamentoManutencao;
        if (this.activeFilters.length > 0) {
          const filterBy = UtilsFunc.createFilter(this.activeFilters);
          alugadosFiltrados = alugadosFiltrados.filter(filterBy);
        }

        // Aplica o termo de busca
        if (this.search) {
          const searchTerm = this.search.toLowerCase().trim();
          alugadosFiltrados = alugadosFiltrados.filter(item => {
            const fornecedorNome = item.fornecedor?.nome_fantasia?.toLowerCase() || '';
            const equipamentoNome = item.equipamento?.descricao?.nome?.toLowerCase() || '';
            const id = item.id?.toString() || '';
                  const esterilizavelId = item.esterilizavel?.id?.toString() || '';

            return (
              fornecedorNome.includes(searchTerm) ||
              equipamentoNome.includes(searchTerm) ||
              id.includes(searchTerm) ||
              esterilizavelId.includes(searchTerm)
            );
          });
        }

        // Separa usuários ativos e inativos
        const alugadosAtivos = alugadosFiltrados.filter(user => user.ativo);
        const alugadosInativos = alugadosFiltrados.filter(user => !user.ativo);

        // Ordenação por apelido
        const ordenarPorApelido = (a, b) => {
          const apelidoA = (a.equipamento?.descricao?.nome || '').toLowerCase();
          const apelidoB = (b.equipamento?.descricao?.nome || '').toLowerCase();
          return apelidoA.localeCompare(apelidoB);
        };

        // Ordena os dois arrays
        const alugadosAtivosOrdenados = alugadosAtivos.sort(ordenarPorApelido);
        const alugadosInativosOrdenados = alugadosInativos.sort(ordenarPorApelido);

        // Concatena usuários ativos seguidos pelos inativos
        return [...alugadosAtivosOrdenados, ...alugadosInativosOrdenados];
    },
  },
  mounted() {
    this.getEquipamentoAlugados()
    this.getDeposito()
    this.getFornecedor();
    // this.getUsuario()
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.novoRegistro.data_envio = dataFormatada
    this.applyFilters();
    this.getDisponibilidade();   
    this.getFornecedorsLista();
    this.getEquipamentoLista();
  },
  watch: {
    selectedUsuarioItems: {
      handler(newVal) {
        this.isSaveEnabled = Array.isArray(newVal) && newVal.length > 0;
      },
      immediate: true
    },
    'formUpdate.ativo': {
      handler(newValue) {
        if (newValue === false) {  // Mudou para Devolver
          const hoje = new Date();
          const dataFormatada = hoje.getFullYear() + '-' + 
            String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
            String(hoje.getDate()).padStart(2, '0');
          
          this.formUpdate.data_recebimento = this.dataOriginal || dataFormatada;
        } else {  // Mudou para Em Uso
          this.formUpdate.data_recebimento = null;  // Limpa a data
        }
      },
      immediate: true
    },
    isReportTriggered(newValue) {
      if (newValue) {
        this.generateReport();
        this.isReportTriggered = false; // Reseta o flag
      }
    }
  }
};
</script>

<style scoped>
.expanded-content {
  width: 100%;
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.expanded-content h4 {
  margin-bottom: 8px;
  font-weight: bold;
}

.expanded-content p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

</style>

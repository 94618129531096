<template>
  <v-container class="container">
    <v-btn color="success" depressed size="small" @click="generatePDF">
      <v-icon left>mdi-printer</v-icon> Rel. Notas Fiscais Equipe APROVADAS
    </v-btn>
  </v-container>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../service/utilsFunc";
import logo from "../../assets/logo.png";
import gptw from "../../assets/GPTW_2023-2024.png"
import ona from "../../assets/ONA_Nivel_3.png"
import UtilsPDF from '../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Produtividade",
  data: () => withCRUDUtils({
    loading: false,
  }),
  props: {
    dados: { type: Array, default: () => [] },
    compinicial: String,
    compfinal: String,
  },
  methods: {
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF: (L-38):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relação de Notas Fiscais Equipe`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, {
        align: "right",
      });

      if (this.dados && this.dados.length > 0) {
        const competencia1 = moment(this.compinicial, "MM/YYYY").isValid() ? moment(this.compinicial, "MM/YYYY").format("MM/YYYY") : 'Data Inválida';
        const competencia2 = moment(this.compfinal, "MM/YYYY").isValid() ? moment(this.compfinal, "MM/YYYY").format("MM/YYYY") : 'Data Inválida';

        doc.setFontSize(11);
        doc.text(`Competência: ${competencia1} até ${competencia2}`, 73, 32);
      }

    },
    generatePDF() {
      this.loading = true;
      this.$toast.success(`O documento está sendo preparado. Aguarde, por favor.`);

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      // Filtra os dados para incluir apenas os aceitos
      const dadosFiltrados = this.dados.filter(item => item.aceite);

      // Obtém a competência do primeiro item, supondo que todos os itens têm a mesma competência
      const competencia1 = moment(this.compinicial, "MM/YYYY").isValid() ? moment(this.compinicial, "MM/YYYY").format("MM/YYYY") : 'Desconhecida';
      const competencia2 = moment(this.compfinal, "MM/YYYY").isValid() ? moment(this.compfinal, "MM/YYYY").format("MM/YYYY") : 'Desconhecida';

      // Prepara os dados para a tabela e calcula o total
      let valorTotal = 0;
      const corpoTabela = dadosFiltrados.map(item => {
        const valorConsiderado = item.antecipacao ? item.valor_antecipacao_nf : item.valor_nf;
        valorTotal += valorConsiderado;
        return [
          item.id,
          item.prestador.apelido,
          item.prestador.especialidade.nome,
          item.valor_nf.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          item.antecipacao ? 'Sim' : 'Não',
          item.obs || '',
          valorConsiderado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        ];
      });

      // Adiciona a tabela ao documento
      doc.autoTable({
        head: [['ID', 'Prestador', 'Especialidade', 'Valor N.F.', 'Antecipação', 'Observações', 'Valor a Considerar']],
        body: corpoTabela,
        // theme: 'grid',
        startY: 40,
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.02,
        },
      });

      // Adiciona o valor total
      const finalY = doc.autoTable.previous.finalY; // Posição Y após a tabela
      const pageWidth = doc.internal.pageSize.width;
      const rightMargin = 15; 
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total: ${valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, pageWidth - rightMargin, finalY + 10, { align: 'right' });

      UtilsPDF.addPageNumbers(doc);
      const filename = `Relatório Notas Fiscais Equipe - Competência: ${competencia1} até ${competencia2}.pdf`;
      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },

  },
};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}
</style>

<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-data-table :loading="isTableLoading" loading-text="Carregando Informações...." :items="filteredData" :headers="headersWithValidadeInfo" :search="search" class="fixed-header-table" fixed-header height="600px" :items-per-page="-1" >
                <template v-slot:top>
                    <v-row class="mt-2">
                        <v-col cols="5">
                            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
                        </v-col>
                        <v-col class="text-end">
                            <v-badge left bordered overlap :content="activeFilters.length">
                                <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                                <v-icon left>
                                    mdi-filter
                                </v-icon>
                                Filtros
                                </v-btn>
                            </v-badge>
                            <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar </v-btn>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-card outlined v-show="showFilters" dense>
                        <h4 class="ml-4 mt-4">Filtrar por</h4>
                        <v-row dense class="py-2 px-4">
                            <v-col cols="2">
                            <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                                v-model="filters.is_active.value" outlined dense />
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-row dense>
                            <v-col class="text-end">
                                <v-btn color="error" text elevation="0" @click="clearFilters">
                                Limpar filtros
                                </v-btn>
                                <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                                Aplicar filtros
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                    <v-row class="mt-2 mb-2">
                        <!-- Wrapper dos botões alinhados à esquerda -->
                        <v-col cols="auto" class="d-flex button-group pa-1"> <!-- gap-4 adiciona espaçamento entre os botões -->
                            <!-- Botão Novo EEquipamento-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  color="success"  class="elevation-0 compact-btn ml-3"  @click="dialog.create2 = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon><div class="d-flex flex-column compact-btn-text"> <span>"Próprio" Novo</span> <span>Equipamento</span> </div> </v-btn>
                                </template>
                                <span>Clique para cadastrar um novo equipamento próprio</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  color="success"  class="elevation-0 compact-btn ml-3"  @click="dialog.create3 = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon><div class="d-flex flex-column compact-btn-text"> <span>"Alugado" Novo</span> <span>Equipamento</span> </div> </v-btn>
                                </template>
                                <span>Clique para cadastrar um novo equipamento alugado</span>
                            </v-tooltip>
                            <!-- Equipamentos Alugados -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#FABC4A', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.enviar = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Equipamentos</span><span>Locados</span></div> </v-btn>
                                    </template>
                                    <span>Clique para gerenciar os equipamentos alugados</span>
                                </v-tooltip>
                                <!-- <div v-if="quantidadeAlugada >=1" class="badge-red">
                                    {{ quantidadeAlugada }}
                                </div> -->
                            </div>
                            <!-- Botão Receber Esterilizáveis -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#FABC4A', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.create = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Equipamentos</span> <span>Manutenção</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para gerenciar os equipamentos em manutenção</span>
                                </v-tooltip>
                                <div v-if="quantidadeEquipamentoManutencao >=1" class="badge-red">
                                    {{ quantidadeEquipamentoManutencao }}
                                </div>
                            </div>
                            <!-- Botão Trocar Equipamento   -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createTroca = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Trocar</span> <span>Equipamento</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para substituir Equipamento em domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisTroca >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisTroca }}
                                </div>
                            </div>
                            <!-- Botão Retirada de Esterilizáveis -->
                             <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#376EA1', color: 'white' }"  class="elevation-0 compact-btn"  @click="dialog.retirada = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Retirar</span> <span>Equipamento</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para retirar Equipamento do domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisRetirada >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisRetirada }}
                                </div>
                            </div>

                            <!-- Botão Requisições com badge -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createRequisicao = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Requisições</span> <span>Paciente</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Atender Requisição</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveis >= 1" class="badge-red">
                                    {{ quantidadePacientesElegiveis }}
                                </div>
                            </div>
                            <!-- Botão Entrega -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.entrega = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Aguardando</span> <span>Conf. entrega</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Confirma a entrega no domucílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisEntrega >= 1" class="badge-red">
                                    {{ quantidadePacientesElegiveisEntrega }}
                                </div>
                            </div>
                            <!-- Botão Usuário -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#FF6501', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createUsuario = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Usuário</span> <span>Profissional</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Dispensar ou Recolher de Usuário</span>
                                </v-tooltip>
                            </div>
                            <!-- Botão Rastreabilidade -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: 'orange', color: 'white' }" class="elevation-0 compact-btn mr-3 ml-2" @click="dialog.rastreabilidade = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> Rastreabilidade </v-btn>
                                    </template>
                                    <span>Clique para acompanhar a rastreabilidade</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <!--  Vencimento -->
                       <v-col cols="auto" class="pa-0 mr-4" 
                            v-if="itemsStatus.vencidos > 0 || itemsStatus.proximosVencer > 0">
                            <div class="d-flex align-center">
                                <v-icon small color="error" left>mdi-alert</v-icon>
                                <div class="d-flex flex-column ml-1 status-text">
                                    <span class="text-caption text-xs">Calibração</span>
                                    <span v-if="itemsStatus.vencidos > 0" class="error--text text-xs font-weight-medium">
                                        {{ itemsStatus.vencidos }} {{ itemsStatus.vencidos === 1 ? 'Item' : 'Itens' }} vencido(s)
                                    </span>
                                    <span v-if="itemsStatus.proximosVencer > 0" class="warning--text text-xs font-weight-medium">
                                        {{ itemsStatus.proximosVencer }} {{ itemsStatus.proximosVencer === 1 ? 'Item' : 'Itens' }} próx. venc.
                                    </span>
                                </div>
                            </div>
                        </v-col>

                        <!-- Coluna de Status de Vida Útil  90 dias-->
                        <v-col cols="auto" class="pa-0" 
                            v-if="itemsStatus.vidaUtilVencida > 0 || itemsStatus.proximoVidaUtil > 0 || itemsStatus.semVidaUtil > 0">
                            <div class="d-flex align-center">
                                <v-icon small color="error" left>mdi-alert</v-icon>
                                <div class="d-flex flex-column ml-1 status-text">
                                    <span class="text-caption text-xs">Vida Útil</span>
                                    <span v-if="itemsStatus.vidaUtilVencida > 0" class="error--text text-xs font-weight-medium">
                                        {{ itemsStatus.vidaUtilVencida }} {{ itemsStatus.vidaUtilVencida === 1 ? 'Item' : 'Itens' }} vencido(s)
                                    </span>
                                    <span v-if="itemsStatus.proximoVidaUtil > 0" class="warning--text text-xs font-weight-medium">
                                        {{ itemsStatus.proximoVidaUtil }} {{ itemsStatus.proximoVidaUtil === 1 ? 'Item' : 'Itens' }} próx. venc.
                                    </span>
                                    <span v-if="itemsStatus.semVidaUtil > 0" class="grey--text text-xs font-weight-medium">
                                        {{ itemsStatus.semVidaUtil }} {{ itemsStatus.semVidaUtil === 1 ? 'Item' : 'Itens' }} sem informação
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.edit`]="{ item }" > 
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                                <v-icon color="green">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Clique para editar</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.equipamento_fornecedor_nome`]="{ item }">
                    <div v-if="isTableLoading" class="d-flex justify-center align-center">
                        <v-progress-circular indeterminate color="primary" size="24"></v-progress-circular>
                    </div>
                    <div v-else>
                        <span>{{ item.equipamento_fornecedor_nome }}</span>
                    </div>
                </template>                
                <template v-slot:[`item.equipamento_data_envio`]="{ item }">
                    {{ item.equipamento_data_envio | formatDatabr }}
                </template>
                <template v-slot:[`item.data_aquisicao`]="{ item }">
                    {{ (item?.data_aquisicao ? item?.data_aquisicao : item?.data_inicio_locacao) | formatDatabr }}
                </template>
                <template v-slot:[`item.aquisicao.devolucao`]="{ item }">
                    {{ (item?.data_baixa ? item?.data_baixa : item?.data_fim_locacao) | formatDatabr }}
                </template>
                <template v-slot:[`item.propriedade`]="{ item }">
                    {{ item.propriedade === true ? 'Próprio': 'Alugado' }}
                </template>
                <template v-slot:[`item.qtd_dias`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                {{ item.qtd_dias ? `${(item.qtd_dias)} dias` : `${calculateDaysDifference(item.data_inicio_locacao)} dias` }}
                            </span>
                        </template>
                        <span v-if="item.qtd_dias && item.descricao && item.descricao.vida_util">
                            {{ calcularDiasRestantes(item) }} dias restantes para vencer
                        </span>
                        <span v-else>
                            Equipamento Locado
                        </span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.ativo`]="{ item }">
                    <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                        <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                    </v-chip>
                </template>
                <template v-slot:[`item.data_requisicao`]="{ item }">
                    {{ item.data_requisicaoPaciente ? $options.filters.formatDatabr(item.data_requisicaoPaciente) : $options.filters.formatDatabr(item.data_requisicaoUsuario) }}
                </template>
                <template v-slot:[`item.data_implantacao`]="{ item }">
                    {{ item.data_implantacaoPaciente ? $options.filters.formatDatabr(item.data_implantacaoPaciente) : $options.filters.formatDatabr(item.data_entregaUsuario) }}
                </template>
                <template v-slot:[`item.data_validade_calibracao`]="{ item }">
                    <span v-if="item.disponibilidade.id !== 7 ">{{ item.data_validade_calibracao | formatDatabr }}</span>
                    <span v-else></span>
                </template>

            </v-data-table>
<!-- Editar OK REVISADO -->
            <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                        Editar Equipamento - ID {{selectedRow.id}} - {{selectedRow.descricao.nome}} {{ selectedRow?.empresa_locadora !== null ? '- "EQUIPAMENTO LOCADO"': ''}}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon>  mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <EditarEquipamento :currentItem="selectedRow" :initialPatrimonio="selectedRow.patrimonio" :loading="isLoading('put:esquipamento')" :onSubmit="updateEquipamento" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Novo Equipamento próprio OK REVISADO-->
            <v-dialog v-model="dialog.create2" v-if="dialog.create2"  max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Cadastro de Novo Equipamento Próprio:
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon>  mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EntradaEquipamento :currentItem="newItem" :loading="isLoading('post:equipamento1')" :onSubmit="createEquipamento1" />
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Novo Equipamento Alugado OK REVISADO-->
            <v-dialog v-model="dialog.create3" v-if="dialog.create3"  max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Cadastro de Novo Equipamento Alugado:
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon>  mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EntradaEquipamentoAlugado :currentItem="newItem" :loading="isLoading('post:equipamento')" :onSubmit="createEquipamento" />
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Equipamentos em Alugados OK REVISADO-->
            <v-dialog v-model="dialog.enviar" v-if="dialog.enviar" max-width="95%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Gestão de Equipamentos Alugados
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Alugado :currentItem="newItem" :loading="isLoading('put:alugados')" :onSubmit="updateEquipamentoAlugados"/>
                     </v-card-text>
                </v-card>
            </v-dialog>

<!-- Equipamentos Manutenção -->
            <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Gestão de Equipamentos em Manutenção
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon>  mdi-close  </v-icon>
                    </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Manutencao :currentItem="newItem" :loading="isLoading('post:manutencao')" :onSubmit="upadteEquipamentoManutencao" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Trocar OK -->
            <v-dialog v-model="dialog.createTroca" v-if="dialog.createTroca" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Troca de Equipamento do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Trocar :currentItem="newItem" :loading="isLoading('put:equipamentotrocar')" :onSubmit="updateEquipamentoTrocar"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Retirada OK -->
            <v-dialog v-model="dialog.retirada" v-if="dialog.retirada" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Retirada de Equipamento do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Retirada :currentItem="newItem" :loading="isLoading('put:equipamentoretirada')" :onSubmit="updateEquipamentoRetirada"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Requisição OK -->
            <v-dialog v-model="dialog.createRequisicao" v-if="dialog.createRequisicao" max-width="80%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Requisição:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Requisicao :currentItem="newItem" :loading="isLoading('post:equipamento')" :onSubmit="requisicaoEquipamento" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Entrega -->
            <v-dialog v-model="dialog.entrega" v-if="dialog.entrega" max-width="95%" @input="handleDialogClose"  >
                <v-card>
                    <v-card-title class="blod_color">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Entrega :currentItem="newItem" :loading="isLoading('post:entraga')" :onSubmit="createEntrega" :numeroEntraga="quantidadePacientesElegiveisEntrega"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Usuário OK REVISADO -->
            <v-dialog v-model="dialog.createUsuario" v-if="dialog.createUsuario" max-width="90%" @input="handleDialogClose"  >
                <v-card>
                    <v-card-title class="blod_color">
                    Gestão de Equipamento com Usúario:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Usuario :currentItem="newItem" :loading="isLoading('post:usuario')" :onSubmit="createUsuario" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Rastreabilidade OK REVISADO -->
            <v-dialog v-model="dialog.rastreabilidade" v-if="dialog.rastreabilidade" max-width="80%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Rastreabilidade do Equipamento:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Rastreabilidade :currentItem="newItem" :loading="isLoading('post:usuario')" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import api from '../../../http';
import UtilsFunc from "../../../service/utilsFunc";
import EntradaEquipamento from './EntradaEquipamento.vue';
import EditarEquipamento from './EditarEquipamento.vue';
import Manutencao from './Manutencao.vue';
import Alugado from './Alugado.vue';
import Entrega from './entrega/Index.vue';
import Requisicao from './Requisicao.vue'
import Trocar from './TrocaEquipamento.vue';
import Retirada from './RetiradaEquipamento.vue';
import Rastreabilidade from './Rastreabilidade.vue';
import EntradaEquipamentoAlugado from './EntradaEquipamentoAlugado.vue';
import Usuario from './Usuario.vue';
import { mapMutations } from 'vuex'

const { withCRUDUtils } = UtilsFunc

export default {
    name: 'ControleEsterilivaveis',
    components: {
        EntradaEquipamento,
        EditarEquipamento,
        Manutencao,
        Requisicao,
        Alugado,
        Trocar,
        Retirada,
        Usuario,
        Rastreabilidade,
        Entrega,
        EntradaEquipamentoAlugado,
    },
    data: () => withCRUDUtils ({
        isTableLoading: true,
        dialog: {
            create2: false,
            create3: false,
            enviar: false,
            createTroca: false,
            createReceber: false,
            createRequisicao: false,
            retirada: false,
            createUsuario: false,
            rastreabilidade: false,
            entrega: false,
        },
        modal: false,
        equipamento: [],  
        fornecedor: [], 
        paciente: [],
        pacientes: [],
        usuariosEquipamento: [],
        loading: false,
        search: '',
        singleExpand: false,
        tabIndex: 0,
        validade: 0,
        quantidadePacientesElegiveis: 0,
        quantidadePacientesElegiveisTroca: 0,
        quantidadePacientesElegiveisRetirada: 0,
        quantidadePacientesElegiveisEntrega: 0,
        quantidadeEquipamentoManutencao: 0,
        quantidadeAlugada: 0,
        newItem: {
            ativo: true
        },
        newItemAquisicao: {
            ativo: true
        },
        filters: {
            // fornecedor: null,
            // razaoSocial: null,
            is_active: { value: true, compareType: 'equal', prop: 'ativo' }
        },
        statusOptions: [
            { label: "Ativo", value: true },
            { label: "Inativo", value: false }
        ],
        showFilters: false,
        activeFilters: [],
        headers: [
            { text: "Editar", value: "edit" },
            { text: "Id", align: "center", sortable: true, value: "id" },
            { text: "Descrição", align: "center", sortable: true, value: "descricao.nome", width: 200 },
            { text: "Deposito", align: "center", sortable: true, value: "deposito.nome" },
            { text: "Patrimoônio", align: "center", sortable: true, value: "patrimonio" },
            { text: "Data Aquisição (ou Locação)", align: "center", sortable: true, value: "data_aquisicao" },
            { text: "Data Baixa (ou Devolução)", align: "center", sortable: true, value: "aquisicao.devolucao" },
            { text: "Dias Ativo", align: "center", sortable: true, value: "qtd_dias" },
            { text: "Disponibilidade", align: "center", sortable: true, value: "disponibilidade.nome" },
            { text: "Tipo", align: "center", sortable: true, value: "descricao.tipo_equipamento.nome" },
            { text: "Próprio ou Alugado", align: "center", sortable: true, value: "propriedade", width: 50 },
            { text: "Usuário", align: "start", sortable: true, value: "usuariosEquipamento_nome", width: 200 },
            { text: "Paciente", align: "start", sortable: true, value: "paciente_nome", width: 200 },
            { text: "Dia Dispensação", align: "center", sortable: true, value: "paciente_dia_dispensacao_equipamento", width: 30 },
            { text: "Data Requisição", align: "start", sortable: true, value: "data_requisicao" },
            { text: "Data da Implantação", align: "start", sortable: true, value: "data_implantacao" },
            { text: "Data da Validade Calibração", align: "start", sortable: true, width: 140, value: "data_validade_calibracao" },
            { text: "Status", value: "ativo" },
        ],
    }), 
    methods: {
        ...mapMutations(['setModal']),
        handleDialogClose(val) {
            if (!val) { // quando o diálogo fecha, val será false
                this.atualizar();
            }
        },
        closeDialog() {
            this.dialog.create2 = false; //
            this.dialog.create3 = false; //
            this.dialog.update = false; //
            this.dialog.enviar = false //
            this.dialog.create = false; //
            this.dialog.createTroca = false, //
            this.dialog.retirada = false, //
            this.dialog.createRequisicao = false, //
            this.dialog.entrega = false, //
            this.dialog.createUsuario = false; //
            this.dialog.rastreabilidade = false, //
            this.atualizar();
        },
        ...mapMutations(['setModal']),
        async carregarDadosIniciais() {

            this.isTableLoading = true; // Ativa o loading geral da tabela
           await Promise.all([this.getEquipamento(), this.getFornecedor(), this.getPaciente(), this.getUsuarioEquipamento()])
                .then(() => {
                    this.enriquecerEsterelizaveisComFornecedor();
                    this.enriquecerEquipamentoComPaciente();
                    this. enriquecerEquipamentoComUsuario();
                })
                .catch((error) => {
                    console.error("Erro ao carregar dados iniciais:", error);
                    this.$toast.error("Erro ao carregar dados iniciais.");
                })
                .finally(() => {
                    this.isTableLoading = false; // Desativa o loading após tudo ser carregado
                });
        },
        atualizar() {
            this.carregarDadosIniciais();
            this.search = ''; // Limpa o campo de busca
            this.showFilters = false;
            this.clearFilters();
        },
        async getFornecedor() {
          try {
                const { data } = await api('fornecedores/fornecedores/')
                this.fornecedor = data
                // console.log('Fornecedor --< ',this.fornecedor)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro getFornecedor. ${error}`);
            } 
        },
        async getPaciente() {
    try {
        const { data } = await api('pacientes/equipamentos/');
        
        // Filtro inicial para pacientes ativos
        this.pacientes = data.filter(item => item.ativo);

        // Reduzir dados agrupados e contar combinações únicas
        const retiradas = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                paciente.data_solicitacao_retirada &&
                paciente.equipamento &&
                paciente.ativo &&
                paciente.disponibilidade?.id !== 14
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});
        this.quantidadePacientesElegiveisRetirada = Object.keys(retiradas).length;

        const entregaRetirada = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                paciente.data_solicitacao_retirada !== null &&
                paciente.disponibilidade?.id === 14 &&
                paciente.equipamento &&
                paciente.ativo === true
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});

        const entregaTroca = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                paciente.data_solicitacao_troca !== null &&
                paciente.data_implantacao !== null &&
                paciente.ativo === true &&
                paciente.equipamento?.disponibilidade?.id === 14 &&
                (!paciente.data_solicitacao_retirada || paciente.data_solicitacao_retirada === '') &&
                paciente.equipamento
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});

        const entregaRequisicao = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                !paciente.data_implantacao &&
                paciente.disponibilidade?.id === 13 &&
                paciente.equipamento &&
                paciente.ativo === true
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});

        const entregaRetirada1 = Object.keys(entregaRetirada).length;
        const entregaTroca1 = Object.keys(entregaTroca).length;
        const entregaRequisicao1 = Object.keys(entregaRequisicao).length;

        this.quantidadePacientesElegiveisEntrega = entregaRetirada1 + entregaTroca1 + entregaRequisicao1;

        const trocas = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                paciente.data_solicitacao_troca !== null &&
                paciente.equipamento &&
                paciente.ativo &&
                paciente.equipamento.disponibilidade !== 14 &&
                paciente.equipamento.disponibilidade !== 13
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});
        this.quantidadePacientesElegiveisTroca = Object.keys(trocas).length;

        const requisicoes = data.reduce((acc, paciente) => {
            if (
                paciente?.paciente?.id &&
                paciente.data_requisicao &&
                paciente.nome_equipamento &&
                paciente.ativo &&
                !paciente.data_implantacao &&
                paciente.disponibilidade?.id === 3
            ) {
                const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                if (!acc[chave]) {
                    acc[chave] = true;
                }
            }
            return acc;
        }, {});
        this.quantidadePacientesElegiveis = Object.keys(requisicoes).length;

    } catch (error) {
        console.error(error);
        this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
    }
}
,
        async getEquipamento() {

            try {
                const response = await api.get('equipamentos/equipamentos/')
                const ordemDisponibilidade = [4, 3, 7, 6, 9, 1, 2, 5]; // Ordem desejada

                this.equipamento = response.data.sort((a, b) => {
                    if (a.ativo !== b.ativo) {
                        return b.ativo - a.ativo; // Ativos primeiro
                    }
                    // Ordenação pela disponibilidade
                    const indexA = ordemDisponibilidade.indexOf(a.disponibilidade?.id || -1);
                    const indexB = ordemDisponibilidade.indexOf(b.disponibilidade?.id || -1);

                    if (indexA !== indexB) {
                        return indexA - indexB; // Ordem por disponibilidade
                    }

                    // Se as disponibilidades forem iguais, ordena pelo nome do equipamento
                    const nomeA = a.descricao?.nome?.toLowerCase() || '';
                    const nomeB = b.descricao?.nome?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
                
                // Pegar a data de hoje
                const hoje = new Date();
                hoje.setHours(0, 0, 0, 0); // Zerar horas para comparar apenas as datas

                // Filtrar itens que precisam de atenção na validade
                this.validade = this.equipamento.filter(item => {
                    // Verificar se não está em esterilização (id diferente de 7)
                    const naoEstaEmEquipamento = item.disponibilidade?.id !== 7;
                    
                    // Verificar data de validade
                    if (item.data_validade_calibracao) {
                        const dataValidade = new Date(item.data_validade_calibracao);
                        dataValidade.setHours(0, 0, 0, 0);
                        const dataExpirada = dataValidade <= hoje;
                        
                        return naoEstaEmEquipamento && dataExpirada;
                    }
                    
                    return false;
                }).length;

                this.quantidadeEquipamentoManutencao = this.equipamento.filter(item => item.ativo && item.disponibilidade.id === 19).length;
                this.quantidadeAlugada = this.equipamento.filter(item => item.ativo && item.propriedade === false).length;

                // console.log('Quantidade de itens com validade expirada:', this.validade);
                // console.log('Esterelizavel ordenado -> ', this.esterelizavel);
                this.applyFilters()
            } catch(error) {
                console.error(error)
            } 
        },
        async getUsuarioEquipamento() {
            try {
                const { data } = await api('equipamentos/equipamento_usuarios/')
                this.usuariosEquipamento = data.filter(item => item.ativo)
                // console.log('Usuario --< ',this.usuariosEquipamento)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
            } 
            },
        enriquecerEsterelizaveisComFornecedor() {

            if (!this.esterelizavel || !this.fornecedor) return;

            // Associa o nome do fornecedor ao esterelizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                if (item.equipamento_fornecedor) {
                    const fornecedor = this.fornecedor.find(
                        (f) => f.id === item.equipamento_fornecedor
                    );
                    item.equipamento_fornecedor_nome = fornecedor ? fornecedor.nome_fantasia : "N/A";
                } 
                return item;
            });

        },
        enriquecerEquipamentoComPaciente() {
            if (!this.equipamento || !this.pacientes) return;

            // Associa o nome do paciente ao equipamento
            this.equipamento = this.equipamento.map((item) => {
                const pacienteAssociado = this.pacientes.find(
                    (pac) => pac.equipamento?.id === item.id
                );

                item.paciente_nome = pacienteAssociado ? pacienteAssociado.paciente.nome : "";
                item.frequencia_nome = pacienteAssociado && pacienteAssociado.frequencia ? pacienteAssociado.frequencia.nome : '';
                item.paciente_dia_dispensacao_equipamento = pacienteAssociado ? pacienteAssociado.paciente.dia_dispensacao_equipamento : "";
                item.data_requisicaoPaciente = pacienteAssociado ? pacienteAssociado.data_requisicao : null;
                item.data_implantacaoPaciente = pacienteAssociado ? pacienteAssociado.data_implantacao : null;
                return item;
            });
        },
        enriquecerEquipamentoComUsuario() {
            if (!this.equipamento || !this.usuariosEquipamento) return;

            // Associa o nome do paciente ao equipamento
            this.equipamento = this.equipamento.map((item) => {
                const usuarioAssociado = this.usuariosEquipamento.find(
                    (pac) => pac.equipamento?.id === item.id
                );

                item.usuariosEquipamento_nome = usuarioAssociado ? `${usuarioAssociado.usuario.apelido} - ${usuarioAssociado.usuario.especialidade.nome}` : "";
                item.data_requisicaoUsuario = usuarioAssociado ? usuarioAssociado.data_requisicao : null;
                item.data_entregaUsuario = usuarioAssociado ? usuarioAssociado.data_entrega : null;
                return item;
            });
        },
        async createEquipamento(fields) {
            const LOADING_NAME = "post:equipamento";
            this.setLoading(LOADING_NAME);

            try {
                // Criação do equipamento
                const response = await api.post('equipamentos/equipamento_criar/', fields);
                const equipamentoId = response.data?.id; // Supondo que o ID seja retornado neste campo

                if (equipamentoId) {
                this.$toast.success("Equipamento Criado com Sucesso");

                // Preparar os dados para o equipamento alugado
                const alugadoFields = {
                    fornecedor: fields.empresa_locadora,
                    nomeEquipamento: fields.nomeEquipamento,
                    equipamento: equipamentoId,
                    dataContratacao: fields.data_inicio_locacao,
                    valor: fields.valordiario ? fields.valordiario : fields.valormensal,
                    desconto: 0,
                    cobranca: fields.valordiario > fields.valormensal,
                    ativo: true,
                };

                // Criação da linha no endpoint de equipamento alugado
                try {
                    await api.post('equipamentos/equipamento_alugado_criar/', alugadoFields);
                    this.$toast.success("Equipamento Alugado Criado com Sucesso");
                } catch (error) {
                    console.error("Erro ao criar Equipamento Alugado:", error);
                    this.$toast.error("Erro ao criar Equipamento Alugado.");
                }
                } else {
                this.$toast.error("Equipamento criado, mas ID não retornado pela API.");
                }

                this.dialog.create2 = false;
                this.dialog.create3 = false;
                this.atualizar();
            } catch (error) {
                console.error("Erro ao criar Equipamento:", error);
                this.$toast.error(`Erro ao criar Equipamento: ${error}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async createEquipamento1(fields) {
            const LOADING_NAME = "post:equipamento1";
             this.setLoading(LOADING_NAME)
            // console.log('Item Enviado --> ', fields)
            try {
                await api.post('equipamentos/equipamento_criar/', fields)
                this.$toast.success('Equipamento Criado com Sucesso')
                this.dialog.create2 = false
                this.dialog.create3 = false
                this.atualizar()
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async updateEquipamento(fields) {
            const LOADING_NAME = "put:esquipamento"
            this.setLoading(LOADING_NAME)
            try {
                await api.put(`/equipamentos/equipamento_editar/${fields.id}/`, fields)
                 this.dialog.update = false
                this.$toast.success('Equipamento Editado com Sucesso')
                this.atualizar()
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
       async updateEquipamentoAlugados(fields) {
            const LOADING_NAME = "put:alugados";
            this.setLoading(LOADING_NAME);
            // console.log('Item Enviado --> ', fields)
            try {
                await api.put(`equipamentos/equipamento_editar/${fields.id}/`, fields);
                this.$toast.success('Equipamento Atualziado com Sucesso')
                this.dialog.enviar = false
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao atualizar o equipamento ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
            }
        },
       async upadteEquipamentoManutencao(fields) {
            const LOADING_NAME = "post:manutencao"; 
            this.setLoading(LOADING_NAME);
            // console.log('Criando a Nota --> ', fields)
            try {
                await api.post(`/equipamentos/equipamento_manutencao_criar/`, fields );
                this.dialog.create = false
                this.$toast.success('Equipamento atualizado com sucesso!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
       async updateEquipamentoTrocar(fields) {
            const LOADING_NAME = "put:equipamentotrocar";
            this.setLoading(LOADING_NAME);
            try {
                await api.put(`/equipamentos/equipamento_editar/${fields.id}/`, fields);
                this.dialog.createTroca = false,
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao trocar o equipamento ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.$toast.success('Equipamento Trocado com Sucesso')
                this.atualizar(); // Atualiza a tabela
            }
        },
       async updateEquipamentoRetirada(fields) {
        const LOADING_NAME = "put:equipamentoretirada";
            this.setLoading(LOADING_NAME);
            try {
                await  api.put(`/equipamentos/equipamento_editar/${fields.id}/`, fields)
                this.$toast.success('Equipamentos retirados com sucesso!');
                this.dialog.retirada = false;
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao retirar os equipamentos:`, error);
                this.$toast.error(`Erro ao retirar os equipamentos: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
  }
        },
       async updateEquipamentoReceber(fields) {
            const LOADING_NAME = "put:equipamentoreceber";
            this.setLoading(LOADING_NAME);

            try {
                // Itera sobre cada item no array `fields` e envia individualmente
                for (const field of fields) {
                    try {
                        await api.post(`/equipamentos/equipamentos/${field.id}/`, {
                            disponibilidade: field.disponibilidade,
                        });
                        console.log(`Esterilizável ${field.id} atualizado com sucesso.`);
                    } catch (error) {
                        console.error(`Erro ao atualizar o esterilizável ${field.id}:`, error);
                        this.$toast.error(
                            `Erro ao atualizar o item ${field.id}: ${error.message}`
                        );
                    }
                }
                this.$toast.success("Todos os itens foram processados com sucesso!");
                this.atualizar(); // Atualiza a tabela
            } catch (error) {
                console.error("Erro geral ao atualizar os itens:", error);
                this.$toast.error("Erro ao processar alguns itens.");
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async requisicaoEquipamento(fields) {
            const LOADING_NAME = "post:esterilizacao";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await  api.put(`/equipamentos/equipamento_editar/${fields.id}/`, fields)
                this.dialog.createRequisicao = false
                this.$toast.success('Equipamento recebido com sucesso!!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async createEntrega(fields) {
            const LOADING_NAME = "post:entraga";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await  api.put(`/equipamentos/equipamento_editar/${fields.id}/`, fields)
                this.dialog.entrega = false
                this.$toast.success('Equipamento entregue com sucesso!!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async createUsuario(fields) {
            const LOADING_NAME = "post:usuario";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await api.post(`/equipamentos/equipamento_criar/`, fields);
                // this.getEsterilizacao()
                this.dialog.create = false
                this.$toast.success('Equipamento criado com sucesso!!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async applyFilters() {
            this.search = '';
            const filters = JSON.parse(JSON.stringify(this.filters))
            this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
        },
        clearFilters() {
            Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
            this.filters = {
                is_active: { value: true, compareType: 'equal', prop: 'ativo' }
            }

            this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }]
        },
        calcularDiasRestantes(item) {
            if (!item.qtd_dias || !item.descricao || !item.descricao.vida_util) return 0;
            const diasRestantes = item.descricao.vida_util - item.qtd_dias;
            return diasRestantes > 0 ? diasRestantes : 0;
        },
        calculateDaysDifference(startDate) {
            if (!startDate) return 'Data inválida';

            const today = new Date();
            const start = new Date(startDate);

            // Converte para UTC para evitar problemas de timezone
            const utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
            const utcStart = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());

            const differenceInMilliseconds = utcToday - utcStart;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            return differenceInDays >= 0 ? differenceInDays : 0; // Retorna 0 se a diferença for negativa
        },
    },
    computed: {
        itemsStatus() {
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            
            // Initialize all counters
            let vencidos = 0;
            let proximosVencer = 0;
            let vidaUtilVencida = 0;
            let proximoVidaUtil = 0;
            let semVidaUtil = 0;  // New counter

            this.filteredData.forEach(item => {
                const naoEstaEmEsterilizacao = item.disponibilidade?.id !== 7;
                
                // calibracao
                if (item.data_validade_calibracao && naoEstaEmEsterilizacao) {
                    const dataValidade = new Date(item.data_validade_calibracao);
                    dataValidade.setHours(0, 0, 0, 0);
                    
                    if (dataValidade < hoje) {
                        vencidos++;
                    } else {
                        const diasAteVencer = Math.ceil((dataValidade - hoje) / (1000 * 60 * 60 * 24));
                        if (diasAteVencer <= 90) {
                            proximosVencer++;
                        }
                    }
                }

                // vida util
                if (item.descricao) {
                    if (!item.descricao.vida_util) {
                        semVidaUtil++;  // Count items missing vida_util
                    } else if (item.qtd_dias) {
                        const diasRestantes = item.descricao.vida_util - item.qtd_dias;
                        if (diasRestantes <= 0) {
                            vidaUtilVencida++;
                        } else if (diasRestantes <= 90) {
                            proximoVidaUtil++;
                        }
                    }
                }
            });

            return {
                vencidos,
                proximosVencer,
                vidaUtilVencida,
                proximoVidaUtil,
                semVidaUtil
            };
        },

        headersWithValidadeInfo() {
            const updatedHeaders = [...this.headers];
            const validadeIndex = updatedHeaders.findIndex(
                header => header.value === "data_validade_calibracao"
            );

            if (validadeIndex !== -1 && this.itemsVencidos > 0) {
                updatedHeaders[validadeIndex] = {
                    ...updatedHeaders[validadeIndex],
                    text: `Data da Validade Esterilização ( ${this.itemsVencidos} vencido(s) )`
                };
            }

            return updatedHeaders;
        },


        filteredData() {
        let data = this.equipamento;

        // Aplica filtros ativos
        const filterBy = UtilsFunc.createFilter(this.activeFilters);
        data = data.filter(filterBy);

        // Se houver um termo de pesquisa, filtra adicionalmente
        if (this.search) {
            const searchTerm = this.search.toLowerCase().trim();
            data = data.filter(item => {
                // Verifica e registra cada campo de pesquisa
                const usuarioNome = item.usuariosEquipamento_nome || '';
                const nomeEquipamento = item.descricao?.nome || '';
                const pacienteNome = item.paciente_nome || '';
                const frequenciaNome = item.frequencia_nome || '';
                const patrimonio = item.patrimonio || '';
                const disponibilidade = item.disponibilidade.nome || '';
                // const esterilizadoraNome = item.esterilizacao_fornecedor_nome || '';

                // Quebra o nome do usuário em partes
                const usuarioParts = usuarioNome.toLowerCase().split(' ');
                const searchParts = searchTerm.toLowerCase().split(' ');

                // Verifica se pelo menos uma parte corresponde
                const matchUsuarioNome = usuarioParts.some(part => 
                    searchParts.some(search => part.includes(search))
                );
                const matchNomeEquipamento = nomeEquipamento.toLowerCase().includes(searchTerm);
                const matchPacienteNome = pacienteNome.toLowerCase().includes(searchTerm);
                const matchFrequenciaNome = frequenciaNome.toLowerCase().includes(searchTerm);
                const matchdisponibilidade = disponibilidade.toLowerCase().includes(searchTerm);
                // const matchPEsterilizadoraeNome = esterilizadoraNome.toLowerCase().includes(searchTerm);
                const matchId = item.id.toString().includes(searchTerm);
                

                return matchUsuarioNome || 
                       matchNomeEquipamento || 
                       matchPacienteNome || 
                       matchFrequenciaNome || 
                       matchId ||
                       patrimonio ||
                       matchdisponibilidade
                    //    matchPEsterilizadoraeNome;
            });
        }

        return data;
        },
        
    },
   mounted() {
        this.carregarDadosIniciais();
    }

}
</script>

<style scoped>
.badge-red {
  position: absolute;
  top: -8px; /* Ajuste fino da posição vertical */
  right: -8px; /* Ajuste fino da posição horizontal */
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.mr-2 {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.status-text {
    font-size:  12px;
    line-height: 1.1;
}

.text-xs {
    font-size:  12px;
}

.compact-btn {
    font-size: 12px !important;
    letter-spacing: -0.2px !important;
    padding: 0 12px !important;
    height: 42px !important;
}

.compact-btn-text {
    font-size: 12px !important;
    line-height: 1.2 !important;
    text-align: center !important;
}

.compact-icon {
    font-size: 18px !important;
    margin-right: 4px !important;
}

.button-group {
    gap: 10px !important;
}
</style>



<template>
  <v-form @submit.prevent="submitForm" ref="formRef">
    <v-row>
      <v-col cols="4" md="6" offset-md="3">
        <v-select label="Tipo de Item" outlined dense v-model="selectedItemType" :items="itemTypes" item-text="text"
          item-value="value"></v-select>
      </v-col>
    </v-row>
    <v-row class="mx-4 my-2">
      <v-row>
        <v-col v-if="selectedItemType === 1">
          <v-autocomplete v-model="selectedTaxa" :items="taxas" outlined dense item-value="id" :item-text="taxaText">
            <template v-slot:label>
              <span>Taxa <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="selectedItemType === 3">
          <v-autocomplete v-model="selectedProcedimento" :items="procedimentos" outlined dense clearable item-value="id"
            :item-text="procedimentoText">
            <template v-slot:label>
              <span>Procedimento <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="selectedItemType === 2">
          <v-autocomplete v-model="selectedEquipamento" :items="equipamentos" outlined dense clearable item-value="id"
            :item-text="equipamentoText">
            <template v-slot:label>
              <span>Equipamento <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="selectedItemType === 3 || selectedItemType === 2 || selectedItemType === 1" cols="3">
          <v-select :items="SimNao" outlined dense v-model="currentItem.ilimitado">
            <template v-slot:label>
              <span>Ilimitado <span style="color: red;">*</span></span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="selectedItemType === 3 || selectedItemType === 2 || selectedItemType === 1">
        <v-col cols="4">
          <v-text-field v-model="currentItem.quantidade" label="Quantidade" outlined dense clearable
            type="Number"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field outlined dense clearable type="date" v-model="currentItem.inicio_vigencia">
            <template v-slot:label>
              <span>Inicio Vigência<span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="Fim Vigência" outlined dense clearable type="date"
            v-model="currentItem.fim_vigencia"></v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <!-- Botão Salvar-->

    <v-row dense v-if="selectedItemType === 1 || selectedItemType === 2 || selectedItemType === 3">
      <v-col class="text-end mt-6">
        <v-tooltip top :disabled="isFormValid">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting"
                :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
            </span>
          </template>
          <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
import api from '../../../../http';

export default {
  name: "ProcedureFormItemPacote",
  components: { ConfirmButton },
  props: {
    currentItem2: Object,
    loading: Boolean,
    pacoteId: Number,
    onClose: Function,
    idoperadora: Number,
  },

  data: () => UtilsFunc.withCRUDUtils({
    currentItem: {
      ilimitado: true,
      quantidade: null,
      inicio_vigencia: '',
      fim_vigencia: '',
      taxa: null,
      procedimento: null,
      equipamento: null
    },
    procedimentos: [],
    taxas: [],
    itempacote: [],
    equipamentos: [],
    nomeequipamentos: [],
    nometaxas:[],
    selectedTaxa: null,
    selectedEquipamento: null,
    selectedProcedimento: null,
    selectedItemType: null,
    isSubmitting: false,
    isLoadingTaxas: false,
    isLoadingEquipamentos: false,
    isLoadingProcedimentos: false,
    requiredField: [e => (e && e !== "") || 'Obrigatório'],
    itemTypes: [
      { value: 1, text: 'Taxa' },
      { value: 2, text: 'Equipamento' },
      { value: 3, text: 'Procedimento' }
    ],
    SimNao: [
      { value: true, text: 'Sim' },
      { value: false, text: 'Não' },

    ],
  }),
  computed: {
    taxaText() {
      return (item) => {
        return item.taxa ? `${item.taxa.nome} - ${item.nomecomercial ? item.nomecomercial : ''}` : '';
      }
    },
    equipamentoText() {
      return (item) => {
        return item.equipamento ? `${item.equipamento.nome}- ${item.nomecomercial ? item.nomecomercial : ''}` : '';
      }
    },
    procedimentoText() {
      return (item) => {
        return item.procedimento ? `${item.procedimento.nome} - ${item.nomecomercial ? item.nomecomercial : ''}` : '';
      }
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    isFormValid() {
      return !!(this.currentItem.ilimitado != null &&
        this.currentItem.inicio_vigencia &&
        (this.selectedTaxa || this.selectedProcedimento || this.selectedEquipamento));
    },
  },
  methods: {
    async getItensPacote() {
      const LOADING_NAME = 'get:itenspacote';
      this.setLoading(LOADING_NAME);
      try {
        const response = await api.get(`operadoras/operadora-itempacotes/?pacote=${this.pacoteId}`);
        this.itempacote = response.data.filter(item => item.ativo);
        // console.log('Itemdopacote --> ', this.itempacote)
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
      }
    },

    async getTaxas() {
      this.isLoadingTaxas = true;
      try {
        const { data } = await api.get(`operadoras/operadora-taxas/?operadora=${this.idoperadora}`);
        this.taxas = data.filter(taxa => taxa.ativo);
        // console.log('Taxas', this.taxas)
      } finally {
        this.isLoadingTaxas = false;
      }
    },
    async getEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`operadoras/operadora-equipamentos/?operadora=${this.idoperadora}`);
        this.equipamentos = data.filter(item => item.ativo)
        // console.log('Equipamento', this.equipamentos)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getNomeTaxa() {
      this.isLoadingTaxas = true;
      try {
        const { data } = await api.get(`taxas/taxas/`);
        this.nometaxas = data.filter(item => item.ativo)
        // console.log('NomeTaxas', this.nometaxas)
      } finally {
        this.isLoadingTaxas = false;
      }
    },

    async getProcedimento() {
      this.isLoadingProcedimentos = true;
      try {
        const { data } = await api.get(`operadoras/operadora-procedimentos/?operadora=${this.idoperadora}`);
         this.procedimentos = data
            .filter(procedimento => procedimento.ativo)
            .sort((a, b) => {
                if (a.procedimento.nome < b.procedimento.nome) return -1;
                if (a.procedimento.nome > b.procedimento.nome) return 1;
                return 0;
            });
        // console.log('Procedimentos', this.procedimentos)
      } finally {
        this.isLoadingProcedimentos = false;
      }
    },
    async submitForm() {
      if (!this.isFormValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      const selectedTaxaObj = this.taxas.find(taxa => taxa.id === this.selectedTaxa);
      const selectedNomeTaxa = this.nometaxas.find(nometaxa => nometaxa.id === (selectedTaxaObj ? selectedTaxaObj.taxa.id : null));
      const selectedEquipamentoObj = this.equipamentos.find(equipamento => equipamento.id === this.selectedEquipamento);
      const selectedNomeEquipamento = this.nomeequipamentos.find(nomeequipamento => nomeequipamento.id === (selectedEquipamentoObj ? selectedEquipamentoObj.equipamento.id : null));
      let itemData = {
        pacote: this.pacoteId,
        taxa: this.selectedTaxa ? this.selectedTaxa : null,
        taxa_ref: selectedNomeTaxa ? selectedNomeTaxa.id : null,
        equipamento: this.selectedEquipamento ? this.selectedEquipamento : null,
        equipamento_ref: selectedNomeEquipamento ? selectedNomeEquipamento.id : null, 
        procedimento: this.selectedProcedimento ? this.selectedProcedimento : null,
        operadora: this.idoperadora ? this.idoperadora : null,
        ilimitado: this.currentItem.ilimitado,
        quantidade: this.currentItem.quantidade || null,
        inicio_vigencia: this.currentItem.inicio_vigencia ? this.currentItem.inicio_vigencia : null,
        fim_vigencia: this.currentItem.fim_vigencia ? this.currentItem.fim_vigencia : null,
        ativo: true
      };
      try {
        this.isSubmitting = true;
        // console.log(itemData)
        let response = await api.post('/operadoras/operadora-itempacotes/', itemData);
        if (response.status === 201 || response.status === 200) {
          this.$toast.success('Item do Pacote cadastrado com sucesso!');
          this.onClose()
        } else {
          throw new Error('Falha ao cadastrar item');
        }
      } catch (error) {
        this.$toast.error(`Erro ao cadastrar item do pacote: ${error.message || error}`);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    this.getNomeEquipamento();
    this.getNomeTaxa();
    this.getEquipamento();
    this.getProcedimento();
    this.getTaxas();
    await this.getItensPacote();
  },
  watch: {
    selectedItemType(newValue) {
      if (!this.currentItem.operadoraId) return;

      switch (newValue) {
        case 1:
          this.getTaxas(this.currentItem.operadoraId);
          break;
        case 2:
          this.getEquipamento(this.currentItem.operadoraId);
          break;
        case 3:
          this.getProcedimento(this.currentItem.operadoraId);
          break;
      }

      // Reset de campos ao mudar o tipo do item
      this.selectedTaxa = null;
      this.selectedProcedimento = null;
      this.selectedEquipamento = null;
    },
    'currentItem.ilimitado'(newValue) {
      if (newValue === false) {
        this.currentItem.quantidade = 1;
      } else {
        this.currentItem.quantidade = null;
      }
    },
  }
}
</script>

<style scoped>
.small-text {
  font-size: smaller;
}
</style>


   

<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
      :loading="loading">
      <v-icon left>mdi-printer</v-icon> ABEMID
    </v-btn>
  </v-container>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../../service/utilsPDF';
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Abemid",
  data: () => withCRUDUtils({
    loading: false,
    abemid: [],
    assinaturas: [],
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getAbemid()
        ]);
        this.savePDF();
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    drawHeader(doc) {
      const abemid = this.abemid[0];

      try {
          doc.addImage(logo, 10, 8, 28, 28);
          doc.addImage(ona, 160, 10, 15, 15);
          doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
          this.$toast.error("Falha ao adicionar a imagem ao PDF (L-61):", error);
      }

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      // Ajustar a coordenada 'rectY' para mover o retângulo para cima
      doc.setLineWidth(0.5);
      const rectX = 3;
      const rectY = 37;  // Diminuir este valor para mover o retângulo para cima (era 40)
      const rectWidth = 200;
      const rectHeight = 25;  // Aumente este valor para aumentar a altura do retângulo
      const borderRadius = 2;
      doc.roundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius, borderRadius, "S");

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`ABEMID`, 105, 23, { align: "center" });

      moment.locale("pt-br");

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const xFixedPosition = 10;
      const lineHeight = 6;

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right" });

      doc.setFontSize(11);
      doc.text(`Avaliado em: ${UtilsPDF.formatDate(abemid.timestamp)}`, 81, 32);

      let yPosition = rectY + 6;  // Ajusta a posição inicial do texto dentro do retângulo
      doc.text(`Paciente: ${this.selectedBudgets[0]?.nome ?? ''}`, xFixedPosition, yPosition);

      const dataNascimento = this.selectedBudgets[0].data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(this.selectedBudgets[0].data_nascimento)}`  : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, yPosition, { align: "right" });

      yPosition += lineHeight;
      doc.text(`Operadora: ${this.selectedBudgets[0].operadora.nome}`, xFixedPosition, yPosition);
     doc.text(`Nº da Carteira: ${this.selectedBudgets[0].nr_carteirinha ? this.selectedBudgets[0].nr_carteirinha : ''}`, 200, yPosition, {  align: "right", });

      yPosition += lineHeight;

      const cid = this.selectedBudgets[0].cid?.nome || '';

      if (cid && cid.length > 0) {
          const cidDescricao =  this.selectedBudgets[0].cid.descricao || '';
          const fullText = `CID: ${cid} - ${cidDescricao}`;

          const splittedText = doc.splitTextToSize(fullText, rectWidth - 10);

          splittedText.forEach((line, i) => {
              doc.text(line, xFixedPosition, yPosition + (lineHeight * i));
          });

          yPosition += (lineHeight * splittedText.length);
      } else {
          doc.setFont("helvetica", "normal");
          doc.text(`CID: `, xFixedPosition, yPosition);
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF() {
      const abemid = this.abemid[0]
      const periodoOrcamento = `${UtilsPDF.formatDateToFilename(abemid.timestamp)}`;
      const filename = `ABEMID_${this.selectedBudgets[0]?.nome.replace(/\s+/g, '_')}_${this.selectedBudgets[0]?.operadora?.nome.replace(/\s+/g, '_')}_Avaliado_em_ ${periodoOrcamento}.pdf`;

      await this.generatePDF();

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)

      // Código para salbvar no doc do paciente 
      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())
      await this.saveDocument(signedPDFBlob, filename);
      
      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    // Fórmula para salvar automaticamente o doc nos docs do paciente
    async saveDocument(pdfBlob, filename) {
      const today = new Date().toISOString().split('T')[0];
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("paciente", this.abemid[0].paciente.id);
      formData.append("tipodoc", 32);
      formData.append("data_carregado", today);
      try {
        await api.post('pacientes/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
    // Função para calcular a soma das pontuações dentro de abemid
    calculateTotalScore() {
      if (!this.abemid || this.abemid.length === 0) return 0;

      const abemid = this.abemid[0].abemid;

      const totalScore = 
        (abemid.suporte_terapeutico?.pontuacao || 0) +
        (abemid.quimioterapia?.pontuacao || 0) +
        (abemid.suporte_ventilatorio?.pontuacao || 0) +
        (abemid.lesao?.pontuacao || 0) +
        (abemid.grau_atividade?.pontuacao || 0) +
        (abemid.dependencia?.pontuacao || 0) +
        (abemid.terapia_nutricional?.pontuacao || 0);
      // console.log(totalScore)
      return totalScore;
    },
    // Adicionar função para contar pontuações 5
    countFives() {
      const abemid = this.abemid[0].abemid;
      let count = 0;

      const fields = [
        abemid.suporte_terapeutico,
        abemid.quimioterapia,
        abemid.suporte_ventilatorio,
        abemid.lesao,
        abemid.grau_atividade,
        abemid.dependencia,
        abemid.terapia_nutricional
      ];

      fields.forEach(field => {
        if (field.pontuacao === 5) {
          count++;
        }
      });

      return count;
    },

    // Função para determinar a classificação do paciente
    determineClassification() {
      const fiveCount = this.countFives();
      const totalScore = this.calculateTotalScore();

      if (fiveCount >= 2) {
        return {
          range: "Acima de 19 pontos",
          complexity: "Alta Complexidade",
          highlightedIndex: 3
        };
      } else if (fiveCount === 1) {
        return {
          range: "De 13 à 18 pontos",
          complexity: "Média Complexidade",
          highlightedIndex: 2
        };
      } else {
        if (totalScore <= 7) {
          return {
            range: "Inferior a 07 pontos",
            complexity: "Paciente não elegível para Internação Domiciliar",
            highlightedIndex: 0
          };
        } else if (totalScore >= 8 && totalScore <= 12) {
          return {
            range: "De 08 à 12 pontos",
            complexity: "Baixa Complexidade",
            highlightedIndex: 1
          };
        } else if (totalScore >= 13 && totalScore <= 18) {
          return {
            range: "De 13 à 18 pontos",
            complexity: "Média Complexidade",
            highlightedIndex: 2
          };
        } else if (totalScore > 19) {
          return {
            range: "Acima de 19 pontos",
            complexity: "Alta Complexidade",
            highlightedIndex: 3
          };
        }
      }
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const abemid = this.abemid[0].abemid;
      const classification = this.determineClassification();
      const highlightedIndex = classification.highlightedIndex;

      const pageWidth = doc.internal.pageSize.getWidth();
      let yPosition = 63;
      const xOffset = 10; // Deslocamento à direita
      const xOffsetTitulo = 20
      const rowHeight = 5; // Altura das linhas reduzida para caber em uma única página

      // Define a fonte e o tamanho
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);

      // Função para desenhar uma linha de cabeçalho
      const drawHeaderRow = (y) => {
        doc.setFontSize(8);
        doc.setTextColor(255, 255, 255); // Texto branco
        doc.setFillColor(57, 106, 159); // Azul para o fundo
        doc.rect(10, y, pageWidth - 20, rowHeight, 'F'); // Fundo retangular
        doc.setDrawColor(0, 0, 0); // Contorno preto
        doc.setLineWidth(0.1); // Fina linha de contorno
        doc.rect(10, y, pageWidth - 20, rowHeight); // Contorno da linha de cabeçalho

        doc.text("DESCRIÇÃO",  xOffsetTitulo + 30, y + 4, { align: 'center' });
        doc.text("ITENS DE AVALIAÇÃO",  xOffsetTitulo + 110, y + 4, { align: 'center' });
        doc.text("PONTUAÇÃO",  xOffsetTitulo + 160, y + 4, { align: 'center' });
      };

    // Função para desenhar uma linha de conteúdo com divisores verticais
      const drawContentRow = (description, item, score, y, isHighlighted = false, isFirstRow = false, rowSpan = 1) => {
        doc.setTextColor(0, 0, 0); // Texto preto

      // Desenha a célula de "Descrição" apenas na primeira linha do grupo
      if (isFirstRow) {
          doc.setFont("helvetica", "normal");
          const descriptionYPosition = y + (rowSpan * rowHeight) / 2; // Centraliza verticalmente a descrição
          doc.text(description, 12, descriptionYPosition); // Ajuste a posição conforme necessário

          // Desenha o contorno ao redor da célula de descrição para abranger todas as linhas do grupo
          doc.setDrawColor(0, 0, 0);
          doc.rect(10, y, 80, rowHeight * rowSpan); // Retângulo ao redor de todas as linhas da descrição
      }

      // Desenha os itens na linha atual
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      doc.text(item, xOffset + 92, y + 4);

      // Se for a pontuação destacada e a descrição coincidir, colore o fundo da célula
      if (isHighlighted) {
          // console.log(`Highlighting: ${item} with score ${score}`);
          doc.setFillColor(144, 238, 144); // Verde claro
          doc.rect(160, y, pageWidth - 170, rowHeight, 'F'); // Desenha o fundo colorido na célula de pontuação
      } else {
          doc.setFillColor(255, 255, 255); // Branco padrão
          doc.rect(160, y, pageWidth - 170, rowHeight, 'F'); // Fundo branco padrão
      }

      // Desenha a pontuação na linha atual
      doc.setTextColor(0, 0, 0); // Texto preto
      doc.text(score, xOffset + 170, y + 4, { align: 'center' });

      // Desenha as linhas verticais divisórias
      doc.setDrawColor(0, 0, 0); // Contorno preto
      doc.line(90, y, 90, y + rowHeight); // Linha entre descrição e itens de avaliação
      doc.line(160, y, 160, y + rowHeight); // Linha entre itens de avaliação e pontuação

      // Desenha as bordas ao redor da célula de itens e pontuação
      doc.rect(90, y, 70, rowHeight); // Desenha o retângulo dos itens de avaliação
      doc.rect(160, y, pageWidth - 170, rowHeight); // Desenha o retângulo da pontuação
    };

    // Função para desenhar um grupo de linhas relacionadas a uma descrição
    const drawGroup = (description, items, highlightedItem) => {
        const rowSpan = items.length; // Número de linhas que o grupo ocupará

        items.forEach((row, index) => {
            const isFirstRow = index === 0; // Apenas a primeira linha do grupo recebe a descrição
            const isHighlighted = row.score === String(highlightedItem.pontuacao) && row.item.trim() === highlightedItem.descricao.trim();
            // console.log(`Checking: ${row.item.trim()} against ${highlightedItem.descricao.trim()}`); // Log para depuração
            drawContentRow(description, row.item, row.score, yPosition, isHighlighted, isFirstRow, rowSpan);
            yPosition += rowHeight;
        });
    };
    
    // Adicionar as variáveis que armazenam os dados do endpoint
    const supportTherapeutic = abemid.suporte_terapeutico;
    const chemotherapy = abemid.quimioterapia;
    const ventilatorySupport = abemid.suporte_ventilatorio;
    const vascularLesion = abemid.lesao;
    const activityLevel = abemid.grau_atividade;
    const rehabilitationDependency = abemid.dependencia;
    const nutritionalTherapy = abemid.terapia_nutricional;

    // Desenhar a tabela de Suporte Terapêutico
    drawHeaderRow(yPosition); // Cabeçalho
    yPosition += rowHeight;

    const suporteTerapeuticoItems = [
        { item: "Sonda Vesical Permanente", score: "1" },
        { item: "Sonda Vesical Intermitente", score: "2" },
        { item: "Traqueostomia sem Aspiração", score: "2" },
        { item: "Traqueostomia com Aspiração", score: "5" },
        { item: "Aspiração de Vias Aéreas Sup.", score: "3" },
        { item: "Acesso Venoso Prof. Continuo", score: "5" },
        { item: "Acesso Venoso Intermitente", score: "4" },
        { item: "Acesso Venoso Periférico Continuo", score: "5" },
        { item: "Diálise Domiciliar", score: "5" },
    ];

    drawGroup("Suporte Terapêutico", suporteTerapeuticoItems, supportTherapeutic);

    // Desenhar a tabela de Quimioterapia
    const quimioterapiaItems = [
        { item: "Oral", score: "1" },
        { item: "Subcutânea", score: "3" },
        { item: "Intravenosa", score: "5" },
        { item: "Intra Tecal", score: "5" },
    ];

    drawGroup("Quimioterapia", quimioterapiaItems, chemotherapy);

      // Desenhar a tabela de Suporte Ventilatório
      const suporteVentilatorioItems = [
        { item: "O2 Intermitente", score: "2" },
        { item: "O2 Contínuo", score: "3" },
        { item: "Ventilação Mec. Intermitente", score: "4" },
        { item: "Ventilação Mec. Contínua", score: "5" },
      ];
      drawGroup("Suporte Ventilatório", suporteVentilatorioItems, ventilatorySupport);

      // Desenhar a tabela de Lesão Vascular/Cutânea
      const lesaoVascularItems = [
        { item: "Úlcera de Pressão Grau I", score: "2" },
        { item: "Úlcera de Pressão Grau II", score: "3" },
        { item: "Úlcera de Pressão Grau III", score: "4" },
        { item: "Úlcera de Pressão Grau IV", score: "5" },
      ];
      drawGroup("Lesão Vascular/Cutânea", lesaoVascularItems, vascularLesion, vascularLesion);

      // Desenhar a tabela de Grau de Atividade
      const grauAtividadeItems = [
        { item: "Independente", score: "0" },
        { item: "Semi-Dependente", score: "2" },
        { item: "Dependente Total", score: "5" },
      ];
      drawGroup("Grau de Atividade da Vida Diária Relac. a Cuidados Técnicos", grauAtividadeItems, activityLevel);

      // Desenhar a tabela de Dependência de Reabilitação
      const dependenciaReabilitacaoItems = [
        { item: "Independente", score: "0" },
        { item: "Dependente", score: "2" },
      ];
      drawGroup("Dependência de Reabilitação Fisio/Fono/Etc. Seções Diárias", dependenciaReabilitacaoItems, rehabilitationDependency);

      // Desenhar a tabela de Terapia Nutricional
      const terapiaNutricionalItems = [
        { item: "Suplementação Oral", score: "1" },
        { item: "Gastrostomia", score: "2" },
        { item: "SNE", score: "3" },
        { item: "Jejuno Ileo", score: "3" },
        { item: "Nutrição Parenteral Total", score: "5" },
      ];
      drawGroup("Terapia Nutricional", terapiaNutricionalItems, nutritionalTherapy);

      // Adicionar campo "Pontuação = " logo abaixo da tabela e alinhado à direita
      const textXPosition = pageWidth - 20;  // Ajuste a posição X conforme necessário
      const totalScore = this.calculateTotalScore();  // Calcular a pontuação total
      doc.setFontSize(12);
      doc.text(`Pontuação = ${totalScore}`, textXPosition, yPosition + 5, { align: 'right' });


     // Adicionar título "CLASSIFICAÇÃO DOS PACIENTES" com fundo azul
      yPosition += 9; // Adicionar um espaço extra menor para aproximar o título da tabela
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");

      // Definir o fundo azul e desenhar o retângulo para o título
      doc.setFillColor(57, 106, 159); // Azul para o fundo
      doc.rect(10, yPosition, pageWidth - 20, rowHeight, 'F'); // Fundo retangular para o título

      // Escrever o título com texto branco
      doc.setTextColor(255, 255, 255);
      doc.text("CLASSIFICAÇÃO DO PACIENTE", 70, yPosition + 4);

      yPosition += rowHeight; // Pequeno espaço antes da próxima linha

      // Adicionar colunas de classificação dos pacientes com fundo branco
      doc.setTextColor(0, 0, 0);

      // Adicionar colunas de classificação dos pacientes
      const classificationLeft = [
        "Inferior a 07 pontos",
        "De 08 à 12 pontos",
        "De 13 à 18 pontos",
        "Acima de 19 pontos"
      ];

      const classificationRight = [
        "Paciente não elegível para Internação Domiciliar",
        "Baixa Complexidade",
        "Média Complexidade",
        "Alta Complexidade"
      ];
    // Destacar a linha com base na pontuação total
    // let highlightedIndex = -1;
    // if (totalScore <= 7) {
    //     highlightedIndex = 0;
    // } else if (totalScore >= 8 && totalScore <= 12) {
    //     highlightedIndex = 1;
    // } else if (totalScore >= 13 && totalScore <= 18) {
    //     highlightedIndex = 2;
    // } else if (totalScore > 19) {
    //     highlightedIndex = 3;
    // }

    // Desenhar as linhas da classificação e destacar a linha correspondente
    classificationLeft.forEach((leftText, index) => {
        const rightText = classificationRight[index];

        if (index === highlightedIndex) {
            doc.setFillColor(144, 238, 144); // Cor verde clara
            doc.rect(10, yPosition, pageWidth - 20, rowHeight, 'F'); // Fundo verde claro
        } else {
            doc.setFillColor(255, 255, 255); // Fundo branco padrão
        }
        
        doc.setDrawColor(0, 0, 0);
        doc.rect(10, yPosition, pageWidth - 20, rowHeight);

        doc.text(leftText, 15, yPosition + 4);
        const verticalLineX = 90;
        doc.line(verticalLineX, yPosition, verticalLineX, yPosition + rowHeight);

        doc.text(rightText, verticalLineX + 10, yPosition + 4);

        yPosition += rowHeight;
    });

      doc.setFontSize(7);
      doc.setFont("helvetica", "italic");

      // Adicionar as linhas de observação
      doc.text("*Ao obter um score 5, o paciente migra automaticamente para média complexidade", 10, yPosition + 4);
      yPosition += rowHeight; // Espaço para a próxima linha

      doc.text("**Ao obter dois ou mais scores 5, o paciente migra automaticamente para alta complexidade", 10, yPosition + 4);
      yPosition += rowHeight; // Espaço para a próxima linha

      doc.text("***Obs. A migração acima referida, ocorre independente dos pontos totais obtidos", 10, yPosition + 4);

      // Ajustar a posição para o carimbo, nome e especialidade
      yPosition -= rowHeight * 2; // Suba um pouco para alinhar ao lado das observações

      // Adicionar imagem do carimbo e nome do profissional ao lado das observações
      const profissional = this.abemid[0].profissional;
      const especialidade = this.abemid[0].especialidade;
      
      if (profissional) {
          const xCarimboPosition = pageWidth - 40;  // Posição X para a imagem e texto do carimbo
          let carimboImageURL = profissional.carimbo;
          const params = profissional.carimbo.split('://')
            if (params[0] === 'http' && params[1] !== 'localhost') {
              carimboImageURL = carimboImageURL.replace('http', 'https')
            }

          if (carimboImageURL) {
              // Adiciona a imagem do carimbo se disponível
              doc.addImage(carimboImageURL, 'JPEG', xCarimboPosition, yPosition +2, 25, 10); // Tamanho e posição ajustados
          }
          // Adiciona o nome do profissional abaixo da imagem do carimbo
          doc.setFontSize(8);
          doc.text(`${profissional.apelido} - ${especialidade.nome}`, xCarimboPosition + 12, yPosition + 18, { align: 'center' });
      }

      UtilsPDF.addPageNumbers(doc);
      this.doc = doc;
  },
  async getAbemid() {
    try {
      const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?paciente_id=${this.selectedBudgets[0].id}&abemid=true`);
      this.abemid = data;
      const casaDataWithValues = data.filter(item => item.abemid && Object.keys(item.abemid).length > 0);
      if (casaDataWithValues.length > 0) {
        const latestcasaData = casaDataWithValues.reduce((latest, current) => {
          return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
        });
        this.abemid = [latestcasaData];
        // console.log('abemid -> ', this.abemid)
      } else {
        this.abemid[0] = null;
        // console.log('abemid -> ', this.abemid)
      }
    } catch (error) {
      // this.$toast.error("Erro ao buscar os dados do abemid:", error);
    } 
},
  },
};
</script>
<template>
  <div class="pa-4" dense>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense >
        <v-col cols="2">
          <v-text-field dense label="ID Solicitação" v-model="localEsterelizavel.id"  outlined disabled ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field dense label="Nome do Esterilizável" v-model="localEsterelizavel.nome_esterilizavel.nome"  outlined disabled ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-autocomplete dense outlined label="Status" v-model="localEsterelizavel.ativo" :items="ativoOptions" item-value="value" item-text="nome" :disabled="originalDisponibilidadeID !==3"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field dense label="Disponibilidade Atual" v-model="esterilizavel.disponibilidade.nome" outlined disabled
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-autocomplete dense label="Frenquência de Troca" v-model="localEsterelizavel.frequencia" :items="frequencia" item-text="nome" item-value="id" outlined v-bind="attrs" v-on="on"></v-autocomplete>
          </template>
          <span>Nesse Campo você escolhe com que frequência o sistema irá gerar automaticamente uma troca para o Suprimentos efetuar na residência desse esterilizável</span>
        </v-tooltip>
        </v-col>
      </v-row>
 
      <v-row desen >
        <v-col cols="4" >
          <v-text-field dense  label="Data de Requisição" v-model="localEsterelizavel.data_requisicao" type="date" outlined disabled></v-text-field>
        </v-col>
        <v-col cols="4" v-if="originalDisponibilidadeID !== 3">
          <v-text-field dense  label="Data Prevista da Implantação" :value="localEsterelizavel.data_prevista_implantacao" type="date" outlined disabled></v-text-field>
        </v-col>
        <v-col cols="4" v-if="originalDisponibilidadeID !== 3">
          <v-text-field dense  label="Data da Implantação" v-model="localEsterelizavel.data_implantacao" type="date" outlined   disabled></v-text-field>
        </v-col>
      </v-row>
 
      <v-row desen v-if="this.localEsterelizavel.ativo">
        <v-col cols="3" v-if="this.localEsterelizavel.disponibilidade.id !== 13  && this.localEsterelizavel.disponibilidade.id !== 14">
          <v-autocomplete dense outlined label="Nova Disponibilidade" v-model="localEsterelizavel.disponibilidade" :items="computedDisponibilidade"  item-value="id" item-text="nome" :loading="loadingDisponibilidade"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" v-if="originalDisponibilidadeID === 3">
          <v-text-field dense  label="Data Prevista da Implantação" v-model="localEsterelizavel.data_prevista_implantacao" type="date" outlined ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 4">
          <v-text-field dense  label="Data de Solicitação de Troca" v-model="localEsterelizavel.data_solicitacao_troca" type="date" outlined ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 4">
          <v-text-field dense  label="Data Prevista para Troca" v-model="hojeDataPrevista" type="date" outlined   ></v-text-field>
        </v-col>
        <!-- <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 4">
          <v-text-field dense  label="Data da Troca" v-model="localEsterelizavel.data_troca" type="date" outlined   disabled></v-text-field>
        </v-col> -->

        <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 12">
          <v-text-field dense  label="Data de Solicitação de Retirada" v-model="localEsterelizavel.data_solicitacao_retirada" type="date" outlined ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 12" >
          <v-text-field dense  label="Data Prevista para Retirada" v-model="hojeDataPrevista" type="date" outlined   ></v-text-field>
        </v-col>
        <!-- <v-col cols="3" v-if="localEsterelizavel.disponibilidade === 12" >
          <v-text-field dense  label="Data da Retirada" v-model="localEsterelizavel.data_retirada" type="date" outlined  disabled ></v-text-field>
        </v-col> -->
      </v-row>
       <v-row dense v-if="this.localEsterelizavel.disponibilidade.id !== 13  && this.localEsterelizavel.disponibilidade.id !== 14">
        <v-col >
          <v-text-field dense label="Observação" v-model="localEsterelizavel.obs" outlined ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end" v-if="this.localEsterelizavel.disponibilidade.id !== 13  && this.localEsterelizavel.disponibilidade.id !== 14">
          <!-- <v-btn class="mr-4 ml-5 elevation-0" @click="cancelarEntrega" :loading="isLoadingaCancelar" color="red" v-if="originalDisponibilidadeID === 3 && this.localEsterelizavel.ativo" > Cancelar "{{esterilizavel.disponibilidade.nome}}""  </v-btn> -->
          <ConfirmButton color="success" :loading="isSubmitting">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import api from '@/http'
import ConfirmButton from '../../../../../ConfirmButton.vue';
import UtilsFunc from '../../../../../../service/utilsFunc'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "EditarEquipamento",
  data() {
    return withCRUDUtils({
      disponibilidade: [],
      frequencia: [],
      loadingDisponibilidade: false, 
      loadingFrequencia: false,
      isSubmitting: false, 
      disponibilidadeAnterior: null,
      isLoadingaCancelar: false,
      hojeData: '',
      hojeDataPrevista: '',
      frequenciaOptions: [
        { text: 'Sem programação', value: null },
        { text: 'Semanal', value: 7 },
        { text: 'Quinzenal', value: 15 },
        { text: 'Mensal', value: 30 },
        { text: 'Trimestral', value: 90 },
        { text: 'Semestral', value: 180 }
      ],
      valoresAnteriores: {
        data_solicitacao_troca: null,
        data_solicitacao_retirada: null,
      },
      localEsterelizavel: { ...this.esterilizavel, },
    });
  },
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    ativoOptions: {
      type: Array,
      default: () => [
        { value: true, nome: 'Ativo' },
        { value: false, nome: 'Inativo' },
      ],
    },
    esterilizavel: { type: Object, default: null },
  },
  
  methods: {
    async localOnSubmit() {
      try {
        this.isSubmitting = true;
        const validation = this.$refs.formRef.validate();
        if (!validation) {
          this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
          return;
        }
        if (this.localEsterelizavel.disponibilidade === 4) {
          if (!this.localEsterelizavel.data_solicitacao_troca || !this.hojeDataPrevista) {
            this.$toast.error("Por favor, preencha a data de solicitação de troca e a data prevista para troca!");
            return;
          }
        }

        if (this.localEsterelizavel.disponibilidade === 12) {
          if (!this.localEsterelizavel.data_solicitacao_retirada || !this.hojeDataPrevista) {
            this.$toast.error("Por favor, preencha a data de solicitação de retirada e a data prevista para retirada!");
            return;
          }
        }
        const frequencia = this.localEsterelizavel.frequencia && typeof this.localEsterelizavel.frequencia === 'object' ? this.localEsterelizavel.frequencia.id : this.localEsterelizavel.frequencia;
        const fields = {
          // ...this.localEsterelizavel,
          id: this.localEsterelizavel.id,
          frequencia: frequencia,
          obs: this.localEsterelizavel.obs,
          ativo: this.localEsterelizavel.ativo,
          data_prevista_implantacao: this.localEsterelizavel.data_prevista_implantacao,
          requisicao: this.localEsterelizavel.ativo ? true : false,
          }
          if(this.localEsterelizavel.disponibilidade === 4) { // Requisitar Troca
            // console.log('entrou troca')
            fields.disponibilidade = this.localEsterelizavel.disponibilidade,
            fields.data_solicitacao_troca = this.localEsterelizavel.data_solicitacao_troca,
            fields.data_prevista_solicitacao_troca = this.hojeDataPrevista,
            fields.data_solicitacao_retirada = null,
            fields.data_prevista_solicitacao_retirada = null,
            fields.data_retirada = null,
            fields.troca = true,
            fields.retirada = null, 
            fields.requisicao = null
            try {
            await api.put(`/equipamentos/esterelizavel_editar/${this.localEsterelizavel.esterilizavel.id}/`, {
                id: this.localEsterelizavel.esterilizavel.id,
                disponibilidade: this.localEsterelizavel.disponibilidade,
              })
            } catch (error) {
              // console.error('Erro ao atualizar equipamento em paciente:', error);
            }
          }
          if(this.localEsterelizavel.disponibilidade === 12) { // Requisitar Retirada
            // console.log('entrou retirada')
            fields.disponibilidade = this.localEsterelizavel.disponibilidade,
            fields.data_solicitacao_troca = null,
            fields.data_prevista_solicitacao_troca = null,
            fields.data_solicitacao_retirada = this.localEsterelizavel.data_solicitacao_retirada,
            fields.data_prevista_solicitacao_retirada = this.hojeDataPrevista,
            fields.data_troca = null,
            fields.troca = null,
            fields.retirada = true, 
            fields.requisicao = null
            try {
            await api.put(`/equipamentos/esterelizavel_editar/${this.localEsterelizavel.esterilizavel.id}/`, {
                id: this.localEsterelizavel.esterilizavel.id,
                disponibilidade: this.localEsterelizavel.disponibilidade,
              })
            } catch (error) {
              // console.error('Erro ao atualizar equipamento em paciente:', error);
            }
            }

            // console.log(fields)
        await this.onSubmit(fields)
        // if(this.localEsterelizavel.disponibilidade === 12 && this.localEsterelizavel.disponibilidade === 4){
        //   this.ItensRetiradosPacienteNoEsterelizavel()
        // }
      } catch (error) {
        console.error('Erro ao salvar:', error);
        this.$toast.error("Erro ao salvar os dados");
      } finally {
        this.isSubmitting = false;
        this.$emit('close');
      }
    },
    async cancelarEntrega() {
      this.isLoadingaCancelar = true;

      try {
        const fields = { // paciente/esterilizavel
          id: this.localEsterelizavel.id,
          // disponibilidade: 1,
        }
        if (this.localEsterelizavel?.disponibilidade?.id === 3) { // Requisitar
          fields.requisicao = null;
          fields.ativo = false
 
        }
        if (this.localEsterelizavel?.disponibilidade?.id === 12) { // Requisitar Retirada
          fields.data_solicitacao_retirada = null;
          fields.data_prevista_solicitacao_retirada = null;
          fields.data_solicitacao_troca = null;
          fields.data_prevista_solicitacao_troca = null;
          fields.retirada = null;
          fields.troca = null;
          fields.disponibilidade = 1;
          try {
            await api.put(`/equipamentos/esterelizavel_editar/${this.localEsterelizavel.esterilizavel.id}/`, {
                id: this.localEsterelizavel.esterilizavel.id,
                disponibilidade: 1,
              })
           
          } catch (error) {
            console.error('Erro ao atualizar equipamento em paciente:', error);
          }
        }
        if (this.localEsterelizavel?.disponibilidade?.id === 4) { // Requisitar Troca
          console.log('entrou')
          fields.data_solicitacao_retirada = null;
          fields.data_prevista_solicitacao_retirada = null;
          fields.data_solicitacao_troca = null;
          fields.data_prevista_solicitacao_troca = null;
          fields.troca = null;
          fields.retirada = null;
          fields.disponibilidade = 1

          try {
            await api.put(`/equipamentos/esterelizavel_editar/${this.localEsterelizavel.esterilizavel.id}/`, {
                id: this.localEsterelizavel.esterilizavel.id,
                disponibilidade: 1,
              })
           
          } catch (error) {
            console.error('Erro ao atualizar equipamento em paciente:', error);
          }
        }
      // console.log(fields)
        await this.onSubmit(fields).finally(() => {
        this.isSubmitting = false; // Desativar o loading
      });   
        this.$toast.success('Cancelado com sucesso!');
      } catch (error) {
        console.error('Erro ao cancelar:', error);
        this.$toast.error('Erro ao cancelar a requisição.');
      } finally {
        this.isLoadingaCancelar = false;
        // Emite o evento para o componente pai
        this.$emit('close');
      }
    },
    

    async getDisponibilidade() {
      this.loadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingDisponibilidade = false;
      }
    },
    async getFrequencia() {
      this.loadingFrequencia = true
      try {
        const { data } = await api.get("/atendimentos/frequencias/");
        this.frequencia = data;
        // console.log(this.frequencia)
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.loadingFrequencia = false
      }
    },
  },
  computed: {
    originalDisponibilidadeID() {
      return this.localEsterelizavel.disponibilidade.id;
    },
    originalDisponibilidadeInativo() {
      return this.localEsterelizavel.ativo;
    },
    computedDisponibilidade() {
      // Lista base de disponibilidades
      let filtered = this.disponibilidade.filter(d => [3, 4, 12].includes(d.id));

      // Aplica a regra se a disponibilidade original for 3
      if (this.esterilizavel?.disponibilidade?.id === 3) {
        filtered = filtered.filter(d => [3].includes(d.id));
      } else {
        filtered = filtered.filter(d => [4, 12].includes(d.id));
      }

      // Adiciona a disponibilidade atual se não estiver na lista filtrada
      const currentDisponibilidade = this.disponibilidade.find(d => d.id === this.localEsterelizavel.disponibilidade?.id);
      if (currentDisponibilidade && !filtered.some(d => d.id === currentDisponibilidade.id)) {
        filtered.push(currentDisponibilidade);
      }

      return filtered;
    },
  },
  mounted(){
    this.getDisponibilidade()
    this.getFrequencia()
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');

    const dataFormatadaAmanha = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate() +1).padStart(2, '0');

    this.hojeData = dataFormatada
    this.hojeDataPrevista = dataFormatadaAmanha
  },
  watch: {
   'localEsterelizavel.ativo': {
      handler(newValue) {
        if (newValue === false && this.localEsterelizavel.disponibilidade !== 3) {
          // Salva a disponibilidade atual antes de mudar para 12
          this.disponibilidadeAnterior = this.localEsterelizavel.disponibilidade;
          this.localEsterelizavel.disponibilidade = 12;
        } else {
          // Se voltar para true e tiver uma disponibilidade anterior salva
          if (this.disponibilidadeAnterior !== null) {
            this.localEsterelizavel.disponibilidade = this.disponibilidadeAnterior;
            this.disponibilidadeAnterior = null;
          }
        }
      }
    },
    'localEsterelizavel.disponibilidade': {
      handler(newValue) {
        const today = new Date().toISOString().split('T')[0];

        if (newValue === 4) {  // Se mudar para solicitação de retirada
          this.localEsterelizavel.data_solicitacao_troca = today;
        }

        if (newValue === 12) {  // Se mudar para solicitação de troca
          this.localEsterelizavel.data_solicitacao_retirada = today;
        }
      },
      immediate: true // Isso faz com que o watcher execute imediatamente quando o componente é criado
    },
  },
  
}
</script>
<template>
  <v-container fluid class="mr-2 ml-2">
    <!-- <v-card flat>
      <v-card-title>
        <strong> -->
          <h1 class="h1">Cadastros<span v-if="id">{{ id }}</span></h1>
          
        <!-- </strong>
      </v-card-title>
    </v-card> -->
    <v-tabs v-model="tab">
      <!-- <v-tab v-show="autoriza.aba_paciente">Pacientes</v-tab> -->
      <v-tab v-show="autoriza.aba_equipe">Equipe</v-tab>
      <v-tab v-show="autoriza.aba_operadoras">Operadoras</v-tab>
      <v-tab v-show="autoriza.aba_empresa">Empresa</v-tab>
      <v-tab v-show="autoriza.aba_fornecedor">Fornecedores</v-tab>
      <v-tab v-show="autoriza.aba_complementar">Complementar</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- <v-tab-item v-show="autoriza.aba_paciente">
        <Pacientes :id="getAutochamada()" />
      </v-tab-item> -->
      <v-tab-item v-show="autoriza.aba_equipe">
        <EquipeMultidisciplinar />
      </v-tab-item>
      <v-tab-item v-show="autoriza.aba_operadoras">
        <Operadoras />
      </v-tab-item>
      <v-tab-item v-show="autoriza.aba_empresa">
        <Empresas />
      </v-tab-item>
      <v-tab-item v-show="autoriza.aba_fornecedor">
        <Fornecedores />
      </v-tab-item>
      <v-tab-item v-show="autoriza.aba_complementar">
        <Complementary />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import '../../assets/css/styles.css'
import api from '../../http';
import { mapGetters } from 'vuex';

export default {
  name: 'Contas',
  props: {
    id: { type: null }
    
  },
  components: {
    // Pacientes: () => import("./Pacientes.vue"),
    EquipeMultidisciplinar: () => import("./EquipeMultidisciplinar.vue"),
    Operadoras: () => import("./Operadoras.vue"),
    Empresas: () => import("./Empresas.vue"),
    Fornecedores:() => import("./Fornecedores.vue"),
    Complementary: () => import("./Complementary.vue"),
  },
  data: () => ({
    tab: 0,
    autoriza:{
      aba_paciente:false,
      aba_equipe:false,
      aba_empresa:false,
      aba_operadoras:false,
      aba_fornecedor:false,
      aba_complementar:false,
    },
    ref_permissoes:null,
    loading:false,
  }),
  methods: {
    
    async get_listas(url, variavel){
      this.loading = true
      const res = await api.get(url)
      try{
          this.autoriza[variavel] = res.data?.status 
      }catch(error){
        this.$errorHandler(error);
      }finally{this.loading = false}
    },
    getAutochamada() {
      if(this.id !== undefined){
        console.log('getAutochamada id')
        return this.id
      }
      if (this.$route.query.autochamada ){
        console.log('getAutochamada params')
        return this.$route.query.autochamada;
      }
    },
  }, computed: {
    ...mapGetters(['permissoes'])
  }, mounted() {
    this.getAutochamada()
    this.get_listas('contas/config/?permissao=aba_paciente', 'aba_paciente')
    this.get_listas('contas/config/?permissao=aba_equipe', 'aba_equipe')
    this.get_listas('contas/config/?permissao=aba_empresa', 'aba_empresa')
    this.get_listas('contas/config/?permissao=aba_operadoras', 'aba_operadoras')
    this.get_listas('contas/config/?permissao=aba_fornecedor', 'aba_fornecedor')
    this.get_listas('contas/config/?permissao=aba_complementar', 'aba_complementar')
  }

}
</script>


<template>
  <v-container>
    <div class="fixed-section pa-2">
      <v-row dense>
        <v-col cols="8">
          <v-autocomplete
            v-model="searchId"
              :items="equipamentosIds"
              :search-input.sync="searchInput"
              :filter="filterItems"
              label="Pesquisar Equipamento"
              outlined
              dense
              clearable
              :loading="isLoadingIds"
              item-text="searchableText"
              item-value="id"
              return-object
              hide-no-data
          >
            <template v-slot:selection="{ item }">
              {{ item.label }} - {{ item.nome }} - {{item.disponibilidade}} {{item.data_baixa}}
            </template>
            <template v-slot:item="{ item }">
              {{ item.label }} - {{ item.nome }} - {{item.disponibilidade}} {{item.data_baixa}}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="auto">
          <v-btn 
            color="primary" 
            @click="searchEquipamento" 
            :disabled="!searchId"
            :loading="isLoadingDados"
          >
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="selectedEquipamento" dense>
        <v-col cols="12">
          <v-card v-if="selectedEquipamento" class="mb-4">
            <v-card-title class="py-2" >Detalhes do Equipamento</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-4" >
            <v-row>
                  <v-col cols="12" md="4">
                      <strong>ID:</strong> {{ selectedEquipamento?.id || 'N/A' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Nome:</strong> {{ selectedEquipamento?.descricao?.nome || 'N/A' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Status:</strong> 
                      <v-chip :color="selectedEquipamento?.ativo ? 'blue' : 'grey'">
                          {{ selectedEquipamento?.ativo ? 'Ativo' : 'Inativo' }}
                      </v-chip>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="4">
                      <strong>Disponibilidade:</strong> {{ selectedEquipamento?.disponibilidade?.nome || 'N/A' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Depósito:</strong> {{ selectedEquipamento?.deposito?.nome || 'N/A' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Validade Calibração:</strong> 
                      {{ formatarData(selectedEquipamento?.data_validade_calibracao) }}
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="4">
                      <strong>Valor:</strong> {{ formatarMoeda(selectedEquipamento?.valor_final) }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Fornecedor:</strong> 
                      {{ selectedEquipamento?.aquisicao?.fornecedor?.nome_fantasia || 'N/A' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Data Aquisição:</strong> 
                      {{ formatarData(selectedEquipamento?.aquisicao?.dataAquisicao)}}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Qtd Dias:</strong> 
                      {{ selectedEquipamento?.data_baixa ? selectedEquipamento.qtd_dias_baixa : selectedEquipamento.qtd_dias }} dias
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Patrimônio:</strong> 
                      {{ selectedEquipamento?.patrimonio ? selectedEquipamento?.patrimonio : 'Não Cadastrado' }}
                  </v-col>
                  <v-col cols="12" md="4">
                      <strong>Histórico de Patrimônio:</strong> 
                      {{ selectedEquipamento?.historico_patrimonio ? selectedEquipamento?.historico_patrimonio : 'Não Cadastrado' }}
                  </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="3">
                      <strong>Próprio ou Alugado</strong> {{ selectedEquipamento.propriedade? "Prórpio" : "Alugado"}} 
                  </v-col>
                  <v-col cols="12" md="3" v-if="selectedEquipamento.propriedade === false">
                      <strong>Empresa Locadora</strong> {{ selectedEquipamento?.empresa_locadora?.nome_fantasia}} 
                  </v-col>
                  <v-col cols="12" md="3" v-if="selectedEquipamento.propriedade === false">
                      <strong>Data Inicio da Locação</strong> {{formatarData(selectedEquipamento?.data_inicio_locacao)}} 
                  </v-col>
                  <v-col cols="12" md="3" v-if="selectedEquipamento.propriedade === false && selectedEquipamento?.data_fim_locacao">
                      <strong>Data Fim da Locação</strong> {{formatarData(selectedEquipamento?.data_fim_locacao)}} 
                  </v-col>
                 

                  
              </v-row>
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="selectedEquipamento">
      <v-divider class="mt-4"></v-divider>
      <div class="text-h6 my-4 px-2">Histórico Completo</div>
    </div>
    <!-- Histórico do Equipamento -->
     <v-row v-if="selectedEquipamento" class="mt-4">
      
      <v-col cols="12">
        <v-card>
          <!-- <v-card-title>Histórico Completo</v-card-title> -->
          <v-card-text>
            <!-- Loading do Histórico -->
            <v-overlay :value="isLoadingHistorico">
              <v-progress-circular 
                indeterminate 
                size="64" 
                color="primary"
              ></v-progress-circular>
              <p class="mt-3 text-center">Carregando histórico...</p>
            </v-overlay>

            <v-timeline v-if="!isLoadingHistorico && historicoCompleto.length">
              <v-timeline-item 
                  v-for="item in historicoCompleto" 
                  :key="item.id"
                  :color="getTimelineColor(item.tipo)"
                  small
              >
                  <v-row>
                      <v-col cols="12">
                          <strong>
                              {{ getTipoLabel(item) }}: 
                              {{ item.tipo === 'paciente' ? item.paciente.nome : 
                                item.tipo === 'usuario' ? item.usuario.apelido :
                                item.tipo === 'manutencao' ? `${item.fornecedor.nome_fantasia} - Empresa de Manutenção` :
                                item.fornecedor_nome }}
                          </strong>
                      </v-col>

                      <!-- Dados do item (seja paciente, usuário ou Equipamento) -->
                      <v-col 
                          v-for="(data, label) in item.datas" 
                          :key="label" 
                          v-show="data !== null && data !== undefined && data !== ''"
                          cols="12" 
                          sm="6"
                      >
                          <strong>{{ label }}:</strong> 
                          {{ formatarData(data) }}
                      </v-col>

                      <v-col cols="12">
                          <v-chip 
                              :color="getChipColor(item)"
                              small
                          >
                              {{ getChipLabel(item) }}
                          </v-chip>
                      </v-col>
                  </v-row>
              </v-timeline-item>
            </v-timeline>
            <p v-else-if="!isLoadingHistorico" class="text-center">Sem histórico</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="errorMessage" dense>
      <v-col cols="12">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "ConsultaEquipamento",
   data: () => withCRUDUtils ({
      searchId: null,
      equipamentosIds: [],
      selectedEquipamento: null,
      historicoCompleto: [],
      isLoadingDados: false,
      isLoadingHistorico: false,
      isLoadingIds: false,
      errorMessage: '',
      searchInput: '',
  }),
  methods: {
    async carregarEquipamentosIds() {
      this.isLoadingIds = true;
      try {
        const response = await api.get('equipamentos/equipamentos/');
        this.equipamentosIds = response.data.map(item => {
          // Formata a data de baixa se existir
          const dataBaixaFormatada = item.data_baixa ? this.formatarData(item.data_baixa) : '';
          
          return {
            id: item.id,
            label: `ID: ${item.id}`,
            nome: ` Nome: ${item.descricao?.nome}`,
            disponibilidade: ` Disponibilidade: ${item.disponibilidade?.nome || 'Não Cadastrada'}`,
            data_baixa: item.data_baixa ? ` - Data da Baixa: ${dataBaixaFormatada}` : '',
            searchableText: `ID: ${item.id} ${item.descricao?.nome || ''} ${item.disponibilidade?.nome || ''} ${dataBaixaFormatada || ''}`
          };
        });
      } catch (error) {
        console.error('Erro ao carregar IDs de Equipamento:', error);
        this.$toast.error('Erro ao carregar lista de Equipamento');
      } finally {
        this.isLoadingIds = false;
      }
    },
    async searchEquipamento() {
    this.errorMessage = '';
    this.selectedEquipamento = null;
    this.historicoCompleto = [];

    if (!this.searchId) {
        this.$toast.error('Por favor, selecione um Equipamento');
        return;
    }

    this.isLoadingDados = true;
    try {
        const idBusca = this.searchId.id;
        
        // Carrega todos os dados necessários de uma vez
        const [
            equipamentoResponse,
            pacientesResponse,
            usuariosResponse,
            manutencaoResponse
        ] = await Promise.all([
            api.get('equipamentos/equipamentos/'),
            api.get('pacientes/equipamentos/'),
            api.get('equipamentos/equipamento_usuarios/'),
            api.get('equipamentos/equipamento_manutencao/')
        ]);

        // Encontra o Equipamento
        this.selectedEquipamento = equipamentoResponse.data.find(item => item.id === idBusca);
        
        if (!this.selectedEquipamento) {
            this.$toast.error('Equipamento não encontrado');
            return;
        }

        // Processa histórico de pacientes
        const pacientesHistorico = pacientesResponse.data
            .filter(item => item.equipamento?.id === this.selectedEquipamento?.id)
            .map(item => ({
                ...item,
                tipo: 'paciente',
                datas: {
                    'Data Requisição': item.data_requisicao,
                    'Data Prevista Implantação': item.data_prevista_implantacao,
                    'Data Implantação': item.data_implantacao,
                    'Data Solicitação Troca': item.data_solicitacao_troca,
                    'Data Prevista Troca': item.data_prevista_solicitacao_troca,
                    'Data Troca': item.data_troca,
                    'Data Solicitação Retirada': item.data_solicitacao_retirada,
                    'Data Prevista Retirada': item.data_prevista_solicitacao_retirada,
                    'Data Retirada': item.data_retirada
                },
                data_ordem: new Date(item.data_requisicao || item.data_prevista_implantacao || item.data_implantacao)
            }));

        // Processa histórico de usuários
        const usuariosHistorico = usuariosResponse.data
            .filter(item => item.equipamento?.id === this.selectedEquipamento?.id)
            .map(item => ({
                ...item,
                tipo: 'usuario',
                datas: {
                    'Data Requisição': item.data_requisicao,
                    'Data Entrega': item.data_entrega,
                    'Data Devolução': item.data_devolucao
                },
                data_ordem: new Date(item.data_requisicao || item.data_entrega)
            }));

        // Processa histórico de Equipamento
        const manutencaoHistorico = manutencaoResponse.data
            .filter(item => item.equipamento?.id === this.selectedEquipamento?.id)
            .map(item => ({
                ...item,
                tipo: 'manutencao',
                datas: {
                    'Data de Envio': item.data_envio,
                    'Data de Recebimento': item.data_recebimento
                },
                data_ordem: new Date(item.data_envio || item.data_recebimento),
                fornecedor_nome: item.fornecedor?.nome_fantasia,
                ativo: true
            }));

        // Combina e ordena todo o histórico
        this.historicoCompleto = [...pacientesHistorico, ...usuariosHistorico, ...manutencaoHistorico]
            .sort((a, b) => b.data_ordem - a.data_ordem);

    } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar os dados do Equipamento');
        this.selectedEquipamento = null;
        this.historicoCompleto = [];
    } finally {
        this.isLoadingDados = false;
    }
},

    async buscarHistoricoCompleto() {
      this.isLoadingHistorico = true;
      try {
          // Busca pacientes
          const pacientesResponse = await api.get('pacientes/equipamentos/');
          const pacientesHistorico = pacientesResponse.data
              .filter(item => item.equipamento?.id === this.selectedEquipamento?.id)
              .map(item => ({
                  ...item,
                  tipo: 'paciente',
                  datas: {
                      'Data Requisição': item.data_requisicao,
                      'Data Prevista Implantação': item.data_prevista_implantacao,
                      'Data Implantação': item.data_implantacao,
                      'Data Solicitação Troca': item.data_solicitacao_troca,
                      'Data Prevista Troca': item.data_prevista_solicitacao_troca,
                      'Data Troca': item.data_troca,
                      'Data Solicitação Retirada': item.data_solicitacao_retirada,
                      'Data Prevista Retirada': item.data_prevista_solicitacao_retirada,
                      'Data Retirada': item.data_retirada
                  },
                  data_ordem: new Date(item.data_requisicao || item.data_prevista_implantacao || item.data_implantacao)
              }));

          // Busca usuários
          const usuariosResponse = await api.get('equipamentos/equipamento_usuarios/');
          const usuariosHistorico = usuariosResponse.data
              .filter(item => item.equipamento?.id === this.selectedEquipamento?.id)
              .map(item => ({
                  ...item,
                  tipo: 'usuario',
                  datas: {
                      'Data Requisição': item.data_requisicao,
                      'Data Entrega': item.data_entrega,
                      'Data Devolução': item.data_devolucao
                  },
                  data_ordem: new Date(item.data_requisicao || item.data_entrega)
              }));

            // Busca Equipamento
            const manutencaoResponse = await api.get('equipamentos/equipamento_manutencao/');
            const manutencaoHistorico = manutencaoResponse.data
                .filter(item => item.equipamento.includes(this.selectedEquipamento?.id))
                .map(item => ({
                    ...item,
                    tipo: 'manutencao',
                    datas: {
                        'Data de Envio': item.data_envio,
                        'Data de Recebimento': item.data_recebimento
                    },
                    data_ordem: new Date(item.data_envio || item.data_recebimento),
                    fornecedor_nome: item.fornecedor?.nome_fantasia,
                    ativo: true // Considerando sempre ativo para histórico
                }));


            this.historicoCompleto = [...pacientesHistorico, ...usuariosHistorico, ...manutencaoHistorico]
            .sort((a, b) => b.data_ordem - a.data_ordem);

      } catch (error) {
          console.error('Erro ao buscar histórico completo:', error);
          this.$toast.error(`Erro ao buscar histórico: ${error}`);
      } finally {
          this.isLoadingHistorico = false;
      }
    },
    formatarData(data) {
        if (!data) return 'Não informado';
        const [ano, mes, dia] = data.split('-');
        return `${dia}/${mes}/${ano}`;
    },
    formatarMoeda(valor) {
      if (!valor) return 'R$ 0,00';
      return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
      }).format(valor);
    },
    getTimelineColor(tipo) {
        switch(tipo) {
            case 'paciente': return 'primary';
            case 'usuario': return 'secondary';
            case 'manutencao': return 'orange';
            default: return 'grey';
        }
    },

    getTipoLabel(item) {
        switch(item.tipo) {
            case 'paciente': return 'Paciente';
            case 'usuario': return 'Usuário';
            case 'manutencao': return 'Equipamento';
            default: return 'Desconhecido';
        }
    },

    getChipColor(item) {
        if (item.tipo === 'manutencao') {
            return item.data_recebimento ? 'success' : 'warning';
        }
        return item.ativo ? 'blue' : 'grey';
    },

    getChipLabel(item) {
        if (item.tipo === 'manutencao') {
            return item.data_recebimento ? 'Recebido' : 'Em Processo';
        }
        return item.ativo ? 'Ativo' : (item.tipo === 'paciente' ? 'Inativo' : 'Devolvido');
    },
    filterItems(item) {
      if (!this.searchInput) return true;
      
      const searchTerm = this.searchInput.toLowerCase();
      return item.searchableText.toLowerCase().includes(searchTerm);
    },
  },
   mounted() {
    this.carregarEquipamentosIds();
  },
};
</script>

<style scoped>
.fixed-section {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding: 16px;
    margin-bottom: 16px;
}

/* Remove a sombra dos cards */
.v-card {
    box-shadow: none !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px;
}

.v-timeline-item {
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
}

/* Ajuste para telas menores */
@media (max-width: 600px) {
    .fixed-section {
        position: relative;
        padding: 8px;
    }
}

/* Estilo para o scroll */
:deep(.v-timeline) {
    padding-top: 8px;
}

.history-section {
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
}
</style>
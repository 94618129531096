<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading=loading>
    <v-icon left>mdi-printer</v-icon> Rel. Prorrogação
  </v-btn>
</template>
  
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "@/service/utilsFunc";
import UtilsPDF from '../../service/utilsPDF';
import logo from "@/assets/logo.png";
import gptw from "@/assets/GPTW_2023-2024.png"
import ona from "@/assets/ONA_Nivel_3.png"
import "../../assets/css/styles.css";

const { withCRUDUtils } = UtilsFunc

export default {
  name: "Prorrogacao",
  data: () => withCRUDUtils({
    prorrogacao: [],
    orcamento: [],
    quadroclinico: [],
    loading: false,
    position: undefined,
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String,
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProrrogacao(),
          this.getPlanoTerapeutico(),
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-51):", error);
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF: (L-70):", error);
      }
      
      const planoterapeutico = this.planoterapeutico
      const data = this.orcamento[0]
      const quadro = this.quadroclinico;

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relatório para Prorrogação`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right", });

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 36);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);


      yPosition = initialYPosition + lineHeight

      const dataNascimento = data && data.data_nascimento
        ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}`
        : 'Data Nascimento: ';

      doc.text(
        dataNascimento,
        200,
        initialYPosition,
        { align: "right" }
      );

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxHeight = 270;
      const pageWidth = 210;
      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {

        doc.setFontSize(12);
        doc.text(`Solicito prorrogação dos atendimentos para o período de ${UtilsPDF.formatDate(data.data_inicio)} até ${UtilsPDF.formatDate(data.data_fim)} para o(a) `, 20, 73);

        yPosition += lineHeight
        doc.setFontSize(12);
        doc.text(`paciente ${data.paciente}, Data de Nascimento ${UtilsPDF.formatDate(data.data_nascimento)}. Segue abaixo o plano`, 10, 79);

        yPosition += lineHeight;
        doc.setFontSize(12);
        doc.text(`terapêutico proposto e os relatórios de cada especialidade que atende o(a) paciente.`, 10, 85);
        yPosition += lineHeight * 2;

  //  Quadro Clínico se houver
        if (quadro?.quadro_clinico && quadro.quadro_clinico !== "Sem informações recentes") {
          yPosition += lineHeight * 2;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          let titulo = "Quadro Clínico: ";
          let tituloLargura = doc.getTextWidth(titulo);
          doc.text(titulo, xFixedPosition, yPosition);
          yPosition += lineHeight;
          doc.setFont("helvetica", "normal");
          let maxLineWidth = 210; // Ajuste conforme necessário
          let conteudo = quadro.quadro_clinico;
          let linhas = doc.splitTextToSize(conteudo, maxLineWidth - tituloLargura);
          doc.text(linhas, xFixedPosition, yPosition);
          yPosition += (linhas.length * 5);
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }
// Plano Terapêutico Proposto
        yPosition += ((lineHeight * 2) + 5);
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        let textWidth2 = doc.getTextWidth("Plano Terapêutico Proposto");
        let xCenteredPosition2 = pageWidth / 2 - textWidth2 / 2;
        doc.text("Plano Terapêutico Proposto", xCenteredPosition2, yPosition);

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");

        // Objeto para armazenar a soma de procedimentos por procedimento_id
        let procedimentoMap = {};

        // Iterar sobre o array de planoterapeutico
        planoterapeutico.forEach(item => {
          let procedimentoId = item.procedimento?.procedimento_id;
          let procedimentoNome = item.procedimento?.nomecomercial
            ? `${item.procedimento?.nomecomercial} - ${item.procedimento?.procedimento}`
            : item.procedimento?.procedimento;
          let frequenciaNome = item.frequencia?.nome || "N/A";

          // Se o procedimento já existir no mapa, incrementar a quantidade
          if (procedimentoMap[procedimentoId]) {
            procedimentoMap[procedimentoId].quantidade += 1;
          } else {
            // Caso contrário, adicionar o procedimento no mapa
            procedimentoMap[procedimentoId] = {
              'Procedimento': procedimentoNome,
              'Frequência': frequenciaNome
            };
          }
        });

        // Preparar os dados para a tabela
        const columns = ['Procedimento', 'Frequência'];
        const rows = Object.values(procedimentoMap).map(item => [
          item['Procedimento'],
          item['Frequência']
        ]);

        // Renderizar a tabela no PDF
        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
        });


        yPosition = doc.lastAutoTable.finalY + lineHeight; // Obtém a altura da tabela
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 250);
      }

      yPosition += lineHeight;
      this.position = yPosition;
      // Exclui uma página em branco no fim do arquivo, se houver
      if (!this.contentAdded && doc.internal.getNumberOfPages() > 1) {
        doc.deletePage(doc.internal.getNumberOfPages());
      }
    },
    async generatePDF() {

      // Verificar se existem dados de prorrogação
      if (!this.prorrogacao || this.prorrogacao.length === 0) {
        this.$toast.error("Não foi possível gerar o relatório: Sem dados suficiente.");
        this.loading = false;
        return;  // Interrompe a geração do PDF
      }

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const maxWidth = 185;
      const maxHeight = 280;
      const pageWidth = 210;
      const xFixedPosition = 10;
      const lineHeight = 6;
      const lineWidth = 3;

      const orcamento = this.orcamento;
      const data = this.prorrogacao;

      this.contentAdded = false;
      let yPosition = this.position
      let validItems = data.filter(item => item.texto_info);

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Relatório por Especialidade");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;

      doc.text("Relatório por Especialidade", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight;

      for (let i = 0; i < validItems.length; i++) {
        let item = validItems[i];
        let xPosition = 10;
        doc.setFontSize(12);
        // console.log(item)

        if (yPosition >= 260) { // Se a evolução começar no fim da página, adiciona uma nova página
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 260)
        }

        xPosition = 10;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        yPosition += lineHeight;

        doc.setFont("helvetica", "bold");
        doc.text(`Profissional:`, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Profissional: `);

        doc.setFont("helvetica", "normal");
        doc.text(`${item.profissional.apelido}.`, xPosition, yPosition);

        const profissionalWidth = doc.getTextWidth(`Profissional: ${item.profissional.apelido}. `);
        xPosition = xFixedPosition + profissionalWidth + lineWidth

        doc.setFont("helvetica", "bold");
        doc.text(`Conselho:`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`Conselho:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.profissional.conselho.nome}.`, xPosition, yPosition);

        const conselhoWidth = xPosition + doc.getTextWidth(`${item.profissional.conselho.nome}. `);

        doc.setFont("helvetica", "bold");
        doc.text(`Nº Conselho: `, conselhoWidth, yPosition);
        xPosition = conselhoWidth + doc.getTextWidth(`Nº Conselho:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.profissional.nconselho}.`, xPosition, yPosition);


        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        doc.setFont("helvetica", "bold");
        doc.text(`Especialidade: `, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Especialidade:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.especialidade.nome}`, xPosition, yPosition);

        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);


        doc.setFont("helvetica", "bold");
        doc.text(`Procedimento: `, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Procedimento:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.procedimento.nomecomercial || item.procedimento.procedimento}`, xPosition, yPosition);

        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        doc.setFont("helvetica", "bold");
        doc.text(`Frequência: `, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Frequência:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.frequencia.nome}.`, xPosition, yPosition);

        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        if (item.uso_respirador !== "NÃO POSSUI" && item.uso_respirador !== "") {
          doc.setFont("helvetica", "bold");
          doc.text(`Usa Respirador de forma`, xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Usa Respirador de forma:`);

          doc.setFont("helvetica", "normal");
          doc.text(`${item.uso_respirador === 'CONTÍNUO' ? 'contínua' : 'intermitente'}.`, xPosition, yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }

      // Peso e Altura
        if ( item?.peso || item?.altura) {
          doc.setFont("helvetica", "bold");
          doc.text("Altura:", xPosition, yPosition);
          doc.text(`${item.altura ? `${item.altura} kg` : 'Não cadastrado'} - Peso: ${item.peso ? `${item.peso} m` : 'Não cadastrado'} `, xPosition + 15, yPosition);
          yPosition += lineHeight;
        }

        // TQT
        if ( item?.tqt && (item.profissional.conselho.id === 4 || item.profissional.conselho.id === 3 )) {
          doc.setFont("helvetica", "bold");
          doc.text("Possui TQT: ", xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Possui TQT: `);

          doc.setFont("helvetica", "normal");
          doc.text(` Modelo: ${item.tqt.modelo}, Número: ${item.numeracao_tqt}`, xPosition, yPosition);
          yPosition += lineHeight; // Move para a próxima linha
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }
    // GTT
        if ( item?.via_alimentacao_gtt  && (item.profissional.conselho.id === 4 || item.profissional.conselho.id === 3)) {
          doc.setFont("helvetica", "bold");
          doc.text("Possui Sonda / GTT: ", xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Possui Sonda / GTT: `);

          doc.setFont("helvetica", "normal");
          doc.text(` Modelo: ${item?.via_alimentacao_gtt?.tipo ? item?.via_alimentacao_gtt?.tipo : 'Não informada'}; Tamanho: ${item?.tamanho_gtt ? item?.tamanho_gtt : 'Não informado'}`, xPosition + 42, yPosition); yPosition += lineHeight; // Move para a próxima linha
          yPosition += lineHeight; // Move para a próxima linha
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }

      // Respirador   
        if ( item?.tipo_ventilador_mecanico  && item?.profissional.conselho.id === 1) {       
          
          doc.setFont("helvetica", "bold");
          doc.text("Modelo: ", xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Modelo: `);
          
          // Adiciona o valor do modelo com espaçamento adicional
          doc.setFont("helvetica", "normal");
          doc.setFontSize(11);
          let modeloVentilador = `${item?.tipo_ventilador_mecanico.tipo}; Modo Ventilatório: ${item?.modo_ventilatorio.tipo};` || 'Não relatado';
          doc.setFontSize(11);
          doc.text(modeloVentilador,  xPosition, yPosition); 
         
          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
          
          doc.setFont("helvetica", "bold");
          doc.text("Parâmetros: ", xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Parâmetros: `);
          
          // Adiciona o valor do modelo com espaçamento adicional
          doc.setFont("helvetica", "normal");
          doc.setFontSize(11);
          let parametros = `Vc: ${item?.ventilador_vc} ml; IPAP: ${item?.ventilador_ipap} cmH2O; EPAP: ${item?.ventilador_epap} cmH2O; FR: ${item?.ventilador_fr} rpm; TINS: ${item?.ventilador_vc} s.` || 'Não relatado';
          doc.text(parametros,  xPosition, yPosition); 

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
          
        }

        const nonVariableParts = [
          "Relatório da Última Evolução: ",
          "Objetivo Esperado: ",
          "Informações para Prorrogação: ",
        ];
        const variableParts = [
          `${item.texto_evolucao}`,
          `${item.texto_meta}`,
          `${item.texto_info}`,
        ];

        let fullText = `Relatório da Última Evolução: ${item.texto_evolucao}. `;

        if (item.diagnostico_clinico !== null) {
          fullText += `Diagnóstico Clínico: ${item.diagnostico_clinico} .`;
        }
        if (item.conduta !== null) {
          fullText += `Conduta: ${item.conduta} .`;
        }
        if (item.exame_fisico !== null) {
          fullText += `Exame Físico: ${item.exame_fisico} .`;
        }

        fullText += ` \nObjetivo Esperado: ${item.texto_meta}. \nInformações para Prorrogação: ${item.texto_info} `;

        let evolution = doc.getTextWidth(`Relatório da Última Evolução: : ${item.texto_evolucao}. Diagnóstico Clínico: ${item.diagnostico_clinico}. Conduta: ${item.conduta}. Exame Físico: ${item.exame_fisico}. Objetivo Esperado: ${item.texto_meta}. Informações para Prorrogação: ${item.texto_info}.`)
        let splittedText = doc.splitTextToSize(fullText, maxWidth);

        if (evolution < maxWidth) {
          doc.setFont("helvetica", "bold");
          doc.text("Relatório da Última Evolução: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(`${item.texto_evolucao}. `, xFixedPosition + lineWidth + doc.getTextWidth("Relatório da Última Evolução: "), yPosition);

          // Adicionar Diagnóstico Clínico apenas se estiver presente
          if (item.diagnostico_clinico !== null && item.diagnostico_clinico !== undefined) {
            doc.text(` Diagnóstico Clínico: ${item.diagnostico_clinico}. `, xFixedPosition + lineWidth + doc.getTextWidth("Relatório da Última Evolução: "), yPosition);
          }
          if (item.conduta !== null && item.conduta !== undefined) {
            doc.text(` Conduta: ${item.conduta}. `, xFixedPosition + lineWidth + doc.getTextWidth("Relatório da Última Evolução: "), yPosition);
          }
          if (item.exame_fisico !== null && item.exame_fisico !== undefined) {
            doc.text(` Exame Físico: ${item.exame_fisico} .`, xFixedPosition + lineWidth + doc.getTextWidth("Relatório da Última Evolução: "), yPosition);
          }

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          doc.text("Objetivo Esperado: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(` ${item.texto_meta}.`, xFixedPosition + lineWidth + doc.getTextWidth("Objetivo Esperado: "), yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          doc.text("Informações para Prorrogação: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(` ${item.texto_info}.`, xFixedPosition + lineWidth + doc.getTextWidth("Informações para Prorrogação: "), yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);


        } else {
          splittedText.forEach((line) => {
            yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

            let currentX = xFixedPosition;

            nonVariableParts.forEach((part) => {
              if (line.includes(part)) {
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(part, currentX, yPosition);
                currentX += doc.getTextWidth(part);
                line = line.replace(part, "");
              }
            });

            variableParts.forEach((part) => {
              if (line.includes(part)) {
                doc.setFontSize(12);
                doc.setFont("helvetica", "normal");
                doc.text(part, currentX, yPosition);
                currentX += doc.getTextWidth(part);
                line = line.replace(part, "");
              }
            });

            if (line.trim() !== "") {
              doc.setFontSize(12);
              doc.setFont("helvetica", "normal");
              doc.text(line, currentX, yPosition);
            }

            yPosition += lineHeight;
          })


         if (item.profissional.carimbo) {
  let url = process.env.NODE_ENV === 'production'
    ? 'https://api.gssma.com.br'
    : 'https://api.gssma.com.br';
  let carimbo = `${url}${item.profissional.carimbo}`;

  try {
    // Definir o título "Carimbo"
    doc.setFont("helvetica", "bold");
    doc.text("Carimbo: ", xFixedPosition, yPosition);
    doc.setFont("helvetica", "normal");
    yPosition += lineHeight;

    // Extrair a extensão da imagem
    let imageFormat = carimbo.split('.').pop().toUpperCase();
    if (imageFormat === "JPG") imageFormat = "JPEG"; // Converter JPG para JPEG

    // Carregar a imagem do carimbo
    doc.addImage(
      carimbo,
      imageFormat,
      xFixedPosition,
      yPosition,
      30, // largura
      10   // altura
    );

    const imgHeight = 15;
    yPosition += imgHeight + lineHeight;
  } catch (error) {
    console.error("Erro ao carregar a imagem do carimbo:", error);
    doc.text("Carimbo não disponível", xFixedPosition, yPosition);
    yPosition += lineHeight;
  }
} else {
  yPosition += lineHeight;
}


          if (yPosition <= 230 || yPosition >= 140) {
            yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
            if ((i !== validItems.length - 1) && yPosition > 100 && yPosition < 250) {
              doc.line(10, yPosition, pageWidth - 10, yPosition);
              yPosition += 2;
            }
          }
        }
      }

      // Exclui uma página em branco no fim do arquivo, se houver
      if (!this.contentAdded && doc.internal.getNumberOfPages() > 1) {
        doc.deletePage(doc.internal.getNumberOfPages());
      }

      UtilsPDF.addPageNumbers(doc);
      this.doc = doc
      const empresa = orcamento[0]?.convenio.replace(/\s+/g, '_');
      const name = orcamento[0]?.paciente.replace(/\s+/g, '_');
      const period = `Periodo_de_${UtilsPDF.formatDateToFilename(orcamento[0]?.data_inicio)}_ate_${UtilsPDF.formatDateToFilename(orcamento[0]?.data_fim)}`;

      const filename = `Relatorio_de_Prorrogacao_${name}_${empresa}_${period}.pdf`;

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    checkAndAddPage(doc, yPosition, lineHeight, maxHeight) {
      if (yPosition + lineHeight > maxHeight) {
        doc.addPage();
        this.drawHeader(doc);
        this.position = 80;
        return this.position;  // retorna o novo yPosition
      } else {
        this.contentAdded = true;
        return yPosition;  // retorna o yPosition inalterado
      }
    },
    async getProrrogacao() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/evolucoes-prorrogacao/?sessao=${this.selectedBudgets[0]}`);
          this.prorrogacao = data;
          // console.log('this.prorrogacao -> ', this.prorrogacao)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados da Prorrogação: (L-547):", error);
        }
      }
    },
    async getPlanoTerapeutico() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`);
          this.planoterapeutico = data;
          // console.log('this.planoterapeuticoo -> ', this.planoterapeutico)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados da Prorrogação: (L-547):", error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.orcamento = data
          this.pacienteID = this.orcamento[0].paciente_id
          this.getQuadroClinico()
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Orçamento: (L-559):", error);
        }
      }
    },
    async getQuadroClinico() {
      if (this.pacienteID) {
        try {
          const { data } = await api.get(`atendimentos/app-texto-quadroclinico/?paciente=${this.pacienteID}`);
          this.quadroclinico = data[0]
          // console.log('getQuadroClinico -> ', this.quadroclinico)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Quadro Clínico: (L-570):", error);
        }
      }
    },
  },
};
</script>
<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Esterilizável" v-model="currentItem.nome.nome" outlined  disabled/>
      </v-col>
      <v-col >
        <v-autocomplete :loading="isLoadingDeposito" dense label="Depósito" v-model="currentItem.deposito" outlined  :items="deposito" item-text="nome" item-value="id" />
      </v-col>
      <v-col >
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Disponibilidade" v-model="currentItem.disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id" :disabled="!isDisponibilidadeEditable" />
      </v-col>
      <v-col cols="3" >
        <v-text-field type="date" outlined dense label="Data da Validade Esterilização" v-model="currentItem.data_validade_esterilizacao"> </v-text-field>
      </v-col>
    </v-row>
    
    <!-- Paciente -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="paciente.length > 0"></v-divider>
    <h3 class="blod_color" v-if="paciente.length > 0"> Dados do Paciente:</h3> <br>
    <!-- <v-row > -->
    <!-- <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="(usuario.length <= 0) && ![ 7, 5, 9, 11].includes(currentDisponibilidadeId)"></v-divider>
    <h3 class="blod_color" v-if="(usuario.length <= 0) && ![ 7, 5, 9, 11].includes(currentDisponibilidadeId)"> Dados do Paciente:</h3> <br> -->
    <v-row  v-if="(paciente.length > 0)">
       <v-col cols="2">
          <v-text-field :loading="isLoadingPaciente" dense  label="ID do Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.id : ''"  outlined  readonly disabled />
      </v-col>
      <v-col cols="4">
          <v-text-field :loading="isLoadingPaciente" dense  label="Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.nome : ''"  outlined  readonly  disabled/>
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Requisição"  :value="paciente.length > 0 ? paciente[0].data_requisicao : ''" type="date" outlined readonly  disabled/>  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Implantação"  :value="paciente.length > 0 ? paciente[0].data_implantacao : ''"   type="date" outlined readonly disabled />  
      </v-col>
      <!-- <v-col cols="2" v-if="(paciente.length > 0)">
        <v-btn-toggle  v-model="paciente[0].ativo" mandatory  class="white--text">
          <v-btn  :value="false" :color="paciente[0].ativo === false ? 'error' : ''" class="white--text"  > Inativo </v-btn>
          <v-btn  :value="true" :color="paciente[0].ativo === true ? 'green' : ''" class="white--text" > Ativo </v-btn>
        </v-btn-toggle>
      </v-col> -->
    </v-row>
    <!-- <v-row >
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingPacientDisponivel" dense  label="Selecione o Paciente"  :items="pacienteDisponivel" item-text="nome_formatado" item-value="id" outlined  />
      </v-col>
      <v-col cols="3">
        <v-text-field :loading="isLoadingPacientDisponivel" dense  label="Data da Implantação"  type="date"  outlined clearable />  
      </v-col>
    </v-row>  -->








    <!-- Usuário -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="usuario.length > 0"></v-divider>
    <h3 class="blod_color"  v-if="usuario.length > 0"> Dados do Usuário "Profissional":</h3> <br>
    <v-row  v-if="usuario.length > 0">
      <v-col cols="1">
          <v-text-field :loading="isLoadingUsuario" dense  label="ID do Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.id : ''"  outlined  readonly  disabled/>
      </v-col>
      <v-col cols="3">
          <v-text-field :loading="isLoadingUsuario" dense  label="Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.apelido : ''"  outlined  readonly disabled/>
      </v-col>
      <v-col cols="2">
          <v-text-field :loading="isLoadingUsuario" dense  label="Especialidade"  :value="usuario.length > 0 ? usuario[0].usuario.especialidade.nome : ''"  outlined  readonly disabled />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Requisição"  :value="usuario.length > 0 ? usuario[0].data_requisicao : ''" type="date" outlined readonly disabled/>  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Entrega"  :value="usuario.length > 0 ? usuario[0].data_entrega : ''"   type="date" outlined readonly  disabled/>  
      </v-col>
      <!-- <v-col cols="2" v-if="usuario.length > 0">
        <v-btn-toggle  v-model="usuario[0].ativo" mandatory  class="white--text">
          <v-btn  :value="false" :color="usuario[0].ativo === false ? 'error' : ''" class="white--text"  > Inativo </v-btn>
          <v-btn  :value="true" :color="usuario[0].ativo === true ? 'green' : ''" class="white--text" > Ativo </v-btn>
        </v-btn-toggle>
      </v-col> -->
    </v-row>
    <!-- <v-row >
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingUsuarioDisponivel" dense  label="Selecione o Usuário"  :items="usuarioDisponivel" item-text="nome_formatado" item-value="id" outlined  @change="handleUsuarioChange"/>
      </v-col>
      <v-col cols="2">
          <v-text-field :loading="isLoadingUsuario" dense  label="Especialidade" :value="usuarioSelecionado?.especialidade?.nome" outlined  disabled />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuarioDisponivel"  dense  label="Data da Entrega"  type="date"  outlined clearable />  
      </v-col> 
    </v-row> -->



    <!-- Empresa de Esterelização -->
    <!-- <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;"  ></v-divider>
    <h3 class="blod_color">Enviar para Esterilização:</h3> <br>
    <v-row>
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingFornecedor" dense  label="Empresa Esterelizadora"  :items="fornecedorEsterelizacao" item-text="nome_fantasia" item-value="id" outlined  v-model="currentItem.esterilizacao_fornecedor" clearable/>
      </v-col>
      <v-col cols="3">
        <v-text-field :loading="isLoadingFornecedor" dense  label="Data do Envio"  type="date" outlined clearable v-model="currentItem.esterilizacao_data_envio" />  
      </v-col>
    </v-row> -->

    <!-- Dados Gerais -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;"></v-divider>
    <h3 class="blod_color"> Fornecedor do Esterilizável "Compra":</h3> <br>
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingFornecedor"  dense  label="Fornecedor"  v-model="currentItem.fornecedor"  :value="fornecedorInicial" outlined   :items="fornecedorAquisicao"  item-text="nome_fantasia"  item-value="id" disabled></v-autocomplete>
      </v-col>
       <v-col cols="2">
        <v-text-field  outlined dense label="Data da Aquisição" v-model="currentItem.data_aquisicao" type="date" clearable ></v-text-field>
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Valor Compra R$" v-model="currentItem.valor_final" outlined  />
      </v-col>
      <v-col cols="2" v-if="currentItem.disponibilidade.id !== 1">
        <v-text-field  outlined dense label="Data da Baixa" v-model="currentItem.data_baixa" type="date" clearable ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field  outlined dense label="Observação" v-model="currentItem.obs"></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text" v-if="currentItem.disponibilidade.id !== 1">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "EditarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      isLoadingDisponibilidade: false, // Status de carregamento
      isLoadingDeposito: false, // Status de carregamento
      isLoadingFornecedor: false,
      isLoadingPaciente: false,
      isLoadingPacientDisponivel: false,
      isLoadingUsuario: false,
      isLoadingUsuarioDisponivel: false, 
      disponibilidade: [],
      deposito: [],
      fornecedor:[],
      paciente: [],
      usuario: [],
      usuarioDisponivel: [],
      pacienteDisponivel: [],
      fornecedorAquisicao: [],
      fornecedorEsterelizacao: [],
      selectedPaciente: null,
      selectedUsuario: null,
      usuarioSelecionado: null,
    });
  },
  components: { ConfirmButton },
  methods: {
    async localOnSubmit() {
      // Valida o formulário
      const validation = this.$refs.formRef.validate();

      // Verifica se o formulário está válido
      if (!validation) {
        this.$toast.error("Preencha todos os campos obrigatórios antes de salvar.");
        return;
      }
      // Validação para 'ativo' e 'data_baixa'
      if (this.currentItem.ativo === 0 && !this.currentItem.data_baixa) {
        this.$toast.error("Para inativar o item, é necessário informar a Data da Baixa.");
        return;
      }

      if (this.currentItem.ativo === 1 && this.currentItem.data_baixa) {
        this.$toast.error("A Data da Baixa deve estar vazia ao ativar o item.");
        return;
      }

      const disponibilidadeId = typeof this.currentItem.disponibilidade === "object" 
        ? this.currentItem.disponibilidade.id 
        : this.currentItem.disponibilidade;

      const depositoId = typeof this.currentItem.deposito === "object" 
        ? this.currentItem.deposito.id 
        : this.currentItem.deposito;



        // Cria um objeto específico para esterilização
        const fields = {
          id: this.currentItem.id,
          ativo: this.currentItem.ativo,
          valor_final: this.currentItem.valor_final,
          disponibilidade: disponibilidadeId,
          deposito: depositoId,
          data_baixa: this.currentItem.data_baixa,
          data_aquisicao: this.currentItem.data_aquisicao,
          obs: this.currentItem.obs,
          data_validade_esterilizacao: this.currentItem.data_validade_esterilizacao,
        };

      // console.log(fields)
      try {
        await this.onSubmit(fields);
      } catch (error) {
        console.error("Erro ao salvar dados:", error);
        this.$toast.error("Erro ao salvar os dados.");
      }
    },

    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        
        // Primeiro, filtramos normalmente
        const filteredData = data.filter(item => 
          item.ativo && ![1, 2, 3, 4, 7, 12, 13, 14, 15, 16, 17, 18].includes(item.id)
        );

        // Pegamos o ID da disponibilidade atual
        const currentDisponibilidadeId = typeof this.currentItem.disponibilidade === 'object' 
          ? this.currentItem.disponibilidade.id 
          : this.currentItem.disponibilidade;

        // Se a disponibilidade atual existe e não está na lista filtrada
        if (currentDisponibilidadeId && !filteredData.find(item => item.id === currentDisponibilidadeId)) {
          // Encontramos a disponibilidade atual nos dados originais
          const currentDisponibilidade = data.find(item => item.id === currentDisponibilidadeId);
          if (currentDisponibilidade) {
            // Adicionamos ela à lista filtrada
            filteredData.push(currentDisponibilidade);
          }
        }

        this.disponibilidade = filteredData;
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async getDeposito() {
      this.isLoadingDeposito = true;
      try {
        const { data } = await api.get(`/uteis/deposito/`);
        this.deposito = data.filter(item => item.ativo)
        // console.log(this.deposito)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDeposito = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true
      try {
        const { data } = await api('fornecedores/fornecedores/')
        this.fornecedorAquisicao = data.filter(item => item.ativo).sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia))
        this.fornecedorEsterelizacao = data.filter(item => 
            item.tipo && item.tipo.some(tipo => tipo.id === 8)
        ).sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        // console.log('Fornecedor --< ',this.fornecedor)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-228). ${error}`);
      } finally {
        this.isLoadingFornecedor = false
      }
    },
    async getPacienteDisponivel() {
      this.isLoadingPacientDisponivel = true;
      try {
        const response = await api('pacientes/pacientes_simples/');
        this.pacienteDisponivel = response.data
        // console.log('Paciente Disponivel', this.pacienteDisponivel )
      } catch (error) {
        this.$toast.error(`Erro ao buscar pacientes ativos: ${error}`);
      } finally {
        this.isLoadingPacientDisponivel = false;
      }
    },
    async getPaciente() {
      this.isLoadingPaciente = true;
      try {
        const { data } = await api('pacientes/esterilizaveis/');
        this.paciente = data
          .filter(item => item.esterilizavel?.id === this.currentItem.id && item.ativo)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Paciente --< ', this.paciente);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
      } finally {
        this.isLoadingPaciente = false;
      }
    },
    async getUsuarioEsterilizavel() {
      this.isLoadingUsuario = true
      try {
        const { data } = await api('equipamentos/esterelizavel_usuarios/')
        this.usuario = data
          .filter(item => item.ativo && item.esterilizavel?.id === this.currentItem.id)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Usuario --< ',this.usuario)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
      } finally {
        this.isLoadingUsuario = false
      }
    },
    async getUsuarioDisponivel() {
      this.isLoadingUsuarioDisponivel = true
      try {
        const { data } = await api('contas/usuario_simples/')
        this.usuarioDisponivel = data
        // console.log('Usuario Disponivel --< ',this.usuarioDisponivel)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários disponíveis. ${error}`);
      } finally {
        this.isLoadingUsuarioDisponivel = false
      }
    },
    handleUsuarioChange(id) {
     this.usuarioSelecionado = this.usuarioDisponivel.find(u => u.id === id)
   },
  },
  computed: {
    isDisponibilidadeEditable() {
      return this.paciente.length === 0 && this.usuario.length === 0;
    },
    currentDisponibilidadeId() {
      // Garante que verifica o ID mesmo que seja um objeto
      return typeof this.currentItem.disponibilidade === "object"
        ? this.currentItem.disponibilidade?.id
        : this.currentItem.disponibilidade;
    },
    fornecedorInicial() {
      // Retorna o ID do fornecedor da aquisição se existir
      return this.currentItem?.aquisicao?.fornecedor?.id || null;
    },
  },
  watch: {
    'currentItem.disponibilidade': {
      handler(newVal) {
        // Disponibilidade Dsecartar
         const hoje = new Date();
            const dataFormatada = hoje.getFullYear() + '-' + 
                String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
                String(hoje.getDate()).padStart(2, '0');
        const disponibilidadeId = typeof newVal === 'object' ? newVal.id : newVal;
        if (disponibilidadeId === 9) { 
          this.previousAtivoState = this.currentItem.ativo;
          this.currentItem.ativo = false;
          this.currentItem.data_baixa = dataFormatada
        } else if (this.previousAtivoState !== undefined) {
          this.currentItem.ativo = this.previousAtivoState;
          this.currentItem.data_baixa = null
        }
      },
      deep: true
    },
    
     fornecedor(newFornecedorList) {
      if (newFornecedorList.length === 1 && this.currentDisponibilidadeId === 7) {
        this.currentItem.esterilizacao_fornecedor = newFornecedorList[0].id;
      }
    },
    'currentItem.data_baixa': {
      handler(newVal) {
        this.currentItem.ativo = newVal ? 0 : 1;
      },
      immediate: true
    }
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    // this.currentItem.esterilizacao_fornecedor = null 
    this.currentItem.fornecedor = this.fornecedorInicial;
    this.getPaciente()
    this.getPacienteDisponivel()
    this.getUsuarioEsterilizavel()
    this.getUsuarioDisponivel()
    this.getDisponibilidade()
    this.getDeposito()
    this.getFornecedor()
    // // Garante que o campo fornecedor aparece se a disponibilidade inicial for 7
    // if (this.currentDisponibilidadeId === 7 && this.fornecedor.length === 1) {
    //   this.currentItem.esterilizacao_fornecedor = this.fornecedor[0].id;
    // }
   }

};
</script>

<template>
    <div class="pa-4" >
        <v-form @submit.prevent="localOnSubmit" ref="formRef">
            <div class="grid-container">
                <!-- Dados do item -->
                <template v-for="(item, index) in items">
                    <v-row dense :key="index" >
                       <v-col cols="3">
                           <v-autocomplete :loading="isLoadingEquipamentoFornecedor" label="Equipamento e Fornecedor" dense :items="equipamentoFornecedor" item-text="nome" item-value="id" return-object v-model="item.equipamento" outlined :rules="requiredField" />
                       </v-col>
                        <v-col cols="2">
                            <v-autocomplete :loading="isLoadingDepositos" :items="depositos" item-text="nome" item-value="id" return-object label="Deposito" v-model="item.deposito" dense  outlined :rules="requiredField" > </v-autocomplete>
                        </v-col>
                         <v-col cols="2">
                            <v-text-field dense label="Patrimônio" v-model="item.patrimonio"  outlined type="number"  > </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense label="Data de Início da Locação" v-model="dataTrocaEfetivada"  outlined type="date" :rules="requiredField" > </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense label="Data da Validade Calibração" v-model="item.data_validade_calibracao"  outlined type="date" :rules="requiredField" > </v-text-field>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn color="success" icon outlined @click="addItem" > <v-icon >mdi-plus</v-icon> </v-btn>
                        </v-col >
                        <v-col v-if="index > 0" class="text-center">
                            <v-btn  color="error"  icon outlined  @click="removeItem(index)" >  <v-icon >mdi-delete</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </template>
                   
            </div>
            <!-- Botõa de Salvar -->
            <v-row class="justify-end align-center mt-0"  dense>
                <!-- <v-col class="text-end mx-4"> -->
                    <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"><v-icon left>mdi-refresh</v-icon> Atualizar </v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn class="mr-4" color="red" text @click="clear()"> Limpar </v-btn> -->
                    <v-tooltip top :disabled="isSaveEnabled">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                            <ConfirmButton  :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" > Salvar </ConfirmButton>
                            </span>
                        </template>
                        <span>  Preencha todos os campos obrigatórios, selecione uma nota fiscal e verifique se todos os itens têm valores e datas válidos </span>
                    </v-tooltip>
                <!-- </v-col> -->
            </v-row>
        </v-form>
    </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../service/utilsFunc'
import ConfirmButton from '../../ConfirmButton.vue'


const { withCRUDUtils } = UtilsFunc

export default {
    name: "CadastroControle",
    components: { ConfirmButton },
    data: () => withCRUDUtils ({
        items: [],
        newItem: {ativo: true},
        isLoadingDados: false,
        equipamento: [],
        aquisicao: [],
        depositos: [],
        fornecedor: [],
        equipamentoFornecedor: [],
        dataTrocaEfetivada: '',
        isLoadingEquipamentoFornecedor: false, // Carregando esterilizável
        isLoadingEquipamento: false, // Carregando esterilizável
        isLoadingDepositos: false, // Carregando depósitos
        isLoadingAquisicoes: false, // Carregando aquisições
        isLoadingAFornecedor: false, // Carregando aquisições
        isSaveEnabled: false,
        requiredField: [v => !!v || 'Este campo é obrigatório',],
        validateNovo: [ e => (e === 0 || e === 1) || 'Selecione uma opção válida',],
        options: {
            locale: "pt-BR",
            prefix: "R$",
            suffix: "",
            length: 11,
            precision: 2,
        },
    }),
    props: {
        onSubmit: Function,
        loading: Boolean,
        formRef: Object,
        currentItem: { 
            type: Object, 
            default: () => ({
                ativo: true,
            })
        },
    },
   
    methods: {
        async getNomeEquipamento() {
            this.isLoadingEquipamento = true;
            try {
                const response = await api.get('equipamentos/nomeequipamentos/');
                this.equipamento = response.data.filter(item => item.ativo);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingEquipamento = false;
            }
        },
        async getDepositos() {
            this.isLoadingDepositos = true;
            try {
                const response = await api.get('uteis/deposito/');
                this.depositos = response.data.filter(item => item.ativo);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingDepositos = false;
            }
        },
        async getFornecedor() {
            this.isLoadingAFornecedor = true;
            try {
                const { data } = await api('fornecedores/fornecedores/')
                data.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
                this.fornecedor = data.filter(item => item.ativo)
                // console.log(this.fornecedor)
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingAFornecedor = false;
            }
        },
        async getEquipamentoForncedor() {
            this.isLoadingEquipamentoFornecedor = true;
            try {
                const { data } = await api.get(`/equipamentos/equipamentos_fornecedores/`);
                // console.log(data)
                this.equipamentoFornecedor = data
                    .filter(item => item.ativo)
                    .map(item => ({
                        id: item.id,
                        nome: `${item.nomeEquipamento.nome} - ${this.getFornecedorNome(item.fornecedor)}`,
                        nomeEquipamento: item.nomeEquipamento,
                        fornecedor: item.fornecedor,
                        valordiario: item.valordiario,
                        valormensal: item.valormensal
                    }));
                // console.log('Equipamento e Fornecedor -> ', this.equipamentoFornecedor);
            } catch (error) {
                this.$toast.error(`Erro ao buscar equipamentos e fornecedores: ${error}`);
            } finally {
                this.isLoadingEquipamentoFornecedor = false;
            }
        },
 
        getFornecedorNome(fornecedorId) {
            const fornecedor = this.fornecedor.find(f => f.id === fornecedorId);
            return fornecedor ? fornecedor.nome_fantasia : 'Fornecedor Desconhecido';
        },
        localOnSubmit() {
            const validation = this.$refs.formRef.validate();
            if (!validation) return;
           

            // Cria um array com todos os itens replicados pela quantidade
            const Itens = this.items.flatMap(item => {
                return Array.from({ length: item.quantidade || 1 }).map(() => ({
                    descricao: item.equipamento.nomeEquipamento.id,
                    deposito: item.deposito.id,
                    propriedade: false,
                    patrimonio: item.patrimonio,
                    historico_patrimonio: item.patrimonio,
                    disponibilidade: 6,
                    data_validade_calibracao: item.data_validade_calibracao,
                    empresa_locadora: item.equipamento.fornecedor,
                    data_inicio_locacao: this.dataTrocaEfetivada,
                    ativo: true,
                    valordiario: item.equipamento.valordiario,
                    valormensal: item.equipamento.valormensal,
                    nomeEquipamento: item.equipamento.nomeEquipamento.id
                }));
            });
            for (const fields of Itens) {
                // console.log(fields)
                this.onSubmit(fields);
            }

        },
        addItem() {
            this.items.push({
                equipamento: null,
                deposito: null,
                patrimonio: null,
                data_inicio_locacao: null,
                data_validade_calibracao: null,

            });
        },
        removeItem(index) {
            // Remove o item do array
            this.items.splice(index, 1);
        },
        clear() {
           this.items.forEach(item => {
                item.quantidade = 1 // Valor padrão

                item.equipamento = null;
                item.deposito = null;
                item.patrimonio = null;
                item.data_inicio_locacao = null;
                item.data_validade_calibracao = null;
                this.removeItem()
            });
            this.removeItem()
        },
       
        atualizarDados() {
            this.isLoadingDados = true;
            try {
                this.clear();
                this.addItem();
                this.currentItem.ativo = Number(this.currentItem.ativo)
                this.getDepositos()
                this.getEquipamentoForncedor() 
                this.getFornecedor()
                this.isLoadingDados = false
            } catch (error) {
                this.$toast.error('Erro ao atualizar os dados');
                console.error(error);
            } finally {
                this.isLoadingDados = false;
            }
        },
      
    },
    watch: {
  items: {
    handler() {
      // Verifica se todos os campos obrigatórios estão preenchidos
      this.isSaveEnabled = this.items.every(
        (item) =>
          item.equipamento &&
          item.deposito &&
          item.patrimonio &&
          item.data_validade_calibracao
      );
    },
    deep: true, // Monitora alterações profundas em objetos e arrays
  },
},

    // created() {
    //     this.items.push({
    //         equipamento: null,
    //         deposito: null,
    //         patrimonio: null,
    //         data_inicio_locacao: null,
    //         data_validade_calibracao: null,
    //     });
    //     },
    mounted() {
        this.clear();
        this.addItem();
        this.currentItem.ativo = Number(this.currentItem.ativo)
        this.getEquipamentoForncedor() 
        this.getNomeEquipamento()
        this.getDepositos()
        this.getFornecedor()
        const hoje = new Date();
        const dataFormatada = hoje.getFullYear() + '-' + 
            String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
            String(hoje.getDate()).padStart(2, '0');
        this.dataTrocaEfetivada = dataFormatada
    },
}
</script>

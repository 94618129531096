<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-autocomplete 
          dense
          :loading="isLoadingEsterilizacao"
          v-model="fornecedorSelecionado"
          :items="fornecedores"
          item-value="id"
          item-text="nome_fantasia"
          outlined
          clearable
          :rules="[validaCampo]"
        >
          <template v-slot:label>
            <span>Fornecedor <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field dense label="Razão Social" v-model="fornecedor.razao_social" outlined  disabled></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-text-field dense label="Nº NF / Recibo" v-model="nota_fiscal" outlined  :rules="[validaCampo]">
          <template v-slot:label>
            <span>Nº NF / Recibo <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field dense label="Data do Envio do Material" v-model="data_envio" outlined type="date" :rules="[validaCampo]">
          <template v-slot:label>
            <span>Data do Envio do Material<span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field dense label="Data do Recebimento do Material" v-model="data_recebimento" outlined type="date" :rules="[validaCampo]">
          <template v-slot:label>
            <span>Data do Recebimento do Material<span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <vuetify-money dense label="Valor da Nota Fiscal" v-model="valor" outlined></vuetify-money>
      </v-col>
      <v-col>
        <v-text-field dense label="Desconto Geral da Nota Fiscal" v-model="desconto" outlined type="number" />
      </v-col>
      <v-col>
        <vuetify-money dense label="Valor Final da NF" :value="valorFinal" outlined disabled/>
      </v-col>
    </v-row>
    <!-- <v-row dense>
      <v-col>
        <v-data-table
          dense
          height="400"
          :loading="isLoadingEsterilizavel"
          :headers="esterelizavelHeaders"
          :items="esterelizavel"
          item-value="id"
          show-select
          v-model="selectedEsterelizaveis"
          class="elevation-1"
        >
          <template v-slot:[`item.nome`]="{ item }">
            <span>{{ item.nome.nome }}</span>
          </template>
          <template v-slot:[`item.disponibilidade`]="{ item }">
            <v-chip :color="item.disponibilidade?.id === 7 ? 'green' : 'grey'" dark>
              {{ item.disponibilidade?.nome || 'Indefinido' }}
            </v-chip>
          </template>
          <template v-slot:[`item.esterilizacao_fornecedor`]="{ item }">
            <span>
              {{ item.esterilizacao_fornecedor_nome || 'N/A' }}
            </span>
          </template>
          <template v-slot:[`item.esterilizacao_data_envio`]="{ item }">
            <span>
              {{ item.esterilizacao_data_envio | formatDatabr }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row> -->

    <v-row dense>
      <v-btn-toggle v-model="ativo" mandatory class="white--text">
        <v-btn :color="!ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>

      <!-- <v-col cols="3" class="ml-5">
        <v-text-field dense label="Data de Validade da Esterilização" v-model="data_validade_esterilizacao" outlined type="date" :rules="requiredField"/>
      </v-col> -->
    </v-row>
    <v-row dense>
      <v-col class="text-end">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton 
                :loading="loading" 
                color="success"
                :disabled="!isSaveEnabled"
                :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
              >
                Salvar
              </ConfirmButton>
            </span>
          </template>
          <span>Preencha todos os campos obrigatórios, selecione pelo menos um esterilizável e verifique as datas</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      fornecedores: [], // Lista de fornecedores
      fornecedor: { razao_social: "" }, // Detalhes do fornecedor selecionado
      fornecedorSelecionado: null, // ID do fornecedor selecionado
      nota_fiscal: "", // Número da nota fiscal
      data_envio: "", // Data da nota fiscal
      data_recebimento: "", // Data da nota fiscal
      ativo: true,
      isLoadingEsterilizacao: false, // Status de carregamento
      isLoadingEsterilizavel: false, // Status de carregamento
      esterelizavel: [],
      selectedEsterelizaveis: [], // Lista de esterilizáveis selecionados
      valor: 0, // Inicializa com 0 para evitar cálculos inválidos
      desconto: 0, // Inicializa com 0
      data_validade_esterilizacao: '',
      requiredField: [e => (e ? true : 'Obrigatório')],
      esterelizavelHeaders: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome.nome" },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Empresa de Esterilização", value: "esterilizacao_fornecedor" },
        { text: "Data de Envio", value: "esterilizacao_data_envio" },
      ],
      });
  },
  components: { ConfirmButton },
  methods: {
    async getFornecedor() {
      this.isLoadingEsterilizacao = true;
      try {
        const { data } = await api('fornecedores/fornecedores/');
        const fornecedoresFiltrados = data
          .filter(item => item.ativo && item.tipo.some(tipo => tipo.id === 8))
          .map(f => ({ id: f.id, nome_fantasia: f.nome_fantasia || "Sem Nome" }))
          .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));

        this.fornecedores = fornecedoresFiltrados;

        // Seleciona automaticamente se houver apenas um fornecedor
        if (fornecedoresFiltrados.length === 1) {
          this.fornecedorSelecionado = fornecedoresFiltrados[0].id;
          this.fornecedor.razao_social = fornecedoresFiltrados[0].nome_fantasia;
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedores. ${error}`);
      } finally {
        this.isLoadingEsterilizacao = false;
      }
    },
    async getEsterelizavel() {
      this.isLoadingEsterilizavel = true;
      try {
          // Aguarda o carregamento dos fornecedores
          if (this.fornecedores.length === 0) {
              await this.getFornecedor();
          }

          const response = await api.get("equipamentos/esterelizavels/");
          this.esterelizavel = response.data
              .filter(item => item.disponibilidade?.id === 7) // Filtra os itens com disponibilidade.id === 7
              .map((item) => {
                  // Associa o nome do fornecedor
                  if (item.esterilizacao_fornecedor) {
                      const fornecedor = this.fornecedores.find(
                          (f) => f.id === item.esterilizacao_fornecedor
                      );
                      item.esterilizacao_fornecedor_nome = fornecedor
                          ? fornecedor.nome_fantasia
                          : "N/A";
                  } else {
                      item.esterilizacao_fornecedor_nome = "N/A";
                  }
                  return item;
              });
        } catch (error) {
          console.error(error);
          this.$toast.error(`Erro ao carregar esterelizáveis: ${error}`);
        } finally {
          this.isLoadingEsterilizavel = false;
        }
    },
    localOnSubmit() {
        const validation = this.$refs.formRef.validate();
        if (!validation) return;

        // const esterilizaveisIds = this.selectedEsterelizaveis.map(item => item.id);

        const fields = {
            fornecedor: this.fornecedorSelecionado,
            nota_fiscal: this.nota_fiscal,
            data_envio: this.data_envio,
            data_recebimento: this.data_recebimento,
            ativo: this.ativo,
            // esterilizavel: esterilizaveisIds,
            // ids_esterilizaveis: esterilizaveisIds.length > 0 ? esterilizaveisIds.join(",") : null,
            valor: this.valor ? this.valor : null,
            desconto: this.desconto ? this.desconto : 0,
            valor_final: this.valorFinal ? this.valorFinal : null
        };

        // Chama o método para salvar os dados principais
        this.onSubmit(fields)
            // .then(() => {
            //     // Após o sucesso, atualiza o status de disponibilidade dos esterilizáveis
            //     this.updateDisponibilidadeEsterilizaveis(esterilizaveisIds, 6); // Define o novo ID de disponibilidade como 6
            // })
            // .catch((error) => {
            //     console.error("Erro ao salvar os dados principais:", error);
            //     this.$toast.error("Erro ao salvar os dados principais.");
            // });
    },

    async updateDisponibilidadeEsterilizaveis(esterilizaveisIds, novaDisponibilidadeId) {
        const LOADING_NAME = "put:esterilizavel-disponibilidade";
        this.setLoading(LOADING_NAME);

        try {
            // Itera sobre os IDs dos esterilizáveis e atualiza a disponibilidade de cada um
            const promises = esterilizaveisIds.map(id =>
                api.put(`/equipamentos/esterelizavel/${id}/`, {
                    disponibilidade: novaDisponibilidadeId
                })
            );

            await Promise.all(promises); // Aguarda todas as requisições serem concluídas

            this.$toast.success("Disponibilidade dos esterilizáveis atualizada com sucesso!");
            this.getEsterelizavel(); // Atualiza a lista de esterilizáveis
        } catch (error) {
            console.error("Erro ao atualizar disponibilidade:", error);
            this.$toast.error("Erro ao atualizar disponibilidade dos esterilizáveis.");
        } finally {
            this.setLoading(LOADING_NAME, true);
        }
    },
    validaCampo(value) {
        return !!value || 'Este campo é obrigatório.';
    }

  },
  computed: {
    valorFinal() {
      const valorNumerico = parseFloat(this.valor) || 0;
      const descontoNumerico = parseFloat(this.desconto) || 0;

      return (valorNumerico - (valorNumerico * descontoNumerico) / 100).toFixed(2);
    },
    isSaveEnabled() {
      // Verifica se todos os campos obrigatórios estão preenchidos
      if (!this.fornecedorSelecionado || 
          !this.nota_fiscal || 
          !this.data_envio || 
          !this.data_recebimento ) {
        return false;
      }

      // // Verifica se tem itens selecionados na tabela
      // if (!this.selectedEsterelizaveis || this.selectedEsterelizaveis.length === 0) {
      //   return false;
      // }

      // // Verifica se a data de validade é maior que hoje
      // const hoje = new Date();
      // hoje.setHours(0, 0, 0, 0);
      // const dataValidade = new Date(this.data_validade_esterilizacao);
      // if (dataValidade <= hoje) {
      //   return false;
      // }

      // Verifica se a data de recebimento é maior ou igual à data de envio
      const dataEnvio = new Date(this.data_envio);
      const dataRecebimento = new Date(this.data_recebimento);
      if (dataRecebimento < dataEnvio) {
        return false;
      }

      // Se chegou até aqui, todas as validações passaram
      return true;
    }
  },
  watch: {
    fornecedorSelecionado(newVal) {
      const fornecedor = this.fornecedores.find(f => f.id === newVal);
      if (fornecedor) {
        this.fornecedor.razao_social = fornecedor.nome_fantasia;
      } else {
        this.fornecedor.razao_social = "";
      }
    },
  },
  mounted() {
    this.ativo = Number(this.ativo)
    this.isLoadingEsterilizavel = true; // Ativa o loading para esterelizáveis
    this.getFornecedor()
        .then(() => {
            return this.getEsterelizavel();
        })
        .catch((error) => {
            console.error("Erro ao carregar dados iniciais:", error);
            this.$toast.error("Erro ao carregar dados iniciais.");
        })
        .finally(() => {
            this.isLoadingEsterilizavel = false; // Desativa o loading após tudo ser carregado
        });

  },
};
</script>

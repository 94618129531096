<template>
  <v-container class="container">
    <v-btn color="success" depressed size="small" @click="generatePDF">
      <v-icon left>mdi-printer</v-icon> Rel. Envio Equipamento para Manutenção
    </v-btn>
  </v-container>
</template>

    
<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Requisicao",
  data: () => withCRUDUtils({
    loading: false,
    nomefornecedor: [],
    equipamentos: [],
  }),
  props: {
    fornecedor: {
      type: [Object, Number],
      default: null,
    },
    equipamento: {
      type: [Object, Number],
      default: null,
    },
    dataEnvio: {
      type: String,
      default: "",
    },
    corretiva: {
      type: Boolean,
      default: false,
    },
    preventiva: {
      type: Boolean,
      default: false,
    },
    calibracao: {
      type: Boolean,
      default: false,
    },
    obs: {
      type: String,
      default: "",
    },
  },


  methods: {
     async getFornecedor() {
      try {
        const { data } = await api('fornecedores/fornecedores/');
        const fornecedorEncontrado = data.find(item => item.ativo && item.id === this.fornecedor);
        if (fornecedorEncontrado) {
          this.nomefornecedor = fornecedorEncontrado;
        } else {
          this.$toast.error("Fornecedor não encontrado.");
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedor: ${error}`);
      }
    },
    async getEquipamento() {
      try {
        const equipamentoId = Number(this.equipamento);
        if (isNaN(equipamentoId)) {
          console.warn('ID do equipamento é inválido:', this.equipamento);
          this.$toast.warning('ID do equipamento é inválido!');
          return;
        }
        const { data } = await api('/equipamentos/equipamentos/');
        const equipamentoManutencao = data.filter(item => item.id === equipamentoId);
        this.equipamentos = equipamentoManutencao; 
      } catch (error) {
        console.error('Erro ao carregar equipamento:', error);
        this.$toast.error(`Erro ao carregar equipamento: ${error}`);
      }
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
        
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-91):", error);
      }

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(14);
      doc.text(`ENVIO DE EQUIPAMENTO PARA MANUTENÇÃO`, 105, 32, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);
    
    },
    async generatePDF() {
      const preventiva = this.preventiva;
      const corretiva = this.corretiva;
      const calibracao = this.calibracao;
      const observacao = this.obs || "Não informado";
      if (!this.dataEnvio && !this.corretiva && !this.preventiva && !this.calibracao) {
        this.$toast.error("Sem informações suficientes para gerar o relatório.");
        return;
      }
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      await this.getFornecedor();
      await this.getEquipamento();
      if (!this.equipamentos || !this.nomefornecedor) {
        this.$toast.error("Os dados necessários para o relatório não foram encontrados.");
        return;
      }

      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      yPosition += lineHeight * 2;
      doc.setFontSize(14);
      
      yPosition += lineHeight * 2;
      
      doc.setFont("helvetica", "bold");
      const currentDate = new Date();
      const dataentrega = this.dataEnvio ? this.dataEnvio : currentDate

      const nomeEmpresa = this.nomefornecedor?.nome_fantasia || "Não Informado";
      doc.text(`Empresa de Manutenção: ${nomeEmpresa}`, 100, 45, { align: "center" });
      
      yPosition += lineHeight * 2;
      doc.setFontSize(12);
      
      doc.text(`Data da Envio: ${UtilsPDF.formatDate(dataentrega)}`, 100, 55, { align: "center" });
      
      yPosition += lineHeight * 2;
      const blueColor = [57, 106, 159];
      doc.setFont("helvetica", "bold");
      const equipamentos = Array.isArray(this.equipamentos) ? this.equipamentos : [this.equipamentos];
      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
        },
        head: [['Id Equip.', 'Equipamento', 'Patrimônio', 'Data Validade Calibração', 'Manutenção Preventiva?', 'Manutenção Corretiva?', 'Necessita Calibração?']],
        body: equipamentos.map(item => [
          item.id,
          item.descricao?.nome,
          item.patrimonio,
          UtilsPDF.formatDate(item.data_validade_calibracao),
          preventiva ? "SIM" : "NÃO",
          corretiva ? "SIM" : "NÃO",
          calibracao ? "SIM" : "NÃO",
        ]),
        columnStyles: {
          0: { cellWidth: 10 }, // Define a largura da primeira coluna (Profissional)
        },
      });

      yPosition += lineHeight * 2;
      
      const tableHeight2 = doc.autoTable.previous.finalY; // Obtém a altura da tabela    
      yPosition = tableHeight2 + lineHeight + 12;

      doc.setFontSize(12); 
      doc.setFontSize(12);
      doc.text(`Observação: ${observacao}`, 15, yPosition);
      console.log(this.obs)
      yPosition += lineHeight * 6;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Recebedor: ___________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Data do Recebimento: ____ / ____ / ______", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Nome: _______________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("CPF ou RG: __________________________________", 15, yPosition);
      yPosition += lineHeight * 2;   
    

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório_Entrega_Equipamento_Manutenção.pdf`;
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
   
    

  },
};
</script>
<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Equipamento" v-model="currentItem.descricao.nome" outlined  disabled/>
      </v-col>
      <v-col cols="1" >
        <v-text-field type="number" outlined dense label="Patrimônio" v-model="currentItem.patrimonio" @change="handlePatrimonioChange"> </v-text-field>
      </v-col>
      <v-col >
        <v-autocomplete :loading="isLoadingDeposito" dense label="Depósito" v-model="currentItem.deposito" outlined  :items="deposito" item-text="nome" item-value="id" />
      </v-col>
      <v-col v-if="currentItem.empresa_locadora === null">
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Disponibilidade" v-model="currentItem.disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id" :disabled="!isDisponibilidadeEditable" />
      </v-col>
      <v-col cols="3" >
        <v-text-field type="date" outlined dense label="Data da Validade Calibracao" v-model="currentItem.data_validade_calibracao"> </v-text-field>
      </v-col>
    </v-row>
    <!-- Paciente -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="paciente.length > 0"></v-divider>
    <h3 class="blod_color" v-if="paciente.length > 0"> Dados do Paciente:</h3> <br>
    <v-row  v-if="(paciente.length > 0)">
       <v-col cols="2">
          <v-text-field :loading="isLoadingPaciente" dense  label="ID do Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.id : ''"  outlined  readonly disabled />
      </v-col>
      <v-col cols="4">
          <v-text-field :loading="isLoadingPaciente" dense  label="Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.nome : ''"  outlined  readonly  disabled/>
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Requisição"  :value="paciente.length > 0 ? paciente[0].data_requisicao : ''" type="date" outlined readonly  disabled/>  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Implantação"  :value="paciente.length > 0 ? paciente[0].data_implantacao : ''"   type="date" outlined readonly disabled />  
      </v-col>
    </v-row>


<!-- Manutenção -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="currentItem.disponibilidade === 19"></v-divider>
    <h3 class="blod_color" v-if="currentItem.disponibilidade === 19"> Manutenção:</h3> <br>
    <v-row  v-if="currentItem.disponibilidade === 19">
        <v-col cols="4">
            <v-autocomplete  :loading="isLoadingFornecedor" dense  label="Empresa"  :items="fornecedor" item-text="nome_fantasia" item-value="id" outlined v-model="currentItem.fornecedor_manutencao"/>
        </v-col>
        <v-col cols="3">
          <v-text-field  dense  label="Data de envio para Manutenção"  type="date"  outlined clearable v-model="currentItem.data_envio_manutencao"/>  
        </v-col>
        <v-col cols="5">
          <v-text-field  dense  label="Observação para Manutenção"   outlined clearable v-model="currentItem.obs_manutencao"/>  
        </v-col>
    </v-row>

    <!-- Usuário -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="usuario.length > 0"></v-divider>
    <h3 class="blod_color"  v-if="usuario.length > 0"> Dados do Usuário "Profissional":</h3> <br>
    <v-row  v-if="usuario.length > 0">
      <v-col cols="1">
          <v-text-field :loading="isLoadingUsuario" dense  label="ID do Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.id : ''"  outlined  readonly  disabled/>
      </v-col>
      <v-col cols="3">
          <v-text-field :loading="isLoadingUsuario" dense  label="Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.apelido : ''"  outlined  readonly disabled/>
      </v-col>
      <v-col cols="2">
          <v-text-field :loading="isLoadingUsuario" dense  label="Especialidade"  :value="usuario.length > 0 ? usuario[0].usuario.especialidade.nome : ''"  outlined  readonly disabled />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Requisição"  :value="usuario.length > 0 ? usuario[0].data_requisicao : ''" type="date" outlined readonly disabled/>  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Entrega"  :value="usuario.length > 0 ? usuario[0].data_entrega : ''"   type="date" outlined readonly  disabled/>  
      </v-col>
    </v-row>

    <!-- Dados Gerais -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;"></v-divider>
    <h3 class="blod_color"  v-if="!currentItem?.empresa_locadora"> Fornecedor do Equipamento "Compra": </h3> <br>
    <v-row dense  v-if="!currentItem?.empresa_locadora">
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingFornecedor"  dense  label="Fornecedor"  v-model="currentItem.fornecedor"  :value="fornecedorInicial" outlined   :items="fornecedorAquisicao"  item-text="nome_fantasia"  item-value="id" disabled></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field  outlined dense label="Data da Aquisição" v-model="currentItem.data_aquisicao" type="date" clearable ></v-text-field>
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Valor Compra R$" v-model="currentItem.valor_final" outlined  />
      </v-col>
      <v-col cols="2">
        <v-text-field  outlined dense label="Data da Baixa" v-model="currentItem.data_baixa" type="date" clearable ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field  outlined dense label="Observação" v-model="currentItem.obs"></v-text-field>
      </v-col>
    </v-row>

<!-- Botão Salvar -->
    <v-row dense>
      <v-col v-if="!currentItem?.empresa_locadora">
        <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text" >
          <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
          <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "EditarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
    initialPatrimonio: {
      type: String,
      required: true,
    },
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      isLoadingDisponibilidade: false, // Status de carregamento
      isLoadingDeposito: false, // Status de carregamento
      isLoadingFornecedor: false,
      isLoadingPaciente: false,
      isLoadingPacientDisponivel: false,
      isLoadingUsuario: false,
      isLoadingUsuarioDisponivel: false, 
      disponibilidade: [],
      deposito: [],
      fornecedor:[],
      paciente: [],
      usuario: [],
      usuarioDisponivel: [],
      pacienteDisponivel: [],
      fornecedorAquisicao: [],
      // fornecedorEsterelizacao: [],
      selectedPaciente: null,
      selectedUsuario: null,
      usuarioSelecionado: null,
      originalPatrimonio: this.initialPatrimonio || '',
      localCurrentItem: {
        patrimonio: this.initialPatrimonio || '',
      },
    });
  },
  components: { ConfirmButton },
  methods: {
    handlePatrimonioChange() {
      const newPatrimonio = this.currentItem.patrimonio;
      if (!newPatrimonio) return;

      // Get current history from either currentItem or initialPatrimonio
      const currentHistory = this.currentItem.historico_patrimonio || this.initialPatrimonio || '';
      const patrimonioList = currentHistory.split(',').map(p => p.trim()).filter(p => p);

      // Check if the new patrimonio is already in history
      if (!patrimonioList.includes(newPatrimonio.toString())) {
        // Add new patrimonio to history
        patrimonioList.push(newPatrimonio.toString());
        // Update the history string
        this.currentItem.historico_patrimonio = patrimonioList.join(',');
      }
    },
    async localOnSubmit() {
      // Valida o formulário
      const validation = this.$refs.formRef.validate();

      // Verifica se o formulário está válido
      if (!validation) {
        this.$toast.error("Preencha todos os campos obrigatórios antes de salvar.");
        return;
      }
      
      // Validação para 'ativo' e 'data_baixa'
      if (this.currentItem.ativo === 0 && !this.currentItem.data_baixa) {
        this.$toast.error("Para inativar o item, é necessário informar a Data da Baixa.");
        return;
      }

      if (this.currentItem.ativo === 1 && this.currentItem.data_baixa) {
        this.$toast.error("A Data da Baixa deve estar vazia ao ativar o item.");
        return;
      }

      const disponibilidadeId = typeof this.currentItem.disponibilidade === "object" 
        ? this.currentItem.disponibilidade.id 
        : this.currentItem.disponibilidade;

      const depositoId = typeof this.currentItem.deposito === "object" 
        ? this.currentItem.deposito.id 
        : this.currentItem.deposito;

    
        const fields = {
          id: this.currentItem.id,
          ativo: this.currentItem.ativo,
          valor_final: this.currentItem.valor_final,
          disponibilidade: disponibilidadeId,
          deposito: depositoId,
          data_baixa: this.currentItem.data_baixa,
          obs: this.currentItem.obs,
          patrimonio: this.currentItem.patrimonio,
          historico_patrimonio: this.currentItem.historico_patrimonio,
          data_aquisicao: this.currentItem.data_aquisicao,
          data_validade_calibracao: this.currentItem.data_validade_calibracao,
        };

      // console.log(fields)
      try {
        await this.ItensInlucidoManutencao();
        await this.onSubmit(fields);
      } catch (error) {
        console.error("Erro ao salvar dados:", error);
        this.$toast.error("Erro ao salvar os dados.");
      }
    },
     async ItensInlucidoManutencao() {
      try {
        const payload = {
          fornecedor: this.currentItem.fornecedor,
          id_equipamento: this.currentItem.id,
          data_envio: this.currentItem.data_envio_manutencao,
          obs: this.currentItem.obs_manutencao

        };
        await api.post(`/equipamentos/equipamento_manutencao_criar/`, payload)
      } catch (error) {
        console.error("Erro ao criar a manutenção:", error);
        this.$toast.error("Erro ao criar a manutenção.");
      } finally {
        this.$toast.success('Manutenção Criada com sucesso!')
      }
    },
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        
        // Primeiro, filtramos normalmente
        const filteredData = data.filter(item => 
          item.ativo && ![1, 2, 3, 4, 7, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20].includes(item.id)
        );

        // Pegamos o ID da disponibilidade atual
        const currentDisponibilidadeId = typeof this.currentItem.disponibilidade === 'object' 
          ? this.currentItem.disponibilidade.id 
          : this.currentItem.disponibilidade;

        // Se a disponibilidade atual existe e não está na lista filtrada
        if (currentDisponibilidadeId && !filteredData.find(item => item.id === currentDisponibilidadeId)) {
          // Encontramos a disponibilidade atual nos dados originais
          const currentDisponibilidade = data.find(item => item.id === currentDisponibilidadeId);
          if (currentDisponibilidade) {
            // Adicionamos ela à lista filtrada
            filteredData.push(currentDisponibilidade);
          }
        }

        this.disponibilidade = filteredData;
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async getDeposito() {
      this.isLoadingDeposito = true;
      try {
        const { data } = await api.get(`/uteis/deposito/`);
        this.deposito = data.filter(item => item.ativo)
        // console.log(this.deposito)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDeposito = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true
      try {
        const { data } = await api('fornecedores/fornecedores/')
        this.fornecedorAquisicao = data.filter(item => item.ativo).sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia))
        this.fornecedor = data
          .filter(item => 
            item.ativo && 
            item.tipo.some(tipo => tipo.id === 4)
          )
          .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia))

      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-228). ${error}`);
      } finally {
        this.isLoadingFornecedor = false
      }
    },
    async getPacienteDisponivel() {
      this.isLoadingPacientDisponivel = true;
      try {
        const response = await api('pacientes/pacientes_simples/');
        this.pacienteDisponivel = response.data
        // console.log('Paciente Disponivel', this.pacienteDisponivel )
      } catch (error) {
        this.$toast.error(`Erro ao buscar pacientes ativos: ${error}`);
      } finally {
        this.isLoadingPacientDisponivel = false;
      }
    },
    async getPaciente() {
      this.isLoadingPaciente = true;
      try {
        const { data } = await api('pacientes/equipamentos/');
        this.paciente = data
          .filter(item => item.equipamento?.id === this.currentItem.id && item.ativo)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Paciente --< ', this.paciente);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
      } finally {
        this.isLoadingPaciente = false;
      }
    },
    async getUsuarioEquipamento() {
      this.isLoadingUsuario = true
      try {
        const { data } = await api('equipamentos/equipamento_usuarios/')
        this.usuario = data
          .filter(item => item.ativo && item.equipamento?.id === this.currentItem.id)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Usuario --< ',this.usuario)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
      } finally {
        this.isLoadingUsuario = false
      }
    },
    async getUsuarioDisponivel() {
      this.isLoadingUsuarioDisponivel = true
      try {
        const { data } = await api('contas/usuario_simples/')
        this.usuarioDisponivel = data
        // console.log('Usuario Disponivel --< ',this.usuarioDisponivel)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários disponíveis. ${error}`);
      } finally {
        this.isLoadingUsuarioDisponivel = false
      }
    },
    handleUsuarioChange(id) {
     this.usuarioSelecionado = this.usuarioDisponivel.find(u => u.id === id)
   },
  },
  computed: {
    isDisponibilidadeEditable() {
      return this.paciente.length === 0 && this.usuario.length === 0 ;
    },
    currentDisponibilidadeId() {
      // Garante que verifica o ID mesmo que seja um objeto
      return typeof this.currentItem.disponibilidade === "object"
        ? this.currentItem.disponibilidade?.id
        : this.currentItem.disponibilidade;
    },
    fornecedorInicial() {
      // Retorna o ID do fornecedor da aquisição se existir
      return this.currentItem?.aquisicao?.fornecedor?.id || null;
    },
  },
  watch: {
    'currentItem.disponibilidade': {
      handler(newVal) {
        // Disponibilidade Dsecartar
         const hoje = new Date();
            const dataFormatada = hoje.getFullYear() + '-' + 
                String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
                String(hoje.getDate()).padStart(2, '0');
        const disponibilidadeId = typeof newVal === 'object' ? newVal.id : newVal;
        if (disponibilidadeId === 9) { 
          this.previousAtivoState = this.currentItem.ativo;
          this.currentItem.ativo = false;
          this.currentItem.data_baixa = dataFormatada
        } else if (this.previousAtivoState !== undefined) {
          this.currentItem.ativo = this.previousAtivoState;
          this.currentItem.data_baixa = null
        }
      },
      deep: true
    },
    
     fornecedor(newFornecedorList) {
      if (newFornecedorList.length === 1 && this.currentDisponibilidadeId === 7) {
        this.currentItem.esterilizacao_fornecedor = newFornecedorList[0].id;
      }
    },
    'currentItem.data_baixa': {
      handler(newVal) {
        this.currentItem.ativo = newVal ? 0 : 1;
      },
      immediate: true
    }
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    // this.currentItem.esterilizacao_fornecedor = null 
    this.currentItem.fornecedor = this.fornecedorInicial;
    if (!this.currentItem.historico_patrimonio) {
      this.currentItem.historico_patrimonio = this.initialPatrimonio || '';
    }
    if (this.currentItem.disponibilidade === 19) {
      this.isDisponibilidadeEditable = true;
    }
    this.getPaciente()
    this.getPacienteDisponivel()
    this.getUsuarioEquipamento()
    this.getUsuarioDisponivel()
    this.getDisponibilidade()
    this.getDeposito()
    this.getFornecedor()
    // // Garante que o campo fornecedor aparece se a disponibilidade inicial for 7
    // if (this.currentDisponibilidadeId === 7 && this.fornecedor.length === 1) {
    //   this.currentItem.esterilizacao_fornecedor = this.fornecedor[0].id;
    // }
   }

};
</script>

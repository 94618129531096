<template>
  <div dense class="base-form">
    <v-form @submit.prevent="localOnSubmit" ref="baseForm">
      <v-row>
        <v-col md="6">
        <!-- Logomarca -->
          <v-card outlined>
            <v-avatar size="100%" height="120" tile>
              <span v-if="!logoPreview" class="text-center">
                Logomarca
              </span>
              <v-img contain v-else :src="logoPreview"></v-img>
            </v-avatar>
            <v-divider />
            <v-btn icon class="close-btn" @click="() => handleDeleteFileField('logo')">
              <v-icon>mdi mdi-delete</v-icon>
            </v-btn>
            <div class="px-2 pt-4">
              <v-file-input counter show-size persistent-hint accept="image/png, image/jpeg, image/bmp"
                label="Logomarca da Empresa" dense outlined @change="(e) => onFileSelected(e, 'logo')"
                :hint="inputFileHint.logo" clearable />
            </div>
          </v-card>
        </v-col>
        <!-- Carimbo -->
        <v-col md="6">
          <v-card outlined>
            <v-avatar size="100%" height="120" tile>
              <span v-if="!stampPreview" class="text-center">
                Carimbo
              </span>
              <v-img contain v-else :src="stampPreview"></v-img>
            </v-avatar>
            <v-divider />
            <v-btn icon class="close-btn" @click="() => handleDeleteFileField('carimbo')">
              <v-icon>mdi mdi-delete</v-icon>
            </v-btn>
            <div class="px-2 pt-4">
              <v-file-input counter show-size persistent-hint accept="image/png, image/jpeg, image/bmp"
                label="Carimbo da Empresa" dense outlined @change="(e) => onFileSelected(e, 'carimbo')"
                :hint="inputFileHint.carimbo" clearable />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <p></p>
      <h2 class="blod_color">Dados gerais</h2>
      <p></p>
      <v-row>
        <v-col>
          <Input label="Nome fantasia" v-model="currentItem.nome_fantasia" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input label="Razão social" v-model="currentItem.razao_social" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input label="CNPJ" v-model="currentItem.cnpj" v-mask="'XX.XXX.XXX/XXXX-XX'" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input label="CNES" v-model="currentItem.cnes" type="number" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <v-select outlined dense label="Tipo" v-model="currentItem.tipo" :items="types" item-text="nome" item-value="id"
          :loading="isLoading('get:tipo')" return-object :rules="requiredField" clearable>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Input label="Telefone" v-model="currentItem.tel" placeholder="Digite (XX) XXXX-XXXX" :rules="requiredField"
            clearable />
        </v-col>
        <v-col>
          <Input label="E-mail institucional" v-model="currentItem.email" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input label="E-mail recuperaçõa de senha" v-model="currentItem.emailrecuperacaosenha" :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input :type="showPassword ? 'text' : 'password'" label="Senha do e-mail de recuperação de senha"
            v-model="currentItem.senharecuperacaosenha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShowPassword" clearable />
        </v-col>
        <v-col>
          <Input label="Site" v-model="currentItem.site" clearable />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Input dense label="CEP" @change="autofillAddressFromCep" v-mask="'#####-###'" v-model="currentItem.cep"
            :rules="requiredField" clearable />
        </v-col>
        <v-col>
          <Input label="Logradouro" v-model="currentItem.logradouro" clearable />
        </v-col>
        <v-col>
          <Input label="Número" v-model="currentItem.numero" clearable />
        </v-col>
        <v-col>
          <v-autocomplete dense label="Estado" v-model="currentItem.estado" outlined :loading="isLoading('get:ufs')"
            :items="ufs" item-value="sigla" item-text="sigla" return-object @change="getLocales" clearable />
        </v-col>
        <v-col>
          <v-autocomplete dense label="Município" v-model="currentItem.municipio" outlined
            :loading="isLoading('get:locales')" :items="locales" item-value="localidade" item-text="localidade"
            return-object clearable />
        </v-col>
        <v-col>
          <Input label="Bairro" v-model="currentItem.bairro" clearable />
        </v-col>
        <v-col>
          <Input label="Complemento" v-model="currentItem.complemento" clearable />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select dense label="Médico responsável" v-model="currentItem.medico_responsavel" outlined :items="doctors"
            :loading="isLoading('get:doctors')" item-text="apelido" item-value="apelido" :rules="requiredField" clearable>
          </v-select>
        </v-col>
        <v-col>
          <v-file-input counter show-size persistent-hint accept="*/*"
            label="Certificado digital da empresa" dense outlined @change="(e) => onFileSelected(e, 'certificado')"
            :hint="inputFileHint.certificado" clearable>
          </v-file-input>
        </v-col>

        <v-col>
          <Input :type="showPassword ? 'text' : 'password'" label="Senha Certificado"
            v-model="currentItem.senha_certificado" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShowPassword" clearable />
        </v-col>
        <v-col>
          <Input type="date" label="Validade certificado" v-model="currentItem.validade_certificado" clearable />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <Input label="Tempo logado no sistema (horas)" type="number" v-model="currentItem.tempo_token"
            :rules="requiredField" />
        </v-col>
        <v-col cols="3">
          <v-select outlined dense label="Ativar geolocalização para coleta de assinatura? "
            v-model="currentItem.active_geo" :items="booleanOptions" item-text="label" item-value="value" >
          </v-select>
        </v-col>
        <v-col cols="3">
          <Input label="Raio para coleta de assinatura" type="number" v-model="currentItem.geo_metros"
            :rules="[...requiredField, minimumRadiusValue]" />
        </v-col>
        <v-col>
          <v-select outlined dense label="Visita apenas no mês corrente?" v-model="currentItem.visita_mes"
            :items="booleanOptions" item-text="label" item-value="value" >
          </v-select>
        </v-col>
        <v-col cols="3">
          <Input label="Tempo MÁXIMO para lançar visitas? (DIAS)" type="number" v-model="currentItem.tempo_max_visita"
            :rules="requiredField" />
        </v-col>
        <v-col cols="2">
          <div style="display: flex; align-items: center;">
            <Input label="Escolha cor do sistema" type="color"
              v-model="currentItem.cor" :rules="requiredField" />
            <!-- Div para visualização ao lado do campo -->
            <div :style="{ 
               backgroundColor: currentItem.cor, 
               height: '40px', 
               width: '40px', 
               border: '1px solid #ccc', 
               marginLeft: '20px', 
               marginTop: '-25px' 

             }"> 
             </div> 
          </div>
        </v-col>
        <v-col cols="2">
          <v-text-field label="% Impostos" v-model="currentItem.imposto" clearable outlined dense />
        </v-col>
        <v-col cols="2">
          <v-text-field label="% Custo fixo" v-model="currentItem.custo_fixo" clearable outlined dense/>
        </v-col>
        <v-col cols="2">
          <v-text-field label="% Rentabilidade" v-model="currentItem.rentabilidade" clearable outlined dense/>
        </v-col>
      </v-row>
      <!-- Botão de ATIVO e INATIVO -->
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="isActive" mandatory class="white--text">
            <v-btn :color="!isActive ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="isActive ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!-- Botão para Salvar -->
      <v-row dense class="justify-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-row>
      <!-- Carregar documentos da empresa -->
      <p></p>
      <h2 class="blod_color">Documentos da empresa</h2> <p></p>
      <h3 class="blod_color">Carregar documentos: </h3>
      <p></p>
      <v-row dense class="my-3">
      <v-col md="3">
        <v-select outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id" >
          <template v-slot:label>
            <span>Tipo de documento <span style="color: red;">*</span></span>
          </template>
        </v-select>
      </v-col>
      <v-col md="6">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput" >
          <template v-slot:label>
            <span>Anexar documento <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
      <v-col md="3">
        <v-tooltip top :disabled="!!(selectedDocumentType && selectedFile)">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading" :disabled="!isFormValid || isUploading">
                <v-icon left>mdi-upload</v-icon>
                Enviar documento
              </v-btn>
            </span>
          </template>
          <span>Escolha o "Tipo de documento" e carregue o arquivo para habilitar este botão.</span>
        </v-tooltip> 
      </v-col>
    </v-row>
    <!-- <v-progress-linear v-if="isUploading" :indeterminate="true" color="blue"></v-progress-linear> -->
      <!-- Parte inferior: listar e baixar documentos -->
      <h2 class="blod_color mt-5 mb-5">Documentos carregados:</h2>
        <v-list dense>
          <v-progress-linear v-if="isUploading" loading-text="Carregando Informações...." :indeterminate="true" color="blue"></v-progress-linear>
          <v-list-item-group v-for="(doc, index) in uploadedDocuments" :key="index">
            <v-list-item class="my-2">
              <v-list-item-content>
                <v-list-item-title class="mb-2"> 
                  <span class="font-weight-bold text-primary blod_color ">{{doc.tipodoc.nome}}</span> 
                  <br>
                  <span>{{ extractFileName(doc.arquivo) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Carregado em: {{ formatDate(doc.data_carregado) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small color="green" class="white--text" :href="doc.arquivo" target="_blank">
                  <v-icon left>mdi-download</v-icon>
                  Baixar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
      </v-list>
    </v-form>
  </div>
</template>

<script>
import Input from '../../../Input.vue';
import ConfirmButton from '../../../ConfirmButton.vue';
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import '../../../../assets/css/styles.css';

import axios from "axios"

const { withCRUDUtils } = UtilsFunc;

const previewNamesByField = {
  logo: 'logoPreview',
  carimbo: 'stampPreview',
}

export default {
  name: "FormCompany",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function,
  },
  components: { Input, ConfirmButton },
  data: () => withCRUDUtils({
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    minimumRadiusValue(value) {
      return value >= 50 || 'O raio deve ser de pelo menos 50 metros.';

    },
    booleanOptions: [{ label: 'Sim', value: true }, { label: "Não", value: false }],
    doctors: [],
    types: [],
    ufs: [],
    locales: [],
    logoPreview: null,
    stampPreview: null,
    filesToUpload: {},
    showPassword: false,
    documentTypes: [], 
    selectedDocumentType: null,
    uploadedDocuments: [],
    isUploading: false,
    isActives: true,
    selectedFile: null,
  }),
  methods: {
    localOnSubmit() {
      const isValidForm = this.$refs.baseForm.validate()
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const formatedFields = { ...this.currentItem }
      formatedFields.visita_mes = Boolean(this.currentItem.visita_mes);
      formatedFields.active_geo = Boolean(this.currentItem.active_geo);

      if (this.filesToUpload.certificado) {
        formatedFields.certificado = this.filesToUpload.certificado
      }
      if (this.filesToUpload.carimbo !== undefined) {
        formatedFields.carimbo = this.filesToUpload.carimbo
      }
      if (this.filesToUpload.logo !== undefined) {
        formatedFields.logo = this.filesToUpload.logo
      }
      if (this.currentItem.estado) {
        formatedFields.estado = this.currentItem.estado.sigla
      }
      if (this.currentItem.municipio) {
        formatedFields.municipio = this.currentItem.municipio.localidade
      }
      this.onSubmit(formatedFields)
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    onFileSelected(event, fieldName) {
      const previewName = previewNamesByField[fieldName]
      if (!event) {
        const defaultFile = this.currentItem[fieldName];
        this.filesToUpload[fieldName] = undefined
        if (previewName) this[previewName] = defaultFile;
      } else {
        this.filesToUpload[fieldName] = event;
        if (previewName) this[previewName] = window.URL.createObjectURL(event);
      }
    },
    async getLocales(uf) {
      const LOADING_NAME = "get:locales";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`);
      this.setLoading(LOADING_NAME, true);
      this.locales = data;
    },
    async getUFS() {
      const LOADING_NAME = "get:ufs";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`uteis/uf/`);
      this.ufs = data;
      this.setLoading(LOADING_NAME, true);
    },
    resetFields() {
      this.currentItem.bairro = null
      this.currentItem.estado = null
      this.currentItem.municipio = null
      this.currentItem.logradouro = null
      this.currentItem.complemento = null
    },
    async autofillAddressFromCep(cep) {
      try {
        this.resetFields()
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf);
        await this.getLocales(uf);
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade);
        this.currentItem.bairro = data.bairro
        this.currentItem.estado = uf
        this.currentItem.municipio = localidade
        this.currentItem.logradouro = data.logradouro
        this.currentItem.complemento = data.complemento
      }
      catch (error) {
        this.resetFields()
        this.$toast.error("CEP não encontrado");
      }
    },
    async getDoctors() {
      const LOADING_NAME = "get:doctors";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/contas/usuarios/?ativo=1&pesquisar=false");
        this.doctors = data
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getTypes() {
      const LOADING_NAME = "get:tipo";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/multi_empresas/tipos/")
        this.types  = data.filter(tipo => tipo.ativo); 
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    handleDeleteFileField(field) {
      const previewName = previewNamesByField[field]
      this.currentItem[field] = null
      this[previewName] = undefined
      this.removeCarimboLogo()
    },
    resetNewItem() {
      this.newItem = {
        operadora: null,
        paciente: null,
        modalidade: null,
        data_inicio: null,
        data_fim: null,
        senha_data_inicio: null,
        senha_data_fim: null,
        senha: null,
        nr_guia_prestador: null,
        nr_guia_operador: null
      };
      this.paciente = {
        id: null,
        data_nascimento: null
      };
    },
    //Para pegar os nomes dos documentos ativos 
    async getDocumentTypes() {
      const LOADING_NAME = "get:documentTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tiposdocs/");
        this.documentTypes = data.filter(doctype => doctype.ativo); 
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Metodo para fazer o uploading do documento
    async uploadDocument() {
      if (!this.selectedDocumentType || !this.selectedFile) {
          this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
          return;
      }

      this.isUploading = true;

      let formData = new FormData();
      formData.append("arquivo", this.selectedFile);
      formData.append("tipodoc", this.selectedDocumentType);
      formData.append("ativo", this.isActives);
      formData.append("empresa", this.currentItem.id);
      const currentDate = new Date().toISOString().split('T')[0];
      formData.append("data_carregado", currentDate);
      this.ativo = true

      try {
          await api.post("/multi_empresas/docs/", formData);
          this.$toast.success("Documento carregado com sucesso!");
          this.selectedFile = null; 
          this.selectedDocumentType = null;
          this.fetchUploadedDocuments();
          
      } catch (error) {

          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
          this.isUploading = false;
      }
    },
  // Pegar os documentos do back
    async fetchUploadedDocuments() {
      this.isUploading = true;
      try {
        const { data } = await api.get(`/multi_empresas/docs/?empresa=${this.currentItem.id}`);
        this.uploadedDocuments = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os documentos. Erro: ${error}`);
      } finally {
          this.isUploading = false;
      } 
    },
    // Pega os arquivos e mostra mna parte inferior da tela os arquivos
    async loadUploadedDocuments() {
      try {
        const { data } = await api.get(`/multi_empresas/docs/?empresa=${this.currentItem.id}`);
        this.uploadedDocuments = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Erro ao carregar documentos: ${error}`);
      }
    },
    // Retira o caminho do nome do arquivo 
    extractFileName(url) {
      if (url) {
        return url.split('/').pop();
      }
      return "";
    },
    // Verifica o tamnaho do doc se ultrapasa 1MB 
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 1000000) { // maior que 1MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (1MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    formatFieldAsDecimal(event) {
      const value = event.target.value;
      event.target.value = value.replace(/,/g, '.');
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
  },
  created() {
  this.getDocumentTypes();
  },
  computed: {
    inputFileHint: function () {
      const data = {}
      if (!this.filesToUpload.certificado && this.currentItem.certificado) {
        data.certificado = this.currentItem.certificado.split("/").pop()
      }
      if (!this.filesToUpload.carimbo && this.currentItem.carimbo) {
        data.carimbo = this.currentItem.carimbo.split("/").pop()
      }
      if (!this.filesToUpload.logo && this.currentItem.logo) {
        data.logo = this.currentItem.logo.split("/").pop()
      }
      return data
    },
    isActive: {
      get() {
        return this.currentItem.ativo ? 1 : 0
      },
      set(value) {
        return (this.currentItem.ativo = value)
      }
    },
    isFormValid() {
      return this.selectedDocumentType && this.selectedFile;
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
  mounted() {
    this.getUFS();
    this.getDoctors()
    this.getTypes()
    this.fetchUploadedDocuments();
    this.loadUploadedDocuments();
    if (this.currentItem.logo) this.logoPreview = this.currentItem.logo
    if (this.currentItem.carimbo) this.stampPreview = this.currentItem.carimbo
    if (this.currentItem.estado) {
      const uf = {
        sigla: this.currentItem.estado
      }
      this.getLocales(uf)
    }
  },
  watch: {
    'currentItem.imposto'(newValue) {
      this.currentItem.imposto = newValue.replace(/,/g, '.');
    },
    'currentItem.custo_fixo'(newValue) {
      this.currentItem.custo_fixo = newValue.replace(/,/g, '.');
    },
    'currentItem.rentabilidade'(newValue) {
      this.currentItem.rentabilidade = newValue.replace(/,/g, '.');
    },
  },

}
</script>

<style scoped>
button.close-btn {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>

<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon>
    Declaração de Atividade "LMO"
  </v-btn>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapGetters } from 'vuex';
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/lmo.png";
import Assinatura from "../../../assets/Assinatura_Flavio_Andrade.png";

export default {
  name: "ContratoPaciente",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
      
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      
      try {

        const hasInvalidDate = this.selectedBudgets.some(
          budget => !budget.data_contrato || !budget.rg || !budget.cpf
        );

        if (hasInvalidDate) {
          this.$toast.info("A data do início do contrato ou RG ou CPF não esão cadastrados. O relatório não será gerado.");
          this.loading = false;
          return;
        }
        this.$toast.success(`O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`);
        const doc = this.generatePDF();
        await this.saveAndSignPDF(doc);
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      this.addContractText(doc);
      return doc; // Retorna o objeto doc
    },
    async saveAndSignPDF(doc) {
      const formattedName = String(this.selectedBudgets[0].nome).replace(/\s/g, '_')
      const filename = `Declaracao_Atividade_${formattedName}_${moment().format('YYYYMMDD_HHmmss')}.pdf`;
      
      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);
      formData.append("empresa", 10); // que essa linha está passando um valor estatico que precisará de refatoramento quando implementar o multiempresas.
      
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);
      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())

      // Salve o PDF no servidor como um documento do paciente. Está salvando antes de assinar
      await this.saveDocument(signedPDFBlob, filename);

      const signedPdfUrl = signedForm.path;

      this.download(signedPdfUrl, filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    async saveDocument(pdfBlob, filename) {
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("usuario", this.selectedBudgets[0].id);
      formData.append("tipodoc", 31);

      try {
        await api.post('contas/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
    drawHeader(doc) {
      try {
        doc.addImage(Assinatura, 90, 185, 30, 30);
        doc.addImage(logo, 10, 8, 28, 28);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
      }

      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("L M O TECNOLOGIA", 105, 22, { align: "center" });
      doc.setFontSize(16);
      doc.text("DECLARAÇÃO", 105, 53, { align: "center" });
    },
    addContractText(doc) {
      const usuario = this.username
      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const budget = this.selectedBudgets[0];
      const startY = 80;
      const cpf = budget.cpf || 'não cadastrado';
      const rg = budget.rg || 'não cadastrado';
      const pj = budget.cnpj != null ? `a empresa ${budget.razao_social}, com nome Fantasía ${budget.nome_fantasia}, inscrita no CNPJ Nº ${budget.cnpj}, representada (responsável também pela execução do serviço) pelo` : ''
      const dataContrato = UtilsPDF.formatDate(budget.data_contrato) || '"data não cadastrada"';
      const datafimContrato = budget.data_fim_contrato ? ` até ${(UtilsPDF.formatDate(budget.data_fim_contrato))}` : 'até atualmente'

      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");

      const contractText = `
                 L M O Andrade LTDA, pessoa jurídica de direito privado, inscrita no CNPJ Nº 44.840.191/0001-75, localizada na Estrada do Araçagy, SN, bairro Araçagy, São Jose de Ribamar - MA, por intermédio de seu representante legal o Sr. Flávio Roberto Melo de Andrade, inscrito sob o CPF nº 787.501.993-15, DECLARA para os devidos fins que ${pj} o(a) Sr.(a) ${budget.nome}, inscrito(a) no RG nº ${rg}, com CPF nº ${cpf}, é prestador(a) de serviço nesta empresa, sem vínculo empregatício, como ${budget.especialidade.nome}, desde ${dataContrato} ${datafimContrato}, executando as seguintes atividades: atendimento domiciliar a paciente em assistência e/ou internação domiciliar, além de elaboração de evolução em sistema.

                  Por ser verdade, firmo a presente e coloco-me à disposição para os esclarecimentos que se fizerem necessários.


                                              São José de Ribamar, ${UtilsPDF.formatDate(currentDate)} às ${hour}.



                                             _____________________________________
                                                          Flavio Roberto Melo Andrade
                                                                             Diretor 
                                                                L M O ANDRADE LTDA
                                                          CNPJ Nº 44.840.191/0001-75

    `;

      const usuariotexto = `Documento gerado por: ${usuario}`;

      doc.setFontSize(12);
      const lines = doc.splitTextToSize(contractText, 190);
      let currentY = startY;

      lines.forEach(line => {
        doc.text(line, 10, currentY);
        currentY += 7; 
      });

      // Adicionar texto do usuário no final da página
      doc.setFontSize(6);
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(usuariotexto);
      const xPosition = pageWidth - textWidth - 10; 
      currentY = doc.internal.pageSize.height - 23; 
      doc.text(usuariotexto, xPosition, currentY);

      UtilsPDF.addPageNumbersLMO(doc);
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    lista() {
      return this.userData || {}
    },
  },
  computer:{ ...mapGetters(['userData']),},
  mounted(){
    this.lista();
  }
};
</script>

<template>
  <v-container class="container">
    <!-- {{paciente}} -->
    <v-btn color="success" depressed size="small" @click="generatePDF">
      <v-icon left>mdi-printer</v-icon> Rel. Retirada de Esterilizável
    </v-btn>
  </v-container>
</template>

    
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"
import UtilsPDF from '../../../service/utilsPDF';
import api from '../../../http';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Retirada",
  data: () => withCRUDUtils({
    loading: false,
    endereco: [],
  }),
  props: {
    paciente: { type: Array, default: () => [] },
    esterilizaveis: { type: Array, default: () => [] },
    pacienteSelecionado: { type: Object },
    dataEntrega: {type: [Date, String], // Permite tanto Date quanto String
      required: true,}

  },

  methods: {
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
        this.getEndereco()
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-91):", error);
      }
      const lineHeight = 6;
      const xFixedPosition = 10;
      let initialYPosition = 45;
      let yPosition = 45;
      const endereco = this.endereco[0]

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Requisição de Retirada de Esterilizáveis`, 105, 28, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      doc.setFontSize(12);
      doc.text(`Paciente: ${this.pacienteSelecionado.nomePaciente}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = `Data Nascimento: ${UtilsPDF.formatDate(this.pacienteSelecionado.data_nascimento)}`;
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });
      
      doc.text(`Operadora / Contrato: ${endereco.operadora.nome} `, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${endereco.nr_carteirinha}`, 200, yPosition, { align: "right", });
      
      yPosition = yPosition + lineHeight
      doc.text(`Endereço: Logradouro ${endereco.endereco.logradouro}, Nº ${endereco.endereco.nr}, Bairro ${endereco.endereco.bairro} `, xFixedPosition, yPosition);
      yPosition = yPosition + lineHeight
      doc.text(`Complemento: ${endereco.endereco.complemento}, Cidade: ${endereco.endereco.cidade} / ${endereco.endereco.estado}. Contato: ${endereco.telefone}. `, xFixedPosition, yPosition);
    },
     drawFooter(doc, yPosition) {
      const lineHeight = 6; // Altura entre as linhas
      const maxWidth = 190; // Largura máxima para o texto antes de quebrar a linha

      // Gerar o texto dos esterilizáveis
      const itensTexto = this.paciente
        .map(
          (item) =>
            `${item.esterilizavel.nome} - R$${item.esterilizavel.valor_cobranca
              .toFixed(2)
              .replace(".", ",")}`
        )
        .join("; ");

      // Texto completo com OBS
      const textoCompleto = `OBS: Valores de reposição dos itens são: ${itensTexto}. Caso não tenha a devolução dos itens  ou sejam entregues com avarias o valor de reposição será cobrado integralmente.`;

      // Adicionar o texto ao PDF com quebra automática
      yPosition += lineHeight; // Movendo para a próxima linha
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);

      const splitText = doc.splitTextToSize(textoCompleto, maxWidth);
      splitText.forEach((line) => {
        doc.text(line, 10, yPosition);
        yPosition += lineHeight;
      });

      return yPosition;
    },
    async generatePDF() {
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      // Extrai o pacienteId do primeiro item da lista de pacientes
      const id = this.pacienteSelecionado?.pacienteId;
  
      // Verifica se o ID existe antes de chamar getEndereco
      if (id) {
        await this.getEndereco(id);
      } else {
        this.$toast.error("Não foi possível encontrar o ID do paciente");
      }
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const lineHeight = 4;
      let yPosition = 35;

      yPosition += lineHeight * 2;
      const blueColor = [57, 106, 159];
      doc.setFontSize(12);
      
      yPosition += lineHeight * 8;
      
      doc.setFont("helvetica", "bold");
      doc.text(`Data Prevista para Recolher: ${UtilsPDF.formatDate(this.dataEntrega)}`, 100, 75, { align: "center" });

      yPosition += lineHeight * 2;


      doc.setFont("helvetica", "bold");
      doc.text(`Itens do Paciente para Efetuar o Recolhimento - "RECOLHER"`, 100, 85, { align: "center" });
      // doc.text(`RECOLHER`, 100, 87, { align: "center" });
      yPosition += lineHeight * 2;
      

      doc.autoTable({
        startY: yPosition,
        theme: 'striped',
        headStyles: {
          fillColor: blueColor,
          halign: 'center',
        },
        styles: {
          lineColor: blueColor,
          lineWidth: 0.1,
        },
        head: [['Id', 'Esterelizável', 'Recolhido?']],
        body: this.paciente.map(item => [
          item.esterilizavel.id,
          item.esterilizavel.nome,
        ]),
        columnStyles: {
          0: { cellWidth: 10 }, // Define a largura da primeira coluna (Profissional)
        },
      });

      const tableHeight = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight + lineHeight + 6;

      // doc.setFont("helvetica", "bold");
      // doc.text(`Itens Esterilizáveis - "ENTREGAR"`, 100, yPosition, { align: "center" });
      // // doc.text(`DEIXAR NA RESIDENCIA`, 100, yPosition + 7, { align: "center" });
      // yPosition += lineHeight;
      
      // doc.autoTable({
      //   startY: yPosition,
      //   theme: 'striped',
      //   headStyles: {
      //     fillColor: blueColor,
      //     halign: 'center',
      //   },
      //   styles: {
      //     lineColor: blueColor,
      //     lineWidth: 0.1,
      //   },
      //   head: [['Id', 'Esterelizável', 'Validade da Esterilização', 'Confirmar']],
      //   body: this.esterilizaveis.map(item => [
      //     item.id,
      //     item.nome.nome,
      //     UtilsPDF.formatDate(item.data_validade_esterilizacao)
      //   ]),
      //   columnStyles: {
      //     0: { cellWidth: 10 }, // Define a largura da primeira coluna (Profissional)
      //   },
      // });

      // const tableHeight2 = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      // yPosition = tableHeight2 + lineHeight + 12;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text("Assinatura: ___________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Data do Recebimento: ____ / ____ / ______", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("Nome: _______________________________________", 15, yPosition);
      yPosition += lineHeight * 2;
      doc.text("CPF ou RG: __________________________________", 15, yPosition);
      yPosition += lineHeight * 2;    
      
      // Desenhar o rodapé com os itens de esterilizáveis
      yPosition = this.drawFooter(doc, yPosition);

      UtilsPDF.addPageNumbers(doc);

      const filename = `Relatório de Retirada de Esterilizaveis.pdf`;
      // UtilsPDF.savePDF(doc, filename, this.$toast);
      doc.save(filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    async getEndereco(id) {
      try {
        // const id = this.paciente ? this.paciente.pacienteId : ''
        // console.log(id)
        const { data } = await api.get(`pacientes/detalhes/?paciente=${id}`);
        this.endereco = data;
        // console.log('PACIENTE --> ', this.endereco)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Endereço:", error);
      }
    },

  },
  
};
</script>
<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Retirada" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data Presvista de Retirada" disabled v-model="dataPrevista" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados a Retirada:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" item-value="id" show-select v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1"  >
        <template v-slot:[`item.esterilizavel`]="{ item }">
          {{ item.esterilizavel?.nome }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
    </v-data-table>
      </v-col>
    </v-row>
    <!-- <v-row dense >
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Nova Disponibilidade" v-model="currentItem.disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id"/>
      </v-col>
    </v-row> -->

    <v-row class="justify-end align-center mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizar" :loading="isLoadingDados">  <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
       <v-spacer></v-spacer>
      <v-col cols="auto" class="d-flex align-center">
        <div class="align-self-top py-4" left top  :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" :disabled="!isSaveEnabled" > 
          <v-menu eager offset-x left offset-y top v-model="menu">
            <!-- <template v-slot:activator="{ on, attrs }" >
              <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on" :disabled="selectedPacienteItems.length === 0"> mdi-printer </v-icon>
            </template> -->
            <v-container class="container">
              <template >
                <div > 
                  <Relatorio ref="relatorioComponent" :paciente="this.selectedPacienteItems" :esterilizaveis="this.selectedEsterelizaveis" :pacienteSelecionado="resumoPacientes.find(p => p.id === pacienteSelecionado)" :dataEntrega="dataPrevista"> </Relatorio>
                </div>
              </template>
            </v-container>
          </v-menu>
        </div>
      </v-col>
      <v-col  cols="auto" class="d-flex align-center">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton 
                :loading="loading" 
                color="success"
                :disabled="!isSaveEnabled"
                :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
              >
                Salvar
              </ConfirmButton>
            </span>
          </template>
          <span>Selecione pelo menos um item e uma disponibilidade para salvar</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import Relatorio from '../../relatorios/entregaSuprimento/RetirarEsterilizacao.vue';

export default {
  name: "RetirarEsterilizaveis",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingDisponibilidade: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPrevista: "",
      dataImplantacao: "",
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      disponibilidade: [],
      isLoadingDados: false,
      menu: false,
      isReportTriggered: false,
      ItemPacientelHeaders: [
        { text: "Id", value: "id" },
        { text: "Id Esterelizável", value: "esterilizavel.id" },
        { text: "Esterilizável", value: "esterilizavel" },
        // { text: "Quantidade Solicitada", value: "quantidade" },
      ],
    });
  },
  components: { ConfirmButton, Relatorio },
  methods: {
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo && ![1, 2, 3, 4, 5, 7, 9, 11, 12, 13, 14, 15, 16].includes(item.id))
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        // Filtra primeiro os registros relevantes
        this.pacientes = response.data.filter(item => 
          item.data_solicitacao_retirada !== null && 
          item.disponibilidade !== 14 &&
          item.retirada === true &&
          item.esterilizavel &&
          item.ativo === true
        );
        
        // Agrupamento mantendo esterilizáveis separados
        const agrupamento = this.pacientes.reduce((acc, atual) => {
          const dataReq = atual.data_solicitacao_retirada.split('T')[0];
          const chave = `${atual.paciente.id}-${dataReq}`;
          
          if (!acc[chave]) {
            const data = new Date(atual.data_solicitacao_retirada);
            data.setDate(data.getDate() + 1);
            const dataFormatada = data.toLocaleDateString('pt-BR');
            
            acc[chave] = {
              id: chave,
              idEsterilizavelPaciente: atual.id, 
              pacienteId: atual.paciente.id,
              nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
              nomePaciente: atual.paciente.nome,
              data_nascimento: atual.paciente.data_nascimento,
              esterilizavel: [{
                id: atual.id,
                esterilizavel: { nome: atual.esterilizavel.nome.nome , id: atual.esterilizavel.id, valor_cobranca: atual.esterilizavel.nome.valor_cobranca, },
                quantidade: 1
              }],
              data_solicitacao_retirada: atual.data_solicitacao_retirada,
              data_prevista_solicitacao_retirada: atual.data_prevista_solicitacao_retirada,
              data_prevista_implantacao: atual.data_prevista_implantacao
            };
          } else {
            acc[chave].esterilizavel.push({
              id: atual.id,
              idEsterilizavelPaciente: atual.id,
              esterilizavel: {  nome: atual.esterilizavel.nome.nome, id: atual.esterilizavel.id, valor_cobranca: atual.esterilizavel.nome.valor_cobranca  },
              quantidade: 1
            });
          }
          return acc;
        }, {});
        // Converte para array final
        this.resumoPacientes = Object.values(agrupamento)
          .map(item => ({
            id: item.id,
            pacienteId: item.pacienteId,
            nomePaciente: item.nomePaciente,
            data_nascimento: item.data_nascimento,
            nome: item.nome,
            esterilizavel: item.esterilizavel,
            data_solicitacao_retirada: item.data_solicitacao_retirada,
            data_prevista_solicitacao_retirada: item.data_prevista_solicitacao_retirada,
            data_prevista_implantacao: item.data_prevista_implantacao
          }))
          .sort((a, b) => {
            const dataA = new Date(a.data_solicitacao_retirada);
            const dataB = new Date(b.data_solicitacao_retirada);
            return dataA - dataB;
          });

      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
      
    },
    updatePacienteData() {
      this.selectedPacienteItems = [];
      
      const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
        );
        
        if (paciente) {
          this.dataRequisicao = paciente.data_solicitacao_retirada || "";
          this.dataPrevista = paciente.data_prevista_solicitacao_retirada || "";
          
          this.filterPaciente = paciente.esterilizavel.map(item => ({
            id: item.id,
            esterilizavel: {
              id: item.esterilizavel.id,
              nome: item.esterilizavel.nome,
              valor_cobranca: item.esterilizavel.valor_cobranca,
            },
           
            quantidade: item.quantidade
          }));
        } else {
          this.dataRequisicao = "";
          this.dataPrevista = "";
          this.filterPaciente = [];
        }
      },
      async localOnSubmit() {
        const validation = this.$refs.formRef.validate();
          if (!validation) return;
          this.isReportTriggered = true;
        // console.log(this.selectedPacienteItems)
          const Itens = this.selectedPacienteItems.map(item => {
            const fields = {
                id: item.esterilizavel.id,
                // disponibilidade: this.currentItem.disponibilidade,
                disponibilidade: 14,
                idEsterilizavelPaciente: item.id
            };
              
            return fields;
          });
          for (const fields of Itens) {
              this.onSubmit(fields)
              .then(() => {
                  // Passamos o ID do paciente_esterilizavel para atualização
                  this.updatePacienteEsterelizavel([fields.idEsterilizavelPaciente]);
              })
              .catch((error) => {
                  console.error("Erro ao salvar os dados principais:", error);
                  this.$toast.error("Erro ao salvar os dados principais.");
              });
          }
      },
      async updatePacienteEsterelizavel(idsEsterilizavelPaciente) {
        const LOADING_NAME = "put:esterilizavel-paciente";
        this.setLoading(LOADING_NAME);
        // console.log(idsEsterilizavelPaciente)

        try {
            const promises = idsEsterilizavelPaciente.map(id =>
                api.put(`/pacientes/esterilizavel/${id}/`, {
                    id: this.id,
                    // ativo: false,
                    disponibilidade: 14,
                    // data_retirada: dataFormatada
                })
            );

            await Promise.all(promises); // Aguarda todas as requisições serem concluídas

            this.$toast.success("Esterilizável do Paciente Atualziado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar Esterilizável do Paciente:", error);
            this.$toast.error("Erro ao atualizar Esterilizável do Paciente dos esterilizáveis.");
        } finally {
            this.setLoading(LOADING_NAME, true);
        }
    },
     generateReport() {
      if (this.selectedPacienteItems.length > 0 ) {
        this.$toast.success("Gerando relatório...");
        // Chama o componente ou função de geração do relatório
        this.$refs.relatorioComponent.generatePDF(this.selectedPacienteItems );
      } else {
        this.$toast.error("Nenhum item selecionado para o relatório.");
      }
    },
    
    atualizar() {
      this.getPacientes()
      this.getDisponibilidade()
    },
  },
  computed: {
    isSaveEnabled() {
      // Verifica se tem pelo menos um item selecionado e uma disponibilidade selecionada
      return (
        this.selectedPacienteItems.length > 0 
        // && this.currentItem?.disponibilidade !== undefined && 
        // this.currentItem?.disponibilidade !== null
      );
    },
  },
  watch: {
    isReportTriggered(newValue) {
      if (newValue) {
        this.generateReport();
        this.isReportTriggered = false; // Reseta o flag
      }
    }
  },
  mounted() {
    this.getDisponibilidade()   
    this.getPacientes();
  },
};
</script>

<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-data-table :loading="isTableLoading" loading-text="Carregando Informações...." :items="filteredData" :headers="headersWithValidadeInfo" :search="search" class="fixed-header-table" fixed-header height="600px" :items-per-page="-1" >
                <template v-slot:top>
                    <v-row class="mt-2">
                        <v-col cols="5">
                            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
                        </v-col>
                        <v-col class="text-end">
                            <v-badge left bordered overlap :content="activeFilters.length">
                                <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                                <v-icon left>
                                    mdi-filter
                                </v-icon>
                                Filtros
                                </v-btn>
                            </v-badge>
                            <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar </v-btn>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-card outlined v-show="showFilters" dense>
                        <h4 class="ml-4 mt-4">Filtrar por</h4>
                        <v-row dense class="py-2 px-4">
                            <v-col cols="2">
                            <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                                v-model="filters.is_active.value" outlined dense />
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-row dense>
                            <v-col class="text-end">
                                <v-btn color="error" text elevation="0" @click="clearFilters">
                                Limpar filtros
                                </v-btn>
                                <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                                Aplicar filtros
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                    <v-row class="mt-2 mb-2">
                        <!-- Wrapper dos botões alinhados à esquerda -->
                        <v-col cols="auto" class="d-flex button-group pa-1"> <!-- gap-4 adiciona espaçamento entre os botões -->
                            <!-- Botão Novo Esterilizavel-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  color="success"  class="elevation-0 compact-btn ml-3"  @click="openModal"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon><div class="d-flex flex-column compact-btn-text"> <span>Novo</span> <span>Esterilizável</span> </div> </v-btn>
                                </template>
                                <span>Clique para cadastrar um novo esterilizável</span>
                            </v-tooltip>
                            <!-- Botão Enviar Esterilizáveis -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#FABC4A', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.enviar = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Enviar p/</span> <span>Esterilização</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para enviar um ou mais Esterilizáveis para Esterilização</span>
                                </v-tooltip>
                                <div v-if="quantidadeEsterilizavelEnviar >=1" class="badge-red">
                                    {{ quantidadeEsterilizavelEnviar }}
                                </div>
                            </div>
                            <!-- Botão Receber Esterilizáveis -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#FABC4A', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.create = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Receber da</span> <span>Esterilização</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para receber uma Nota de Esterilização</span>
                                </v-tooltip>
                                <div v-if="quantidadeEsterilizavelreceber >=1" class="badge-red">
                                    {{ quantidadeEsterilizavelreceber }}
                                </div>
                            </div>
                            <!-- Botão Trocar Esterilizáveis   -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createTroca = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Trocar</span> <span>Esterilizável</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para substituir Esterilizavel em domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisTroca >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisTroca }}
                                </div>
                            </div>
                            <!-- Botão Retirada de Esterilizáveis -->
                             <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  :style="{ backgroundColor: '#376EA1', color: 'white' }"  class="elevation-0 compact-btn"  @click="dialog.retirada = true"  v-bind="attrs"  v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon>  <div class="d-flex flex-column compact-btn-text"> <span>Retirar</span> <span>Esterilizável</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para retirar Esterilizavel do domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisRetirada >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisRetirada }}
                                </div>
                            </div>

                            <!-- Botão Requisições com badge -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createRequisicao = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Requisições</span> <span>Paciente</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Atender Requisição</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveis >= 1" class="badge-red">
                                    {{ quantidadePacientesElegiveis }}
                                </div>
                            </div>
                            <!-- Botão Entrega -->
                            <div style="position: relative;" class="mr-2">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#376EA1', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.entrega = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Aguardando</span> <span>Conf. entrega</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Confirma a entrega no domucílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisEntrega >= 1" class="badge-red">
                                    {{ quantidadePacientesElegiveisEntrega }}
                                </div>
                            </div>
                            <!-- Botão Usuário -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: '#FF6501', color: 'white' }" class="elevation-0 compact-btn" @click="dialog.createUsuario = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> <div class="d-flex flex-column compact-btn-text"> <span>Usuário</span> <span>Profissional</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para Dispensar ou Recolher de Usuário</span>
                                </v-tooltip>
                            </div>
                            <!-- Botão Rastreabilidade -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :style="{ backgroundColor: 'orange', color: 'white' }" class="elevation-0 compact-btn mr-3 ml-2" @click="dialog.rastreabilidade = true" v-bind="attrs" v-on="on" > <v-icon small class="compact-icon" left>mdi-plus</v-icon> Rastreabilidade </v-btn>
                                    </template>
                                    <span>Clique para acompanhar a rastreabilidade</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                        <!--  Vencimento dos Esterilizáveis  45 dias-->
                       <v-col cols="auto" class="pa-0 mr-4" 
                            v-if="itemsStatus.vencidos > 0 || itemsStatus.proximosVencer > 0">
                            <div class="d-flex align-center">
                                <v-icon small color="error" left>mdi-alert</v-icon>
                                <div class="d-flex flex-column ml-1 status-text">
                                    <span class="text-caption text-xs">Esterilização</span>
                                    <span v-if="itemsStatus.vencidos > 0" class="error--text text-xs font-weight-medium">
                                        {{ itemsStatus.vencidos }} {{ itemsStatus.vencidos === 1 ? 'Item' : 'Itens' }} vencido(s)
                                    </span>
                                    <span v-if="itemsStatus.proximosVencer > 0" class="warning--text text-xs font-weight-medium">
                                        {{ itemsStatus.proximosVencer }} {{ itemsStatus.proximosVencer === 1 ? 'Item' : 'Itens' }} próx. venc.
                                    </span>
                                </div>
                            </div>
                        </v-col>

                        <!-- Coluna de Status de Vida Útil  90 dias-->
                        <v-col cols="auto" class="pa-0" 
                            v-if="itemsStatus.vidaUtilVencida > 0 || itemsStatus.proximoVidaUtil > 0 || itemsStatus.semVidaUtil > 0">
                            <div class="d-flex align-center">
                                <v-icon small color="error" left>mdi-alert</v-icon>
                                <div class="d-flex flex-column ml-1 status-text">
                                    <span class="text-caption text-xs">Vida Útil</span>
                                    <span v-if="itemsStatus.vidaUtilVencida > 0" class="error--text text-xs font-weight-medium">
                                        {{ itemsStatus.vidaUtilVencida }} {{ itemsStatus.vidaUtilVencida === 1 ? 'Item' : 'Itens' }} vencido(s)
                                    </span>
                                    <span v-if="itemsStatus.proximoVidaUtil > 0" class="warning--text text-xs font-weight-medium">
                                        {{ itemsStatus.proximoVidaUtil }} {{ itemsStatus.proximoVidaUtil === 1 ? 'Item' : 'Itens' }} próx. venc.
                                    </span>
                                    <span v-if="itemsStatus.semVidaUtil > 0" class="grey--text text-xs font-weight-medium">
                                        {{ itemsStatus.semVidaUtil }} {{ itemsStatus.semVidaUtil === 1 ? 'Item' : 'Itens' }} sem informação
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                                <v-icon color="green">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Clique para editar</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.esterilizacao_fornecedor_nome`]="{ item }">
                    <div v-if="isTableLoading" class="d-flex justify-center align-center">
                        <v-progress-circular indeterminate color="primary" size="24"></v-progress-circular>
                    </div>
                    <div v-else>
                        <span>{{ item.esterilizacao_fornecedor_nome }}</span>
                    </div>
                </template>                
                <template v-slot:[`item.esterilizacao_data_envio`]="{ item }">
                    {{ item.esterilizacao_data_envio | formatDatabr }}
                </template>
                <template v-slot:[`item.aquisicao.dataAquisicao`]="{ item }">
                    {{ item.aquisicao.dataAquisicao | formatDatabr }}
                </template>
                <template v-slot:[`item.qtd_dias`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                {{ item.qtd_dias ? `${item.qtd_dias} dias` : '' }}
                            </span>
                        </template>
                        <span v-if="item.qtd_dias && item.nome && item.nome.vida_util">
                            {{ calcularDiasRestantes(item) }} dias restantes para vencer
                        </span>
                        <span v-else>
                            Sem cadastro de Dias de Vida Útil
                        </span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.ativo`]="{ item }">
                    <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                        <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                    </v-chip>
                </template>
                <template v-slot:[`item.data_requisicao`]="{ item }">
                    {{ item.data_requisicaoPaciente ? $options.filters.formatDatabr(item.data_requisicaoPaciente) : $options.filters.formatDatabr(item.data_requisicaoUsuario) }}
                </template>
                <template v-slot:[`item.data_implantacao`]="{ item }">
                    {{ item.data_implantacaoPaciente ? $options.filters.formatDatabr(item.data_implantacaoPaciente) : $options.filters.formatDatabr(item.data_entregaUsuario) }}
                </template>
                <template v-slot:[`item.data_validade_esterilizacao`]="{ item }">
                    <span v-if="item.disponibilidade?.id !== 7 ">{{ item.data_validade_esterilizacao | formatDatabr }}</span>
                    <span v-else></span>
                </template>

            </v-data-table>
<!-- Editar -->
            <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="90%" @input="handleDialogClose"  >
                <v-card>
                    <v-card-title class="blod_color">
                        Editar Esterilizável - ID {{selectedRow.id}} - {{selectedRow.nome.nome}}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon>  mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <EditarEsterilizavel :currentItem="selectedRow" :loading="isLoading('put:esterilizavel')" :onSubmit="updateEsterilizavel" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Novo Esterilizavel-->
            <v-dialog v-model="dialog.create2" v-if="dialog.create2" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Cadastro de Novo Esterilizável:
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon>  mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EntradaEsterilizaveis :currentItem="newItem" :loading="isLoading('post:esterilizavel')" :onSubmit="createEsterilizavel" />
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Enviar para Esterilização -->
            <v-dialog v-model="dialog.enviar" v-if="dialog.enviar" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Enviar Esterilizáveis para Esterilização
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EnviarEsterilizaveis :currentItem="newItem" :loading="isLoading('put:esterilizavelenviar')" :onSubmit="updateEsterilizavelEnviar"/>
                     </v-card-text>
                </v-card>
            </v-dialog>

<!-- Receber Esterelizáveis -->
            <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Nova Nota de Esterilização:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon>  mdi-close </v-icon>
                    </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <EsterilizacaoCriar :currentItem="newItem" :loading="isLoading('post:esterilizacaoreceber')" :onSubmit="upadteEsterelizavelReceber" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Trocar -->
            <v-dialog v-model="dialog.createTroca" v-if="dialog.createTroca" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Troca de Esterilizáveis do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Trocar :currentItem="newItem" :loading="isLoading('put:esterilizaveltrocar')" :onSubmit="updateEsterilizavelTrocar"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Retirada -->
            <v-dialog v-model="dialog.retirada" v-if="dialog.retirada" max-width="90%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Retirada de Esterilizáveis do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Retirada :currentItem="newItem" :loading="isLoading('put:esterilizavelretirada')" :onSubmit="updateEsterilizavelRetirada"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Requisição -->
            <v-dialog v-model="dialog.createRequisicao" v-if="dialog.createRequisicao" max-width="80%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Requisição:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Requisicao :currentItem="newItem" :loading="isLoading('post:esterilizacao')" :onSubmit="createEsterilizacao" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Entrega -->
            <v-dialog v-model="dialog.entrega" v-if="dialog.entrega" max-width="95%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Entrega :currentItem="newItem" :loading="isLoading('post:entraga')" :onSubmit="createEntrega" :numeroEntraga="quantidadePacientesElegiveisEntrega"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Usuário -->
            <v-dialog v-model="dialog.createUsuario" v-if="dialog.createUsuario" max-width="90%" @input="handleDialogClose">
                <v-card>
                    <v-card-title class="blod_color">
                    Gestão de Esterelizavél com Usúario:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Usuario :currentItem="newItem" :loading="isLoading('post:usuario')" :onSubmit="createUsuario" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Rastreabilidade -->
            <v-dialog v-model="dialog.rastreabilidade" v-if="dialog.rastreabilidade" max-width="80%" @input="handleDialogClose" >
                <v-card>
                    <v-card-title class="blod_color">
                    Rastreabilidade do Esterilizável:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Rastreabilidade :currentItem="newItem" :loading="isLoading('post:usuario')" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import api from '../../../http';
import UtilsFunc from "../../../service/utilsFunc";
import EntradaEsterilizaveis from './EntradaEsterilizaveis.vue';
import EditarEsterilizavel from './EditarEsterilizavel.vue';
import EsterilizacaoCriar from './EsterilizaçãoReceber.vue';
import EnviarEsterilizaveis from './EnviarEsterilizaveis.vue';
import Entrega from './entrega/Index.vue';
import Requisicao from './Requisicao.vue'
import Trocar from './TrocaEsterilizavel.vue';
import Retirada from './RetiradaEsterilizaveis.vue';
import Rastreabilidade from './Rastreabilidade.vue';
import Usuario from './Usuario.vue';
import { mapMutations } from 'vuex'

const { withCRUDUtils } = UtilsFunc

export default {
    name: 'ControleEsterilivaveis',
    components: {
        EntradaEsterilizaveis,
        EditarEsterilizavel,
        EsterilizacaoCriar,
        Requisicao,
        EnviarEsterilizaveis,
        Trocar,
        Retirada,
        Usuario,
        Rastreabilidade,
        Entrega,
    },
    data: () => withCRUDUtils ({
        isTableLoading: true,
        dialog: {
            create2: false,
            enviar: false,
            createTroca: false,
            createReceber: false,
            createRequisicao: false,
            retirada: false,
            createUsuario: false,
            rastreabilidade: false,
            entrega: false,
        },
        modal: false,
        esterelizavel: [],  
        fornecedor: [], 
        paciente: [],
        pacientes: [],
        usuariosEsterilizavel: [],
        loading: false,
        search: '',
        singleExpand: false,
        tabIndex: 0,
        validade: 0,
        quantidadePacientesElegiveis: 0,
        quantidadePacientesElegiveisTroca: 0,
        quantidadePacientesElegiveisRetirada: 0,
        quantidadePacientesElegiveisEntrega: 0,
        quantidadeEsterilizavelreceber: 0,
        quantidadeEsterilizavelEnviar: 0,
        newItem: {
            ativo: true
        },
        newItemAquisicao: {
            ativo: true
        },
        filters: {
            // fornecedor: null,
            // razaoSocial: null,
            is_active: { value: true, compareType: 'equal', prop: 'ativo' }
        },
        statusOptions: [
            { label: "Ativo", value: true },
            { label: "Inativo", value: false }
        ],
        showFilters: false,
        activeFilters: [],
        headers: [
            { text: "Editar", value: "edit" },
            { text: "Id", align: "center", sortable: true, value: "id" },
            { text: "Descrição", align: "center", sortable: true, value: "nome.nome" },
            { text: "Deposito", align: "center", sortable: true, value: "deposito.nome" },
            { text: "Data Aquisição", align: "center", sortable: true, value: "aquisicao.dataAquisicao" },
            { text: "Dias Ativo", align: "center", sortable: true, value: "qtd_dias" },
            { text: "Disponibilidade", align: "center", sortable: true, value: "disponibilidade.nome" },
            { text: "Usuário", align: "start", sortable: true, value: "usuariosEsterilizavel_nome", width: 200 },
            { text: "Paciente", align: "start", sortable: true, value: "paciente_nome", width: 200 },
            { text: "Dia Dispensação", align: "center", sortable: true, value: "paciente_dia_dispensacao_esterilizavel", width: 30 },
            { text: "Data Requisição", align: "start", sortable: true, value: "data_requisicao" },
            { text: "Frequencia", align: "start", sortable: true, value: "frequencia_nome" },
            { text: "Data da Entrega", align: "start", sortable: true, value: "data_implantacao" },
            { text: "Esterilizadora", align: "start", sortable: true, value: "esterilizacao_fornecedor_nome" },
            { text: "Data do Envio Esterilização", align: "start", sortable: true, value: "esterilizacao_data_envio" },
            { text: "Data da Validade Esterilização", align: "start", sortable: true, width: 140, value: "data_validade_esterilizacao" },
            { text: "Status", value: "ativo" },
        ],
    }), 
    methods: {
        ...mapMutations(['setModal']),
        openModal() {
            this.dialog.create2 = true
            this.modal = true
            this.setModal(this.modal)
        },
        openModalReceber() {
            this.dialog.createReceber = true
            this.modal = true
            this.setModal(this.modal)
        },
        ...mapMutations(['setModal']),
        handleDialogClose(val) {
            if (!val) { // quando o diálogo fecha, val será false
                this.atualizar();
            }
        },
        closeDialog() {
            this.dialog.createUsuario = false;
            this.dialog.create2 = false;
            this.dialog.update = false;
            this.dialog.enviar = false
            this.dialog.create = false;
            this.dialog.createTroca = false,
            this.dialog.retirada = false,
            this.dialog.createRequisicao = false,
            this.dialog.entrega = false,
            this.dialog.rastreabilidade = false,
            this.atualizar();
        },
        async carregarDadosIniciais() {
            // this.getPaciente()
            // this.getEsterelizavel()
            // this.getFornecedor()
            // this.getUsuarioEsterilizavel()
            this.isTableLoading = true; // Ativa o loading geral da tabela
           await Promise.all([this.getEsterelizavel(), this.getFornecedor(), this.getPaciente(), this.getUsuarioEsterilizavel()])
                .then(() => {
                    this.enriquecerEsterelizaveisComFornecedor();
                    this.enriquecerEsterelizaveisComPaciente();
                    this. enriquecerEsterelizaveisComUsuario();
                })
                .catch((error) => {
                    console.error("Erro ao carregar dados iniciais:", error);
                    this.$toast.error("Erro ao carregar dados iniciais.");
                })
                .finally(() => {
                    this.isTableLoading = false; // Desativa o loading após tudo ser carregado
                });
        },
        atualizar() {
            this.carregarDadosIniciais();
            this.search = ''; // Limpa o campo de busca
            this.clearFilters();
        },
        async getFornecedor() {
          try {
                const { data } = await api('fornecedores/fornecedores/')
                this.fornecedor = data
                // console.log('Fornecedor --< ',this.fornecedor)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro getFornecedor. ${error}`);
            } 
        },
        async getPaciente() {
            try {
                const { data } = await api('pacientes/esterilizaveis/');
                this.pacientes = data.filter(item => item.ativo)
                // console.log(this.pacientes)
                // const pacientesAgrupados = {};

                // Agrupa primeiro por paciente.id e data_solicitacao_retirada
                const retiradas = data.reduce((acc, paciente) => {
                    if (paciente.data_solicitacao_retirada && paciente.esterilizavel && paciente.ativo && paciente.disponibilidade?.id !== 14 && paciente.retirada === true) {
                        const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                        if (!acc[chave]) {
                            acc[chave] = true;
                        }
                    }
                    return acc;
                }, {});

                // Conta o número de chaves únicas (combinações únicas de paciente.id e data_solicitacao_retirada)
                this.quantidadePacientesElegiveisRetirada = Object.keys(retiradas).length;

                // Mesmo processo para solicitações de troca
                const trocas = data.reduce((acc, paciente) => {
                    if (paciente.data_solicitacao_troca && paciente.esterilizavel && paciente.troca && paciente.ativo && paciente.esterilizavel.disponibilidade !== 14 && paciente.esterilizavel.disponibilidade !== 13) {
                        const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                        if (!acc[chave]) {
                            acc[chave] = true;
                        }
                    }
                    return acc;
                }, {});

                this.quantidadePacientesElegiveisTroca = Object.keys(trocas).length;

               // Mesmo processo para solicitações de troca
                const requisicoes = data.reduce((acc, paciente) => {
                    if (paciente.data_requisicao && paciente.nome_esterilizavel && paciente.ativo && !paciente.data_implantacao && paciente.disponibilidade?.id === 3 && paciente.requisicao) {
                        const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                        if (!acc[chave]) {
                            acc[chave] = true;
                        }
                    }
                    return acc;
                }, {});

                this.quantidadePacientesElegiveis = Object.keys(requisicoes).length;



                const entregaRetirada = data.reduce((acc, paciente) => {
                  if (paciente.data_solicitacao_retirada !== null && paciente.retirada === true && paciente.disponibilidade?.id === 14 &&  paciente.esterilizavel && paciente.ativo === true) {
                      const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
                }, {});
                const entregaTroca = data.reduce((acc, paciente) => {
                  if (paciente.data_solicitacao_troca !== null && paciente.troca === true && (paciente.data_implantacao !== null) && paciente.ativo === true &&  paciente.esterilizavel.disponibilidade === 14 && (!paciente.data_solicitacao_retirada || paciente.data_solicitacao_retirada === '') && paciente.esterilizavel) {
                    const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
                }, {});
                // console.log(entregaTroca)
                const entregaRequisicao = data.reduce((acc, paciente) => {
                  if (!paciente.data_implantacao  &&  paciente.requisicao === true && paciente.disponibilidade?.id === 13 &&  paciente.esterilizavel &&  paciente.ativo === true) {
                      const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
                }, {});
                const entregaRetirada1 = Object.keys(entregaRetirada).length
                const entregaTroca1= Object.keys(entregaTroca).length
                const entregaRequisicao1= Object.keys(entregaRequisicao).length
      
                // Conta o número de chaves únicas (combinações únicas de paciente.id e data_solicitacao_retirada)
                this.quantidadePacientesElegiveisEntrega = entregaRetirada1 + entregaTroca1 + entregaRequisicao1;


                

            } catch (error) {
                console.log(error)
                this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
            }
        },
        async getEsterelizavel() {

            try {
                const response = await api.get('equipamentos/esterelizavels/')
                const ordemDisponibilidade = [4, 3, 7, 6, 9, 1, 2, 5]; // Ordem desejada
                // console.log(response.data)
                this.esterelizavel = response.data.sort((a, b) => {
                    if (a.ativo !== b.ativo) {
                        return b.ativo - a.ativo; // Ativos primeiro
                    }
                    // Ordenação pela disponibilidade
                    const indexA = ordemDisponibilidade.indexOf(a.disponibilidade?.id || -1);
                    const indexB = ordemDisponibilidade.indexOf(b.disponibilidade?.id || -1);

                    if (indexA !== indexB) {
                        return indexA - indexB; // Ordem por disponibilidade
                    }

                    // Se as disponibilidades forem iguais, ordena pelo nome do esterilizável
                    const nomeA = a.nome?.nome?.toLowerCase() || '';
                    const nomeB = b.nome?.nome?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
                // Pegar a data de hoje
                const hoje = new Date();
                hoje.setHours(0, 0, 0, 0); // Zerar horas para comparar apenas as datas

                // Filtrar itens que precisam de atenção na validade
                this.validade = this.esterelizavel.filter(item => {
                    // Verificar se não está em esterilização (id diferente de 7)
                    const naoEstaEmEsterilizacao = item?.disponibilidade?.id !== 7;
                    
                    // Verificar data de validade
                    if (item.data_validade_esterilizacao) {
                        const dataValidade = new Date(item.data_validade_esterilizacao);
                        dataValidade.setHours(0, 0, 0, 0);
                        const dataExpirada = dataValidade <= hoje;
                        
                        return naoEstaEmEsterilizacao && dataExpirada;
                    }
                    
                    return false;
                }).length;

                this.quantidadeEsterilizavelreceber = this.esterelizavel.filter(item => item.ativo && item?.disponibilidade?.id === 7).length;
                this.quantidadeEsterilizavelEnviar = this.esterelizavel.filter(item => item.ativo && item?.disponibilidade?.id === 10).length;

                // console.log('Quantidade de itens com validade expirada:', this.validade);
                // console.log('Esterelizavel ordenado -> ', this.esterelizavel);
                this.applyFilters()
            } catch(error) {
                console.error(error)
            } 
        },
        async getUsuarioEsterilizavel() {
            try {
                const { data } = await api('equipamentos/esterelizavel_usuarios/')
                this.usuariosEsterilizavel = data.filter(item => item.ativo)
                // console.log('Usuario --< ',this.usuariosEsterilizavel)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
            } 
            },
        enriquecerEsterelizaveisComFornecedor() {

            if (!this.esterelizavel || !this.fornecedor) return;

            this.esterelizavel = this.esterelizavel.map((item) => {
                if (item && item.esterilizacao_fornecedor) {
                    const fornecedor = this.fornecedor.find(
                        (f) => f.id === item.esterilizacao_fornecedor
                    );
                    item.esterilizacao_fornecedor_nome = fornecedor ? fornecedor.nome_fantasia : "N/A";
                } 
                return item;
            });

        },
        enriquecerEsterelizaveisComPaciente() {
            if (!this.esterelizavel || !this.pacientes) return;

            // Associa o nome do paciente ao esterilizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                const pacienteAssociado = this.pacientes.find(
                    (pac) => pac.esterilizavel?.id === item.id
                );

                item.paciente_nome = pacienteAssociado ? pacienteAssociado.paciente.nome : "";
                item.frequencia_nome = pacienteAssociado && pacienteAssociado.frequencia ? pacienteAssociado.frequencia.nome : '';
                item.paciente_dia_dispensacao_esterilizavel = pacienteAssociado ? pacienteAssociado.paciente.dia_dispensacao_esterilizavel : "";
                item.data_requisicaoPaciente = pacienteAssociado ? pacienteAssociado.data_requisicao : null;
                item.data_implantacaoPaciente = pacienteAssociado ? pacienteAssociado.data_implantacao : null;
                return item;
            });
        },
        enriquecerEsterelizaveisComUsuario() {
            if (!this.esterelizavel || !this.usuariosEsterilizavel) return;

            // Associa o nome do paciente ao esterilizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                const usuarioAssociado = this.usuariosEsterilizavel.find(
                    (pac) => pac.esterilizavel?.id === item.id
                );

                item.usuariosEsterilizavel_nome = usuarioAssociado ? `${usuarioAssociado.usuario.apelido} - ${usuarioAssociado.usuario.especialidade.nome}` : "";
                item.data_requisicaoUsuario = usuarioAssociado ? usuarioAssociado.data_requisicao : null;
                item.data_entregaUsuario = usuarioAssociado ? usuarioAssociado.data_entrega : null;
                return item;
            });
        },
        async createEsterilizavel(fields) {
            const LOADING_NAME = "post:esterilizavel"
            this.setLoading(LOADING_NAME)
            // console.log('Item Enviado --> ', fields)
            try {
                await api.post('equipamentos/esterelizavel_criar/', fields)
                this.$toast.success('Esterilizável Criado com Sucesso')
                this.dialog.create2 = false
                this.atualizar()
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async updateEsterilizavel(fields) {
            const LOADING_NAME = "put:esterilizavel"
            this.setLoading(LOADING_NAME)
            try {
                await api.put(`/equipamentos/esterelizavel_editar/${fields.id}/`, fields)
                 this.dialog.update = false
                this.$toast.success('Esterilizável Editado com Sucesso')
                this.atualizar()
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
       async updateEsterilizavelEnviar(fields) {
            const LOADING_NAME = "put:esterilizavelenviar";
            this.setLoading(LOADING_NAME);
            // console.log('Item Enviado --> ', fields)
            try {
                await api.put(`equipamentos/esterelizavel_editar/${fields.id}/`, fields);
                this.$toast.success('Esterilizável Enviado com Sucesso')
                this.dialog.enviar = false
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao atualizar o esterilizável ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
            }
        },
       async upadteEsterelizavelReceber(fields) {
            const LOADING_NAME = "post:esterilizacaoreceber"; 
            this.setLoading(LOADING_NAME);
            // console.log('Criando a Nota --> ', fields)
            try {
                await api.post(`/equipamentos/esterilizacao_criar/`, fields );
                this.dialog.create = false
                this.$toast.success('Esterilização atualizada com sucesso!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
       async updateEsterilizavelTrocar(fields) {
            const LOADING_NAME = "put:esterilizaveltrocar";
            this.setLoading(LOADING_NAME);
            try {
                await api.put(`/equipamentos/esterelizavel_editar/${fields.id}/`, fields);
                this.dialog.createTroca = false,
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao trocar o esterilizável ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.$toast.success('Esterilizável Trocado com Sucesso')
                this.atualizar(); // Atualiza a tabela
            }
        },
       async updateEsterilizavelRetirada(fields) {
        const LOADING_NAME = "put:esterilizavelretirada";
            this.setLoading(LOADING_NAME);
            try {
                await  api.put(`/equipamentos/esterelizavel_editar/${fields.id}/`, fields)
                this.$toast.success('Esterilizáveis retirados com sucesso!');
                this.dialog.retirada = false;
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao retirar os esterilizáveis:`, error);
                this.$toast.error(`Erro ao retirar os esterilizáveis: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
  }
        },
       async updateEsterilizavelReceber(fields) {
            const LOADING_NAME = "put:esterilizavelreceber";
            this.setLoading(LOADING_NAME);

            try {
                // Itera sobre cada item no array `fields` e envia individualmente
                for (const field of fields) {
                    try {
                        await api.post(`/equipamentos/esterelizavels/${field.id}/`, {
                            disponibilidade: field.disponibilidade,
                        });
                        console.log(`Esterilizável ${field.id} atualizado com sucesso.`);
                    } catch (error) {
                        console.error(`Erro ao atualizar o esterilizável ${field.id}:`, error);
                        this.$toast.error(
                            `Erro ao atualizar o item ${field.id}: ${error.message}`
                        );
                    }
                }
                this.$toast.success("Todos os itens foram processados com sucesso!");
                this.atualizar(); // Atualiza a tabela
            } catch (error) {
                console.error("Erro geral ao atualizar os itens:", error);
                this.$toast.error("Erro ao processar alguns itens.");
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async createEsterilizacao(fields) {
            const LOADING_NAME = "post:esterilizacao";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await  api.put(`/equipamentos/esterelizavel_editar/${fields.id}/`, fields)
                this.dialog.createRequisicao = false
                this.$toast.success('Esterilização criada com sucesso!!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async createEntrega(fields) {
            const LOADING_NAME = "post:entraga";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await  api.put(`/equipamentos/esterelizavel_editar/${fields.id}/`, fields)
                this.dialog.entrega = false
                this.$toast.success('Esterilização entregue com sucesso!!')
                this.atualizar()
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async createUsuario(fields) {
            const LOADING_NAME = "post:usuario";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await api.post(`/equipamentos/esterilizacao_criar/`, fields);
                // this.getEsterilizacao()
                this.dialog.create = false
                this.$toast.success('Esterilização criada com sucesso!!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
        async applyFilters() {
            this.search = '';
            const filters = JSON.parse(JSON.stringify(this.filters))
            this.activeFilters = Object.values(filters).filter(filter => filter.value !== undefined);
        },
        clearFilters() {
            Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
            this.filters = {
                is_active: { value: true, compareType: 'equal', prop: 'ativo' }
            }

            this.activeFilters = [{ value: true, compareType: 'equal', prop: 'ativo' }]
        },
        calcularDiasRestantes(item) {
            if (!item.qtd_dias || !item.nome || !item.nome.vida_util) return 0;
            const diasRestantes = item.nome.vida_util - item.qtd_dias;
            return diasRestantes > 0 ? diasRestantes : 0;
        },
    },
    computed: {
        itemsStatus() {
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            
            // Initialize all counters
            let vencidos = 0;
            let proximosVencer = 0;
            let vidaUtilVencida = 0;
            let proximoVidaUtil = 0;
            let semVidaUtil = 0;  // New counter

            this.filteredData.forEach(item => {
                const naoEstaEmEsterilizacao = item.disponibilidade?.id !== 7;
                
                // Check sterilization expiration
                if (item.data_validade_esterilizacao && naoEstaEmEsterilizacao) {
                    const dataValidade = new Date(item.data_validade_esterilizacao);
                    dataValidade.setHours(0, 0, 0, 0);
                    
                    if (dataValidade <= hoje) {
                        vencidos++;
                    } else {
                        const diasAteVencer = Math.ceil((dataValidade - hoje) / (1000 * 60 * 60 * 24));
                        if (diasAteVencer <= 45) {
                            proximosVencer++;
                        }
                    }
                }

                // Check useful life
                if (item.nome) {
                    if (!item.nome.vida_util) {
                        semVidaUtil++;  // Count items missing vida_util
                    } else if (item.qtd_dias) {
                        const diasRestantes = item.nome.vida_util - item.qtd_dias;
                        if (diasRestantes <= 0) {
                            vidaUtilVencida++;
                        } else if (diasRestantes <= 90) {
                            proximoVidaUtil++;
                        }
                    }
                }
            });

            return {
                vencidos,
                proximosVencer,
                vidaUtilVencida,
                proximoVidaUtil,
                semVidaUtil
            };
        },

        headersWithValidadeInfo() {
            const updatedHeaders = [...this.headers];
            const validadeIndex = updatedHeaders.findIndex(
                header => header.value === "data_validade_esterilizacao"
            );

            if (validadeIndex !== -1 && this.itemsVencidos > 0) {
                updatedHeaders[validadeIndex] = {
                    ...updatedHeaders[validadeIndex],
                    text: `Data da Validade Esterilização ( ${this.itemsVencidos} vencido(s) )`
                };
            }

            return updatedHeaders;
        },


    filteredData() {
        let data = this.esterelizavel.filter(item => item && item.id);
        // console.log('data-->',data)

        // Aplica filtros ativos
        const filterBy = UtilsFunc.createFilter(this.activeFilters);
        data = data.filter(filterBy);

        // Se houver um termo de pesquisa, filtra adicionalmente
        if (this.search) {
            const searchTerm = this.search.toLowerCase().trim();
            data = data.filter(item => {
                // Verifica e registra cada campo de pesquisa
                const usuarioNome = item.usuariosEsterilizavel_nome || '';
                const nomeEsterilizavel = item.nome?.nome || '';
                const pacienteNome = item.paciente_nome || '';
                const frequenciaNome = item.frequencia_nome || '';
                const esterilizadoraNome = item.esterilizacao_fornecedor_nome || '';

                // Quebra o nome do usuário em partes
                const usuarioParts = usuarioNome.toLowerCase().split(' ');
                const searchParts = searchTerm.toLowerCase().split(' ');

                // Verifica se pelo menos uma parte corresponde
                const matchUsuarioNome = usuarioParts.some(part => 
                    searchParts.some(search => part.includes(search))
                );
                const matchNomeEsterilizavel = nomeEsterilizavel.toLowerCase().includes(searchTerm);
                const matchPacienteNome = pacienteNome.toLowerCase().includes(searchTerm);
                const matchFrequenciaNome = frequenciaNome.toLowerCase().includes(searchTerm);
                const matchPEsterilizadoraeNome = esterilizadoraNome.toLowerCase().includes(searchTerm);
                const matchId = item?.id.toString().includes(searchTerm);
                

                return matchUsuarioNome || 
                       matchNomeEsterilizavel || 
                       matchPacienteNome || 
                       matchFrequenciaNome || 
                       matchId ||
                       matchPEsterilizadoraeNome;
            });
        }

        return data;
    },
    },
   mounted() {
        this.carregarDadosIniciais();
        
    }

}
</script>

<style scoped>
.badge-red {
  position: absolute;
  top: -8px; /* Ajuste fino da posição vertical */
  right: -8px; /* Ajuste fino da posição horizontal */
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.mr-2 {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.status-text {
    font-size:  12px;
    line-height: 1.1;
}

.text-xs {
    font-size:  12px;
}

.compact-btn {
    font-size: 12px !important;
    letter-spacing: -0.2px !important;
    padding: 0 12px !important;
    height: 42px !important;
}

.compact-btn-text {
    font-size: 12px !important;
    line-height: 1.2 !important;
    text-align: center !important;
}

.compact-icon {
    font-size: 18px !important;
    margin-right: 4px !important;
}

.button-group {
    gap: 10px !important;
}
</style>



<template>
  <div class="pa-4">
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <div v-if="isLoadingData" class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <!-- Campo de pesquisa -->
        <v-row class="mb-4">
          <v-col cols="8">
            <v-text-field v-model="searchTerm" label="Pesquisar esterilizável" outlined dense hide-details clearable placeholder="Digite o nome do esterilizável..." ></v-text-field>
          </v-col>
          <v-col class="mt-2">
            <v-btn small dense style="color: red; font-weight: bold;" @click="limpar">Limpar Seleção</v-btn>
          </v-col>
        </v-row>

        <div class="grid-container">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="4"
                  v-for="(item) in filteredEsterilizavel"
                  :key="item.id"
                  class="mb-4"
                >
                  <!-- Cada item é um card -->
                  <v-card outlined class="pa-2 w-100 standard-card">
                    <v-row class="d-flex align-center equipamento-row">
                      <!-- Checkbox para selecionar -->
                      <v-col cols="1" class="d-flex justify-center mb-3">
                        <v-checkbox v-model="item.selecionado" :value="true" hide-details></v-checkbox>
                      </v-col>
                      <!-- Nome do esterilizável -->
                      <v-col cols="5" class="equipamento-nome-col">
                        <span class="wrap-text font-weight-bold">{{ item.nome }}</span>
                      </v-col>

                      <!-- Frequência de troca -->
                      <v-col cols="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-autocomplete
                              dense
                              label="Frequência"
                              v-model="item.frequencia"
                              :items="frequencia"
                              item-text="nome"
                              item-value="id"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              :rules="requiredField"
                            ></v-autocomplete>
                          </template>
                          <span>
                            Escolha a frequência com que o sistema irá gerar automaticamente uma troca.
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row class="justify-end dense mt-5">
          <v-col cols="3" class="equipamento-nome-col">
            <v-text-field v-model="DataPrevista" label="Data Prevista para Implantação" type="date" outlined dense hide-details :rules="requiredField"> </v-text-field>
          </v-col>
          <v-col class="text-end">
            <v-tooltip top :disabled="isFormValid">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <ConfirmButton
                    :color="isFormValid ? 'success' : 'grey'"
                    :loading="isSubmitting"
                    :disabled="!isFormValid || isSubmitting"
                  >
                    Salvar
                  </ConfirmButton>
                </span>
              </template>
              <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>


<script>
import api from '@/http';
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "NovoEquipamento",
  data: () => withCRUDUtils({
    isSubmitting: false,
    isLoadingData: true,
    loadingFrequencia: false,
    frequencia: [],
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    esterilizavel: [],
    searchTerm: '',
    frequenciaOptions: [
        { text: 'Sem programação', value: null },
        { text: 'Semanal', value: 7 },
        { text: 'Quinzenal', value: 15 },
        { text: 'Mensal', value: 30 },
        { text: 'Trimestral', value: 90 },
        { text: 'Semestral', value: 180 }
      ],
      // dataPrevistaImplantacao: new Date().toISOString().slice(0, 10),
  }),
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    paciente_id: Number,

  },
  computed: {
    isFormValid() {
      return this.esterilizavel.some(esterilizavel => esterilizavel.selecionado );
    },
    filteredEsterilizavel() {
      if (!this.searchTerm) {
        return this.esterilizavel;
      }
      return this.esterilizavel.filter(item =>
        item.nome.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

  },
  methods: {
    async localOnSubmit() {
      const selectedEsterilizaveis = this.esterilizavel.filter(item => item.selecionado);

      // Validação dos campos apenas para os itens selecionados
      const isValid = selectedEsterilizaveis.every(item => item.data_prevista_implantacao );

      if (!isValid) {
        this.$toast.error("Por favor, preencha a data prevista de implantação e a frequência para os itens selecionados!");
        return;
      }
      const hoje = new Date();
        const dataFormatada = hoje.getFullYear() + '-' +
          String(hoje.getMonth() + 1).padStart(2, '0') + '-' +
          String(hoje.getDate()).padStart(2, '0');


      this.isSubmitting = true;
      const field = selectedEsterilizaveis.map(item => ({
        paciente: this.paciente_id,
        nome_esterilizavel: item.id,
        data_requisicao: dataFormatada,
        disponibilidade: 3,
        data_prevista_implantacao: item.data_prevista_implantacao,
        requisicao: true,
        // dias_troca: item.dias_troca,
        frequencia: item.frequencia
      }));

      // console.log('Dados enviados:');
      // console.log(field);

      await this.onSubmit(field).finally(() => {
        this.isSubmitting = false; 
      });
    },
    async getNomeEsterilizavel() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      this.isLoadingData = true;
      try {
        const { data } = await api.get('/equipamentos/nomeesterelizavels/');
        this.esterilizavel = data.filter(item => item.ativo).sort((a, b) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
        });
        // Adicione este bloco de código
        const hoje = new Date();
        const amanha = new Date(hoje);
        amanha.setDate(hoje.getDate() + 1);
        const dataFormatada = amanha.getFullYear() + '-' +
          String(amanha.getMonth() + 1).padStart(2, '0') + '-' +
          String(amanha.getDate()).padStart(2, '0');

        this.esterilizavel.forEach(item => {
          item.data_prevista_implantacao = dataFormatada;
          item.frequencia = 11; // frequencia padrão ao montar a tela
        });
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
        this.isLoadingData =false;
      }
    },
    async getFrequencia() {
      this.loadingFrequencia = true
      try {
        const { data } = await api.get("/atendimentos/frequencias/");
        this.frequencia = data;
        // console.log(this.frequencia)
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.loadingFrequencia = false
      }
    },
    limpar() {
      this.esterilizavel = this.esterilizavel.map(item => ({
        ...item,
        selecionado: false
      }));
    }
  },
  watch: {
    esterilizavel: {
      deep: true,
      handler() {
        // Garante que a validação do formulário será reavaliada
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.getNomeEsterilizavel();
    this.getFrequencia()
    const hoje = new Date();
    const dataFormatadaAmanha = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate() +1).padStart(2, '0');
    this.DataPrevista = dataFormatadaAmanha

  },
  
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.equipamento-row {
  align-items: center;

}

.checkbox-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipamento-nome-col,
.disponibilidade-col {
  display: flex;
  align-items: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ajuste conforme necessário */
}

.wrap-text .v-input__control {
  white-space: normal; /* Permite quebra de linha */
  overflow-wrap: break-word; /* Faz a quebra de palavras longas */
  word-wrap: break-word; /* Suporte adicional para navegadores antigos */
  max-height: 100px; /* Define uma altura máxima, ajustável conforme necessário */
  overflow-y: auto; /* Adiciona rolagem vertical caso o texto seja muito longo */
}

.standard-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

</style>

<template>
  <div class="px-4">
    <!-- Parte Superior: Local para Carregar o documento com o tipo -->
    <h2 class="blod_color">Carregar documentos:</h2>
    <v-row dense class="my-3">
      <v-col md="3">
        <v-autocomplete outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id">
          <template v-slot:label>
            <span>Tipo de documento <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="4">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput">
          <template v-slot:label>
            <span>Anexar documento <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
      <v-col md="3">
        <v-tooltip top :disabled="!!(selectedDocumentType && selectedFile)">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading" :disabled="!isFormValid || isUploading">
                <v-icon left>mdi-upload</v-icon>
                Enviar documento
              </v-btn>
            </span>
          </template>
          <span>Escolha o "Tipo de documento" e carregue o arquivo para habilitar este botão.</span>
        </v-tooltip>
      </v-col>
      <v-col md="2" >
        <v-btn class="mr-4 elevation-0" text @click="atualizar" >
          <v-icon left>
            mdi-reload
          </v-icon> 
          atualizar página
        </v-btn>
      </v-col>
    </v-row>

    <!-- Parte inferior: listar e baixar documentos -->
    <h2 class="blod_color mt-5 mb-5">Documentos carregados:</h2>
    <v-list dense>
      <v-progress-linear v-if="isUploading" loading-text="Carregando Informações...." :indeterminate="true" color="blue"></v-progress-linear>
      <v-list-item-group v-for="(doc, index) in uploadedDocuments" :key="index">
        <v-list-item class="my-2">
          <v-list-item-content>
            <v-list-item-title class="mb-2">
              <span class="font-weight-bold text-primary blod_color">{{ doc.tipodoc.nome }}</span>
              <br>
              <span>{{ extractFileName(doc.arquivo) }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              Carregado em: {{ formatDate(doc.data_carregado) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small color="green" class="white--text" :href="doc.arquivo" target="_blank">
              <v-icon left>mdi-download</v-icon>
              Baixar
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import '../../../../assets/css/styles.css';
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
  data: () => withCRUDUtils({
      selectedDocumentType: null,
      documentTypes: [],
      uploadedDocuments: [],
      isUploading: false,
      isActive: true,
      selectedFile: null,
  }),
  props: {
    pacienteId: { type: Number }
  },
  methods: {
    async getDocumentTypes() {
      const LOADING_NAME = "get:documentTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tiposdocs/");
        this.documentTypes = data.filter(doctype => doctype.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async uploadDocument() {
      if (!this.selectedDocumentType || !this.selectedFile) {
        this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
        return;
      }

      this.isUploading = true;

      let formData = new FormData();
      formData.append("arquivo", this.selectedFile);
      formData.append("tipodoc", this.selectedDocumentType);
      formData.append("ativo", this.isActive);
      formData.append("paciente", this.pacienteId);
      const currentDate = new Date().toISOString().split('T')[0];
      formData.append("data_carregado", currentDate);

      try {
        await api.post("/pacientes/docs/", formData);
        this.$toast.success("Documento carregado com sucesso!");
        this.selectedFile = null;
        this.selectedDocumentType = null;
        this.fetchUploadedDocuments();
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.isUploading = false;
      }
    },
    async fetchUploadedDocuments() {
      this.isUploading = true;
      try {
        const { data } = await api.get(`/pacientes/docs/?paciente=${this.pacienteId}`);
        this.uploadedDocuments = data.sort((a, b) => new Date(b.data_carregado) - new Date(a.data_carregado));
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os documentos. Erro: ${error}`);
      } finally {
        this.isUploading = false;
      }
    },
    extractFileName(url) {
      if (url) {
        return url.split('/').pop();
      }
      return "";
    },
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 2000000) { // maior que 2MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (2MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
    atualizar() {
      this.fetchUploadedDocuments();
    },
  },
  created() {
    this.getDocumentTypes();
  },
  mounted() {
    this.fetchUploadedDocuments();
  },
  computed: {
    isFormValid() {
      return this.selectedDocumentType && this.selectedFile;
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
};
</script>

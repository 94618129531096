<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" class="mt-5" >
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" 
        :disabled="resumoPacientes.length === 1 && (resumoPacientes[0].id === 'no-patients' || resumoPacientes[0].id === 'error')"
        :messages="resumoPacientes.length === 1 && resumoPacientes[0].id === 'no-patients' ? 'Não há pacientes com solicitações de retirada pendente' : ''"
        class="custom-messages"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Retirada" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data Presvista de  Retirada" disabled v-model="dataPresvista" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados a Retirada:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" item-value="id" show-select v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1"  >
        <template v-slot:[`item.esterilizavel`]="{ item }">
          {{ item.esterilizavel?.nome }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
    </v-data-table>
      </v-col>
    </v-row>
    <v-row dense >
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Nova Disponibilidade" v-model="nova_disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id"/>
      </v-col>
       <v-col cols="3">
        <v-text-field dense outlined label="Data da Retirada"  v-model="dataRetiradaEfetivada" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
      <v-col class="text-end">
        <ConfirmButton class="mr-4 ml-5 elevation-0" :onConfirm="cancelarEntrega" :loading="isLoadingaCancelar" color="red" :disabled="!isSaveEnabledCancelar" :style="{ opacity: isSaveEnabledCancelar ? 1 : 0.5 }" > Cancelar Entrega  </ConfirmButton>
        <ConfirmButton :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"> Confirmar Entrega</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "RetirarEsterilizaveis",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingDisponibilidade: false,
      isLoadingaCancelar: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPresvista: "",
      dataImplantacao: "",
      dataRetiradaEfetivada: '',
      nova_disponibilidade: null,
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      disponibilidade: [],
      isLoadingDados: false,
      ItemPacientelHeaders: [
        { text: "Id Esterilizável Retirado", value: "esterilizavel.id" },
        { text: "Esterilizável", value: "esterilizavel" },
        // { text: "Quantidade Solicitada", value: "quantidade" },
      ],
    });
  },
  components: { ConfirmButton },
  methods: {
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo && [6, 10].includes(item.id))
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        // Filtra primeiro os registros relevantes
        this.pacientes = response.data.filter(item => 
          item.data_solicitacao_retirada !== null && 
          item.disponibilidade.id === 14 &&
          item.esterilizavel &&
          item.retirada === true &&
          item.ativo === true
        );
        if (this.pacientes.length === 0) {
            this.resumoPacientes = [{
                id: 'no-patients',
                // nome: 'Não há pacientes com solicitações de troca pendentes'
            }];
        } else {
        // Agrupamento mantendo esterilizáveis separados
        const agrupamento = this.pacientes.reduce((acc, atual) => {
          const dataReq = atual.data_solicitacao_retirada.split('T')[0];
          const chave = `${atual.paciente.id}-${dataReq}`;
          
          if (!acc[chave]) {
            const data = new Date(atual.data_solicitacao_retirada);
            data.setDate(data.getDate() + 1);
            const dataFormatada = data.toLocaleDateString('pt-BR');
            
            acc[chave] = {
              id: chave,
              idEsterilizavelPaciente: atual.id, 
              pacienteId: atual.paciente.id,
              nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
              esterilizavel: [{
                id: atual.id,
                esterilizavel: { nome: atual.esterilizavel.nome.nome , id: atual.esterilizavel.id },
                quantidade: 1
              }],
              data_solicitacao_retirada: atual.data_solicitacao_retirada,
              data_prevista_solicitacao_retirada: atual.data_prevista_solicitacao_retirada,
              data_prevista_implantacao: atual.data_prevista_implantacao
            };
          } else {
            acc[chave].esterilizavel.push({
              id: atual.id,
              idEsterilizavelPaciente: atual.id,
              esterilizavel: {  nome: atual.esterilizavel.nome.nome, id: atual.esterilizavel.id  },
              quantidade: 1
            });
          }
          return acc;
        }, {});
        // Converte para array final
        this.resumoPacientes = Object.values(agrupamento)
          .map(item => ({
            id: item.id,
            pacienteId: item.pacienteId,
            nome: item.nome,
            esterilizavel: item.esterilizavel,
            data_solicitacao_retirada: item.data_solicitacao_retirada,
            data_prevista_solicitacao_retirada: item.data_prevista_solicitacao_retirada,
            data_prevista_implantacao: item.data_prevista_implantacao
          }))
          .sort((a, b) => {
            const dataA = new Date(a.data_solicitacao_retirada);
            const dataB = new Date(b.data_solicitacao_retirada);
            return dataA - dataB;
          });
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
      
    },
    updatePacienteData() {
      
      const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
        );
        
        if (paciente) {
          this.dataRequisicao = paciente.data_solicitacao_retirada || "";
          this.dataPresvista = paciente.data_prevista_solicitacao_retirada || "";
          
          this.filterPaciente = paciente.esterilizavel.map(item => ({
            id: item.id,
            esterilizavel: {
              id: item.esterilizavel.id,
              nome: item.esterilizavel.nome,
            },
            quantidade: item.quantidade
          }));
        } else {
          this.dataRequisicao = "";
          this.dataPresvista = "";
          this.filterPaciente = [];
        }
      },
    async updateEsterelizavel() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
            id: item.esterilizavel.id,
            disponibilidade: this.nova_disponibilidade,
          })
        );
        await Promise.all(promises); // Aguarda todas as requisições serem concluídas
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async updateEsterelizavelCancelar() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.esterilizavel.id}/`, {
            id: item.esterilizavel.id,
            disponibilidade: 12,
          })
        );
        await Promise.all(promises); // Aguarda todas as requisições serem concluídas
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async updatePaciente() {
        try {
            const promises = this.selectedPacienteItems.map((item) =>
                api.put(`/pacientes/esterilizavel/${item.id}/`, {
                    id: item.id,
                    ativo: false,
                    disponibilidade: 18,
                    data_retirada: this.dataRetiradaEfetivada
                })
            );
            await Promise.all(promises); // Aguarda todas as requisições serem concluídas
        } catch (error) {
            console.error("Erro ao atualizar Esterilizável do Paciente:", error);
            this.$toast.error("Erro ao atualizar Esterilizável do Paciente dos esterilizáveis.");
        }
    },
    async updatePacienteCancelar() {
        try {
            const promises = this.selectedPacienteItems.map((item) =>
                api.put(`/pacientes/esterilizavel/${item.id}/`, {
                    id: item.id,
                    ativo: true,
                    disponibilidade: 12,
                })
            );
            await Promise.all(promises); // Aguarda todas as requisições serem concluídas
        } catch (error) {
            console.error("Erro ao atualizar Esterilizável do Paciente:", error);
            this.$toast.error("Erro ao atualizar Esterilizável do Paciente dos esterilizáveis.");
        }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      await this.updateEsterelizavel();
      await this.updatePaciente();  
      this.$toast.success('Atualziado com sucesso!');
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      
    },
    async cancelarEntrega() {
      this.isLoadingaCancelar = true
      await this.updateEsterelizavelCancelar();
      await this.updatePacienteCancelar();  
      this.$toast.success('Cancelado com sucesso!');
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      this.isLoadingaCancelar = false
    },
    
    atualizarDados() {
      this.getPacientes()
      this.getDisponibilidade()
      this.nova_disponibilidade = null
      this.pacienteSelecionado = null
      this.dataRequisicao = ''
      this.dataPresvista = ''
      this.selectedPacienteItems = []
      this.filterPaciente = [];


    },
  },
  computed: {
    isSaveEnabled() {
      return (
        this.selectedPacienteItems.length > 0 && 
        this.nova_disponibilidade !== null
      );
    },
    isSaveEnabledCancelar() {
      return (
        this.selectedPacienteItems.length > 0 
      );
    }
  },
  mounted() {
    this.getDisponibilidade()   
    this.getPacientes();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.dataRetiradaEfetivada = dataFormatada
  },
};
</script>

<style scoped>
.custom-messages ::v-deep .v-messages__message {
    color: red !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>
import { render, staticRenderFns } from "./PatientExpand.vue?vue&type=template&id=5b5f0698&scoped=true"
import script from "./PatientExpand.vue?vue&type=script&lang=js"
export * from "./PatientExpand.vue?vue&type=script&lang=js"
import style0 from "./PatientExpand.vue?vue&type=style&index=0&id=5b5f0698&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5f0698",
  null
  
)

export default component.exports
<template>
  <div>
    <v-row dense>
      <v-col xs="12" sm="12" md="3" lg="3">
        <v-row dense>
          <v-col xs="6" sm="6" md="12">
            <v-card outlined>
              <v-avatar size="100%" height="200" tile>
                <span v-if="!avatarPreview" class="text-center" clearable>
                  Foto do Perfil
                </span>
                <v-img v-else :src="avatarPreview" contain></v-img>
              </v-avatar>
              <v-divider />
              <div class="px-2 pt-4">
                <v-file-input accept="image/png, image/jpeg, image/bmp" label="Foto do perfil" outlined dense show-size
                  counter @change="(e) => onFileSelected(e, 'avatar')" />
              </div>
            </v-card>
          </v-col>

          <v-col xs="6" sm="6" md="12">
            <v-card outlined>
              <v-avatar size="100%" tile height="120">
                <span v-if="!stampPreview" class="text-center" clearable>
                  Imagem do carimbo
                </span>
                <v-img v-else :src="stampPreview" contain></v-img>
              </v-avatar>
              <v-divider />
              <div class="px-2 pt-4 ">
                <v-file-input counter accept="image/png, image/jpeg, image/bmp" show-size label="Imagem do carimbo" dense
                  outlined @change="(e) => onFileSelected(e, 'stamp')">
                </v-file-input>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col xs="12" sm="12" md="9">
        <!--Dados gerais da equipe-->
        <v-form @submit.prevent="putPersonalData" ref="formPersonalData">
          <v-row dense class="form-row">
            <v-col cols="2">
              <v-text-field v-model="currentItem.apelido" :rules="requiredField" clearable outlined dense>
                <template v-slot:label>
                  <span>Nome (apelido) <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="currentItem.first_name" :rules="requiredField" clearable outlined dense @input="formatPatientName">
                <template v-slot:label>
                  <span>Nome completo <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="currentItem.username" :rules="requiredField" clearable outlined dense>
                <template v-slot:label>
                  <span>Usuário <span style="color: red;">*</span></span>
                </template>  
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="currentItem.data_contrato" label="Data início do contrato" type="date" clearable  outlined dense>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="currentItem.data_fim_contrato" label="Data fim do contrato" type="date" clearable  outlined dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-autocomplete dense label="Função" outlined v-model="currentItem.groups" :items="groups" item-value="id"
                item-text="name" multiple return-object clearable :rules="requiredField" >
                <template v-slot:label>
                  <span>Função <span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="currentItem.cpf" v-mask="'###.###.###-##'" clearable
                :rules="combinedCpfRules" outlined dense>
                <template v-slot:label>
                  <span>CPF <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="currentItem.rg" clearable :rules="requiredField" outlined dense>
                <template v-slot:label>
                  <span>RG <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="currentItem.org_exped" dense outlined :items="orgao_exp" return-object
                label="Orgão Exp" item-value="id" item-text="nome" clearable />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <v-autocomplete dense outlined v-model="currentItem.zona" :items="zonas" item-value="id"
                item-text="nome" multiple clearable :rules="requiredField" >
                <template v-slot:label>
                  <span>Zona de atendimento <span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.tel" v-mask="'(##) #####-####'" clearable
                :rules="requiredField" outlined dense>
                <template v-slot:label>
                  <span>Telefone <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.email" label="E-mail" clearable :rules="[...requiredField, emailValidation]" outlined dense>
                <template v-slot:label>
                  <span>E-mail <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.nascimento" type="date" clearabl
                :rules="requiredField" outlined dense>
                <template v-slot:label>
                  <span>Data de nascimento <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete outlined v-model="currentItem.conselho" return-object :items="conselhos"
                item-value="id" item-text="nome" dense clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Conselho <span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="currentItem.uf_inscricao_conselho" outlined return-object :items="ufs"
                item-text="sigla" item-value="id" dense clearable :rules="requiredField">
                <template v-slot:label>
                  <span>UF insc conselho <span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.nconselho" clearable :rules="requiredField" outlined dense>
              <template v-slot:label>
                  <span>Nº do conselho <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete v-model="currentItem.especialidade" return-object :items="especialidades" item-value="id"
                item-text="nome" outlined dense clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Especialidade <span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-select
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :items="statusOptions"
                    label="Assinatura do Paciente Obrigatória"
                    item-text="label"
                    item-value="value"
                    v-model="currentItem.assinatura_obrigatoria"
                    outlined
                    dense
                  />
                </template>
                <span>Este campo indica se a assinatura do paciente na Evolução é obrigatória</span>
              </v-tooltip>
            </v-col>

          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="currentItem.razao_social" label="Razão social" clearable dense outlined ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.nome_fantasia" label="Nome fantasia" clearable outlined dense></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="currentItem.cnpj" label="CNPJ" v-mask="'XX.XXX.XXX/XXXX-XX'" clearable outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea outlined label="Observações" v-model="currentItem.obs" clearable></v-textarea>
            </v-col>
          </v-row>
          <template>
            <v-row dense>
              <v-col cols="2">
                <h4 class="text-h6 mb-2 ml-5">
                  Valor Extra
                </h4>
              </v-col>
              <v-col>
                <v-text-field v-model="currentItem.justificativa_valor_extra" label="Motivo do valor extra (Mensal)" clearable outlined dense></v-text-field>
              </v-col>
              <v-col>
                <vuetify-money dense v-model="currentItem.valor_extra" label="Valor extra (Mensal)" outlined clearable
                  valueWhenIsEmpty=" " :options="options"/>
              </v-col>
              <!-- <v-col> 
                <v-autocomplete dense  v-model="currentItem.periodo" outlined label="Periodicidade do Valor Extra" />
              </v-col> -->
            </v-row>
          </template>
          <v-row dense>
            <v-col>
              <v-btn-toggle v-model="isActive" mandatory class="white--text">
                <v-btn :color="!isActive ? 'error' : ''">
                  Inativo
                </v-btn>
                <v-btn :color="isActive ? 'green' : ''">
                  Ativo
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="text-end">
              <v-tooltip top :disabled="isFormValid"> <!-- Tooltip ativado somente se o formulário não estiver válido -->
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <ConfirmButton :color="isFormValid ? 'success' : 'grey'" 
                                  :loading="isSubmitting" 
                                  :disabled="!isFormValid || isSubmitting">
                      Salvar
                    </ConfirmButton>
                  </span>
                </template>
                <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
        <v-divider class="my-4" />
        <!--Tabela de valores-->
        <!-- <v-card> -->
        <v-data-table :headers="headers" :items="tableValues" :loading="isLoading('get:tableValues')"
          :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc" show-select v-model="selectedRows">
          <template v-slot:top>
            <v-row dense >
              <v-col>
                <h4 class="text-h6 mb-2">
                  Tabela valores
                </h4>
              </v-col>
              <v-col class="text-end">
                <v-btn color="info" class="elevation-0 mr-2" @click="dialog.readjustment = true"
                  :disabled="selectedRows.length <= 0">
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>
                  Reajuste
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip class="ma-2" :color="item.status ? 'blue' : 'grey'">
              <span class="white--text">{{
                item.status ? "Ativo" : "Inativo"
              }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
          <template v-slot:[`item.seg_sex`]="{ item }">
            {{ item.seg_sex | formatReal }}
          </template>
          <!-- Colocar o formato de valores -->
          <template v-slot:[`item.sab_dom_fer`]="{ item }">
            {{ item.sab_dom_fer | formatReal }}
          </template>
          <template v-slot:[`item.inicio_vigencia`]="{ item }">
            {{ formatDate(item.inicio_vigencia) }}
          </template>
          <template v-slot:[`item.fim_vigencia`]="{ item }">
            {{ formatDate(item.fim_vigencia) }}
          </template>
          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-simple-checkbox 
                  :disabled="!item.ativo" 
                  :value="isSelected" 
                  @click="item.ativo ? select(!isSelected) : ''" 
                  v-on="on" 
                  v-bind="attrs">
                </v-simple-checkbox>
              </template>
              <span>Selecione para realizar o reajuste</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="600">
        <v-card>
          <v-card-title class="blod_color">
            Novo valor:
            <v-spacer />
            <v-btn icon @click="dialog.create = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <DadosPessoaisForm :currentItem="newTableValue" :onSubmit="postTableValues"
              :loading="isLoading('post:tableValues')" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="600">
        <v-card>
          <v-card-title class="blod_color">
            Editar valor
            <v-spacer />
            <v-btn icon @click="dialog.update = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <DadosPessoaisFormEditar :currentItem="selectedRow" :onSubmit="putTableValues"
              :loading="isLoading('put:tableValues')" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="dialog.readjustment" v-model="dialog.readjustment" max-width="600">
        <v-card>
          <v-card-title class="blod_color">
            Reajustar valor
            <v-spacer />
            <v-btn icon @click="dialog.readjustment = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <ReadjusmentTeamPrice :selectedRows="selectedRows" :onSubmit="handleAjustMulitplePrices" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import api from "@/http";
import ConfirmButton from "../../../ConfirmButton.vue";
import UtilsFunc from "../../../../service/utilsFunc";
import DadosPessoaisForm from "./DadosPessoaisForm.vue";
import DadosPessoaisFormEditar from "./DadosPessoaisFormEditar.vue";
import ReadjusmentTeamPrice from "./ReadjusmentTeamPrice.vue";
import '../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "DadosPessoais",
  props: {
    currentItem: Object,
    onUpdate: Function,
  },
  data: () =>
    withCRUDUtils({
      newTableValue: {
        status: 1,
      },
      defaultSort: {
        name: "status",
        isDesc: true,
      },
      dialog: {
        readjustment: false
      },
      selectedRows: [],
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      groups: [],
      conselhos: [],
      especialidades: [],
      ufs: [],
      orgao_exp: [],
      localidades: [],
      tableValues: [],
      filesToUpload: {},
      avatarPreview: "",
      stampPreview: "",
      isSubmitting: false,
      zonas: [],
      requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
      statusOptions: [
          { label: "Sim", value: true },
          { label: "Não", value: false }
      ],
      headers: [
        { text: "Editar", value: "acoes", sortable: false },
        { text: "Estado", value: "localidade.uf.sigla" },
        { text: "Município", value: "localidade.localidade" },
        { text: "Seg a Sex", value: "seg_sex" },
        { text: "Sab, Dom e feriados", value: "sab_dom_fer" },
        { text: "Início de vigência", value: "inicio_vigencia" },
        { text: "Fim de vigência", value: "fim_vigencia" },
        { text: "Status", value: "status" },
      ],
    }),
  computed: {
    combinedCpfRules() {
      return [
        ...this.requiredField,
        v => !v || this.validateCPF(v) || 'CPF inválido'
      ];
    },
    isActive: {
      get() {
        return this.currentItem.is_active ? 1 : 0;
      },
      set(value) {
        return (this.currentItem.is_active = Boolean(value));
      },
    },
    isFormValid() {
    // Verifica se todos os campos obrigatórios estão preenchidos
    return this.currentItem.apelido && 
      this.currentItem.first_name &&
      this.currentItem.username &&
      this.currentItem.cpf &&
      this.currentItem.rg &&
      this.currentItem.tel &&
      this.currentItem.email &&
      this.currentItem.nascimento &&
      this.currentItem.conselho &&
      this.currentItem.uf_inscricao_conselho &&
      this.currentItem.nconselho &&
      this.currentItem.especialidade &&
      this.currentItem.zona.length > 0 &&
      this.currentItem.groups.length > 0; 
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    assinaturaObrigatoria: {
      get() {
          console.log('Get assinatura_obrigatoria:', this.currentItem.assinatura_obrigatoria);
          return this.currentItem.assinatura_obrigatoria;
      },
      set(value) {
          console.log('Set assinatura_obrigatoria:', value);
          this.currentItem.assinatura_obrigatoria = value;
      }
    }
  },
  methods: {
    formatPatientName() {
      if (this.currentItem && this.currentItem.first_name) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.currentItem.first_name = this.currentItem.first_name
          .toLowerCase()
          .split(" ")
          .map((word, index) => {
            // Mantém a primeira palavra em maiúsculas mesmo que esteja na lista
            if (index === 0 || !lowercaseWords.includes(word)) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word; // Retorna as palavras em minúsculas se estiverem na lista
          })
          .join(" ");
      }
    },
    onFileSelected(event, fieldName) {
      const currentItemField = fieldName === "avatar" ? "avatar" : "carimbo";
      const previewName =
        fieldName === "avatar" ? "avatarPreview" : "stampPreview";
      if (!event) {
        const defaultFile = this.currentItem[currentItemField];
        this[previewName] = defaultFile;
      } else {
        this.filesToUpload[fieldName] = event;
        this[previewName] = window.URL.createObjectURL(event);
      }
    },
    async putPersonalData() {
      const isValidForm = this.$refs.formPersonalData.validate()
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      this.isSubmitting = true;
      const alreadyRegisteredGroups = this.currentItem.groups.map((group) => group.pk)
      const fields = {
        ...this.currentItem,
        groups: [...alreadyRegisteredGroups],
        user_permissions: this.currentItem.user_permissions.map(item => item.id),
        carimbo: undefined,
        avatar: undefined
      };

      // const LOADING_NAME = "put:personalData";
      // this.setLoading(LOADING_NAME);
      try {
        await api.put(`/contas/usuario/${this.currentItem.id}/`, fields);
        if (this.filesToUpload.avatar)
          await this.uploadFile("avatar", this.filesToUpload.avatar);
        if (this.filesToUpload.stamp)
          await this.uploadFile("carimbo", this.filesToUpload.stamp);
        this.filesToUpload = {};
        this.$toast.success("Dados atualizados com sucesso");
        this.isSubmitting = false;
        await this.onUpdate();
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${JSON.stringify(error.response.data)}`);
      } finally {
        // this.setLoading(LOADING_NAME, true);
        this.isSubmitting = false;
      }
    },
    async uploadFile(fieldName, image) {
      const formData = new FormData();
      formData.append(fieldName, image);
      try {
        const { data } = await api.put(
          `contas/usuario-avatar/${this.currentItem.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this[fieldName === "avatar" ? "avatarPreview" : "stampPreview"] =
          data[fieldName];
        this.currentItem[fieldName] = data[fieldName];
      } catch (error) {
        this.msg_error_avatar = "Erro ao atualizar";
      }
    },
    async get_tabela_valores() {
      const userId = this.currentItem.id;
      if (!userId) return;
      const LOADING_NAME = "get:tableValues";
      this.setLoading(LOADING_NAME);

      try {
        const { data } = await api.get(
          `contas/tabela-valores/?colaborador=${userId}`
        );
         this.tableValues = data.sort((a, b) => {
          // Coloca os itens ativos primeiro
          if (a.ativo && !b.ativo) return -1;
          if (!a.ativo && b.ativo) return 1;

          // Em seguida, ordena pela data de inicio_vigencia (do mais recente para o mais antigo)
          const dateA = a.inicio_vigencia ? new Date(a.inicio_vigencia) : new Date(0);
          const dateB = b.inicio_vigencia ? new Date(b.inicio_vigencia) : new Date(0);
          return dateB - dateA;
        });
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getGroups() {
      try {
        const { data } = await api.get("/contas/grupos/");
        // this.groups = data.filter(item => item.pk != 4); // retiramos a função Emergência
        this.groups = data; 
        // console.log(this.groups)
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    async get_conselhos() {
      const res = await api("contas/conselhos/");
      if (res.status == 200) {
        this.conselhos = res.data.filter(conselho => conselho.ativo);
      }
    },
    async get_especialidades() {
      const res = await api("contas/especialidades/");
      if (res.status == 200) {
        this.especialidades = res.data.filter(especialidade => especialidade.ativo);
      }
    },
    get_uf() {
      api("uteis/uf/").then((e) => {
        this.ufs = e.data;
      });
    },
    async get_orgao_exp() {
      const res = await api("uteis/orgao_exp/");
      if (res.status == 200) {
        this.orgao_exp = res.data.filter(orgao_exp => orgao_exp.ativo);
      }
    },
    async putTableValues() {
      const LOADING_NAME = "put:tableValues";
      this.setLoading(LOADING_NAME);
      // console.log(this.selectedRow)
      try {
        await api.put(
          `contas/tabela-valor/${this.selectedRow.id}/`,
          this.selectedRow
        );
        await this.get_tabela_valores();
        this.$toast.success("Valor Atualizado com Sucesso!");
        this.dialog.update = false;
        this.selectedRow = {};
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async postTableValues() {
      const LOADING_NAME = "post:tableValues";
      this.setLoading(LOADING_NAME);
      try {
        const { id } = this.currentItem;
        const fields = {
          ...this.newTableValue,
          colaborador: id,
          status: Boolean(this.newTableValue.status),
        };
        await api.post("contas/tabela-valores/", fields);
        await this.get_tabela_valores();
        this.$toast.success("Valor Inserido com Sucesso!");
        this.dialog.create = false;
        this.newTableValue = { status: 1 };
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async handleAjustMulitplePrices(fields) {
      const LOADING_NAME = "post:adjust-values";
      this.setLoading(LOADING_NAME);
      try {
        await Promise.all(fields.map(item => api.post("contas/tabela-valores/", item)))
        await this.get_tabela_valores();
        this.$toast.success("Valores ajustados com sucesso!");
        this.dialog.readjustment = false;
        this.selectedRows = []
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    validateCPF(cpf) {
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf == '') return false;
      if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
      let add = 0;
      for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      add = 0;
      for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    },
    emailValidation(email) {
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return pattern.test(email) || 'E-mail inválido.';
    },
     formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    async getZonas(){
      const { data } = await api('contas/zonas/')
      this.zonas = data.filter(zona => zona.ativo);
    },
  },
  mounted() {
    if (this.currentItem.avatar) this.avatarPreview = this.currentItem.avatar;
    if (this.currentItem.carimbo) this.stampPreview = this.currentItem.carimbo;
    this.getZonas();
    this.getGroups();
    this.get_conselhos();
    this.get_especialidades();
    this.get_uf(); 
    this.get_orgao_exp();
    this.get_tabela_valores();
  },
  components: { ConfirmButton, DadosPessoaisForm, DadosPessoaisFormEditar, ReadjusmentTeamPrice },
};
</script>

<style scoped>
.title-border {
  border-bottom: 2px solid #E0E0E0; /* ajuste a cor conforme necessário */
  padding-bottom: 10px;
  color: blue;
}
</style>
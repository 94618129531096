<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon>
    Contrato Particular PF
  </v-btn>
</template>

<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapGetters } from 'vuex';
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";
// import Assinatura from "../../../assets/Assinatura_Flavio_Andrade.png";

export default {
  name: "ContratoPaciente",
  data() {
    return {
      paciente: null,
      loading: false,
      modalidadeMap: {
        1: "Internação Domiciliar 06 horas",
        2: "Sistema de Atenção Domiciliar - SAD",
        3: "Internação Domiciliar 12 horas",
        4: "Internação Domiciliar 24 horas",
        5: "Internação Domiciliar 24 horas com Ventilação Mecânica",
        10: "Tratamento Eletivo Domicilair - TED",
        11: "Locação de Equipamento",
        // Adicione outros mapeamentos conforme necessário
      }
    };
  },
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {

    async getPDF() {
      this.loading = true;

      try {
        // console.log('this.selectedBudgets', this.selectedBudgets)
        // Verificação do campo data_atendimento
        // const hasInvalidDate = this.selectedBudgets.some(
        //   budget => !budget.data_atendimento
        // );

        // if (hasInvalidDate) {
        //   this.$toast.info("A data de atendimento não está cadastrada. O relatório não será gerado.");
        //   this.loading = false;
        //   return;
        // }

         // Verificar idade e se os campos de responsável estão preenchidos
        const today = new Date();
        const birthDate = new Date(this.selectedBudgets[0].data_nascimento); // Considerando que você usa o primeiro item da lista
        let age = today.getFullYear() - birthDate.getFullYear();
        // console.log(age)
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // Ajuste de idade caso o mês ou o dia ainda não tenha passado no ano atual
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        // Verificação se o paciente é menor de 18 anos e se os campos de responsável estão preenchidos
        if (age < 18) {
          const { responsavel, cpf_responsavel } = this.selectedBudgets[0];
          
          if (!responsavel || !cpf_responsavel) {
            this.$toast.info("O paciente é menor de 18 anos. O campo responsável e CPF do responsável são obrigatórios. O relatório não será gerado.");
            this.loading = false;
            return;
          }
        }

        if (!this.paciente) {
          await this.getPaciente();
        }

        // Verificação do endereço após obter os dados do paciente
        const hasInvalidAddress = this.paciente.some(p => !p.endereco);

        if (hasInvalidAddress) {
          this.$toast.info("O endereço não está cadastrado. O relatório não será gerado.");
          this.loading = false;
          return;
        }

        this.$toast.success("O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.");
        // Gere o PDF e salve-o, esperando pelo documento assinado.
        const doc = this.generatePDF();
        await this.saveAndSignPDF(doc);
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
      } finally {
        this.loading = false;
      }
    },
    formatDateForFilename(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Lembre-se de que o mês é zero-indexado
      const year = date.getFullYear();
      return `${day}_${month}_${year}`;
    },
    generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      this.addContractText(doc);
      return doc; // Retorna o objeto doc
    },
    async saveAndSignPDF(doc) {
      const formattedName = String(this.selectedBudgets[0].nome).replace(/\s/g, '_') // troca os espaços por underline
      const today = new Date();
      const formattedDate = this.formatDateForFilename(today);
      const filename = `Contrato_de_Prestacao_de_Servico_${formattedName}_${formattedDate}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);


      // Envia para assinatura
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);

      // const addHttps = signedForm.path.replace('http://', 'https://')
      // const signedPDFBlob = await fetch(addHttps).then(r => r.blob())

      // Salve o PDF no servidor como um documento do paciente. Está salvando antes de assinar
      // await this.saveDocument(signedPDFBlob, filename);

      const signedPdfUrl = signedForm.path;

      this.download(signedPdfUrl, filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    // async saveDocument(pdfBlob, filename) {
    //   const formData = new FormData();
    //   formData.append("arquivo", pdfBlob, filename);
    //   // formData.append("paciente", this.selectedBudgets[0].id);
    //   // formData.append("tipodoc", 14);

    //   try {
    //     await api.post('pacientes/docs/', formData);
    //     this.$toast.success('O contrato foi salvo nos documentos do paciente.');
    //   } catch (error) {
    //     this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
    //   }
    // },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 15, 15, 15);
        doc.addImage(gptw, 185, 15, 15, 15);
        // doc.addImage(Assinatura, 90, 185, 30, 30);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
      }

      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 22, { align: "center" });
      doc.setFontSize(16);
      doc.text(`CONTRATO DE PRESTAÇÃO DE SERVIÇO`, 105, 53, { align: "center" });
    },
    addContractText(doc) {
      doc.setFontSize(12);
      const paciente = this.paciente[0];
      const cpf = paciente.cpf ? `, inscrito(a) no CPF sob o nº ${UtilsPDF.formatCPF(paciente.cpf)}` : ''
      const endereco = paciente.endereco ? `${paciente.endereco.logradouro}, ${paciente.endereco.nr}, ${paciente.endereco.bairro}, ${paciente.endereco.cidade} - ${paciente.endereco.estado} - CEP: ${paciente.endereco.cep}` : '{{ENDEREÇO}}';

      const usuario = this.username;
      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const today = new Date();
      const birthDate = new Date(paciente.data_nascimento); // Considerando que você usa o primeiro item da lista
      let age = today.getFullYear() - birthDate.getFullYear();

      const responsavelfinanceiro = paciente.responsavel ? ` ${paciente.responsavel} responsável administrativo e financeiro nesse contrato , inscrito no CPF Nº ${UtilsPDF.formatCPF(paciente.cpf_responsavel)}, `: ''
      const cpfresponsavel = paciente.cpf_responsavel ? `inscrito no CPF nº ${UtilsPDF.formatCPF(paciente.cpf_responsavel)}, `:''
      const responsavelsimples = paciente.responsavel ? ` ${paciente.responsavel}, ${cpfresponsavel}` : ''
      const responsavel = age > 17 ? responsavelsimples : responsavelfinanceiro

      const pageHeight = doc.internal.pageSize.height;
      let currentY = 70;
      const marginBottom = 30;

      // Função para adicionar texto com partes em negrito
      const addTextWithBold = (doc, textArray) => {
        textArray.forEach(({ text, bold }) => {
          if (bold) {
            doc.setFont("helvetica", "bold");
          } else {
            doc.setFont("helvetica", "normal");
          }
          const lines = doc.splitTextToSize(text, 190);
          lines.forEach(line => {
            if (currentY + 10 > pageHeight - marginBottom) {
              doc.addPage(); // Adicionar nova página se ultrapassar a margem
              currentY = 20;
            }
            doc.text(line, 10, currentY);
            currentY += 6;
          });
        });
      };

      // Texto completo com partes em negrito
      const contractText = [
        { text: `            Pelo presente instrumento particular de CONTRATO DE PRESTAÇÃO DE SERVIÇO ESPECIALIZADO DE “HOME CARE”, firmado entre as partes, de um lado, a saber, ASSISTÊNCIA DOMICILIAR ANDRADE LTDA , empresa inscrita no CNPJ sob o nº 22.121.808/0001-60, com sede na Travessa Bela Vista, nº 04, Araçagy, São José de Ribamar, representada pelo administrador Flávio Roberto Melo de Andrade, inscrito no CPF sob o nº 787.501.993-15, doravante denominada simplesmente CONTRATADA, e de outro, Sr.(a) PACIENTE ${paciente.nome}, brasileiro(a) ${cpf}, residente no endereço ${endereco}, a seguir denominado simplesmente CONTRATANTE, representado pelo Sr.(a) REPRESENTANTE ${responsavel} e ficando justo e contratado o que segue:\n`, bold: false },
        
        { text: "CLÁUSULA PRIMEIRA", bold: true },
        { text: "   O presente contrato tem como objeto a prestação de serviço descrita no orçamento ou seus aditivos que fazem parte integrante deste contrato.\n", bold: false },
         { text: "   Parágrafo único - ", bold: true },
        { text: "       O serviço será exercido na residência do(a) CONTRATANTE, ou em caso de utilização da ambulância, será de transporte.\n", bold: false },

        { text: "CLÁUSULA SEGUNDA", bold: true },
        { text: "   A CONTRATADA prestará os serviços que constarem no orçamento ou aditivos que fazem parte do referido contrato.\n", bold: false },
        
        { text: "   Parágrafo único - ", bold: true },
        { text: "       Todos os serviços deverão constar no orçamento e/ou seus aditivos, bem como período para sua execução e os devidos valores.\n", bold: false },
        
        { text: "CLÁUSULA TERCEIRA", bold: true },
        { text: "   A CONTRATADA prestará o serviço mediante o pagamento prévio do valor do orçamento ou seus aditivos.\n", bold: false },

        { text: "   Parágrafo único – ", bold: true },
        { text: "       Qualquer despesa extra deverá ser previamente autorizada e paga pela CONTRATANTE.\n", bold: false },

        { text: "CLÁUSULA QUARTA", bold: true },
        { text: "   O presente contrato poderá ser rescindido por qualquer das partes, por meio de comunicado escrito, em comum acordo ou unilateralmente, sem notificação prévia, ficando assegurado o prazo de até 02 (dois) dias úteis, contados da rescisão, para a retirada dos materiais da CONTRATADA.\n", bold: false },

        { text: "   Parágrafo Primeiro – ", bold: true },
        { text: "       Fica o(a) CONTRATANTE ciente de que todo material utilizado pela CONTRATADA, quando da prestação de serviço em domicílio, é de propriedade da CONTRATADA.\n", bold: false },

        { text: "   Parágrafo Segundo – ", bold: true },
        { text: "       Caso o(a) CONTRATANTE retenha o material da CONTRATADA, por qualquer motivo, será obrigado(a) a pagar o valor equivalente ao aluguel dos equipamentos e móveis e com relação ao material de consumo, como medicamentos e demais materiais descartáveis, será devido o valor integral.\n", bold: false },

        { text: "   Parágrafo Terceiro – ", bold: true },
        { text: "       Se for constatado extravio ou má conservação dos materiais, por culpa do(a) CONTRATANTE, este será obrigado a restituir material idêntico ou pagar o valor equivalente coonstante no orçamento ou aditivo (Valor do Bem).\n", bold: false },

        { text: "   Parágrafo Quarto – ", bold: true },
        { text: "       A relação de equipamentos e materiais disponibilizados pela CONTRATADA quando da prestação de serviços à CONTRATANTE será parte integrante do presente instrumento.\n", bold: false },
        
        { text: "   Parágrafo Quinto – ", bold: true },
        { text: "       Em caso de solicitação de restituição de qualquer valor pago, essa será devida somente se constatado que o serviço não foi realizado ou posto a disposição e o valor correspondente será restituído mediante aceite de cancelamento da nota fiscal original, sendo gerada outra nota fiscal com o valor do serviço.\n", bold: false },
        
        { text: "   Parágrafo Sexto – ", bold: true },
        { text: "       Em caso de retenção de equipamentos e/ou materiais, sem a devida contraprestação constante do parágrafo segundo desta cláusula, incidirá multa de 10% do valor dos bens.\n", bold: false },
     
        { text: "   Parágrafo Sétimo – ", bold: true },
        { text: "       Os valores em atraso serão corrigidos monetariamente com base no índice IPCA ou outro índice que venha a substituí-lo, conforme a legislação vigente e incidirão juros de 1% (um por cento) ao mês, de forma pro rata die.\n", bold: false },
     
        { text: "   Parágrafo Oitavo – ", bold: true },
        { text: "       Todos os custos relacionados à cobrança judicial ou extrajudicial, incluindo honorários advocatícios e despesas processuais, serão de responsabilidade dos(as) CONTRATANTES inadimplentes.\n", bold: false },

        { text: "CLÁUSULA QUINTA", bold: true },
        { text: "   O presente contrato poderá ser modificado desde que haja concordância entre as partes inclusive no que corresponde à sua prorrogação e deverá ser feito por meio de termo aditivo, por escrito.\n", bold: false },

        { text: "CLÁUSULA SEXTA", bold: true },
        { text: "   A CONTRATADA, por si e por seus colaboradores, obriga-se a atuar no presente Contrato em conformidade com a Legislação vigente sobre Proteção de Dados Pessoais e as determinações de órgãos reguladores/fiscalizadores sobre a matéria, em especial a Lei 13.709/2018.\n", bold: false },
        { text: "   Parágrafo Único – ", bold: true },
        { text: "       Para fins da correta aplicação legislação de proteção de dados, considera-se o disposto abaixo.\n", bold: false },
        { text: "   1. Definições", bold: false },
        { text: "      a. Dados Pessoais: quaisquer informações que, de forma direta ou indireta, identifiquem ou possam identificar uma pessoa física, conforme definido pela Lei Geral de Proteção de Dados Pessoais (LGPD).", bold: false },
        { text: "      b. Dados Sensíveis: dados pessoais relacionados à saúde e outras informações protegidas pela LGPD.", bold: false },
        { text: "      c. Titular dos Dados: pessoa física a quem se referem os dados pessoais tratados.\n", bold: false },
        { text: "   2. Tratamento de Dados Pessoais e Sensíveis", bold: false },
        { text: "      a. As partes comprometem-se a realizar o tratamento de dados pessoais e sensíveis conforme a LGPD, assegurando proteção adequada e consentimento explícito quando necessário.", bold: false },
        { text: "      b. Os dados sensíveis relativos à saúde serão tratados exclusivamente por profissionais autorizados.\n", bold: false },
        { text: "   3. Segurança da Informação", bold: false },
        { text: "      a. Medidas técnicas e administrativas serão adotadas para proteger os dados contra acessos não autorizados e incidentes de segurança.", bold: false },
        { text: "      b. Em caso de violação de dados, a parte responsável deverá notificar imediatamente as autoridades competentes e os titulares afetados.\n", bold: false },
        { text: "   4. Direitos dos Titulares", bold: false },
        { text: "      a. As partes comprometem-se a garantir aos titulares dos dados o exercício dos direitos previstos pela LGPD, como acesso, correção, portabilidade e eliminação dos dados.\n", bold: false },
       
        { text: "CLÁUSULA SÉTIMA", bold: true },
        { text: "   O presente contrato, firmado entre as partes, constituirá título executivo extrajudicial nos termos do art. 784, inciso III, do Código de Processo Civil (Lei nº 13.105/2015), para todos os efeitos legais, podendo ser executado em caso de inadimplemento ou descumprimento de qualquer das obrigações estabelecidas neste instrumento.\n", bold: false },
     
        { text: "CLÁUSULA OITAVA", bold: true },
        { text: "   O presente contrato terá vigência de 1 (um) ano, a contar da data de sua assinatura pelas partes, podendo ser renovado automaticamente por iguais períodos, salvo manifestação em contrário de qualquer uma das partes com antecedência mínima de 30 (trinta) dias antes do término da vigência contratual.\n", bold: false },
        
        // { text: "CLÁUSULA NONA", bold: true },
        // { text: "   RETENÇÃO DE BENS E INADIMPLÊNCIA", bold: true },
        // { text: "   1. Retenção de Bens", bold: false },
        // { text: "      a. Caso o(a) CONTRATANTE retenha qualquer bem da CONTRATADA, por qualquer motivo, estará obrigado(a) a devolver o bem em perfeito estado ou, na sua impossibilidade, restituir o valor correspondente ao bem, conforme estipulado no orçamento ou aditivo.", bold: false },
        // { text: "      b. Em caso de extravio ou danos aos bens fornecidos pela CONTRATADA, o(a) CONTRATANTE será responsável pelo ressarcimento integral do valor de mercado do bem ou pela reposição de outro bem idêntico.\n", bold: false },
        // { text: "   2. Inadimplência", bold: false },
        // { text: "      a. Em caso de inadimplência, o(a) CONTRATANTE estará sujeito(a) à aplicação de multa moratória de 2% (dois por cento) sobre o valor total em aberto.", bold: false },
        // { text: "      b. Incidirão, ainda, juros de 1% (um por cento) ao mês, de forma pro rata die, sobre os valores devidos.", bold: false },
        // { text: "      c. Os valores em atraso serão corrigidos monetariamente com base no índice IPCA ou outro índice que venha a substituí-lo, conforme a legislação vigente.", bold: false },
        // { text: "      d. Todos os custos relacionados à cobrança judicial ou extrajudicial, incluindo honorários advocatícios e despesas processuais, serão de responsabilidade do(a) CONTRATANTE inadimplente.\n", bold: false },

        // { text: "CLÁUSULA DÉCIMA", bold: true },
        // { text: "    PROTEÇÃO DE DADOS PESSOAIS E DADOS SENSÍVEIS", bold: true },
        // { text: "   1. Definições", bold: false },
        // { text: "      a. Dados Pessoais: quaisquer informações que, de forma direta ou indireta, identifiquem ou possam identificar uma pessoa física, conforme definido pela Lei Geral de Proteção de Dados Pessoais (LGPD).", bold: false },
        // { text: "      b. Dados Sensíveis: dados pessoais relacionados à saúde e outras informações protegidas pela LGPD.", bold: false },
        // { text: "      c. Titular dos Dados: pessoa física a quem se referem os dados pessoais tratados.\n", bold: false },
        // { text: "   2. Tratamento de Dados Pessoais e Sensíveis", bold: false },
        // { text: "      a. As partes comprometem-se a realizar o tratamento de dados pessoais e sensíveis conforme a LGPD, assegurando proteção adequada e consentimento explícito quando necessário.", bold: false },
        // { text: "      b. Os dados sensíveis relativos à saúde serão tratados exclusivamente por profissionais autorizados.\n", bold: false },
        // { text: "   3. Segurança da Informação", bold: false },
        // { text: "      a. Medidas técnicas e administrativas serão adotadas para proteger os dados contra acessos não autorizados e incidentes de segurança.", bold: false },
        // { text: "      b. Em caso de violação de dados, a parte responsável deverá notificar imediatamente as autoridades competentes e os titulares afetados.\n", bold: false },
        // { text: "   4. Direitos dos Titulares", bold: false },
        // { text: "      a. As partes comprometem-se a garantir aos titulares dos dados o exercício dos direitos previstos pela LGPD, como acesso, correção, portabilidade e eliminação dos dados.\n", bold: false },
        
        { text: "CLÁUSULA NONA", bold: true },
        { text: "   As partes elegem o foro da Comarca da Ilha de São Luís / MA como único competente para dirimir quaisquer litígios oriundos do presente contrato.\n", bold: false },
        
        { text: "   E, assim, por estarem de pleno acordo com o contido neste instrumento, CONTRATADA e CONTRATANTE o firmam, consoantes os ditames legais.\n\n", bold: false },
      
      
      ];

      // Adicionar o texto formatado com partes em negrito
      addTextWithBold(doc, contractText);

      doc.text(`SÃO JOSÉ DE RIBAMAR, ${UtilsPDF.formatDate(currentDate)}.`, 105, currentY + 5, { align: 'center' });

      // Adicionar espaço antes das colunas
      currentY += 30;

      // Definir as coordenadas para as duas colunas
      const leftColumnX = 20;  // Coluna da esquerda
      const rightColumnX = 120; // Coluna da direita

      // Texto da coluna CONTRATADA (esquerda)
      doc.setFont("helvetica", "bold");
      doc.text("_______________________________ ", leftColumnX, currentY);
      doc.text("CONTRATADA: ", leftColumnX, currentY + 7);
      doc.setFont("helvetica", "normal");
      doc.text("Assistência Domiciliar Andrade LTDA", leftColumnX, currentY + 14);
      doc.text("CNPJ: 22.121.808/0001-60", leftColumnX, currentY + 21);

      // Texto da coluna CONTRATANTE (direita)
      doc.setFont("helvetica", "bold");
      doc.text("_______________________________ ", rightColumnX, currentY);
      doc.text("CONTRATANTE (A) ou RESPONSÁVEL ", rightColumnX, currentY + 7);
      doc.setFont("helvetica", "normal");
      const nome = paciente.necessita_responsavel ? `${paciente.responsavel}` : `${paciente.nome}`
      const cpfassinatura = paciente.necessita_responsavel ? `${UtilsPDF.formatCPF(paciente.cpf_responsavel)}`: `${UtilsPDF.formatCPF(paciente.cpf)}`
      doc.text(`${nome}`, rightColumnX, currentY + 14);
      doc.text(`CPF: ${cpfassinatura}`, rightColumnX, currentY + 21);
      
      // Atualizar currentY para garantir que o próximo texto comece abaixo das colunas
      currentY += 40; // Move o Y para ficar após o final das colunas

      const leftColumnX2 = 20;  // Coluna da esquerda
      const rightColumnX2 = 120; // Coluna da direita

      // Texto da coluna CONTRATADA (esquerda)
      doc.setFont("helvetica", "bold");
      doc.text("_______________________________ ", leftColumnX2, currentY);
      doc.text("Testemunha 01 ", leftColumnX2, currentY + 7);
      doc.setFont("helvetica", "normal");
      doc.text("CPF Nº _____________________", leftColumnX2, currentY + 14);

      // Texto da coluna CONTRATANTE (direita)
      doc.setFont("helvetica", "bold");
      doc.text("_______________________________ ", rightColumnX2, currentY);
      doc.text("Testemunha 02", rightColumnX2, currentY + 7);
      doc.setFont("helvetica", "normal");
      doc.text("CPF Nº _____________________", rightColumnX2, currentY + 14);

      currentY += 28;
      
      // Adicionar a assinatura e informações finais
      const usuariotexto = `Documento gerado por: ${usuario}, ás ${hour}`;
      doc.setFontSize(6);
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(usuariotexto);
      const xPosition = pageWidth - textWidth - 10;
      currentY = doc.internal.pageSize.height - 23;
      doc.text(usuariotexto, xPosition, currentY);

      // Adicionar números de página
      UtilsPDF.addPageNumbers(doc);
    },
    async getPaciente() {
      try {
        const pacienteId = this.selectedBudgets[0].id;
        const { data } = await api.get(`/pacientes/detalhes/?paciente=${pacienteId}&empresa=1`);
        this.paciente = data;
        // console.log(this.paciente)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Paciente (getPaciente):", error);
        throw error;  // Garante que a função `getPDF` saiba que houve um erro.
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    lista() {
      return this.userData || {}
    },
  },
  computer: { ...mapGetters(['userData']), },

  mounted() {
    this.getPaciente();
    this.lista();
  }
};
</script>

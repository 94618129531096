<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div style="position: relative;" class="mr-2 mb-5">
            <h1 class="h1">Gestão de Entrega</h1>
        </div>
      </v-col>
    </v-row>
    <v-tabs v-model="tab" class="mt-5">
        <v-tab class="mt-5">Trocas <span v-if="troca >= 1" class="badge-red">{{ troca }}</span></v-tab>
        <v-tab class="mt-5">Retirada <span v-if="retirada >= 1" class="badge-red">{{ retirada }}</span></v-tab>
        <v-tab class="mt-5">Requisições <span v-if="requisisao >= 1" class="badge-red">{{ requisisao }}</span></v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
          <Trocas @atualizar-pagina-pai="atualizarPaginaPai"/>
      </v-tab-item>
      <v-tab-item>
          <Retiradas @atualizar-pagina-pai="atualizarPaginaPai" />
      </v-tab-item>
      <v-tab-item>
        <Requisicoes @atualizar-pagina-pai="atualizarPaginaPai"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>

import api from '../../../../http';
import '../../../../assets/css/styles.css'

export default {
    name: 'GestaoEntrega',
    props:{
      numeroEntraga: Number
    },
    components: {
        Trocas: () => import("./Troca.vue"),
        Retiradas: () => import("./Retirada.vue"),
        Requisicoes: () => import("./Requisicao.vue"),
    },
    data: () => ({
        tab: 0,
        subTab:0,
        pacientes: [],
        troca: 0,
        retirada: 0,
        requisisao: 0
    }),
    methods: {
      async getPaciente() {
            try {
              const { data } = await api('pacientes/esterilizaveis/');
              this.pacientes = data.filter(item => item.ativo)
              // const pacientesAgrupados = {};
              const trocas = data.reduce((acc, paciente) => {
                  if (paciente.data_solicitacao_troca !== null && paciente.troca === true && (paciente.data_implantacao !== null) && paciente.ativo === true &&  paciente.esterilizavel.disponibilidade === 14 && (!paciente.data_solicitacao_retirada || paciente.data_solicitacao_retirada === '') && paciente.esterilizavel) {
                    const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
              }, {});

              this.troca = Object.keys(trocas).length;
              
              // Agrupa primeiro por paciente.id e data_solicitacao_retirada
              const retiradas = data.reduce((acc, paciente) => {
                  if (paciente.data_solicitacao_retirada !== null && paciente.retirada === true && paciente.disponibilidade.id === 14 &&  paciente.esterilizavel && paciente.ativo === true) {
                      const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
              }, {});

              this.retirada = Object.keys(retiradas).length;

              const requisicoes = data.reduce((acc, paciente) => {
                  if (!paciente.data_implantacao  &&  paciente.requisicao === true && paciente.disponibilidade.id === 13 &&  paciente.esterilizavel &&  paciente.ativo === true) {
                      const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                      if (!acc[chave]) {
                          acc[chave] = true;
                      }
                  }
                  return acc;
              }, {});

              this.requisisao = Object.keys(requisicoes).length;

            } catch (error) {
                console.log(error)
                this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
            }
      },
      async atualizarPaginaPai() {
        await this.getPaciente(); // Método existente para atualizar os dados
      },
    },
    mounted() {
      this.getPaciente()
    }
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.badge-red {
   min-width: 20px;
   height: 20px;
   padding: 0 4px;
   background-color: red;
   color: white;
   font-size: 12px;
   font-weight: bold;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   margin-left: 4px;
   position: relative;
   top: -15px;
}

</style>
<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
      :loading="loading">
      <v-icon left>mdi-printer</v-icon> Rel. Fechamento Conta (GLOSA)
    </v-btn>
  </v-container>
</template>
<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Orcamento",
  components: {
  },
  data: () => withCRUDUtils({
    procedimentos: [],
    budget: [],
    pacote: [],
    taxa: [],
    equipamentos: [],
    loading: false,
    totalFee: null,
    totalEquipment: null
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
          this.getPacote(),
          this.getTaxa(),
          this.getEquipamentos(),
          this.getCodigoRecurso()
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        console.log(error)
        this.$toast.error("Erro ao carregar os dados (L-58):", error);
        this.loading = false;
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error('Falha ao adicionar a imagem ao PDF: (L-68).', error);
      }

      const data = this.budget[0];

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Rel Conta Recurso Glosa Nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28);
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      yPosition = 75
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Segue o resumo de Recurso de Glosa de protocolo Nr ${data.nrprotocolorecursoglosa}.`, 12, yPosition);

      yPosition = 82
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Ficamos a sua inteira disposição para quaisquer dúvidas que tenham.`, 12, yPosition);

      yPosition = 89
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`Obrigada.`, 12, yPosition);
    },

    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
       

      const pageWidth = 210;
      const maxHeight = 270;
      const lineHeight = 6;

      const data = this.budget[0]
      const prorrogacao = this.procedimentos
      let yPosition = 97;
      let xPosition = 10;

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo do Recurso de Glosa");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo do Recurso de Glosa", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 100, this.drawHeader);

      const packageData = this.pacote.length > 0 ? this.pacote[0] : null;

      if (packageData) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Pacote(s)", xPosition, yPosition);
        yPosition += lineHeight;

        const row = {
          'Código': packageData.pacote?.codigo || '',
          'Pacote': packageData.pacote?.nome || '',
          'Código Motivo': this.getMotivoGlosaNome(packageData.motivoglosa_pacote),
          'Justificativa': packageData.justificativarecursoglosa_pacote,
          'Valor Unitário': `${UtilsPDF.formatToBRL(packageData.valor_glosa_pacote)}`,
          
        };

        const columns = ['Código', 'Pacote', 'Código Motivo', 'Justificativa', 'Valor Unitário'];
        const rows = [Object.values(row)];

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 }
        });

        const tableHeight = doc.lastAutoTable.finalY;
        yPosition = tableHeight + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 100, this.drawHeader);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Pacote(s): ${UtilsPDF.formatToBRL(packageData.valor_glosa_pacote)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de pacote encontrado');
      }

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Procedimento(s)", xPosition, yPosition);
      yPosition += lineHeight;

      const table = prorrogacao.map(item => {
        return {
          'Código': item?.procedimento.codigo,
          'Procedimento': item?.procedimento.nomecomercial ? item?.procedimento.nomecomercial : item?.procedimento.procedimento,
          'Cód. Motivo': item?.motivoglosa.codigo,
          'Justificativa': item.justificativarecursoglosa,
          'Valor Unitário': UtilsPDF.formatToBRL(parseFloat(item.valor_glosa) || 0),
         
        };
      });

      const columns = ['Código', 'Procedimento', 'Cód. Motivo', 'Justificativa', 'Valor Unitário'];
      const rows = [];
      table.forEach(item => {
        const row = [];
        columns.forEach(column => {
          row.push(item[column]);
        });
        rows.push(row);
      });

      doc.autoTable({
        startY: yPosition,
        head: [columns],
        body: rows,
        styles: { lineWidth: 0.1 },
        margin: { left: 8, right: 8 },
        columnStyles: {
          2: { cellWidth: 30 },
          5: { cellWidth: 28 },
          8: { cellWidth: 28 }
        },
      });

      const tableHeight = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight + lineHeight;

      // Cálculo do Valor Total dos Procedimentos
      const totalBudget = table.reduce((acc, item) => {
        const valorTotal = UtilsPDF.parseFormattedCurrency(item['Valor Unitário']) || 0;
        return acc + valorTotal;
      }, 0);
      
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total do(s) Procedimento(s): ${totalBudget ? UtilsPDF.formatToBRL(totalBudget) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });
      yPosition += lineHeight * 2;
      
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 100, this.drawHeader);
      const taxas = this.taxa;
      this.totalFee = 0;

      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Código',
          'Taxa',
          'Cód. Motivo', 
          'Justificativa',
          'Valor Unitário',
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo) {
            // const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            // const valorCobrancaFinalTaxa = taxa?.valor_cobranca_final_taxa || 0;

            const row = {
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': taxa?.taxa?.taxa?.nome || '',
              'Código Motivo': this.getMotivoGlosaNome(taxa.motivoglosa_taxa),
              'Justificativa': taxa.justificativarecursoglosa_taxa,
              'Valor Unitário': UtilsPDF.formatToBRL2(taxa.valor_glosa_taxa),

            };

            rows.push(Object.values(row));

            this.totalFee += taxa.valor_glosa_taxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-357):", error);
          // console.error(error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 100, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(this.totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 100, this.drawHeader);


      
      this.totalEquipment = 0;
      const equipamentos = this.equipamentos;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Equipamento(s)", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Código',
          'Equipamento',
          'Cód. Motivo', 
          'Justificativa',
          'Valor Unitário',
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo) {
            const valorGlosaEquipamento = parseFloat(equipamento?.valor_glosa_equipamento) || 0;

            const row = {
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': equipamento?.equipamento?.equipamento?.nome || '',
              'Código Motivo': this.getMotivoGlosaNome(equipamento.motivoglosa_equipamento),
              'Justificativa': equipamento.justificativarecursoglosa_equipamento,
              'Valor Unitário': UtilsPDF.formatToBRL2(valorGlosaEquipamento),
            };

            rows.push(Object.values(row));

            this.totalEquipment += valorGlosaEquipamento;
          }
        });


        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          console.error(error);
        }

         yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(this.totalEquipment)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      const pacoteValor = this.pacote != '' ? this.pacote[0] : 0;
      const totalOrcamento =
        totalBudget + (
          pacoteValor?.valor_glosa_pacote ?
            pacoteValor?.valor_glosa_pacote :
            0
        ) + this.totalFee + this.totalEquipment

      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const dailyAverage = totalOrcamento / numberOfDays;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 100, this.drawHeader);

      const text = 'do Recurso de Glosa'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 100, this.drawHeader);

      const operadora = data && data.convenio ? data.convenio : '';
      // UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 245);

      UtilsPDF.addPageNumbers(doc);
      const data_inicio = data && data?.data_inicio;
      const data_fim = data && data?.data_fim;
      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';
      const nomePaciente = data && data.paciente ? data.paciente : '';

      const filename = `Recurso_de_Glosa_${nomePaciente}_${operadora}_Período_${period}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);
        const signedPdfUrl = signedForm.path;
        this.download(signedPdfUrl, filename);
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao tentar gerar o PDF (L-482).', error.response ? error.response.data : error.message);
      }
    },
    async getProcedimento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/evolucoes_xml/?orcamento=${this.selectedBudgets[0]}`);
          this.procedimentos = data.filter(item => {
            return item.recurso_glosa === true && item.ativo === true;
          });
          // console.log('Procedimento --> ', this.procedimentos)
        } catch (error) {
          console.error('Erro ao buscar os dados dos procedimentos:', error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.budget = data;
          // console.log(this.budget)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Pacote (L-503).', error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/pacotes_xml/?orcamento=${this.selectedBudgets[0]}`);
          this.pacote = data.filter(item => {
            return item.recurso_glosa_pacote === true && item.ativo === true;
          });
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Pacote (L-514).', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas_xml/?orcamento=${this.selectedBudgets[0]}`);
          this.taxa = data.filter(item => {
            return item.recurso_glosa_taxa === true && item.ativo === true;
          });
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da taxa (L-525).', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos_xml/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => {
            return item.recurso_glosa_equipamento === true && item.ativo === true;
          });
          // console.log(this.equipamentos)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da Equipamento (L-536).', error);
        }
      }
    },
    getMotivoGlosaNome(motivoglosaId) {
      const motivo = this.codigorecurso.find(item => item.id === motivoglosaId);
      return motivo ? motivo.codigo : 'N/A';
    },
    async getCodigoRecurso() {
      try {
        const { data } = await api.get('uteis/motivoglosa/');
        this.codigorecurso = data.filter(item => item.ativo);
        // console.log('Código Recurso:', this.codigorecurso); // Verifique os dados aqui
      } catch (error) {
        alert("Erro ao listar os recursos de glosa");
        console.log(error);
      }
     },
  },
};
</script>
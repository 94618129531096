<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <!-- Fornecedor -->
      <v-col>
        <v-autocomplete 
          dense
          :loading="isLoadingNotaFiscal"
          v-model="currentItem.fornecedor_id"
          :items="fornecedores"
          item-value="id"
          item-text="nome_fantasia"
          label="Fornecedor"
          outlined
          clearable
          :rules="[validaCampo]"
        >
          <template v-slot:label>
            <span>Fornecedor <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field 
          dense 
          label="Razão Social" 
          :value="fornecedorNome" 
          outlined  
          disabled 
        />
      </v-col>
    </v-row>

    <!-- Dados da Nota Fiscal -->
    <v-row dense>
      <v-col>
        <v-text-field 
          dense 
          label="Nº Nota Fiscal / Recibo" 
          v-model="currentItem.nota_fiscal" 
          outlined 
          :rules="[validaCampo]"
        >
          <template v-slot:label>
            <span>Nº Nota Fiscal / Recibo <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field 
          dense 
          label="Data da Nota Fiscal" 
          v-model="currentItem.dataAquisicao" 
          outlined 
          type="date" 
          :rules="[validaCampo]"
        >
          <template v-slot:label>
            <span>Data da Nota Fiscal <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field 
          dense 
          label="Desconto Geral da Nota Fiscal %" 
          v-model="currentItem.desconto" 
          outlined 
          type="number" 
        />
      </v-col>
    </v-row>

    <!-- Ativo/Inativo -->
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>

    <!-- Botão de Salvar -->
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "EditarNotaFiscal",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      fornecedores: [], // Lista de fornecedores
      isLoadingNotaFiscal: false, // Status de carregamento
    });
  },
  components: { ConfirmButton },
  computed: {
    fornecedorNome() {
      const fornecedor = this.fornecedores.find(f => f.id === this.currentItem.fornecedor_id);
      return fornecedor ? fornecedor.nome_fantasia : "Fornecedor não encontrado";
    },
  },
  methods: {
    async getFornecedores() {
      this.isLoadingNotaFiscal = true;
      try {
        const { data } = await api('fornecedores/fornecedores/');
        this.fornecedores = data
          .filter(item => item.ativo)
          .map(f => ({ id: f.id, nome_fantasia: f.nome_fantasia || "Sem Nome" }))
          .sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
      } catch (error) {
        this.$toast.error(`Erro ao carregar fornecedores. ${error}`);
      } finally {
        this.isLoadingNotaFiscal = false;
      }
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      if (!this.currentItem.nota_fiscal || !this.currentItem.fornecedor_id) {
          this.$toast.error("Preencha todos os campos obrigatórios.");
          return;
      }

      const fields = {
          id: this.currentItem.id,
          fornecedor: this.currentItem.fornecedor_id, // Certifique-se de enviar o ID do fornecedor
          nota_fiscal: this.currentItem.nota_fiscal,
          dataAquisicao: this.currentItem.dataAquisicao,
          desconto: this.currentItem.desconto,
          ativo: this.currentItem.ativo,
          tipo: this.currentItem.tipo,
      };

      // console.log('Enviando para o backend:', fields);
      this.onSubmit(fields); // Certifique-se de que `onSubmit` está configurado para fazer um PUT corretamente
    },
    validaCampo(value) {
      return !!value || 'Este campo é obrigatório.';
    }

  },
  mounted() {
    this.getFornecedores();
    this.currentItem.ativo = Number(this.currentItem.ativo)
    // Preenche os dados do fornecedor se existirem no currentItem
    if (this.currentItem.fornecedor) {
      this.currentItem.fornecedor_id = this.currentItem.fornecedor.id;
    }
  },
};
</script>

<template>
  <div>
    <v-data-table :loading="isLoading('get:EquipamentoDependente')" :items="dependencia" :search="search" :headers="headers" :items-per-page="-1" height="600px" fixed-header>
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar  </v-btn>
            <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left>  mdi-plus </v-icon> Novo </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
    <template v-slot:[`item.equipamentofilhos`]="{ item }">
      <span>
        {{ item.equipamentofilhos.map(equip => equip.nome).join('; ') }}
      </span>
     
    </template>
    <template v-slot:[`item.esterilizavel`]="{ item }">
      <span >
        {{ item.esterilizavel.map(equip => equip.nome).join('; ') }}
      </span>
      
    </template>
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="1100">
      <v-card>
        <v-card-title class="blod_color">
          Novo Equipamento Principal e seus Dependetes:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <EquipamentoDependenteNovo :currentItem="newItem" :loading="isLoading('post:EquipamentoDependente')" :onSubmit="createEquipamentoDependente" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="1100">
      <v-card>
        <v-card-title class="blod_color">
          Editar Equipamentos / Esterilizáveis Dependentes do Equipamento Principal: " {{ selectedRow.equipamentopai.nome }} "
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <EquipamentoDependenteEditar :currentItem="selectedRow" :loading="isLoading('put:EquipamentoDependente')" :onSubmit="updateEquipamentoDependente" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import EquipamentoDependenteEditar from './EquipamentoDependenteEditar.vue';
import EquipamentoDependenteNovo from './EquipamentoDependenteNovo.vue';
import './../../../../assets/css/styles.css';

export default {
  name: "EquipamentoDependenteIndex",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo:1 },
      headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 90 },
        { text: "Equipamento Principal", value: "equipamentopai.nome" },
        { text: "Equipamentos Dependetes", value: "equipamentofilhos" },
        { text: "Esterilizaveis Dependetes", value: "esterilizavel" },
        { text: "Status", value: "ativo" },
      ],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      },
      dependencia:[],
    });
  },
  methods: {
    async getEquipamentoDependente() {
      const LOADING_NAME = "get:EquipamentoDependente";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("operadoras/operadora-equipamentos-dependencias-novo/");
       this.dependencia = data
      .map(item => {
        if (item.equipamentofilhos && item.equipamentofilhos.length > 0) {
          // Ordenar os equipamentos filhos por nome
          item.equipamentofilhos.sort((a, b) => a.nome.localeCompare(b.nome));
        }
        return item;
      })
      .sort((a, b) => {
        // Ordenar por ativo (True antes de False)
        if (a.ativo !== b.ativo) {
          return a.ativo ? -1 : 1;
        }

        // Ordenar pelo nome do equipamento pai
        return a.equipamentopai.nome.localeCompare(b.equipamentopai.nome);
      });
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createEquipamentoDependente(fields) {
      const LOADING_NAME = "post:EquipamentoDependente";
      this.setLoading(LOADING_NAME);
      try {
        // console.log(fields)
        await api.post(`operadoras/operadora-equipamentos-dependencias-criar/`, fields);
        this.getEquipamentoDependente()
        this.dialog.create = false
        this.$toast.success('EEquipamento Dependente criado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateEquipamentoDependente(fields) {
      const LOADING_NAME = "put:EquipamentoDependente"; 
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/operadoras/operadora-equipamentos-dependencias-editar/${fields.id}/`, 
        { equipamentofilhos: fields.equipamentofilhos, ativo:fields.ativo, esterilizavel: fields.esterilizavel });
        await this.getEquipamentoDependente()
        this.dialog.update = false
        this.$toast.success('Equipamento Dependente atualizado com sucesso!')
      }
      catch (error) {
        console.log(error)
        // this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    atualizar(){
      this.getEquipamentoDependente()
      this.search = '';
    },
  },
  mounted() {
    this.getEquipamentoDependente();
  },
  components: { EquipamentoDependenteEditar, EquipamentoDependenteNovo }
}
</script>

<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" class="mt-5">
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" 
          :disabled="resumoPacientes.length === 1 && (resumoPacientes[0].id === 'no-patients' || resumoPacientes[0].id === 'error')"
          :messages="resumoPacientes.length === 1 && resumoPacientes[0].id === 'no-patients' ? 'Não há pacientes com solicitações de entrega pendente' : ''"
          class="custom-messages"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Troca" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Previsão da Troca" disabled v-model="dataPrevista" type="date"  ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-card-title class="blod_color">Itens Trocados:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col> 
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" show-select item-value v-model="selectedPacienteItems" class="elevation-1" :items-per-page="-1" > </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field dense outlined label="Data da Troca"  v-model="dataTrocaEfetivada" type="date" :rules="requiredField" ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados"> <v-icon left>mdi-refresh</v-icon> Atualizar  </v-btn>
      <v-col class="text-end">
        <ConfirmButton class="mr-4 ml-5 elevation-0" :onConfirm="cancelarEntrega" :loading="isLoadingaCancelar" color="red" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }" > Cancelar Entrega  </ConfirmButton>
        <ConfirmButton :loading="loading"  color="success" :disabled="!isSaveEnabled" :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"> Confirmar Entrega</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingaCancelar: false,
      pacientes: [],
      pacientesEsterelizavel: [],
      itensParaCancelar: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPrevista: "",
      dataTroca: "",
      dataTrocaEfetivada: "",
      selectedPacienteItems: [],
      itensPacienteDesmarcados: [], 
      filterPaciente: [], 
      isLoadingDados: false,
      requiredField: [e => (e ? true : 'Obrigatório')],
      ItemPacientelHeaders: [
        { text: "Id ", value: "idEsterelizavel" },
        { text: "Id Esterilizável Retirado", value: "idEsterelizavelReferencia" },
        { text: "Id Esterilizável Entregue", value: "idEsterilizavelEntregue" },
        { text: "Esterilizável", value: "esterilizavel" },

      ],

    });
  },
  components: { ConfirmButton },
  methods: {
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        this.pacientes = response.data.filter(item => 
          item.data_solicitacao_troca !== null && 
          (item.data_implantacao !== null) &&
          item.ativo === true &&
          item.esterilizavel.disponibilidade === 14 &&
          (!item.data_solicitacao_retirada || item.data_solicitacao_retirada === '') &&
          item.esterilizavel 
        );
        // console.log('this.pacientes', this.pacientes)
        this.pacientesEsterelizavel = response.data.filter(item => 
          item.ativo === true &&
          item.disponibilidade.id === 13 &&
          (!item.data_solicitacao_retirada || item.data_solicitacao_retirada === '')
        );
        // console.log('this.pacientesEsterelizavel', this.pacientesEsterelizavel)
        if (this.pacientes.length === 0) {
            this.resumoPacientes = [{
                id: 'no-patients',
                // nome: 'Não há pacientes com solicitações de troca pendentes'
            }];
        } else {
          // Primeiro agrupamento para juntar os materiais esterilizáveis
          const agrupamento = this.pacientes.reduce((acc, atual) => {
            // Ajustando a chave para considerar apenas a data sem a hora
            const dataReq = atual.data_solicitacao_troca.split('T')[0]; // Pega apenas a parte da data
            const chave = `${atual.paciente.id}-${dataReq}`;
            
            if (!acc[chave]) {
              // Formata a data de requisição
              const data = new Date(atual.data_solicitacao_troca);
              data.setDate(data.getDate() + 1);
              const dataFormatada = data.toLocaleDateString('pt-BR');
              
              // Inicia com um objeto que conta as ocorrências
              const contagem = {};
              contagem[atual.esterilizavel?.id] = 1;

              acc[chave] = {
                id: chave, // Usando a chave composta como ID
                pacienteId: atual.paciente.id, // Mantém o ID original do paciente
                nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
                esterilizavel: [{
                  id: atual.esterilizavel?.nome?.id,
                  nome: atual.esterilizavel?.nome?.nome,
                  idReferencia: atual.esterilizavel?.id,
                  idEntrega: atual.esterilizavelIdCorrespondente,
                  IdEsterelizavel: atual.id,
                  dataValidade: atual.esterilizavel.data_validade_esterilizacao,
                  quantidade: 1
                }],
                data_solicitacao_troca: atual.data_solicitacao_troca,
                data_prevista_solicitacao_troca: atual.data_prevista_solicitacao_troca,
                data_prevista_implantacao: atual.data_prevista_implantacao,
                _contagem: contagem
              };
            } else {
              // Atualiza a contagem do material
              const nomeEsterilizavel = atual.esterilizavel?.nome?.nome;
              acc[chave]._contagem[nomeEsterilizavel] = (acc[chave]._contagem[nomeEsterilizavel] || 0) + 1;
              
              // Verifica se o material já existe no array
              const materialExistente = acc[chave].esterilizavel.find(item => item.esterelizavel?.nome === nomeEsterilizavel);
              
              if (materialExistente) {
                materialExistente.quantidade = acc[chave]._contagem[nomeEsterilizavel];
              } else {
                acc[chave].esterilizavel.push({
                  id: atual.nome_esterilizavel.id,
                  nome: nomeEsterilizavel,
                  idReferencia: atual.esterilizavel?.id,
                  idEntrega: atual.esterilizavelIdCorrespondente,
                  IdEsterelizavel: atual.id,
                  dataValidade: atual.esterilizavel.data_validade_esterilizacao,
                  quantidade: 1
                });
              }
            }
            return acc;
          }, {});

          // Converte para array final
          this.resumoPacientes = Object.values(agrupamento).map(item => ({
            id: item.id, // ID composto
            pacienteId: item.pacienteId, // ID original do paciente
            nome: item.nome,
            esterilizavel: item.esterilizavel,
            data_solicitacao_troca: item.data_solicitacao_troca,
            data_prevista_solicitacao_troca: item.data_prevista_solicitacao_troca,
            data_prevista_implantacao: item.data_prevista_implantacao
          }))
            .sort((a, b) => {
              // Converte as strings de data para objetos Date para comparação
              const dataA = new Date(a.data_solicitacao_troca);
              const dataB = new Date(b.data_solicitacao_troca);
              return dataA - dataB; // Ordem crescente (mais antigas primeiro)
            });
            // console.log('this.resumoPacientes', this.resumoPacientes)
        }
      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
   async updatePacienteData() {
    this.selectedPacienteItems = []; // Limpa seleções anteriores
    this.selectedEsterelizaveis = []; // Limpa seleções anteriores
    this.esterelizavel = []
    
    const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
    );
    
    if (paciente) {
        this.dataRequisicao = paciente.data_solicitacao_troca || "";
        this.dataPrevista = paciente.data_prevista_solicitacao_troca || "";
        this.IDPaciente = paciente.pacienteId;
      
        // Transforma os itens em formato para tabela
        this.filterPaciente = paciente.esterilizavel.map((item, index) => ({
            id: `${item.id}_${index}`,
            originalId: item.id,
            pacienteId: paciente.pacienteId,
            idEsterelizavelReferencia: item.idReferencia,
            idEsterilizavelEntregue: item.idEntrega,
            idEsterelizavel: item.IdEsterelizavel,
            data_validade: item.dataValidade || 'Não disponível',
            esterilizavel: item.nome,
            quantidade: item.quantidade,
            qtdMarcada: 0
        }));
        // console.log('this.filterPaciente', this.filterPaciente)
        // await this.getEsterelizavel();
        // Pré-seleciona todos os itens da tabela de pacientes
        this.selectedPacienteItems = [...this.filterPaciente];

        // Para cada item selecionado do paciente, procura e seleciona o esterilizável correspondente
        this.selectedPacienteItems.forEach(pacienteItem => {
            const esterilizaveisDisponiveis = this.esterelizavel.filter(
                item => item.nome.id === pacienteItem.originalId &&
                item.disponibilidade?.id === 13 // verifica se está disponível
            );

            // Seleciona a quantidade necessária de esterilizáveis
            const quantidadeNecessaria = pacienteItem.quantidade;
            const esterilizaveisParaSelecionar = esterilizaveisDisponiveis.slice(0, quantidadeNecessaria);

            // Adiciona à seleção
            this.selectedEsterelizaveis = [
                ...this.selectedEsterelizaveis,
                ...esterilizaveisParaSelecionar
            ];
        });
        // console.log(' this.esterelizavels',  this.esterelizavels)
        // Atualiza as quantidades marcadas
        // this.compareItems();
      } else {
          this.dataRequisicao = "";
          this.dataPrevista = "";
          this.filterPaciente = [];
      }
    },

    async atualizarDados() {
      this.isLoadingDados = true;
      try {
        this.selectedPacienteItems = [];
        this.pacienteSelecionado = null;
        this.dataRequisicao = "";
        this.dataPrevista = ''
        this.filterPaciente = [];
        await Promise.all([
          this.getPacientes()
        ]);
      } catch (error) {
        this.$toast.error('Erro ao atualizar os dados');
        console.error(error);
      } finally {
        this.isLoadingDados = false;
      }
    },
    
    async ItensRetiradosPacienteNoEsterelizavelCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelReferencia}/`, {
            id: item.idEsterelizavelReferencia,
            disponibilidade: 4,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEsterelizavelCANCELAR() {
      try {
         const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterilizavelEntregue}/`, {
            id: item.idEsterilizavelEntregue,
            disponibilidade: 6,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar esterilizáveis:', error);
        this.$toast.error('Erro ao atualizar esterilizáveis.');
      }
    },
    async ItensAlteradosNoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel/${item.idEsterelizavel}/`, {
            id: item.idEsterelizavel,
            disponibilidade: 4
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPacienteCANCELAR() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel-id/${item.idEsterilizavelEntregue}/`, {
            esterilizavel: item.idEsterilizavelEntregue,
            disponibilidade: 16,
            ativo: false,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },


    async ItensRetiradosPacienteNoEsterelizavel() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterelizavelReferencia}/`, {
            id: item.idEsterelizavelReferencia,
            disponibilidade: 10,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInluidosPacienteNoEsterelizavel() {

      try {
         const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/equipamentos/esterelizavel_editar/${item.idEsterilizavelEntregue}/`, {
            id: item.idEsterilizavelEntregue,
            disponibilidade: 1,
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar esterilizáveis:', error);
        this.$toast.error('Erro ao atualizar esterilizáveis.');
      }
    }, 
    async ItensAlteradosNoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel/${item.idEsterelizavel}/`, {
            id: item.idEsterelizavel,
            ativo: false,
            data_troca: this.dataTrocaEfetivada,
            troca: null,
            disponibilidade: 17

          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    async ItensInlucidosnoPaciente() {
      try {
        const promises = this.selectedPacienteItems.map((item) =>
          api.put(`/pacientes/esterilizavel-id/${item.idEsterilizavelEntregue}/`, {
            esterilizavel: item.idEsterilizavelEntregue,
            data_implantacao: this.dataTrocaEfetivada,
            // data_troca: this.dataTrocaEfetivada,
            disponibilidade: 1,
            troca: null
          })
        );
        await Promise.all(promises);
      } catch (error) {
        console.error('Erro ao atualizar itens do paciente:', error);
        this.$toast.error('Erro ao atualizar itens do paciente.');
      }
    },
    

    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      await this.ItensRetiradosPacienteNoEsterelizavel();
      await this.ItensInluidosPacienteNoEsterelizavel();  
      await this.ItensAlteradosNoPaciente();
      await this.ItensInlucidosnoPaciente();
      this.$toast.success('Baixado com sucesso!');
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
      
    },



  async cancelarEntrega() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
      this.isLoadingaCancelar = true
      await this.ItensRetiradosPacienteNoEsterelizavelCANCELAR();
      await this.ItensInluidosPacienteNoEsterelizavelCANCELAR();  
      await this.ItensAlteradosNoPacienteCANCELAR();
      await this.ItensInlucidosnoPacienteCANCELAR();
      this.$toast.success('Cancelada com sucesso!');
      this.isLoadingaCancelar = true
      this.$emit('atualizar-pagina-pai');
      this.atualizarDados()
    }

  },
  computed: {
    isSaveEnabled() {
      // Se não houver nenhuma seleção em alguma das tabelas, retorna false
      if (!this.selectedPacienteItems.length ) {
          return false;
      }
      return true;
    },
  },
  mounted() {
    this.getPacientes();
    const hoje = new Date();
    const dataFormatada = hoje.getFullYear() + '-' + 
        String(hoje.getMonth() + 1).padStart(2, '0') + '-' + 
        String(hoje.getDate()).padStart(2, '0');
    this.dataTrocaEfetivada = dataFormatada
  },
};
</script>

<style scoped>
.custom-messages ::v-deep .v-messages__message {
    color: red !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>
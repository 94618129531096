<template>
  <v-container fluid @keydown.enter.prevent="pesquisar">
    <v-row>
      <v-col>
        <div class="title-container">
          <h1 class="h1">Orçamentos / Prorrogações / Aditivos</h1>
          <v-tooltip bottom v-if="contaspendentes > 0">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="badge"> {{ contaspendentes }} </span>
            </template>
            <span>Existe(m) {{ contaspendentes }} orçamento(s) pendente(s) de Aprovação / em Análise</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <!-- <pre>{{filteredData}}</pre> -->
        <v-data-table :loading="isLoading('get')" :items="filteredData" :search="search"
          :headers="headersWithGuiasIncompletas" loading-text="Carregando Informações...."
          no-data-text="Selecione os campos desejados para a pesquisa e clique em 'PESQUISAR'" class="fixed-header-table" fixed-header  height="600px" :items-per-page="-1" >
          <template v-slot:top>
            <v-row class="mt-2">
              <v-col class="d-flex justify-end text-end">
                <v-btn @click="dialog.clone = true" :disabled="lista_items.length <= 0" color="info">Prorrogar</v-btn>
                <v-btn class="ml-4" color="success" @click="dialogoCreate()"> <v-icon> mdi-plus </v-icon> Novo </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                        <v-icon color="green" size="28">mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Exportar para Excel</span>
                  </v-tooltip>
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="elevation-0 ml-5" @click="openModal" v-bind="attrs" v-on="on" style="background-color: transparent;">
                      <v-icon color="green" size="28">mdi-file-document-check-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir Indicador Operadora</span>
                </v-tooltip>
                <!-- Relatórios -->
                <div class="align-self-top py-1" left bottom>
                  <v-menu eager offset-x left offset-y bottom v-model="menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                        mdi-printer
                      </v-icon>
                    </template>
                    <v-container class="container">
                      <template v-if="selectedBudgets.length <= 0">
                        <RelatorioOrcamentoFiltroOrcamento :displayedData="displayedData"
                          :isAnyRowSelected="selectedBudgets.length > 0" :dataInicioOrcamento="filters.dateStart.value"
                          :dataFimOrcamento="filters.dateEnd.value" :username="lista.apelido"></RelatorioOrcamentoFiltroOrcamento>
                      </template>
                      <template v-if="selectedBudgets.length > 0">
                        <orcamento :key="'orcamento-' + selectedBudgets.join(',')" :selectedBudgets="selectedBudgets" :username="lista.apelido"> </orcamento>
                        <GuiaSadt :selectedBudgets="selectedBudgets" :username="lista.apelido"></GuiaSadt>
                        <GuiaSadtLimpa :selectedBudgets="selectedBudgets" :username="lista.apelido"></GuiaSadtLimpa>
                        <!-- <GuiaSadtOutrasDespesas :selectedBudgets="selectedBudgets"></GuiaSadtOutrasDespesas> -->
                        <div v-if="isLoadingEvolucoes" class="com-evolucoes">Carregando mais Relatórios...</div>
                        <div v-else-if="filteredDatas.length === 0" class="sem-evolucoes">Sem Evoluções Lançadas</div>
                        <div v-else>
                          <!-- {{filteredDatasEvolucao}} -->
                          <Evolucao ref="evolucao" :key="selectedOrcamentoId" :data_inicio="selectedOrcamentoDtInicio"
                          :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filtered-data="filteredDatasEvolucao" :username="lista.apelido">
                          </Evolucao>
                      </div>
                      <div v-if="filteredDatasTQT.length > 0" >
                        <TQT ref="evolucao" :data_inicio="selectedOrcamentoDtInicio"
                          :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filteredData="filteredDatasTQT" :username="lista.apelido">
                        </TQT>
                      </div>
                      <div v-if="filteredDatasGTT.length > 0" >
                        <GTT ref="evolucao" :data_inicio="selectedOrcamentoDtInicio"
                          :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filteredData="filteredDatasGTT" :username="lista.apelido">
                        </GTT>
                      </div>
                      <div v-if="casa.length > 0" >
                        <AvaliacaoCasa :selectedBudgets="selectedBudgets" :username="lista.apelido" > </AvaliacaoCasa>
                      </div>
                      <div v-if="captacao.length > 0" >
                        <Captacao :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Captacao>
                      </div>
                      <div v-if="abemid.length > 0" >
                        <Abemid :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Abemid>
                      </div>
                      <div v-if="nead.length > 0" >
                        <Nead :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Nead>
                      </div>
                      <div v-if="pps.length > 0" >
                        <Pps :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Pps>
                      </div>
                      </template>
                    </v-container>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <!-- Pesquisa -->
            <div>
              <v-card dense class="mb-6">
                <h4 class="ml-4 mt-4" color></h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data início orçamento" v-model="filters.dateStart.value"
                      outlined dense clearable />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data término do orçamento"
                      v-model="filters.dateEnd.value" outlined dense clearable />
                  </v-col>
                  <v-col cols="2">
                    <v-select hide-details label="Operadoras" :items="operadoraOptions" v-model="filters.operadora.value"
                      item-text="nome" item-value="id" outlined dense clearable />
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Modalidade" :items="modalidadeOptions"
                      v-model="filters.modalidade.value" item-text="nome" item-value="id" outlined dense clearable />
                  </v-col>
                  <v-col cols="2">
                    <v-select hide-details label="Status Orçamento" :items="statusOptions" v-model="filters.ativo.value"
                      item-text="text" item-value="value" outlined dense clearable>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="Senha" v-model="filters.senha.value" outlined dense clearable type="Number"/>
                  </v-col>
                </v-row>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-text-field hide-details label="ID do Orçamento" v-model="filters.id.value"  type="Number" outlined dense clearable>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="ID do Paciente" v-model="filters.pacienteId.value" outlined dense clearable type="Number"/>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field hide-details label="Nome do Paciente" v-model="filters.nomepaciente.value" outlined dense clearable @input="formatPatientName" />
                  </v-col>
                  <v-col class="text-end mx-4">
                    <v-btn class="mr-4" color="red" text @click="clearFilters"> limpar Pesquisa</v-btn>
                    <v-btn class="text-end" color="red" text @click="clearSelecao" :disabled="!selectedBudgets.length && !lista_items.length"> limpar Seleção </v-btn>
                    <v-btn color="success" @click="applyFilters"> Pesquisar </v-btn>
                  </v-col>
                </v-row>
              </v-card>           
           </div>

           <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row class="align-center">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <h3 class="blod_color">Resumo...</h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <h3 class="blod_color mb-5" style="text-align: center; width: 100%;"> DADOS RESUMIDOS </h3>
                </v-row>
                <v-row dense >
                  <v-col cols="4">
                    <div><span class="blod_color">Resumo de Valores dos Orçamentos</span></div>
                    <div v-if="isLoading('get:patients')">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else>
                      <div><span>- Valor dos Orçamento (ID) = {{ totalFatorOrcamentoID() }}</span></div>
                      <div><span>- Valor dos Orçamento (AD) = {{ totalFatorOrcamentoAD() }}</span></div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div><span class="blod_color">Quantidade de Pacientes</span></div>
                    <div v-if="isLoading('get:patients')">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else>
                      <div><span>- QTD dos Paciente (ID) = {{ quantidadePacientesID() }}</span></div>
                      <div><span>- QTD dos Paciente (AD) = {{ quantidadePacientesAD() }}</span></div>
                    </div>
                  </v-col>
                   <v-col cols="2">
                    <div><span class="blod_color">Por Modalidade</span></div>
                    <div v-if="isLoading('get:patients')">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else>
                      <!-- Loop sobre a lista de modalidades -->
                      <div v-for="(modalidade, index) in totalPacientesPorModalidade()" :key="index">
                        <span>{{ modalidade }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div><span class="blod_color">Status dos Orçamentos</span></div>
                    <div v-if="isLoading('get:patients')">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else>
                      <div><span>- Aprovados = {{ totalPacientesAprovados() }}</span></div>
                      <div><span>- Em Análise = {{ totalPacientesEmAnalise() }}</span></div>
                      <div><span>- Reprovados = {{ totalPacientesReprovados() }}</span></div>
                      <div><span>- Atend. Suspenpenso = {{ totalPacientesSuspensos() }}</span></div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


          </template>
          <template v-slot:[`item.equipe`]="{ item }">
            <v-chip class="ma-1" v-for="i in item.equipe" :key="i.id">
              {{ i.first_name }} - {{ i.paciente_id }}
            </v-chip>
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            <span v-if="item.data_inicio">
              {{ item.data_inicio | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.fatorcamento`]="{ item }">
            {{ item.fatorcamento | formatReal }}
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            <span v-if="item.data_fim">
              {{ item.data_fim | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.senha_data_inicio`]="{ item }">
            <span v-if="item.senha_data_inicio">
              {{ item.senha_data_inicio | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.senha_data_validade`]="{ item }">
            <span v-if="item.senha_data_validade">
              {{ item.senha_data_validade | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-tooltip v-if="item.suspender_atendimento" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="30" color="red">mdi-hospital-building</v-icon>
              </template>
              Atendimento Suspenso
            </v-tooltip>
            <v-tooltip v-else-if="item.ativo && item.reprovado == '0'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="30" color="green">mdi-check-circle-outline</v-icon>
              </template>
              Aprovado
            </v-tooltip>
            <v-tooltip v-else-if="!item.ativo && item.reprovado == '0'" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="30" color="purple">mdi-help-circle-outline</v-icon>
              </template>
              Em Análise
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="30" color="red">mdi-close-circle-outline</v-icon>
              </template>
              Reprovado
            </v-tooltip>
          </template>
          <template v-slot:[`item.copiar`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox v-model="lista_items" :value="item.id"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox :input-value="selectedBudgets[0] === item.id" @change="handleCheckboxChange(item)"></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <template v-if="item.manutencao === '1' && item.fechamento === '0'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                    <v-icon size="30" color="red">mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>Em faturamento!!!</span>
              </v-tooltip>
            </template>
            <template v-else-if="item.manutencao === '1' && item.fechamento === '1'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                    <v-icon size="30" color="blue">mdi-eye-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>Conta faturada!!!</span>
              </v-tooltip>
            </template>
            <template v-else-if="item.manutencao === '0' && item.fechamento === '0'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                    <v-icon color="green">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Clique para editar</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :style="{
                  color:
                    item.senha !== null &&
                      item.nr_guia_operador !== null &&
                      item.nr_guia_prestador !== null &&
                      item.senha_data_inicio !== null &&
                      item.senha_data_validade !== null
                      ? 'green' : 'red'
                }">{{ item.id }}</span>
              </template>
              <span v-if="item.senha !== null &&
                item.nr_guia_operador !== null &&
                item.nr_guia_prestador !== null &&
                item.senha_data_inicio !== null &&
                item.senha_data_validade !== null">Guia Completa</span>
              <span v-else>Guia Incompleta</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <IndicadorOperadora v-model="showModal" :showModal="showModal" @update:showModal="showModal = false"></IndicadorOperadora>
    <FullscreenDialog v-model="dialog.update">
      <v-card>
        <v-card-title class="sticky-title title-border">
          Edição de Orçamento Nrº {{ selectedRow.id }} | Paciente: {{ selectedRow.paciente }}
          <v-spacer></v-spacer>
          <v-btn icon class="sticky-icon" @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <EditForm :onDeleteSession="deleteSession" :onsubmit="updateSession" :budgetId="selectedRow.id"
            :IdOrcamento="selectedRow.id" :updateSession="getSessions" :is-loading="isLoading.bind(this)"
            :set-loading="setLoading.bind(this)">
          </EditForm>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <v-dialog v-if="dialog.clone" v-model="dialog.clone" maxWidth="100%">
      <v-card>
        <v-container fluid>
          <v-card-title>
            <h6 class="text-h6 blod_color">Prorrogar Orçamento</h6>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.clone = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <ExtendBudgetForm :onSubmit="cloneSessions" :selectedRows="selectedRows" :modalities="modalidades"
              :loading="isLoading('post:clone')" />
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.create" maxWidth="100%">
      <v-card>
        <v-container fluid>
          <v-card-title>
            <h6 class="text-h6 blod_color">Novo Orçamento</h6>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog.create = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="newSession" ref="form_session">
              <v-row dense>
                <v-col :cols="3">
                  <v-autocomplete v-model="newItem.operadora" return-object name="operadora" outlined clearable
                    :items="operadoras" item-value="id" item-text="nome" required :rules="requiredField"
                    @change="e => getChildrensOperadoras(e)">
                    <template v-slot:label>
                      <span>Operadora <span style="color: red;">*</span></span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col :cols="4">
                  <v-autocomplete return-object v-model="newItem.paciente" name="paciente" outlined clearable
                    :items="pacientes" item-value="id" item-text="nome" required @change="e => (pacienteId = e.id)"
                    :rules="requiredField">
                    <template v-slot:label>
                      <span>Paciente <span style="color: red;">*</span></span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col :cols="1">
                  <v-text-field v-model="paciente.id" name="paciente_id" readonly outlined
                    label="ID do Paciente"></v-text-field>
                </v-col>
                <v-col :cols="2">
                  <v-text-field v-model="paciente.data_nascimento" name="data_nascimento" readonly outlined
                    label="Data de Nascimento" type="date"></v-text-field>
                </v-col>
                <v-col :cols="2">
                  <v-autocomplete v-model="newItem.modalidade" name="modalidade" outlined clearable :items="modalidades"
                    item-value="id" item-text="nome" required :rules="requiredField">
                    <template v-slot:label>
                      <span>Modalidade <span style="color: red;">*</span></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.data_inicio" name="data_inicio" required outlined clearable type="date"
                    :rules="requiredField">
                    <template v-slot:label>
                      <span>Data de início - Orçamento <span style="color: red;">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.data_fim" name="data_fim" required outlined clearable type="date"
                    :rules="requiredField">
                    <template v-slot:label>
                      <span>Data de término - Orçamento <span style="color: red;">*</span></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field  outlined disabled label="QTD Dias Orçados" :value="calculatedDays" ></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.nr_guia_prestador" name="nr_guia_prestador" outlined clearable
                    label="Nrº guia do prestador" required></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.senha" name="senha" outlined label="Senha" clearable
                    required></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.nr_guia_operador" name="nr_guia_operador" outlined clearable
                    label="Nrº guia da operadora" required></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.senha_data_inicio" name="senha_data_inicio" outlined clearable
                    label="Data início - Validade da senha" type="date" required></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-text-field v-model="newItem.senha_data_validade" name="senha_data_fim" outlined clearable
                    label="Data fim - Validade da senha" type="date" required></v-text-field>
                </v-col>

              </v-row>
              <v-row dense class="justify-end gap-4">
                <v-col class="text-end">
                  <v-tooltip top :disabled="isFormValid">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs">
                        <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting"
                          :disabled="!isFormValid || isSubmitting"> Salvar</ConfirmButton>
                      </span>
                    </template>
                    <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import EditForm from './componentes/EditForm.vue';
import ConfirmButton from "../ConfirmButton.vue";
import FullscreenDialog from "../FullscreenDialog.vue";
import ExtendBudgetForm from "./componentes/ExtendBudgetForm.vue";
import Orcamento from '../relatorios/orcamento/Orcamento.vue';
import GuiaSadt from '../relatorios/sadt/Sadt.vue';
import GuiaSadtLimpa from '../relatorios/sadt/SadtLimpa.vue';
// import GuiaSadtOutrasDespesas from '../relatorios/sadt/SadtOutrasDespesas.vue';
import '../../assets/css/styles.css';
import { mapGetters } from "vuex";
import '../../assets/css/styles.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import IndicadorOperadora from '../relatorios/indicadores/IndicadorOperadora.vue';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Sessoes",
  components: {
    EditForm,
    Orcamento,
    GuiaSadt,
    GuiaSadtLimpa,
    // GuiaSadtOutrasDespesas,
    ConfirmButton,
    FullscreenDialog,
    ExtendBudgetForm,
    Evolucao: () => import('../relatorios/evolucoes/Evolucoes.vue'),
    TQT: () => import('../relatorios/evolucoes/Troca_TQT.vue'),
    GTT: () => import('../relatorios/evolucoes/Troca_GTT.vue'),
    AvaliacaoCasa: () => import('../relatorios/avaliacao/Casa.vue'),
    Abemid: () => import('../relatorios/avaliacao/Abemid.vue'),
    Captacao: () => import('../relatorios/avaliacao/Captacao.vue'),
    Pps:() => import('../relatorios/avaliacao/Paliativo.vue'),
    Nead:() => import('../relatorios/avaliacao/Nead.vue'),
    RelatorioOrcamentoFiltroOrcamento: () => import('../relatorios/orcamento/RelatorioOrcamentoFiltroOrcamento.vue'),
    IndicadorOperadora,
  },
  props: {
    id: { type: null }
  },
  data: () => withCRUDUtils({
    dialog: {
      clone: false,
      create: false,
    },
    requiredField: [e => (e !== null && e !== undefined) || 'Obrigatório'],
    errorMessage: 'Campo obrigatório',
    operadora_: "",
    search: "",
    paciente_: "",
    data_inicio: '',
    data_fim: '',
    modalidades: [],
    activeFilters: [],
    modalidadeOptions: [],
    operadoraOptions: [],
    menu: false,
    filters: {
      dateStart: { value: '', compareType: 'gte', prop: 'data_inicio' },
      dateEnd: { value: '', compareType: 'lte', prop: 'data_fim' },
      modalidade: { value: '', compareType: 'equal', prop: 'modalidade_o.id' },
      operadora: { value: '', compareType: 'equal', prop: 'operadora_o.id' },
      ativo: { value: '', compareType: 'equal', prop: 'ativo' },
      senha: { value: '', compareType: 'includes', prop: 'senha' },
      pacienteId: { value: '', compareType: 'includes', prop: 'paciente_id' },
      nomepaciente: { value: '', compareType: 'includes', prop: 'paciente' },
      id: { value: '', compareType: 'includes', prop: 'id' },
    },
    statusOptions: [
      { text: "Aprovado", value: true },
      { text: "Em Análise ou Reprovado", value: false },
    ],
    newItem: {},
    showFilters: false,
    pacienteId: 0,
    operadoras: [],
    pacientes: [],
    lista_items: [],
    evolutions: [],
    selectedEvolutions: [],
    budgets: [],
    selectedBudgets: [],
    loading: false,
    displayedData: [],
    isSubmitting: false,
    headers: [
      { text: "Orçamento aprovado", align: "center", sortable: true, value: "ativo" },
      { text: "Editar orçamento", align: "center", sortable: false, value: "edit" },
      { text: "Selecionar orçamento gerar PDF", align: "center", sortable: false, value: "select_budget" },
      { text: "Prorrogar", align: "start", sortable: false, value: "copiar" },
      { text: "Nrº orçamento", class: "font-weight-black", align: "center", sortable: true, value: "id", },
      { text: "Convênio", align: "center", sortable: true, value: "convenio" },
      { text: "ID paciente", align: "center", sortable: true, value: "paciente_id" },
      { text: "Paciente", align: "center", sortable: true, value: "paciente" },
      { text: "Modalidade", align: "center", sortable: true, value: "modalidade_o.nome" },
      { text: "Data inicio - Orçamento", align: "center", sortable: true, value: "data_inicio" },
      { text: "Data término - Orçamento", align: "center", sortable: true, value: "data_fim" },
      { text: "Senha", align: "center", sortable: true, value: "senha" },
      { text: "Valor Orçado", align: "center", sortable: true, value: "fatorcamento" },
      // { text: "Data Inicio -Validade Senha", align: "center", sortable: true, value: "senha_data_inicio" },
      // { text: "Data Fim - Validade Senha", align: "center", sortable: true, value: "senha_data_validade" },
      // { text: "Nrº Guia   da Operadora", align: "center", sortable: true, value: "nr_guia_operador" },
      // { text: "Nrº Guia do Prestador", align: "center", sortable: true, value: "nr_guia_prestador" },
    ],
    electedOrcamentoId: null,
    idsEvolucoes: [],
    selectedOrcamentoDtInicio: null,
    selectedOrcamentoDtFim: null,
    filteredDatas: [],
    filteredDatasEvolucao: [],
    filteredDatasTQT: [],
    filteredDatasGTT:[],
    casa: [],
    abemid: [],
    pps:[],
    nead: [],
    captacao: [],
    isLoadingEvolucoes: false,
    contaspendentes: 0,
    showModal: false,
  }),
  methods: {
    formatPatientName() {
      if (this.filters && this.filters.nomepaciente && this.filters.nomepaciente.value) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.filters.nomepaciente.value = this.filters.nomepaciente.value
          .toLowerCase()
          .split(" ")
          .map(word => {
            return lowercaseWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
      }
    },
    // Metodo para listar as operadoras e modalidade para o filtro
    async get_listas(url, lista) {
      try {
        const response = await api.get(url);
        if (response.status === 200) {
          if (response.data instanceof Array && response.data.length) {
            // Ordena o array com base no campo 'nome'
            // console.log('aqui', response.data)
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });

            // Filtra o array para manter apenas os elementos com 'ativo' igual a true
            const filteredData = response.data.filter(item => item.ativo === true);

            // Atualiza a lista com os dados filtrados
            this[lista] = filteredData;
          }
        }
      } catch (error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    async getOperadoras() {
      try {
        const { data } = await api.get('operadoras/operadoras/');
        this.operadoras = data
          .filter(operadora => operadora.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.operadoras)
      } catch (error) {
        console.error("Erro ao listar as Operadoras", error)
      }
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data
          .filter(modalidade => modalidade.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        console.error("Erro ao listar as Operadoras");
      }
    },
    async getMotivoReprovacao() {
      try {
        const { data } = await api.get('uteis/motivoreprovacao/');
        this.motivoreprovacao = data
          .filter(motivoreprovacao => motivoreprovacao.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        console.error("Erro ao listar Motivo de Reprovação", error);
      }
    },
    async getPacientes() {
      try {
        const { data } = await api.get(`pacientes/listar/?operadora=${this.newItem.operadora.id}&ativo=1&empresa=${this.companyData.id}`);
        this.pacientes = data.sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        console.error("Erro no link ao solicitar equipe");
      }
    },
    async getChildrensOperadoras() {
      this.getPacientes();
    },
    updateDisplayedData() {
      // Filtrar os dados apenas se eles não forem null ou undefined
      const filteredByActiveFilters = this.tableData.filter(item => {
        // Verifica se o item é válido e se passa no filtro ativo
        return item && UtilsFunc.createFilter(this.activeFilters)(item);
      });

      if (this.search) {
        this.displayedData = filteredByActiveFilters.filter(item => {
         // Verifica se o item é válido antes de tentar criar a string de pesquisa
          if (!item) return false;

          const itemString = [
            item.id,
            item.convenio,
            item.paciente,
            item.modalidade,
            item.senha,
            item.nr_guia_operador,
            item.nr_guia_prestador,
            item.paciente_id
          ].join(' ').toLowerCase();

          return itemString.includes(this.search.toLowerCase());
        });
      } else {
        this.displayedData = filteredByActiveFilters;
      }

      // Verifica e formata dados se item não for null
      this.displayedData = this.displayedData.map(item => {
        if (!item) return null;
        return {
          ...item,
          fatorcamentoFormatted: item.fatorcamento,
          fatrealizadoFormatted: item.fatrealizado
        };
      }).filter(item => item !== null); // Remove qualquer item null da lista final

      // Recalcula o número de guias incompletas
      this.contaspendentes = this.displayedData.filter(item => item && item.fechamento === '0').length;
    },
    async newSession() {
      const isValid = this.$refs.form_session.validate()
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      // Converte as datas para objetos Date
      const dataInicio = new Date(this.newItem.data_inicio);
      const dataFim = new Date(this.newItem.data_fim);

      // Verifica se a data de início é anterior à data de término
      if (dataInicio > dataFim) {
        // Exibe uma mensagem de erro e interrompe a execução
        this.$toast.error('A data de término não pode ser anterior à data de início.');
        return;
      }

      const fields = this.newItem

      const LOADING_NAME = 'post:session'
      this.setLoading(LOADING_NAME)
      this.isSubmitting = true;
      try {
        const data = {
          ...fields,
          ativo: false,
        }
        await api.post('atendimentos/sessoes_post/', data)
        await this.getSessions()
        this.dialog.create = false
        this.pacienteId = 0
        this.newItem = {}
        this.$toast.success('Orçamento criado com sucesso!')
        this.$refs.form_session.reset()

      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data;
          const regex = /<pre class="exception_value">\(\d+,\s*&#x27;(.*?)&#x27;\)<\/pre>/;
          const match = errorMessage.match(regex);

          if (match && match[1]) {
            this.$toast.error(match[1]); // Mensagem do erro
          } else {
            this.$toast.error("Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.");
          }
        } else if (error.request) {
          console.error('Sem resposta do servidor:', error.request);
          this.$toast.error('Sem resposta do servidor. Verifique sua conexão com a internet.');
        } else {
          console.error('Erro ao enviar a solicitação:', error.message);
          this.$toast.error('Erro ao enviar a solicitação. Tente novamente mais tarde.');
        }
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
    async updateSession(fields) {
      if (fields.motivoreprovacao_o && typeof fields.motivoreprovacao_o === 'object') {
        fields.motivoreprovacao = fields.motivoreprovacao_o.id;
      }
      if (fields.hospital_o && typeof fields.hospital_o === 'object') {
        fields.hospital = fields.hospital_o.id;
      }
      if (fields.motivo_suspensao_atendimento_o && typeof fields.motivo_suspensao_atendimento_o === 'object') {
        fields.motivo_suspensao_atendimento = fields.motivo_suspensao_atendimento_o.id;
      }
      const LOADING_NAME = 'put:sessao'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/sessoes_put/${fields.id}/`, fields)
        this.$toast.success('Orçamento atualizado com sucesso!')
        await this.getSessions()
        this.setLoading(LOADING_NAME, false)
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe, Aconteceu algum erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async deleteSession() {
      const LOADING_NAME = 'delete:sessao'
      this.setLoading(LOADING_NAME)
      try {
        await api.delete(`atendimentos/sessao/${this.selectedRow.id}/`)
        this.$toast.success('Orçamento deletado com sucesso!')
        await this.getSessions()
        this.dialog.update = false
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe, Aconteceu algum erro... ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getSessions() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      try {
          let params = {
              date_start: this.filters.dateStart.value,
              date_end: this.filters.dateEnd.value,
              operadora: this.filters.operadora.value ? this.filters.operadora.value : 0,
              modalidade: this.filters.modalidade.value ? this.filters.modalidade.value : 0,
              empresa: 1,
          };

          // Adiciona o campo "ativo" apenas se o valor for true ou false
          if (this.filters.ativo.value === true || this.filters.ativo.value === false) {
              params.ativo = this.filters.ativo.value === true ? 'true' : 'false';
          }
          
          if (this.filters.id.value) {
              params.id = this.filters.id.value;
          }
          
          if (this.filters.nomepaciente.value) {
              params.nomepaciente = this.filters.nomepaciente.value
          }
          if (this.filters.pacienteId.value) {
              params.paciente = this.filters.pacienteId.value
          }
          if (this.filters.senha.value) {
              params.senha = this.filters.senha.value
          }

          const { data } = await api.get("atendimentos/orcamentos/", { params });

          this.tableData = data.map(e => {
              try {
                  e['paciente_o'] = JSON.parse(e.paciente_obj) || '{}';
                  e['operadora_o'] = JSON.parse(e.operadora_obj) || '{}';
                  e['modalidade_o'] = JSON.parse(e.modalidade) || '{}';
                  e['motivoreprovacao_o'] = JSON.parse(e.motivoreprovacao) || '{}';
              } catch (error) {
                  console.error('Erro na string JSON:', error);
              }
              return e;
          }).sort((a, b) => {
              const dateA = new Date(a.data_inicio);
              const dateB = new Date(b.data_inicio);
              return dateA - dateB;
          });

          this.displayedData = this.filteredData;
          this.contaspendentes = this.displayedData.filter(item => item.ativo === false && item.reprovado == "0").length;
          this.updateDisplayedData();
      } catch (error) {
        console.error('Erro ao buscar sessões:', error);
          this.$toast.error(`Desculpe, aconteceu um erro: ${error}`);
      } finally {
          this.setLoading(LOADING_NAME, true);
      }
    },
    async cloneSessions(fields, selectedRows) {
      console.log('fields -->',fields)
      console.log('selectedRows', selectedRows)
      const LOADING_NAME = "post:clone"
      const budgetDetails = await Promise.all(selectedRows.map(async (row) => {
        const { data } = await api.get(`atendimentos/sessoes/?orcamento=${row.id}`)

        const details = data[0]
        return {
          data_inicio: fields.data_inicio,
          data_fim: fields.data_fim,
          senha_data_inicio: fields.data_inicio,
          senha_data_validade: fields.data_fim,
          ativo: fields.ativo,
          modalidade: row.modalidade.id,
          operadora: { id: details.operadora_id },
          paciente: { id: details.paciente_id },
          originId: row.id,
        }
      }))

      this.setLoading(LOADING_NAME)

      try {

        await Promise.all(budgetDetails.map(async (row) => {
          const { data } = await api.post('atendimentos/sessoes_post/', row)

          const proceduresToCreate = selectedRows.find((detailedRow) => detailedRow.id === row.originId)?.maxProcedures || []
          const taxesToCreate = selectedRows.find((detailedRow) => detailedRow.id === row.originId)?.maxTaxes || []
          const equipamentosToCreate = selectedRows.find((detailedRow) => detailedRow.id === row.originId)?.maxEquipamentos || []

          for (const tax of taxesToCreate) {
            const amountToCreate = tax.amountTaxesToCreate
            // console.log(amountToCreate)
            for (let i = 0; i < amountToCreate; i++) {

              await api.post('atendimentos/taxas/', {
                data_fim_taxa: fields.data_inicio,
                data_inicio_taxa: fields.data_inicio ,
                pacote: tax.pacote,
                quantidade_taxa: 1,
                taxa: tax.taxa?.id,
                valor_cobranca_final_taxa: tax.valor,
                taxa_ref: tax.taxa_ref,
                paciente: tax.paciente?.id,
                operadora: tax.operadora,
                sessao: data.id, 
              })
            }
          }
          for (const equ of equipamentosToCreate) {
            if (!equ.amountEquipamentosToCreate || equ.amountEquipamentosToCreate <= 0) {
              continue; // Ignora equipamentos com quantidade vazia ou 0
            }

              const valorCobrancaFinal = equ.valor === null ? null  : parseFloat((equ.valor * equ.amountEquipamentosToCreate).toFixed(2));
              await api.post('atendimentos/equipamentos/', {
                data_fim_equipamento: fields.data_inicio,
                data_inicio_equipamento: fields.data_inicio ,
                pacote: equ.pacote,
                quantidade_equipamento: equ.amountEquipamentosToCreate ,
                valor_cobranca_final_equipamento: valorCobrancaFinal,
                equipamento: equ.equipamento?.id,
                equipamento_ref: equ.equipamento_ref,
                paciente: equ.paciente?.id,
                operadora: equ.operadora,
                sessao: data.id, 
              })
            }

          for (const procedure of proceduresToCreate) {
            const amountToCreate = procedure.amountProceduresToCreate
            for (let i = 0; i < amountToCreate; i++) {
              await api.post('atendimentos/evolucoes-post/', {
                sessao: data.id,
                frequencia: procedure.frequencia.id,
                procedimento: procedure.procedimento.id,
                valor_cobranca: procedure.procedimento.preco,
                valor_cobranca_final: procedure.procedimento.preco
              })
            }
          }

        }))
        this.$toast.success('Orçamento(s) Prorrogado(s) com sucesso!')
        this.dialog.clone = false
        this.lista_items = []
        await this.getSessions()
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }

    },
    clearFilters() {
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.calcularDatasDinamicas()
      this.activeFilters = []
      this.updateDisplayedData();
      // this.dateStartError = false;
      // this.dateEndError = false;
      this.contaspendentes = 0;
      this.search = '';
    },
    clearSelecao() {  
      this.selectedBudgets = [];
      this.lista_items = [];
    },
    applyFilters() {
      // Verifica se todos os filtros estão vazios
      const areAllFiltersEmpty = Object.values(this.filters).every(filter => !filter.value);
      if (areAllFiltersEmpty) {
        this.$toast.error('Por favor, escolha pelo menos um critério de pesquisa.');
        return;
      }

      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters).filter(filter => {
        // Inclui o filtro somente se ele tem um valor (não nulo e não vazio)
        if (filter.value === '' || filter.value === null) {
          return false;
        }
        
        // Tratamento especial para campos como 'modalidade_o.id'
        if (filter.prop === 'modalidade_o.id') {
          return this.tableData.some(data =>
          data.modalidade_o && data.modalidade_o.id === filter.value
          );
        }
        
        return true;
      });
      // console.log(this.activeFilters)

      this.getSessions();
    },
    pesquisar() {
      this.applyFilters()
    },
    dialogoCreate() {
      this.dialog.create = true
      this.newItem.nr_guia_prestador = this.tableData.length + 1
    },
    handleCheckboxChange(item) {
      this.idsEvolucoes = [];
      this.filteredDatasEvolucao = [];
      this.filteredDatasTQT = [];
      this.filteredDatasGTT = [];
      this.casa = [];
      this.abemid = [];
      this.pps = [];
      this.nead = [];
      this.captacao = [];
      if (this.selectedBudgets.includes(item.id)) {
        this.selectedBudgets = [];
        this.selectedOrcamentoId = null;
      } else {
        this.selectedBudgets = [item.id];
        this.selectedOrcamentoId = item.id;
        this.selectedOrcamentoDtInicio = item.data_inicio
        this.selectedOrcamentoDtFim = item.data_fim
        this.getEvolucoes()
      }
    },
    async getEvolucoes() {
      this.isLoadingEvolucoes = true;
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedOrcamentoId}`)
        this.filteredDatas = data.filter(item => item.assinatura !== null && item.ativo === true);
        this.filteredDatasEvolucao = data.filter(item => item.assinatura !== null && item.ativo === true && !(item.procedimento.procedimento_id === 16 || item.procedimento.procedimento_id === 17));
        this.filteredDatasTQT = data.filter(item => item.assinatura !== null && item.ativo === true && item.procedimento.procedimento_id === 16 );
        this.filteredDatasGTT = data.filter(item => item.assinatura !== null && item.ativo === true && item.procedimento.procedimento_id === 17 );
        this.casa = data.filter(item => item.ativo === true && item.casa !== null)
        this.abemid = data.filter(item => item.ativo === true && item.abemid !== null)
        this.pps = data.filter(item => item.ativo === true && item.pps !== null)
        this.nead = data.filter(item => item.ativo === true && item.nead !== null)
        this.captacao = data.filter(item => item.ativo === true && item.captacao !== null)
        this.idsEvolucoes = data
          .filter(item => item.assinatura !== null && item.ativo === true)
          .map(item => item.id);
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpa, aconteceu alguma coisa errada. Erro -> ${error}`)
      } finally {
        this.isLoadingEvolucoes = false;
      }
    },
    calcularDatasDinamicas() {
      // Obtain the second day of the previous month
      const today = new Date();
      const primeiroDiaDoMesAtual = new Date(today.getFullYear(), today.getMonth(), 1);
      const segundoDiaDoMesAnterior = new Date(primeiroDiaDoMesAtual);
      segundoDiaDoMesAnterior.setMonth(primeiroDiaDoMesAtual.getMonth() - 1);
      segundoDiaDoMesAnterior.setDate(1);

      // Pega do ultimo dia no mês
      const ultimoDiaDoMesAtual = new Date(today.getFullYear(), today.getMonth() + 5, 0);

      // Formartar a data 
      this.filters.dateStart.value = `${segundoDiaDoMesAnterior.getFullYear()}-${(segundoDiaDoMesAnterior.getMonth() + 1).toString().padStart(2, '0')}-${segundoDiaDoMesAnterior.getDate().toString().padStart(2, '0')}`;
      this.filters.dateEnd.value = `${ultimoDiaDoMesAtual.getFullYear()}-${(ultimoDiaDoMesAtual.getMonth() + 1).toString().padStart(2, '0')}-${ultimoDiaDoMesAtual.getDate().toString().padStart(2, '0')}`;
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orçamentos');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'orcamentos.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
    openModal() {
      this.showModal = true; // Abre o modal
    },
    closeModal() {
      this.showModal = false; // Fecha o modal
    },
    totalFatorOrcamentoID() {
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return (
          (paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === false) &&
          paciente.modalidade_o && [1, 3, 4, 5].includes(paciente.modalidade_o.id) &&
          paciente.fatorcamento
        );
      });

      const total = pacientesFiltrados.reduce((acc, paciente) => {
        return acc + parseFloat(paciente.fatorcamento || 0);
      }, 0);

      const totalGeral = this.totalFatorOrcamentoGeral();
      const percentual = totalGeral ? (total / totalGeral) * 100 : 0;

      return total ? `R$ ${total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} (${percentual.toFixed(2)}%)` : 'R$ 0,00 (0%)';
    },

    totalFatorOrcamentoAD() {
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return (
          (paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === false) &&
          paciente.modalidade_o && ![1, 3, 4, 5].includes(paciente.modalidade_o.id) &&
          paciente.fatorcamento
        );
      });

      const total = pacientesFiltrados.reduce((acc, paciente) => {
        return acc + parseFloat(paciente.fatorcamento || 0);
      }, 0);

      const totalGeral = this.totalFatorOrcamentoGeral();
      const percentual = totalGeral ? (total / totalGeral) * 100 : 0;

      return total ? `R$ ${total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} (${percentual.toFixed(2)}%)` : 'R$ 0,00 (0%)';
    },
    totalFatorOrcamentoGeral() {
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return paciente.suspender_atendimento === false && paciente.fatorcamento;
      });

      return pacientesFiltrados.reduce((acc, paciente) => {
        return acc + parseFloat(paciente.fatorcamento || 0);
      }, 0);
    },  

    quantidadePacientesID() {
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return (
          (paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === false) &&
          paciente.modalidade_o && [1, 3, 4, 5].includes(paciente.modalidade_o.id) // IDs das modalidades ID
        );
      });

      // Garantindo que os pacientes são únicos pelo paciente_id
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));
      const totalPacientesID = pacientesUnicos.size;

      // Pegando o total de pacientes sem suspender atendimento
      const totalPacientesGeral = this.totalPacientes();

      // Calculando o percentual
      const percentual = totalPacientesGeral ? (totalPacientesID / totalPacientesGeral) * 100 : 0;

      return totalPacientesID ? `${totalPacientesID} (${percentual.toFixed(2)}%)` : '0 (0%)';
    },

    quantidadePacientesAD() {
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return (
          (paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === false) &&
          paciente.modalidade_o && ![1, 3, 4, 5].includes(paciente.modalidade_o.id) // Excluindo IDs das modalidades ID
        );
      });

      // Garantindo que os pacientes são únicos pelo paciente_id
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));
      const totalPacientesAD = pacientesUnicos.size;

      // Pegando o total de pacientes sem suspender atendimento
      const totalPacientesGeral = this.totalPacientes();

      // Calculando o percentual
      const percentual = totalPacientesGeral ? (totalPacientesAD / totalPacientesGeral) * 100 : 0;

      return totalPacientesAD ? `${totalPacientesAD} (${percentual.toFixed(2)}%)` : '0 (0%)';
    },

    totalPacientes() {
      // Filtra apenas os pacientes que não estão com atendimento suspenso
      const pacientesFiltrados = this.tableData.filter(paciente => paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === false );

      // Garante que o paciente_id seja único
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));
      
      return pacientesUnicos.size;
    },



    
    totalPacientesPorModalidade() {
      const modalidadesContagem = {};

      const pacientesFiltrados = this.tableData.filter(paciente => paciente.suspender_atendimento === false || paciente.reprovado === "0" || paciente.ativo === true);

      pacientesFiltrados.forEach(paciente => {
        if (paciente.modalidade_o && paciente.paciente_id) {
          const modalidadeNome = paciente.modalidade_o.nome;

          if (!modalidadesContagem[modalidadeNome]) {
            modalidadesContagem[modalidadeNome] = new Set();
          }

          modalidadesContagem[modalidadeNome].add(paciente.paciente_id);
        }
      });

      const totalPacientesGeral = this.totalPacientes();

      return Object.entries(modalidadesContagem).map(([modalidade, pacientes]) => {
        const quantidade = pacientes.size;
        const percentual = totalPacientesGeral ? (quantidade / totalPacientesGeral) * 100 : 0;

        return `${modalidade} = ${quantidade}  (${percentual.toFixed(2)}%)`;
      });
    },
    totalPacientesAprovados() {
      // Filtrando pacientes aprovados (ativo = true e reprovado = '0')
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return paciente.ativo === true || paciente.reprovado === '0' || paciente.suspender_atendimento === false;
      });

      // Usando Set para garantir que o paciente_id seja único
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));

      // Retornando o número de pacientes únicos
      return pacientesUnicos.size;
    },

    totalPacientesEmAnalise() {
      // Filtrando pacientes em análise (ativo = false e reprovado = '0')
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return paciente.ativo === false && paciente.reprovado === '0';
      });

      // Usando Set para garantir que o paciente_id seja único
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));

      // Retornando o número de pacientes únicos
      return pacientesUnicos.size;
    },

    totalPacientesReprovados() {
      // Filtrando pacientes reprovados (reprovado = '1')
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return paciente.reprovado === '1';
      });

      // Usando Set para garantir que o paciente_id seja único
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));

      // Retornando o número de pacientes únicos
      return pacientesUnicos.size;
    },

    totalPacientesSuspensos() {
      // Filtrando pacientes com atendimento suspenso (suspender_atendimento = true)
      const pacientesFiltrados = this.tableData.filter(paciente => {
        return paciente.suspender_atendimento === true;
      });

      // Usando Set para garantir que o paciente_id seja único
      const pacientesUnicos = new Set(pacientesFiltrados.map(paciente => paciente.paciente_id));

      // Retornando o número de pacientes únicos
      return pacientesUnicos.size;
    },

  },
  mounted() {
    this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
    this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
    this.getOperadoras()
    // this.getSessions()
    this.getMotivoReprovacao()
    this.getModalidades()
    this.calcularDatasDinamicas();
    // this.applyFilters()
    // this.updateDisplayedData();
    // if (this.id !== undefined) {
    //   this.activeFilters = [{ value: Number(this.id), compareType: 'equal', prop: 'id' }]
    //     ; (async () => {
    //       await this.getSessions()
    //       await this.getOperadoras();

    //       if (this.id) {
    //         const currentItem = this.tableData.find(r => r.id === Number(this.id))
    //         this.selectedRow = { ...currentItem }
    //         this.dialog.update = true
    //       }
    //     })()
    // } else {
    //   this.getSessions();
    // }
  },
  computed: {
    ...mapGetters(['companyData', 'userData']),
    selectedRows() {
      return this.filteredData.filter(row => this.lista_items.includes(row.id))
    },
    paciente() {
      return this.pacientes.find(p => p.id === this.pacienteId) || {}
    },
    filteredData() {
      // Verifica se há algum filtro ativo ou se o campo de pesquisa não está vazio
      const isFilterApplied = this.activeFilters.length > 0;
      const isSearchApplied = this.search.trim().length > 0;

      // Retorna os dados filtrados apenas se um filtro ou pesquisa estiver ativo
      if (isFilterApplied || isSearchApplied) {
        return this.displayedData;
      }

      // Retorna um array vazio se nenhum filtro ou pesquisa estiver ativo
      return [];
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!(this.newItem.operadora &&
        this.newItem.paciente &&
        this.newItem.modalidade &&
        this.newItem.data_inicio &&
        this.newItem.data_fim);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    guiasIncompletas() {
      return this.filteredData.reduce((count, item) => {
        // Uma guia é considerada incompleta se qualquer um desses campos for null
        if (item.senha === null ||
          item.nr_guia_operador === null ||
          item.nr_guia_prestador === null ||
          item.senha_data_inicio === null ||
          item.senha_data_validade === null) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    headersWithGuiasIncompletas() {
      // Clona o array de headers para não modificar o original armazenado em `data`
      const updatedHeaders = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho onde o texto deve ser atualizado
      const nrOrcamentoIndex = updatedHeaders.findIndex(header => header.value === 'id');

      // Se encontrou o cabeçalho, atualiza seu texto para incluir a contagem de guias incompletas
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        updatedHeaders[nrOrcamentoIndex].text = `Nrº orçamento (${this.guiasIncompletas} guias incompletas)`;
      }

      return updatedHeaders;
    },
    lista() {
      return this.userData || {}
    },
    calculatedDays() {
      if (this.newItem.data_inicio && this.newItem.data_fim) {
        const startDate = new Date(this.newItem.data_inicio);
        const endDate = new Date(this.newItem.data_fim);
        const timeDiff = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Inclui o dia final no cálculo
        return diffDays;
      }
      return 0; // Retorna 0 se as datas não estiverem definidas
    },
    
  },
  watch: {
    fsearch() {
      this.updateDisplayedData();
    },
    activeFilters() {
      this.updateDisplayedData();
    },
    search() {
      this.updateDisplayedData();
    },
    filteredData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contaspendentes = newVal.filter(item => item.ativo === false && item.reprovado == "0").length;
      }
    },
    'newItem.data_inicio'(newVal) {
      // Atualiza a 'Data início - Validade da senha' com o novo valor
      this.newItem.senha_data_inicio = newVal;
    },
    'newItem.data_fim'(newVal) {
      // Atualiza a 'Data início - Validade da senha' com o novo valor
      this.newItem.senha_data_validade = newVal;
    }
  },
};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}

.sem-evolucoes {
  color: red;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}

.com-evolucoes {
  color: green;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}

.title-border {
  border-bottom: 2px solid #E0E0E0;
  /* ajuste a cor conforme necessário */
  padding-bottom: 10px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap;
  /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0;
  /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0;
  /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.badge {
  background-color: #FAAF19;
  color: #0D518F;
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-size: 0.8rem;
  margin-left: 10px;
  /* Espaço entre o título e a badge */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  flex-shrink: 0;
  /* Impede que a badge encolha */
}
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>

<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
      :loading="loading">
      <v-icon left>mdi-printer</v-icon> NEAD
    </v-btn>
  </v-container>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../../service/utilsPDF';
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Nead",
  data: () => withCRUDUtils({
    loading: false,
    orcamento: '',
    nead: [],
    assinaturas: [],
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getNead()
        ]);
        this.savePDF();
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    drawHeader(doc) {
      // const orcamento = this.orcamento
      const nead = this.nead[0]
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-61):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      // Ajustar a coordenada 'rectY' para mover o retângulo para cima
      doc.setLineWidth(0.5);
      const rectX = 3;
      const rectY = 40;  // Diminuir este valor para mover o retângulo para cima (era 40)
      const rectWidth = 204;
      const rectHeight = 25;  // Aumente este valor para aumentar a altura do retângulo
      const borderRadius = 2;
      doc.roundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius, borderRadius, "S");

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`NEAD`, 105, 23, { align: "center" });

      moment.locale("pt-br");

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const xFixedPosition = 10;
      // const yFixedPosition = 68;
      const lineHeight = 6
      // const pageWidth = 210;

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right", });

      doc.setFontSize(11);
      doc.text(`Avaliado em: ${UtilsPDF.formatDate(nead.timestamp)}`, 81, 32);

      const data = this.orcamento
      const paciente = this.paciente
      this.operadora = data.convenio

      let yPosition = 46
      doc.text( `Paciente: ${paciente ?? ''}`, xFixedPosition, yPosition );

      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}`  : 'Data Nascimento: ';

      doc.text( dataNascimento,  200,  yPosition,  { align: "right" } );

      yPosition += lineHeight
      doc.text(`Operadora: ${this.operadora ?? ''}`, xFixedPosition, yPosition);

      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, {  align: "right", });

      yPosition += lineHeight

      const cid = data && data.cid ? data.cid : '';

      if (cid && cid.length > 0) {
          const cidDescricao = data.cid_descricao || '';
          const fullText = `CID: ${cid} - ${cidDescricao}`;

          const splittedText = doc.splitTextToSize(fullText, rectWidth - 10);

          splittedText.forEach((line, i) => {
              doc.text(line, xFixedPosition, yPosition + (lineHeight * i));
          });

          yPosition += (lineHeight * splittedText.length);
      } else {
          doc.setFont("helvetica", "normal");
          doc.text(`CID: `, xFixedPosition, yPosition);
      }
      const pageWidth = 210;
      const yFixedPosition = 72;
      yPosition += lineHeight
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("TABELA DE AVALIAÇÃO PARA PLANEJAMENTO DE ATENÇÃO DOMICILIAR");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("TABELA DE AVALIAÇÃO PARA PLANEJAMENTO DE ATENÇÃO DOMICILIAR", xCenteredPosition, yFixedPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF() {
      const data = this.orcamento
      const nead = this.nead[0]
      const periodoOrcamento = `${UtilsPDF.formatDateToFilename(nead.timestamp)}`;
      const filename = `NEAD_${this.paciente.replace(/\s+/g, '_')}_${data.convenio.replace(/\s+/g, '_')}_Avaliado_em_ ${periodoOrcamento}.pdf`;

      await this.generatePDF();

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)

      // Código para salbvar no doc do paciente 
      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())
      await this.saveDocument(signedPDFBlob, filename);

      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    // Fórmula para salvar automaticamente o doc nos docs do paciente
    async saveDocument(pdfBlob, filename) {
      const today = new Date().toISOString().split('T')[0];
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("paciente", this.nead[0].paciente.id);
      formData.append("tipodoc", 33);
      formData.append("data_carregado", today);
      try {
        await api.post('pacientes/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      const nead = this.nead[0]


      let yPosition = 75;
  
    // Definição da largura de 90% da página
      const fullWidth = 200;
      const startX = (210 - fullWidth) / 2; // Centraliza o retângulo horizontalmente
      let lineHeight = 5; // A altura da linha para o texto "GRUPO 1 – ELEGIBILIDADE"

      // Desenha o retângulo com 90% da largura da página
      doc.setDrawColor(0, 0, 0);  // Cor preta para as bordas
      doc.setFillColor(57, 106, 159);  // Azul para o fundo
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD'); // 'FD' desenha e preenche o retângulo

// Adiciona o texto "GRUPO 1 – ELEGIBILIDADE"
      doc.setTextColor(255, 255, 255);  // Texto branco
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("GRUPO 1 – ELEGIBILIDADE", startX + 5, yPosition + lineHeight / 2 + 1);  // Ajuste a posição Y para centralizar verticalmente

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha
      // Configuração do fundo e texto
      doc.setFillColor(220, 220, 220);  // Fundo cinza claro para todas as colunas
      doc.setTextC// Configurações iniciais
      const colWidth1 = fullWidth * 0.7; // 70% da largura total para a primeira coluna
      const colWidth2 = fullWidth * 0.15; // 15% da largura total para a segunda coluna
      const colWidth3 = fullWidth * 0.15; // 15% da largura total para a terceira coluna

      // Primeira Coluna: "ELEGIBILIDADE AO ATENDIMENTO DOMICILIAR"
      doc.setFillColor(173, 216, 230);  // Fundo cinza claro para a primeira coluna
      doc.setDrawColor(0, 0, 0);  // Bordas pretas
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX, yPosition, colWidth1, lineHeight, 'FD');
      doc.text("ELEGIBILIDADE AO ATENDIMENTO DOMICILIAR", startX + 45, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna: "SIM"
      doc.setFillColor(144, 238, 144);  // Fundo cinza claro para a segunda coluna
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth1, yPosition, colWidth2, lineHeight, 'FD');
      doc.text("SIM", startX + colWidth1 + 10, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna: "NÃO"
      doc.setFillColor(144, 238, 144);  // Fundo cinza claro para a terceira coluna
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth1 + colWidth2, yPosition, colWidth3, lineHeight, 'FD');
      doc.text("NÃO", startX + colWidth1 + colWidth2 + 10, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

      // Configuração do fundo e texto
      // doc.setFillColor(220, 220, 220);  // Fundo cinza claro para todas as colunas
      doc.setTextC// Configurações iniciais
      const colWidth4 = fullWidth * 0.7; // 70% da largura total para a primeira coluna
      const colWidth5 = fullWidth * 0.15; // 15% da largura total para a segunda coluna
      const colWidth6 = fullWidth * 0.15; // 15% da largura total para a terceira coluna


      // Primeira Coluna: "ELEGIBILIDADE AO ATENDIMENTO DOMICILIAR"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setDrawColor(0, 0, 0);  // Bordas pretas
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX, yPosition, colWidth4, lineHeight, 'FD');
      doc.text("Apresenta Cuidador em período integral?", startX + 5, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna: "SIM"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4, yPosition, colWidth5, lineHeight, 'FD');
      doc.text(`${nead.nead.cuidador ? 'X': ''}`, startX + colWidth4 + 12, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna: "NÃO"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4 + colWidth5, yPosition, colWidth6, lineHeight, 'FD');
      doc.text(`${nead.nead.cuidador ? '': 'X'}`, startX + colWidth4 + colWidth5 + 12, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha 

      // Primeira Coluna: "ELEGIBILIDADE AO ATENDIMENTO DOMICILIAR"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setDrawColor(0, 0, 0);  // Bordas pretas
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX, yPosition, colWidth4, lineHeight, 'FD');
      doc.text("O domicílio é livre de risco?", startX + 5, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna: "SIM"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4, yPosition, colWidth5, lineHeight, 'FD');
      doc.text(`${nead.nead.domicio_risco ? 'X': ''}`, startX + colWidth4 + 12, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna: "NÃO"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4 + colWidth5, yPosition, colWidth6, lineHeight, 'FD');
      doc.text(`${nead.nead.domicio_risco ? '': 'X'}`, startX + colWidth4 + colWidth5 + 12, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

      // Primeira Coluna: "ELEGIBILIDADE AO ATENDIMENTO DOMICILIAR"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setDrawColor(0, 0, 0);  // Bordas pretas
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX, yPosition, colWidth4, lineHeight, 'FD');
      doc.text("Existe algum impedimento para se deslocar até a rede credenciada?", startX + 5, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna: "SIM"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4, yPosition, colWidth5, lineHeight, 'FD');
      doc.text(`${nead.nead.deslocamento ? 'X': ''}`, startX + colWidth4 + 12, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna: "NÃO"
      doc.setFillColor(255, 255, 255);  // Fundo branco
      doc.setTextColor(0, 0, 0);  // Texto preto
      doc.rect(startX + colWidth4 + colWidth5, yPosition, colWidth6, lineHeight, 'FD');
      doc.text(`${nead.nead.deslocamento ? '': 'X'}`, startX + colWidth4 + colWidth5 + 12, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

      doc.setFillColor(220, 220, 220);  // Fundo cinza claro
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD');
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("Se responder 'NÃO' a qualquer uma das questões acima, considerar contraindicar Atenção Domiciliar.", startX + 30, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

  // Adiciona o texto "GRUPO 2 – CRITÉRIO PARA INDICAÇÃO IMEDIATA DE INTERNAÇÃO DOMICILIAR"
       // Desenha o retângulo com 90% da largura da página
      doc.setDrawColor(0, 0, 0);  // Cor preta para as bordas
      doc.setFillColor(57, 106, 159);  // Azul para o fundo
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD'); // 'FD' desenha e preenche o retângulo

      doc.setTextColor(255, 255, 255);  // Texto branco
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("GRUPO 2 – CRITÉRIO PARA INDICAÇÃO IMEDIATA DE INTERNAÇÃO DOMICILIAR", startX + 5, yPosition + lineHeight / 2 + 1);  // Ajuste a posição Y para centralizar verticalmente

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

       // Desenha o retângulo com 90% da largura da página
      doc.setFillColor(173, 216, 230);  // Fundo cinza claro para a primeira coluna 
      doc.setTextColor(0, 0, 0);  // Texto preto  // Azul para o fundo
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD'); // 'FD' desenha e preenche o retângulo
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("PERFIL DE INTERNAÇÃO DOMICILIAR", startX + 70, yPosition + lineHeight / 2 + 1);  // Ajuste a posição Y para centralizar verticalmente

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

       doc.setTextC// Configurações iniciais
      const colWidth7 = fullWidth * 0.3; 
      const colWidth8 = fullWidth * 0.2; 
      const colWidth9 = fullWidth * 0.2; 
      const colWidth10 = fullWidth * 0.3;

        // Primeira Coluna: 
      doc.setFillColor(255, 255, 255); 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX, yPosition, colWidth7, lineHeight, 'FD');
      doc.text("", startX + 45, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      doc.setFillColor(173, 216, 230);
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth7, yPosition, colWidth8, lineHeight, 'FD');
      doc.text("24 HORAS", startX + colWidth7 + 10, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
      doc.setFillColor(173, 216, 230);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth7 + colWidth8, yPosition, colWidth9, lineHeight, 'FD');
      doc.text("12 HORAS", startX + colWidth7 + colWidth8 + 10, yPosition + lineHeight / 2 + 1);


      // Quarta Coluna:
      doc.setFillColor(173, 216, 230);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(6.5);
      doc.rect(startX + colWidth7 + colWidth8 + colWidth9, yPosition, colWidth10, lineHeight, 'FD');
      doc.text("ATENDIMENTO DOMICILIAR / OUTROS PROGRAMAS ", startX + colWidth7 + colWidth8 + colWidth9 + 1, yPosition + lineHeight / 2 + 1);

       yPosition += lineHeight;

      const colWidth11 = fullWidth * 0.3; // Primeira Coluna 
      const colWidth12 = fullWidth * 0.05; // Segunda Coluna 
      const colWidth13 = fullWidth * 0.15; // terceira Coluna 
      const colWidth14 = fullWidth * 0.05; // Quarta Coluna 
      const colWidth15 = fullWidth * 0.15; // Quinta Coluna 
      const colWidth16 = fullWidth * 0.05; // Sexta Coluna 
      const colWidth17 = fullWidth * 0.25; // Setima Coluna 

        // Primeira Coluna: 
      doc.setFillColor(255, 255, 255); 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("ALIMENTAÇÃO PARENTERAL", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
     doc.setFillColor(255, 255, 255);
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.alimentacao_parenteral_NEAD.id === 1 ? 'X': '') : ''}`, startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("Por mais de 12h/dia", startX + colWidth11 + colWidth12 + 2, yPosition + lineHeight / 2 + 1);


      // Quarta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.alimentacao_parenteral_NEAD.id === 2 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("Até 12h/dia", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 2, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ?  (nead.nead.alimentacao_parenteral_NEAD.id === 3 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("Não Utiliza", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 16, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

      const lineHeight1 = 8;

// ASPIRAÇÃO DE TRAQUEOSTOMIA / VIAS AÉREAS INFERIORES
        // Primeira Coluna: 
      doc.setFillColor(255, 255, 255); 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      const wrappedText = doc.splitTextToSize("ASPIRAÇÃO DE TRAQUEOSTOMIA / VIAS AÉREAS INFERIORES", colWidth11 - 2);
      doc.rect(startX, yPosition, colWidth11, lineHeight1, 'FD');
      doc.text(wrappedText, startX + 2, yPosition + (lineHeight1 / 2) - 1);

      // Segunda Coluna:
     doc.setFillColor(255, 255, 255);
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight1, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ?(nead.nead.aspiracao_traqueal_NEAD.id === 1 ? 'X': '') : ''}`, startX + colWidth11 + 4, yPosition + lineHeight1 / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight1, 'FD');
      doc.text("Mais de 5 vezes/dia ", startX + colWidth11 + colWidth12 + 2, yPosition + lineHeight1 / 2 + 1);


      // Quarta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight1, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.aspiracao_traqueal_NEAD.id === 2 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight1 / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight1, 'FD');
      doc.text("Até 5 vezes/dia ", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 2, yPosition + lineHeight1 / 2 + 1);


      // Sexta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight1, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.aspiracao_traqueal_NEAD.id === 3 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight1 / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight1, 'FD');
      doc.text("Não Utiliza", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 16, yPosition + lineHeight1 / 2 + 1);

      yPosition += lineHeight + 2;

      const lineHeight2 = 8;

// VENTILAÇÃO MECÂNICA CONTÍNUA INVASIVA OU NÃO
      // Primeira Coluna: 
      doc.setFillColor(255, 255, 255); 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      const wrappedText2 = doc.splitTextToSize("VENTILAÇÃO MECÂNICA CONTÍNUA INVASIVA OU NÃO", colWidth11 - 2);
      doc.rect(startX, yPosition, colWidth11, lineHeight2, 'FD');
      doc.text(wrappedText2, startX + 2, yPosition + (lineHeight2 / 2) - 1);

      // Segunda Coluna:
     doc.setFillColor(255, 255, 255);
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight2, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.ventilacao_NEAD.id === 1 ? 'X': '') : ''}`, startX + colWidth11 + 4, yPosition + lineHeight2 / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight2, 'FD');
      doc.text("Por mais de 12h/dia ", startX + colWidth11 + colWidth12 + 2, yPosition + lineHeight2 / 2 + 1);


      // Quarta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight2, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.ventilacao_NEAD.id === 2 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight2 / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight2, 'FD');
      doc.text("Até 12h/dia ", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 2, yPosition + lineHeight2 / 2 + 1);


      // Sexta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight2, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? ( nead.nead.ventilacao_NEAD.id === 3 ? 'X': '') :''}`, startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight2 / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight2, 'FD');
      doc.text("Não Utiliza", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 16, yPosition + lineHeight2 / 2 + 1);

      yPosition += lineHeight + 3;


// MEDICAÇÃO PARENTERAL OU HIPODERMÓCLISE
      // Primeira Coluna: 
      doc.setFillColor(255, 255, 255); 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      const wrappedText3 = doc.splitTextToSize("MEDICAÇÃO PARENTERAL OU HIPODERMÓCLISE", colWidth11 - 2);
      doc.rect(startX, yPosition, colWidth11, lineHeight2, 'FD');
      doc.text(wrappedText3, startX + 2, yPosition + (lineHeight2 / 2) - 1);

      // Segunda Coluna:
     doc.setFillColor(255, 255, 255);
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight2, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? ( nead.nead.medicacao_parenteral_NEAD.id === 1 ? 'X': '') : ''}`, startX + colWidth11 + 4, yPosition + lineHeight2 / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 , yPosition, 0, lineHeight2, 'FD');
      doc.text("Mais de 4 vezes/dia ", colWidth11 + colWidth12 +  colWidth13 , yPosition + lineHeight2 / 2 + 1);


      // Sexta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight2, 'FD');
      doc.text(`${nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ? (nead.nead.medicacao_parenteral_NEAD.id === 2 ? 'X': '') : ''}`, startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight2 / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight2, 'FD');
      doc.text("Até 4 vezes/dia", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 16, yPosition + lineHeight2 / 2 + 1);

      yPosition += lineHeight + 2; // Ajusta a posição Y para a próxima linha

      doc.setFillColor(220, 220, 220);  // Fundo cinza claro
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD');
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text("Para indicação de Planejamento de Atenção Domiciliar (P.A.D), considerar a maior complexidade assinalada, ainda que uma única vez.", startX + 10, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

      // Adiciona o texto "GRUPO 2 – CRITÉRIO PARA INDICAÇÃO IMEDIATA DE INTERNAÇÃO DOMICILIAR"
       // Desenha o retângulo com 90% da largura da página
      doc.setDrawColor(0, 0, 0);  // Cor preta para as bordas
      doc.setFillColor(57, 106, 159);  // Azul para o fundo
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD'); // 'FD' desenha e preenche o retângulo

      doc.setTextColor(255, 255, 255);  // Texto branco
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("GRUPO 3 - CRÍTERIO DE APOIO PARA INDICAÇÃO DE PLANEJAMENTO DE ATENÇÃO DOMICILIAR", startX + 5, yPosition + lineHeight / 2 + 1);  // Ajuste a posição Y para centralizar verticalmente

      yPosition += lineHeight; // Ajusta a posição Y para a próxima linha

      const pontuacaoNutricao = nead.nead.nutricao_NEAD.pontuacao;

    // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("ESTADO NUTRICIONAL", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoNutricao === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("EUTRÓFICO", startX + colWidth11 + colWidth12 + 7, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoNutricao === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(6);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("SOBREPESO/EMAGRECIDO", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 1, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoNutricao === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("OBESO / DESNUTRIDO", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 8, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;
  //  Segunda Linha 
      const pontuacaoalimentacao_medicacao_NEAD = nead.nead.alimentacao_medicacao_NEAD.pontuacao;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      const wrappedText4 = doc.splitTextToSize("ALIMENTAÇÃO OU MEDICAÇÕES POR VIA ENTERAL", colWidth11 - 2);
      doc.rect(startX, yPosition, colWidth11, lineHeight1, 'FD');
      doc.text(wrappedText4, startX + 2, yPosition + (lineHeight1 / 2) - 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoalimentacao_medicacao_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight1, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + (lineHeight1 / 2) +1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight1, 'FD');
      doc.text("SEM AUXÍLIO", startX + colWidth11 + colWidth12 + 6, yPosition + (lineHeight1 / 2) + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoalimentacao_medicacao_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight1, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + (lineHeight1 / 2) +1);

      // Quinta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight1, 'FD');
      doc.text("ASSISTIDA", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 6, yPosition + (lineHeight1 / 2) +1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoalimentacao_medicacao_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight1, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + (lineHeight1 / 2) +1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(7);
      const wrappedText5 = doc.splitTextToSize("GASTROSTOMIA / JEJUNOSTOMIA", colWidth16 + 12);
      doc.rect((startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16), yPosition, (colWidth17 * 0.5), lineHeight1, 'FD');
      doc.text(wrappedText5, (startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16) + 1, yPosition + (lineHeight1 - 5 ));
      
       // Oitava Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoalimentacao_medicacao_NEAD === 3) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16 + (colWidth17 * 0.5), yPosition, colWidth16, lineHeight1, 'FD');
      doc.text("3", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16 + (colWidth17 * 0.5) + 4, yPosition + (lineHeight1 / 2) +1);
      
      // Nona Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(7);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16 + (colWidth17 * 0.5 ) + 10 , yPosition, colWidth16 + 5, lineHeight1, 'FD');
      doc.text("SNG / SNE*", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + colWidth15 + colWidth16 + (colWidth17 * 0.5 ) + 11, yPosition + (lineHeight1 / 2) +1);

      yPosition += lineHeight + 2;
// Tereceira Linha
      const pontuacaokatz_NEAD = nead.nead.katz_NEAD.pontuacao; 
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("KATZ** (Se Pediatria Pontuar 2)", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaokatz_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("INDEPENDENTE", startX + colWidth11 + colWidth12 + 4, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaokatz_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(7);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("DEPENDENTE PARCIAL", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 1, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
     if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaokatz_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("DEPENDENTE TOTAL", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 8, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

// QUARTA LINHA
      const pontuacaointernacao_NEAD = nead.nead.internacao_NEAD.pontuacao; 
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("INTERNAÇÕES NO ÚLTIMO ANO", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaointernacao_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("0 - 1 INTERNAÇÃO", startX + colWidth11 + colWidth12 + 2, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaointernacao_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("2 - 3 INTERNAÇÕES", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 1, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaointernacao_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("> 3 INTERNAÇÕES", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 10, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

  // QUinta LINHA
      const pontuacaoapiracao_NEAD = nead.nead.apiracao_NEAD.pontuacao;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("ASPIRAÇÕES VIAS AÉREAS SUPERIORES", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoapiracao_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("AUSENTE", startX + colWidth11 + colWidth12 + 8, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoapiracao_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("ATÉ 5 VEZES AO DIA ", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 1, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoapiracao_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("MAIS DE 5 VEZES AO DIA ", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 8, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

  // Sexta LINHA
      const pontuacaolesao_NEAD = nead.nead.lesao_NEAD.pontuacao;
      const lineHeight3 = 12;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight3, 'FD');
      doc.text("LESÕES", startX + 2, yPosition + lineHeight3 / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaolesao_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight3, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight3 / 2 + 1);

      // Terceira Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(7); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight3, 'FD');
      const text1 = doc.splitTextToSize("NENHUMA OU LESÃO ÚNICA COM CURATIVO SIMPLES", colWidth13 + 1);
      doc.text(text1, startX + colWidth11 + colWidth12 + 1, yPosition + lineHeight3 - 9);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaolesao_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight3, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight3 / 2 + 1);

      // Quinta Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(6);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13 + colWidth14, yPosition, colWidth15, lineHeight3, 'FD');
      const text2 = doc.splitTextToSize("MÚLTIPLAS LESÕES COM CURATIVOS SIMPLES OU ÚNICA LESÃO COM CURATIVO COMPLEXO", colWidth15 - 2);
      doc.text(text2, startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + 1, yPosition + lineHeight3 - 9);

      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaolesao_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight3, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + colWidth15 + 4, yPosition + lineHeight3 / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight3, 'FD');
      const text3 = doc.splitTextToSize("MÚLTIPLAS LESÕES COM CURATIVOS COMPLEXOS", colWidth17 - 2);
      doc.text(text3, startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + colWidth15 + colWidth16 + 6, yPosition + lineHeight3 - 7);

      yPosition += lineHeight + 6;

    //Oitava linha 
      const pontuacaomedicacao_NEAD = nead.nead.medicacao_NEAD.pontuacao;
      const lineHeight4 = 10;
       // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight4, 'FD');
      doc.text("MEDICAÇÕES", startX + 2, yPosition + lineHeight4 / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaomedicacao_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight4, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight4 / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight4, 'FD');
      doc.text("VIA ENTERAL", startX + colWidth11 + colWidth12 + 6, yPosition + lineHeight4 / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaomedicacao_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight4, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight4 / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13 + colWidth14, yPosition, colWidth15, lineHeight4, 'FD');
      const text4 = doc.splitTextToSize("INTRAMUSCULAR OU SUBCUTÂNEA", colWidth15 - 2);
      doc.text(text4, startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + 2, yPosition + lineHeight4 - 6);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaomedicacao_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight4, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight4 / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight4, 'FD');
      const text5 = doc.splitTextToSize("INTRAVENOSA ATÉ 4 VEZES DIA / HIPODERMÓCLISE", colWidth17 - 2);
      doc.text(text5, startX + colWidth11 + colWidth12 + colWidth13 + colWidth14 + colWidth15 + colWidth16 + 2, yPosition + lineHeight3 - 8);

      yPosition += lineHeight + 5;
  //Nona Linha
      const pontuacaoexercicio_ventilatorio_NEAD = nead.nead.exercicio_ventilatorio_NEAD.pontuacao;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("EXERCÍCIOS VENTILATÓRIOS", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoexercicio_ventilatorio_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("AUSENTE", startX + colWidth11 + colWidth12 + 7, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoexercicio_ventilatorio_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("INTERMITENTE", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 4, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoexercicio_ventilatorio_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      // doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      // doc.text("> 3 INTERNAÇÕES", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 10, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

  //Décima Linha

      const pontuacaooxigenio_NEAD = nead.nead.oxigenio_NEAD.pontuacao;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("USO DE OXIGENIOTERAPIA", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaooxigenio_NEAD === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("AUSENTE", startX + colWidth11 + colWidth12 + 7, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaooxigenio_NEAD === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("INTERMITENTE", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14 + 4, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaooxigenio_NEAD === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("CONTÍNUO", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 15, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;
  //Décima Primeira Linha
      const pontuacaoNivelConsciencia = nead.nead.nivel_conciencia_NEAD.pontuacao;
      // Primeira Coluna: 
      doc.setFillColor(173, 216, 230); // FUNDO AZUL 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11, lineHeight, 'FD');
      doc.text("NÍVEL DE CONSCIÊNCIA", startX + 2, yPosition + lineHeight / 2 + 1);

      // Segunda Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoNivelConsciencia === 0) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11, yPosition, colWidth12, lineHeight, 'FD');
      doc.text("0", startX + colWidth11 + 4, yPosition + lineHeight / 2 + 1);

      // Terceira Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + colWidth11 + colWidth12, yPosition, colWidth13, lineHeight, 'FD');
      doc.text("ALERTA", startX + colWidth11 + colWidth12 + 8, yPosition + lineHeight / 2 + 1);

      // Quarta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true ) {
        if (pontuacaoNivelConsciencia === 1) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      } 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 + colWidth13, yPosition, colWidth14, lineHeight, 'FD');
      doc.text("1", startX + colWidth11 + colWidth12 + colWidth13 + 4, yPosition + lineHeight / 2 + 1);

      // Quinta Coluna:
     doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(6);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14, yPosition, colWidth15, lineHeight, 'FD');
      doc.text("CONFUSO / DESORIENTADO", startX + colWidth11 + colWidth12 +  colWidth13  + colWidth14, yPosition + lineHeight / 2 + 1);


      // Sexta Coluna:
      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) {
        if (pontuacaoNivelConsciencia === 2) {
            doc.setFillColor(144, 238, 144); // Fundo verde claro
        } else {
            doc.setFillColor(220, 220, 220);  // Fundo cinza claro
        }  
      } else {
        doc.setFillColor(220, 220, 220);
      }
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15, yPosition, colWidth16, lineHeight, 'FD');
      doc.text("2", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + 4, yPosition + lineHeight / 2 + 1);

      // Setima Coluna:
      doc.setFillColor(255, 255, 255);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text("COMATOSO", startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 +  colWidth16 + 14, yPosition + lineHeight / 2 + 1);

      yPosition += lineHeight;

      // Pontuaçao
      doc.setFillColor(220, 220, 220);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(10);
      doc.rect(startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15 + colWidth16, yPosition, colWidth17, lineHeight, 'FD');
      doc.text(`PONTUAÇÃO FINAL:  ${ (nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) ? (pontuacaoNivelConsciencia + pontuacaoNutricao + pontuacaoalimentacao_medicacao_NEAD + pontuacaoexercicio_ventilatorio_NEAD +
        pontuacaokatz_NEAD + pontuacaointernacao_NEAD + pontuacaoapiracao_NEAD + pontuacaolesao_NEAD + pontuacaomedicacao_NEAD + pontuacaooxigenio_NEAD) : ''}`
        , startX + colWidth11 + colWidth12 +colWidth13  + colWidth14 + colWidth15  + 14, yPosition + lineHeight / 2 + 1);
        const pontuacao = (pontuacaoNivelConsciencia + pontuacaoNutricao + pontuacaoalimentacao_medicacao_NEAD + pontuacaoexercicio_ventilatorio_NEAD +
        pontuacaokatz_NEAD + pontuacaointernacao_NEAD + pontuacaoapiracao_NEAD + pontuacaolesao_NEAD + pontuacaomedicacao_NEAD + pontuacaooxigenio_NEAD)

      yPosition += lineHeight;

      doc.setDrawColor(0, 0, 0);  // Cor preta para as bordas
      doc.setFillColor(57, 106, 159);  // Azul para o fundo
      doc.rect(startX, yPosition, fullWidth, lineHeight, 'FD'); // 'FD' desenha e preenche o retângulo

      doc.setTextColor(255, 255, 255);  // Texto branco
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.text("CLASSIFICAÇÃO DO PACIENTE", startX + 5, yPosition + lineHeight / 2 + 1);  // Ajuste a posição Y para centralizar verticalmente

      yPosition += lineHeight;
      
      const lineHeight6 = 11

      if((nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) 
        && (nead.nead.alimentacao_parenteral_NEAD.id === 3 && nead.nead.aspiracao_traqueal_NEAD.id === 3 && nead.nead.ventilacao_NEAD.id === 3 && nead.nead.medicacao_parenteral_NEAD.id === 2)
        && (pontuacao <= 5)) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255); // Fundo branco
      }
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11 * 0.4, lineHeight6, 'FD');
      doc.text("Até 5 Pontos", startX + 3, yPosition + lineHeight6 / 2 + 1);

       if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true 
        && (nead.nead.alimentacao_parenteral_NEAD.id === 3 && nead.nead.aspiracao_traqueal_NEAD.id === 3 && nead.nead.ventilacao_NEAD.id === 3 && nead.nead.medicacao_parenteral_NEAD.id === 2)
        && pontuacao <= 5) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255);
      }  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + (colWidth11 * 0.4), yPosition, (colWidth11 + colWidth12 + colWidth13), lineHeight6, 'FD');
      const text6 = doc.splitTextToSize("Considerar procedimentos pontuais exclusivos ou outros programas: ( ) Curativos ( ) Medicações Parenterais ( ) Outros Programas ", colWidth17 + 50);
      doc.text(text6, startX + (colWidth11 * 0.4) + 2, yPosition + lineHeight6 / 2   );

      const A = nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true 
      const B_24_horas = nead.nead.alimentacao_parenteral_NEAD.id === 1 || nead.nead.aspiracao_traqueal_NEAD.id === 1 || nead.nead.ventilacao_NEAD.id === 1 || nead.nead.medicacao_parenteral_NEAD.id === 1
      const C_12_horas = nead.nead.alimentacao_parenteral_NEAD.id === 2 || nead.nead.aspiracao_traqueal_NEAD.id === 2 || nead.nead.ventilacao_NEAD.id === 2
      const D_nao_utiliza = nead.nead.alimentacao_parenteral_NEAD.id === 3 || nead.nead.aspiracao_traqueal_NEAD.id === 3 || nead.nead.ventilacao_NEAD.id === 3 || nead.nead.medicacao_parenteral_NEAD.id === 2
      const E_12_17 = pontuacao >= 12 && pontuacao <= 17

      if (A) {
        if (B_24_horas) {
          doc.setFillColor(255, 255, 255); // BRANCO
        } else if (C_12_horas && pontuacao <=17) {
          doc.setFillColor(144, 238, 144);  // VERDE
        } else if (D_nao_utiliza && E_12_17) {
          doc.setFillColor(144, 238, 144); // VERDE
        } else {
          doc.setFillColor(255, 255, 255); // BRANCO
        }
      } else {
        doc.setFillColor(255, 255, 255); // BRANCO
      } 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + 120 , yPosition, 28, lineHeight6, 'FD');
      doc.text("De 12 a 17 Pontos", startX + 122, yPosition + lineHeight6 / 2 + 1);


       if (A) {
        if (B_24_horas) {
          doc.setFillColor(255, 255, 255); // BRANCO
        } else if (C_12_horas && pontuacao <=17) {
          doc.setFillColor(144, 238, 144);  // VERDE
        } else if (D_nao_utiliza && E_12_17) {
          doc.setFillColor(144, 238, 144); // VERDE
        } else {
          doc.setFillColor(255, 255, 255); // BRANCO
        }
      } else {
        doc.setFillColor(255, 255, 255); // BRANCO
      }

      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + 148 , yPosition, 52, lineHeight6, 'FD');
      doc.text("Considerar Internação Domiciliar 12h", startX + 149, yPosition + lineHeight6 / 2 + 1);
      
      yPosition += lineHeight + 6;

      if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true 
        && (nead.nead.alimentacao_parenteral_NEAD.id === 3 && nead.nead.aspiracao_traqueal_NEAD.id === 3 && nead.nead.ventilacao_NEAD.id === 3 && nead.nead.medicacao_parenteral_NEAD.id === 2)
        && pontuacao >= 6 && pontuacao <= 11) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255);
      } 
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX, yPosition, colWidth11 * 0.4, lineHeight6, 'FD');
      doc.text("De 6 a 11 Pontos", startX + 1, yPosition + lineHeight6 / 2 + 1);

     if(nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true 
        && (nead.nead.alimentacao_parenteral_NEAD.id === 3 && nead.nead.aspiracao_traqueal_NEAD.id === 3 && nead.nead.ventilacao_NEAD.id === 3 && nead.nead.medicacao_parenteral_NEAD.id === 2)
        && pontuacao >= 6 && pontuacao <= 11) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255);
      }  
      doc.setTextColor(0, 0, 0); 
      doc.setFontSize(8); 
      doc.rect(startX + (colWidth11 * 0.4), yPosition, (colWidth11 + colWidth12 + colWidth13), lineHeight6, 'FD');
      const text7 = doc.splitTextToSize("Considerar Atendimento Domiciliar Multiprofissional (inclui procedimentos pontuais, desde que não exclusivos) ", colWidth17 + 50);
      doc.text(text7, startX + (colWidth11 * 0.4) + 2, yPosition + lineHeight6 / 2   );

      if((nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) 
        && ((nead.nead.alimentacao_parenteral_NEAD.id === 1 || nead.nead.aspiracao_traqueal_NEAD.id === 1 || nead.nead.ventilacao_NEAD.id === 1 || nead.nead.medicacao_parenteral_NEAD.id === 1)
        || (pontuacao >= 18))) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255);
      }    
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + 120 , yPosition, 28, lineHeight6, 'FD');
      doc.text("18 ou mais Pontos", startX + 121, yPosition + lineHeight6 / 2 + 1);


      if((nead.nead.cuidador === true && nead.nead.domicio_risco === true && nead.nead.deslocamento === true) 
        && ((nead.nead.alimentacao_parenteral_NEAD.id === 1 || nead.nead.aspiracao_traqueal_NEAD.id === 1 || nead.nead.ventilacao_NEAD.id === 1 || nead.nead.medicacao_parenteral_NEAD.id === 1)
        || (pontuacao >= 18))) {
        doc.setFillColor(144, 238, 144); // Fundo verde claro
      } else {
        doc.setFillColor(255, 255, 255);
      }   
      doc.setDrawColor(0, 0, 0);  
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(8);
      doc.rect(startX + 148 , yPosition, 52, lineHeight6, 'FD');
      doc.text("Considerar Internação Domiciliar 24h", startX + 149, yPosition + lineHeight6 / 2 + 1);

      yPosition += lineHeight + 4;
      
      doc.setFillColor(255, 255, 255); 
      doc.setTextColor(0, 0, 0);  
      doc.setFontSize(6.5);
      doc.text("*SNG = Sonda Nasogástrica e SNE = Sonda Nasoentérica  **Consulte a ESCORE KATZ  ***Excluso medicamentos de auto aplicação como insulinas e alguns anticoagulantes", startX + 1, yPosition + lineHeight6 / 2 + 1);

      // Adicionar imagem do carimbo e nome do profissional ao lado das observações
      const profissional = this.nead[0].profissional;
      const especialidade = this.nead[0].especialidade;
      if (profissional) {
          const xCarimboPosition = 180;  // Posição X para a imagem e texto do carimbo
          let carimboImageURL = profissional.carimbo;
          const params = profissional.carimbo.split('://')
            if (params[0] === 'http' && params[1] !== 'localhost') {
              carimboImageURL = carimboImageURL.replace('http', 'https')
            }

          if (carimboImageURL) {
              // Adiciona a imagem do carimbo se disponível
              doc.addImage(carimboImageURL, 'JPEG', xCarimboPosition, yPosition +10, 25, 10); // Tamanho e posição ajustados
          }
          // Adiciona o nome do profissional abaixo da imagem do carimbo
          doc.setFontSize(8);
          doc.text(`${profissional.apelido} - ${especialidade.nome}`, xCarimboPosition + 6, yPosition + 24, { align: 'center' });
      }

      UtilsPDF.addPageNumbers(doc);
      this.doc = doc;
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.orcamento = data[0];
          this.paciente = this.orcamento.paciente
          this.paciente_id = this.orcamento.paciente_id
          // console.log('orcamento ---> ', this.orcamento)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Orçamento (L-323):", error);
        }
      }
    },
    async getNead() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?sessao_id=${this.selectedBudgets[0]}&nead=true`);
        this.nead = data;
        // console.log(this.nead)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Procedimento (L-332):", error);
      }
    },
  },
};
</script>
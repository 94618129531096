<template>
  <div >
    <v-row dense class="my-0 compact-rows">
      <!-- <pre>{{newEquipamento.equipamentosSelecionados}}</pre> -->
      <v-col>
        <!-- Botão de Kit Básico -->
        <v-row dense>
           <v-col >
            <v-btn
              v-for="kit in kitEquipamento"
              :key="kit.id"
              class="blod_color mb-3 mt-3 mr-2"
              @click="selectKit(kit)"
              small dense
            >
              {{ kit.nome }}
            </v-btn>
          </v-col>
          <v-col class="text-end mb-3 mt-3 ml-3">
            <v-btn small dense style="color: red; font-weight: bold;" @click="resetSelectedEquipamentos">Limpar Seleção</v-btn>

          </v-col>
        </v-row>
        <v-expansion-panels multiple>
          <!-- Apoio Respiratório -->
          <v-expansion-panel>
            <v-expansion-panel-header class="expansion-header text-center blod_color">
              <span v-if="selectedCount('apoioRespiratorioEquipamentos') > 0">
                APOIO RESPIRATÓRIO ( {{ selectedCount('apoioRespiratorioEquipamentos') }} equipamento(s) selecionado(s) )
              </span>
              <span v-else>
                 APOIO RESPIRATÓRIO
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="equipamento-row">
                <v-col cols="12" class="text-center" v-if="loadingApoioRespiratorio">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-else-if="apoioRespiratorioEquipamentos.length === 0">
                  <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                </v-col>
                <template v-else>
                  <v-col v-for="(equipamento, index) in apoioRespiratorioEquipamentos" :key="index" class="equipamento-col" cols="3">
                    <v-checkbox
                      v-model="newEquipamento.equipamentosSelecionados"
                      :value="equipamento.id"
                      :label="equipamento.displayText"
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Oxigenoterapia -->
          <v-expansion-panel>
            <v-expansion-panel-header class="expansion-header text-center blod_color">
              <span v-if="selectedCount('oxigenoterapiaEquipamentos') > 0">
                OXIGENOTERAPIA  ( {{ selectedCount('oxigenoterapiaEquipamentos') }} equipamento(s) selecionado(s) )
              </span>
              <span v-else>
                 OXIGENOTERAPIA 
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="equipamento-row">
                <v-col cols="12" class="text-center" v-if="loadingOxigenoterapia">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-else-if="oxigenoterapiaEquipamentos.length === 0">
                  <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                </v-col>
                <template v-else>
                  <v-col v-for="(equipamento, index) in oxigenoterapiaEquipamentos" :key="index" class="equipamento-col" cols="3">
                    <v-checkbox
                      v-model="newEquipamento.equipamentosSelecionados"
                      :value="equipamento.id"
                      :label="equipamento.displayText"
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Mobiliário -->
          <v-expansion-panel>
            <v-expansion-panel-header class="expansion-header text-center blod_color">
              <span v-if="selectedCount('mobiliarioEquipamentos') > 0">
                 MOBILIÁRIO ( {{ selectedCount('mobiliarioEquipamentos') }} equipamento(s) selecionado(s) )
              </span>
              <span v-else>
                  MOBILIÁRIO 
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="equipamento-row">
                <v-col cols="12" class="text-center" v-if="loadingMobiliario">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-else-if="mobiliarioEquipamentos.length === 0">
                  <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                </v-col>
                <template v-else>
                  <v-col v-for="(equipamento, index) in mobiliarioEquipamentos" :key="index" class="equipamento-col" cols="3">
                    <v-checkbox
                      v-model="newEquipamento.equipamentosSelecionados"
                      :value="equipamento.id"
                      :label="equipamento.displayText"
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Demais Equipamentos -->
          <v-expansion-panel>
            <v-expansion-panel-header class="expansion-header text-center blod_color">
              <span v-if="selectedCount('demaisEquipamentos') > 0">
                  DEMAIS EQUIPAMENTOS  ( {{ selectedCount('demaisEquipamentos') }} equipamento(s) selecionado(s) )
              </span>
              <span v-else>
                   DEMAIS EQUIPAMENTOS 
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="equipamento-row">
                <v-col cols="12" class="text-center" v-if="loadingDemaisEquipamentos">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center" v-else-if="demaisEquipamentos.length === 0">
                  <p class="error-message">Sem equipamento cadastrado para a operadora</p>
                </v-col>
                <template v-else>
                  <v-col v-for="(equipamento, index) in demaisEquipamentos" :key="index" class="equipamento-col" cols="3">
                    <v-checkbox
                      v-model="newEquipamento.equipamentosSelecionados"
                      :value="equipamento.id"
                      :label="equipamento.displayText"
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Espaço adicional antes do restante dos campos -->
        <div class="spacer"></div>



        <v-row dense>
          <v-col cols="1">
            <v-select outlined required v-model="newEquipamento.aditivo_equipamentos" item-value="value"
              item-text="nome" :items="statusOptions" :rules="requiredField" >
              <template v-slot:label>
                <span>Aditivo<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="newEquipamento.aditivo_equipamentos" cols="1">
            <v-text-field outlined required v-model="newEquipamento.nr_aditivo" type="number" :rules="nrAditivoRules(newEquipamento)">
              <template v-slot:label>
                <span>Nrº do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="newEquipamento.aditivo_equipamentos" cols="2">
            <v-text-field outlined required v-model="newEquipamento.data_aditivo" type="date"  :rules="dataAditivoRules()">
              <template v-slot:label>
                <span>Data de Início do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newEquipamento.data_inicio_equipamentos" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data de Início da Locação<span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newEquipamento.data_fim_equipamentos" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data de Fim da Locação<span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field outlined required v-model="newEquipamento.quantidade_equipamentos" type="number" :rules="requiredField"  >
              <template v-slot:label>
                <span>Quantidade <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="2">
            <v-text-field label="Total" outlined v-model="newEquipamento.valor_cobranca_final_equipamentos" disabled></v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-end" dense>
      <ConfirmButton color="success" :loading="loading" :onConfirm="onBeforeSubmit" :block="false">
        Salvar
      </ConfirmButton>
    </v-row>
  </div>

</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";


export default {
  name: "GenerateProceduresFormEquipamento",
  props: {
    operadoraId: Number,
    modalidade: Object,
    orcamentoId: Number,
    statusOptions: Array,
    loading: Boolean,
    onSubmit: Function,
    paciente: Number,
    dataInicio: [Date, String],
    dataFim: [Date, String],
  },
  data: () => UtilsFunc.withCRUDUtils({
    validating: false,
    equipamentos: [],
    loadingEquipamentos: false,
    aditivo:[],
    pacoteId:[],
    nomeequipamentos:[],
    equipamentodependente: [],
    kitEquipamento:[],
    maxNrAditivo: null,
    maxDataAditivo: null,
    loadingApoioRespiratorio: false,
    loadingOxigenoterapia: false,
    loadingMobiliario: false,
    loadingDemaisEquipamentos: false,

    newEquipamento: {
      equipamentosSelecionados: [],
      equipamento: null,
      preco: '',
      data_inicio_equipamentos: '',
      data_fim_equipamentos: '',
      quantidade_equipamentos: '',
      valor_cobranca_final_equipamentos: '',
      aditivo_equipamentos: false,
      nr_aditivo: null,
      data_aditivo: null,
      operadora: null,
      equipamento_ref: null,
    },
    isLoading: true,
    nrAditivoRules(newEquipamento) {
      if (newEquipamento.aditivo === true) { 
        return [v => !!v || 'Nrº Aditivo é obrigatório'];
      }
      return []; 
    },
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
  }),
  methods: {
    async getEquipamentos() {
      this.loadingApoioRespiratorio = true;
      this.loadingOxigenoterapia = true;
      this.loadingMobiliario = true;
      this.loadingDemaisEquipamentos = true;

      try {
        const operadoraId = this.operadoraId;
        const { data } = await api.get(`operadoras/operadora-equipamentos/?operadora=${operadoraId}`);
        this.equipamentos = data.filter(equipamento => equipamento.ativo === true);

        // Filtra e para cada tipo de equipamento, desativa o loading após carregar os dados
        this.loadingApoioRespiratorio = false;
        this.loadingOxigenoterapia = false;
        this.loadingMobiliario = false;
        this.loadingDemaisEquipamentos = false;

      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      }
    },
    async getEquipamentoDependencia() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`operadoras/operadora-equipamentos-dependencias-novo/`);
        this.equipamentodependente = data.filter(item => item.ativo)
        // console.log('this.equipamentodependente', this.equipamentodependente)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getKitEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/kitequipamentos/`);
        this.kitEquipamento = data.filter(item => item.ativo)
        // console.log('kitEquipamento -> ', this.kitEquipamento)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getAditivo() {
      this.loadingEquipamentos = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = this.formatDate(data.data_aditivo);
        // console.log(this.aditivo); // Debug
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingEquipamentos = false;
      }
    },
    async getPacote() {
      this.loadingTaxas = true
      const operadora = this.operadoraId
      const modalidade = this.modalidade.id
      try {
        const { data } = await api.get(`operadoras/operadora-pacotes/?operadora=${operadora}`);
        const pacote = data.find(item => item.ativo && item.modalidade.id === modalidade);
        this.pacoteId = pacote ? pacote.id : null;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingTaxas = false;
      }
    },
    async onBeforeSubmit() {
      this.validating = true;
      let isValid = true;

      if (this.newEquipamento.aditivo_equipamentos && !this.newEquipamento.nr_aditivo) {
        isValid = false;
        this.$toast.error('Por favor, preencha o Nrº do Aditivo para todos os campos onde Aditivo está como Sim.');
      }
      if (!this.allFieldsValid()) {
        this.$toast.error('Por favor, preencha todos os campos antes de continuar!');
        this.validating = false;
        return;
      }

      if (!isValid) {
        this.validating = false;
        return;
      }

      for (const equipamentoId of this.newEquipamento.equipamentosSelecionados) {
        const selectedEquipamentoObj = this.equipamentos.find(equipamento => equipamento.id === equipamentoId);
        const selectedNomeEquipamento = this.nomeequipamentos.find(nomeequipamento => nomeequipamento.id === (selectedEquipamentoObj ? selectedEquipamentoObj.equipamento.id : null));

        // Calcula o valor de cobrança do equipamento
        const valorCobrancaEquipamento = selectedEquipamentoObj ? parseFloat(selectedEquipamentoObj.preco) : 0;
        
        let valorCobrancaFinal = valorCobrancaEquipamento * this.newEquipamento.quantidade_equipamentos;

        // Verificar se valorCobrancaFinal é uma string antes de chamar replace
        if (typeof valorCobrancaFinal === 'string') {
          valorCobrancaFinal = parseFloat(valorCobrancaFinal.replace('R$', '').replace('.', '').replace(',', '.'));
        } else if (typeof valorCobrancaFinal === 'number') {
          valorCobrancaFinal = parseFloat(valorCobrancaFinal.toFixed(2)); // Certificar-se de que é um número com 2 casas decimais
        } else {
          valorCobrancaFinal = 0; // Definir um valor padrão em caso de tipo inesperado
        }

        const fields = {
          sessao: this.orcamentoId,
          equipamento: equipamentoId,
          data_inicio_equipamento: this.newEquipamento.data_inicio_equipamentos,
          data_fim_equipamento: this.newEquipamento.data_fim_equipamentos,
          quantidade_equipamento: this.newEquipamento.quantidade_equipamentos,
          valor_cobranca_equipamento: valorCobrancaEquipamento,
          valor_cobranca_final_equipamento: valorCobrancaFinal,
          aditivo_equipamento: this.newEquipamento.aditivo_equipamentos,
          pacote: this.pacoteId ? this.pacoteId : null,
          operadora: this.operadoraId,
          equipamento_ref: selectedNomeEquipamento ? selectedNomeEquipamento.id : null,
        };

        if (this.newEquipamento.aditivo_equipamentos) {
          fields.nr_aditivo = this.newEquipamento.nr_aditivo;
          fields.data_aditivo = this.newEquipamento.data_aditivo;
        }

        // Enviar dados para cada equipamento selecionado
        try {
          const response = await this.onSubmit(fields);
          // console.log('response', response)
          // Verificar se a resposta contém o ID do equipamento criado
          const equipamentoCriadoId = response?.id;
          // console.log('equipamentoCriadoId', equipamentoCriadoId)
          // Preparar dados adicionais para o segundo endpoint que é o que está no paciente prontuário
          const field = {
            paciente: this.paciente,
            nome_equipamento: selectedNomeEquipamento ? selectedNomeEquipamento.id : null,
            operadora: this.operadoraId,
            idEquipamentoOrcamento:  equipamentoCriadoId,
            disponibilidade: 2,
            data_inclusao_orcamento: this.newEquipamento.data_inicio_equipamentos,
          };

          // Enviar dados para o segundo endpoint
          await this.saveEquipamentos(field);
        } catch (error) {
          this.$toast.error(`Erro ao processar equipamentos: ${error.message}`);
        }
      }

      this.validating = false;
    },
    async saveEquipamentos(fields) {
      try {
        // console.log('fields enviado ', fields)
        await api.post(`/pacientes/equipamentos/`, fields);
      } catch (error) {
        this.$toast.error(`Erro ao salvar equipamentos: ${error.message}`);
      }
    },
    allFieldsValid() {
      return (
        this.newEquipamento.equipamentosSelecionados.length > 0 && // Verifica se ao menos um equipamento foi selecionado
        this.newEquipamento.data_inicio_equipamentos &&
        this.newEquipamento.data_fim_equipamentos &&
        this.newEquipamento.quantidade_equipamentos &&
        this.newEquipamento.quantidade_equipamentos !== '' // Verifica se a quantidade foi preenchida
      );
    },
    calculateDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end - start);
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    },
    updateQuantityAndTotal() {
      if (this.newEquipamento.data_inicio_equipamentos && this.newEquipamento.data_fim_equipamentos) {
        const days = this.calculateDaysBetweenDates(this.newEquipamento.data_inicio_equipamentos, this.newEquipamento.data_fim_equipamentos);
        this.newEquipamento.quantidade_equipamentos = days;
        this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * days).toFixed(2);
      }
    },
    dataAditivoRules() {
      return [
        v => !!v || 'Data Início do Aditivo é obrigatória',
        v => new Date(v) <= new Date(this.dataFim) || 'Data Início do Aditivo não pode ser maior que a Data de Fim do Orçamento.',
      ];
    },
    updateTotal() {
      this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * this.newEquipamento.quantidade_equipamentos).toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1); 
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    validateAndFormatDate(dateString) {
      const date = new Date(dateString);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (date < dataInicio) {
        return this.formatDate(this.dataInicio);
      } else if (date > dataFim) {
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
        return '';
      } else {
        return this.formatDate(date);
      }
    },
    selectedCount(group) {
      // Conta quantos itens do grupo estão selecionados
      return this[group].filter(equipamento => 
        this.newEquipamento.equipamentosSelecionados.includes(equipamento.id)
      ).length;
    },
    selectKit(kit) {
      // Limpa os equipamentos selecionados antes de aplicar o novo kit
      // this.resetSelectedEquipamentos();

      let itensMarcados = 0;

      // Para cada equipamento no kit, verifica se existe na lista de equipamentos disponíveis
      kit.equipamento.forEach(equipamentoId => {
        const equipamentoEncontrado = this.equipamentos.find(equipamento => equipamento.equipamento.id === equipamentoId);

        if (equipamentoEncontrado) {
          // Adiciona o equipamento à lista de selecionados
          this.newEquipamento.equipamentosSelecionados.push(equipamentoEncontrado.id);
          itensMarcados++;
        }
      });

      // Exibe uma mensagem com a quantidade de itens marcados
      if (itensMarcados > 0) {
        this.$toast.success(`${kit.nome} foi selecionado.`);
      } else {
        this.$toast.warning(`Nenhum equipamento encontrado para o ${kit.nome}.`);
      }
    },
    resetSelectedEquipamentos() {
      // Limpa todos os equipamentos selecionados
      this.newEquipamento.equipamentosSelecionados = [];
    },
  },
  components: { ConfirmButton },
  computed: {
    procedureTotalValue() {
     const basePrice = parseFloat(this.newEquipamento.valor_cobranca_equipamento);
      const discount = this.newEquipamento.desconto_equipamento ? parseFloat(this.newEquipamento.desconto_equipamento) / 100 : 0;
      const addition = this.newEquipamento.acrescimo_equipamento ? parseFloat(this.newEquipamento.acrescimo_equipamento) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.newEquipamento.quantidade_equipamento;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    formattedEquipamentos() {
      return this.equipamentos.map(equipamento => {
        return {
          ...equipamento,
          // displayText: equipamento.nomecomercial ? `${equipamento.equipamento.nome} - "${equipamento.nomecomercial}" - Cód.: ${equipamento.codigo}` : (`${equipamento.equipamento.nome} - Cód.: ${equipamento.codigo}`)
          displayText: equipamento.equipamento ? `${equipamento.equipamento.nome}` : ''
        };
      });
    },
    apoioRespiratorioEquipamentos() { 
      return this.formattedEquipamentos.filter(equipamento => [11,12,34,68,67,46, 57,47,49,52,53,65].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    oxigenoterapiaEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => [27, 31, 28, 29, 30, 75,51, 66, 50, 54, 55].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    mobiliarioEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => [3, 14,15, 16, 17, 19, 18,20, 22, 21,23, 73, 33, 37, 73, 32].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
    demaisEquipamentos() {
      return this.formattedEquipamentos.filter(equipamento => ![34, 67, 57,47,48, 49, 52,53,65, 17,68, 12, 11, 23, 46, 3,14,15,16, 19, 18,20,22, 21,73,33,51,66,50,54,55,  27, 31,28, 29, 30,75, 37,73, 32 ].includes(equipamento.equipamento.id))
      .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
    },
  },
  watch: {
    'newEquipamento.data_inicio_equipamentos': 'updateQuantityAndTotal',
    'newEquipamento.data_fim_equipamentos': 'updateQuantityAndTotal',
    'newEquipamento.quantidade_equipamentos': 'updateTotal',
    'newEquipamento.equipamento': function (newVal) {
      if (newVal) {
        const selectedequipamento = this.equipamentos.find(equipamento => equipamento.id === newVal);
        if (selectedequipamento) {
          this.newEquipamento.preco = selectedequipamento.preco;
          this.updateQuantityAndTotal();
        }
      }
    },
    dataFim(newVal) {
      if (newVal) {
        this.newEquipamento.data_fim_equipamentos = newVal;
        this.updateQuantityAndTotal();
      }
    },
    'newEquipamento.aditivo_equipamentos': function (newVal) {
      if (newVal) {
        this.newEquipamento.nr_aditivo = this.maxNrAditivo || 1;
        this.newEquipamento.data_aditivo = this.maxDataAditivo || new Date().toISOString();
        this.newEquipamento.data_inicio_equipamentos = this.maxDataAditivo || new Date().toISOString()
      } else {
        this.newEquipamento.nr_aditivo = null;
        this.newEquipamento.data_aditivo = null;
        this.newEquipamento.data_inicio_equipamentos = this.formatDate(new Date().toISOString().substr(0, 10))
      }
    },
    'newEquipamento.nr_aditivo': function(newVal) {
      if (newVal != null) {
        if (newVal == this.maxNrAditivo) {
          this.newEquipamento.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
          this.newEquipamento.data_inicio_equipamentos = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
        } else {
          const data = this.dataInicio < this.formatDate(new Date().toISOString().substr(0, 10)) ? this.formatDate(new Date().toISOString().substr(0, 10)) : this.dataInicio
          this.newEquipamento.data_aditivo = data;
          this.newEquipamento.data_inicio_equipamentos = this.formatDate(new Date().toISOString().substr(0, 10))
        }
      }
    },
    maxNrAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newEquipamento.nr_aditivo = newVal;
        this.newEquipamento.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString());
      }
    },
    maxDataAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newEquipamento.data_aditivo = newVal;
      }
    },
   'newEquipamento.equipamentosSelecionados': function(newVal, oldVal) {
      // Itera sobre os equipamentos que têm dependentes
      this.equipamentodependente.forEach(dependente => {
        const paiId = dependente.equipamentopai.id;
        const filhosIds = dependente.equipamentofilhos.map(filho => filho.id);

        // Itera sobre os equipamentos selecionados
        newVal.forEach(selectedId => {
          // Busca o equipamento selecionado no array de equipamentos disponíveis
          const equipamentoSelecionado = this.equipamentos.find(equip => equip.id === selectedId);

          if (equipamentoSelecionado) {
            const equipamentoId = equipamentoSelecionado.equipamento.id; // Pega o equipamento.equipamento.id

            // Verifica se o equipamento.equipamento.id corresponde ao paiId
            if (equipamentoId === paiId && !oldVal.includes(selectedId)) {
              // Se o equipamento selecionado corresponde ao pai, sugere a seleção dos filhos
              filhosIds.forEach(filhoId => {
                // Busca o equipamento filho no getEquipamentos para encontrar o ID correto a ser marcado
                const equipamentoFilho = this.equipamentos.find(equip => equip.equipamento.id === filhoId);
                
                if (equipamentoFilho && !newVal.includes(equipamentoFilho.id)) {
                  newVal.push(equipamentoFilho.id); // Marca os filhos automaticamente
                }
              });
            }
          }
        });
      });
    },
  },
  mounted() {
    this.getPacote();
    this.getNomeEquipamento();
    this.getEquipamentoDependencia();
    this.getKitEquipamento();
    this.getAditivo().then(() => {
      this.getEquipamentos();
      this.newEquipamento.data_inicio_equipamentos = this.validateAndFormatDate(this.dataInicio);
      this.newEquipamento.data_fim_equipamentos = this.formatDate(this.dataFim);
    });
  },
}
</script>

<style scoped>
.expansion-header {
  justify-content: center;
  font-size: 18px;
}

.spacer {
  margin-top: 20px; /* Ajusta o espaçamento entre os painéis de expansão e o restante dos campos */
}

.equipamento-row {
  margin-bottom: 2px;
}

.equipamento-col {
  margin-bottom: 2px;
  padding: 0px;
}

.error-message {
  color: red;
  font-weight: bold;
}



</style>
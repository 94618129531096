<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon>
    Rel. Assinaturas
  </v-btn>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../service/utilsPDF';
import UtilsFunc from "../../service/utilsFunc";
import logo from "../../assets/logo.png";
import gptw from "../../assets/GPTW_2023-2024.png";
import ona from "../../assets/ONA_Nivel_3.png";
import map from "../../assets/map.jpg";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Assinaturas",
  data: () => withCRUDUtils({
    loading: false,
    orcamento: '',
    procedimentos: [],
    assinaturas: [],
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimentos()
        ]);
        this.savePDF();
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    drawHeader(doc) {
      const orcamento = this.orcamento

      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-61):", error);
      }
      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5);
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relatório de Coleta de Assinatura`, 105, 23, { align: "center" });

      moment.locale("pt-br");

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const xFixedPosition = 10;
      const yFixedPosition = 74;
      const lineHeight = 6
      const pageWidth = 210;

      doc.setFontSize(8);
      doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, {
        align: "right",
      });

      doc.setFontSize(11);
      doc.text(`Período de: ${UtilsPDF.formatDate(orcamento.data_inicio)} até: ${UtilsPDF.formatDate(orcamento.data_fim)}`, 71, 32);

      const data = this.orcamento
      const paciente = this.paciente
      this.operadora = data.convenio

      let yPosition = 46
      doc.text(
        `Paciente: ${paciente ?? ''}`,
        xFixedPosition,
        yPosition
      );

      const dataNascimento = data && data.data_nascimento
        ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}`
        : 'Data Nascimento: ';

      doc.text(
        dataNascimento,
        200,
        yPosition,
        { align: "right" }
      );

      yPosition += lineHeight
      doc.text(`Operadora: ${this.operadora ?? ''}`, xFixedPosition, yPosition);

      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, {
        align: "right",
      });

      yPosition += lineHeight

      const modalidade = data.modalidade ?? ''
      doc.text(
        `Modalidade: ${modalidade}`,
        xFixedPosition,
        yPosition
      );

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      const cid = data && data.cid ? data.cid : ''
      const cid_descricao = data && data.cid_descricao ? data.cid_descricao : ''

      if (cid && cid_descricao) {
        const cidDescricao = cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      yPosition += lineHeight * 2
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Comprovação de Coleta de Assinaturas");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Comprovação de Coleta de Assinaturas", xCenteredPosition, yFixedPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF() {
      const data = this.orcamento
      const periodoOrcamento = `${UtilsPDF.formatDateToFilename(data.data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data.data_fim)}`;
      const filename = `Relatorio_Assinaturas_${this.paciente.replace(/\s+/g, '_')}_${data.convenio.replace(/\s+/g, '_')}_Periodo_${periodoOrcamento}.pdf`;

      await this.generatePDF();

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      let yPosition = 80;
      const data = this.procedimentos

      data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      const imagensAssinaturaBase64 = await Promise.all(
        data.map(item => {
          let signature = item.assinatura;
          const params = item.assinatura.split('://');
          if (params[0] === 'http' && params[1] !== 'localhost') {
            signature = signature.replace('http', 'https');
          }
          return signature;
        })
      );

      const columns = ['Data da Evolução', 'Procedimento', 'Latitude', 'Longitude', 'Assinatura', 'Mapa']
      const table = data.map((item, index) => ({
        'Data da Evolução': UtilsPDF.formatDate(item.timestamp),
        'Procedimento': item.procedimento.procedimento,
        'Latitude': item.lat,
        'Longitude': item.lng,
        'Assinatura': imagensAssinaturaBase64[index],
        'Mapa': {
          content: '',
          styles: { halign: 'center' },
          link: `https://www.google.com/maps?q=${item.lat},${item.lng}`, // Link para o Google Maps.
          linkTarget: '_blank',
          latitude: item.lat,
          longitude: item.lng,
        }
      }));

      const rows = [];

      table.forEach((item, index) => {
        const row = [];
        columns.forEach(column => {
          if (column !== 'Assinatura') {
            row.push(item[column]);
          } else {
            row.push(item[''])
            this.assinaturas.push({ id: index, data: item[column] });
          }
        });
        rows.push(row);
      });

      doc.autoTable({
        startY: yPosition,
        theme: 'grid',
        head: [columns],
        body: rows,
        tableWidth: 'auto',
        margin: { left: 5, right: 5 },
        didDrawCell: data => {
          if (data.column.index === 4 && data.cell.section === 'body') {
            let signature = this.assinaturas[data.row.index];
            if (signature) {
              const imgWidth = 30;
              const imgHeight = 9;

              // Centralizar imagem na célula
              const imgX = data.cell.x + (data.cell.width - imgWidth) / 2;
              const imgY = data.cell.y + (data.cell.height - imgHeight) / 2;

              doc.addImage(signature.data, 'JPEG', imgX, imgY, imgWidth, imgHeight);
            }
          }
          if (data.column.index === 5 && data.cell.section === 'body') {
            let url = data.cell.raw.link;
            doc.link(data.cell.x, data.cell.y, data.cell.width, data.cell.height, { url: url });

            let xPosition = data.cell.x + (data.cell.width / 2);
            let yPosition = data.cell.y + (data.cell.height / 2);
            let posX = xPosition - 2.5;
            let posY = yPosition - 2.5;

            doc.addImage(map, 'JPEG', posX, posY, 5, 5, "SLOW", "map");
          }
        },
        styles: {
          cellPadding: 3,
          fontSize: 10,
          halign: 'center',
          // lineColor: [57, 106, 159],
        },
        headStyles: {
          fillColor: [57, 106, 159],
          textColor: [255, 255, 255],
          fontStyle: 'bold',
          halign: 'center',
        },
        columnStyles: {
          4: { cellWidth: 40 },
          5: { cellWidth: 20 }
        }
      });


      UtilsPDF.addPageNumbers(doc);
      this.doc = doc
      this.assinaturas = [];
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.orcamento = data[0];
          this.paciente = this.orcamento.paciente
          // console.log('orcamento ---> ', this.orcamento)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Orçamento (L-323):", error);
        }
      }
    },
    async getProcedimentos() {
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`);
        this.procedimentos = data.filter(item => item.assinatura != null && item.ativo === true);
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Procedimento (L-332):", error);
      }
    },
  },
};
</script>
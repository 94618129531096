<template>
  <v-main>
    <v-card class="mx-auto my-12" max-width="374" color="#fdf9f9">
      <v-row>
        <v-col class="text-center">
          <v-img class="ma-auto" src="../assets/logo.png" width="250"></v-img>
        </v-col>
      </v-row>
      <v-form v-on:submit.prevent="login" class="text-center">
        <v-card-text>
          <v-row dense>
            <v-col>
              <p class="primary--text" style="font-weight: bold;">Entre com seu Usuário e Senha</p>
              <v-text-field outlined v-model="username" placeholder="Usuario" dense hide-details />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field outlined v-model="password" placeholder="Senha"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-btn type="submit" :loading="loading" color="primary" class="white--text" block elevation="0">
              ENTRAR
              <v-icon class="pa-2">mdi mdi-login</v-icon>
            </v-btn>
          </v-container>
        </v-card-actions>
        <v-row>
          <v-col>
            <router-link to="/esqueci-senha">Esqueci minha senha</router-link>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-main>
</template>
<script>
import { mapGetters } from 'vuex'
import { router } from '../routes'
export default {
  name: 'Login',
  data: () => ({
    username: "",
    password: "",
    showPassword: false,
    loading: false
  }),
  methods: {
    async login() {
      this.loading = true
      try {
        // await this.$store.dispatch('userLogin', { "username": this.username, "password": this.password})
        await this.$store.dispatch('userLogin', { "username": this.username, "password": this.password, "versao": 1651})
      } catch (error) {
        const errorMessage = error.response?.data?.non_field_errors 
        ? error.response.data.non_field_errors[0] 
        : 'Usuário ou senha incorreto';
        this.$toast.error(errorMessage);
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    isSigned: function () {
      if (this.isSigned === 'signed') router.push('/')
    }
  },
  computed: {
    ...mapGetters(['isSigned'])
  },
}
</script>

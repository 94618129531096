<template>
  <v-container style="padding: 0;">
    <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
      :loading="loading">
      <v-icon left>mdi-printer</v-icon> PPS
    </v-btn>
  </v-container>
</template>
<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsPDF from '../../../service/utilsPDF';
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_3.png";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "PPS",
  data: () => withCRUDUtils({
    loading: false,
    orcamento: '',
    pps: [],
    assinaturas: [],
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getPps()
        ]);
        this.savePDF();
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
        this.loading = false;
      }
    },
    drawHeader(doc) {
    const domicilio = this.pps[0];
    const data = this.orcamento;
    const paciente = this.paciente;
    this.operadora = data.convenio;

    try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
    } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (L-61):", error);
    }

    doc.setFont("helvetica", "normal");
    doc.setDrawColor(57, 106, 159);
    doc.setLineWidth(1);
    doc.line(5, 5, 205, 5);

    // Aumente o valor de 'height' para aumentar a altura do retângulo
    doc.setLineWidth(0.5);
    const rectX = 5;
    const rectY = 40;
    const rectWidth = 200;
    const rectHeight = 30;  // Aumente este valor para aumentar a altura do retângulo
    const borderRadius = 3;
    doc.roundedRect(rectX, rectY, rectWidth, rectHeight, borderRadius, borderRadius, "S");


    doc.setDrawColor(0, 0, 0);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("São Luís Home Care", 105, 15, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(16);
    doc.text(`PPS`, 105, 23, { align: "center" });

    moment.locale("pt-br");

    const currentDate = new Date();
    const hour = moment(currentDate).format("HH:mm");
    const xFixedPosition = 10;
    const lineHeight = 6;

    doc.setFontSize(8);
    doc.text(`Gerado por: ${this.username}`, 200, 28, { align: "right"});
    doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
    doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right" });

    doc.setFontSize(11);
    doc.text(`Avaliado em: ${UtilsPDF.formatDate(domicilio.timestamp)}`, 81, 32);

    let yPosition = 46;
    doc.text(`Paciente: ${paciente ?? ''}`, xFixedPosition, yPosition);

    const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}`  : 'Data Nascimento: ';
    doc.text(dataNascimento, 200, yPosition, { align: "right" });

    yPosition += lineHeight;
    doc.text(`Operadora: ${this.operadora ?? ''}`, xFixedPosition, yPosition);
    doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right" });

    yPosition += lineHeight;

    const modalidade = data && data.modalidade ? data.modalidade : '';
    doc.text(`Modalidade: ${modalidade}  `, xFixedPosition, yPosition);

    const maxWidth = 200;
    const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

    const modalidadeText = `Modalidade: ${modalidade} `;
    const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

    const cid = data && data.cid ? data.cid : '';

    if (cid && cid.length > 0) {
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
            doc.setFont("helvetica", "normal");
            doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
            const xPos = i === 0 ? 200 : 10;
            const align = i === 0 ? 'right' : 'left';
            const yPos = yPosition + (lineHeight * i);
            renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);
    } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
    }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async savePDF() {
      const data = this.orcamento
      const pps = this.pps[0]
      const periodoOrcamento = `${UtilsPDF.formatDateToFilename(pps.timestamp)}`;
      const filename = `PPS_${this.paciente.replace(/\s+/g, '_')}_${data.convenio.replace(/\s+/g, '_')}_Avaliado_em_ ${periodoOrcamento}.pdf`;

      await this.generatePDF();

      const pdfBuffer = this.doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)

      // Código para salbvar no doc do paciente 
      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())
      await this.saveDocument(signedPDFBlob, filename);

    
      const signedPdfUrl = signedForm.path

      this.download(signedPdfUrl, filename)
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    // Fórmula para salvar automaticamente o doc nos docs do paciente
    async saveDocument(pdfBlob, filename) {
      const today = new Date().toISOString().split('T')[0];
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("paciente", this.paciente_id);
      formData.append("tipodoc", 36);
      formData.append("data_carregado", today);
      try {
        await api.post('pacientes/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
    async generatePDF() {
    const doc = new jsPDF({ compress: true });
    this.drawHeader(doc);

    const pageWidth = doc.internal.pageSize.getWidth();
    let yPosition = 90;  // Ajusta a posição inicial para começar após o título
    const rowHeight = 12;  // Altura das linhas para o conteúdo
    const headerHeight = 20; // Altura das linhas para o cabeçalho
    const colWidths = [10, 30, 40, 30, 30, 35]; // Largura das colunas

    // Pontuação PPS vinda do endpoint
    const pontos = this.pps[0].pps;
    const pontuacoes = [
        pontos.dambulacao_PPS.pontuacao,
        pontos.atividade_PPS.pontuacao,
        pontos.auto_cuidado_PPS.pontuacao,
        pontos.ingestao_PPS.pontuacao,
        pontos.nivel_conciencia_PPS.pontuacao
    ].filter(value => value !== null && value !== undefined); // Filtra apenas valores válidos

    // Buscar o menor valor da pontuação
    const ppsPontuacao = Math.min(...pontuacoes);

    // Desenha o título "ESCALA DE PERFORMANCE PALIATIVA (PPS)" centralizado
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    const titleText = "ESCALA DE PERFORMANCE PALIATIVA (PPS)";
    const textWidth = doc.getTextWidth(titleText);
    const xCenteredPosition = pageWidth / 2 - textWidth / 2;
    doc.text(titleText, xCenteredPosition, yPosition - 10);  // Desenha o título um pouco acima da tabela

    // Define a fonte e o tamanho para a tabela
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);

    // Função para desenhar uma linha de cabeçalho com altura ajustada
    const drawHeaderRow = (y) => {
        doc.setTextColor(255, 255, 255); // Texto branco
        doc.setFillColor(57, 106, 159); // Azul para o fundo
        doc.rect(10, y, pageWidth - 20, headerHeight, 'F'); // Fundo retangular do cabeçalho com altura aumentada
        doc.setDrawColor(0, 0, 0); // Contorno preto
        doc.setLineWidth(0.1); // Fina linha de contorno
        doc.rect(10, y, pageWidth - 20, headerHeight); // Contorno da linha de cabeçalho

        // Escreve os títulos das colunas em caixa alta, centralizados e quebrados, se necessário
        const headers = ["%", "Deambulação", "Atividade e evidência da Doença", "Auto cuidado", "Ingestão", "Nível de Consciência"];
        let xPosition = 10;
        headers.forEach((header, index) => {
            const splitTitle = doc.splitTextToSize(header.toUpperCase(), colWidths[index] - 5); // Converte para caixa alta e quebra o texto
            const headerY = y + ((headerHeight - (splitTitle.length * 8)) / 2) + 6; // Centraliza verticalmente na célula
            splitTitle.forEach((line, lineIndex) => {
                doc.text(line, xPosition + colWidths[index] / 2, headerY + (lineIndex * 8), { align: "center" });
            });
            xPosition += colWidths[index];
        });

        // Desenha as linhas verticais que separam as colunas
        let lineX = 10;
        colWidths.forEach((width) => {
            doc.line(lineX, y, lineX, y + headerHeight);
            lineX += width;
        });
    };

   // Função para desenhar uma linha de conteúdo com altura padrão e destacar a linha se coincidir com a pontuação PPS
    const drawContentRow = (data, y) => {
      // Verifica se a pontuação coincide com a linha atual
      const isHighlighted = parseInt(data.percentual) === ppsPontuacao;

      if (isHighlighted) {
        doc.setFillColor(144, 238, 144); // Verde claro
        doc.rect(10, y, pageWidth - 20, rowHeight, 'F'); // Fundo verde claro na linha inteira
      }

      doc.setTextColor(0, 0, 0); // Texto preto
      let xPosition = 12; // Iniciar um pouco mais à esquerda
      const rowData = [data.percentual, data.deambulacao, data.atividade, data.autoCuidado, data.ingestao, data.nivelConsciencia];

      rowData.forEach((text, index) => {
        const splitText = doc.splitTextToSize(text, colWidths[index] - 2); // Divide o texto para caber na célula
        doc.text(splitText, xPosition, y + 4);  // Alinha o conteúdo um pouco mais para cima
        xPosition += colWidths[index];
      });

      // Desenha as bordas ao redor das células
      doc.setDrawColor(0, 0, 0);
      doc.rect(10, y, pageWidth - 20, rowHeight);

      // Desenha as linhas verticais que separam as colunas
      let lineX = 10;
      colWidths.forEach((width) => {
        doc.line(lineX, y, lineX, y + rowHeight);
        lineX += width;
      });
    };

    // Desenhar o cabeçalho da tabela com altura aumentada
    drawHeaderRow(yPosition);
    yPosition += headerHeight;  // Ajusta yPosition pela altura do cabeçalho

    // Dados da tabela baseados no conteúdo do PDF
    const ppsData = [
        { percentual: "100", deambulacao: "Completa", atividade: "Normal; sem evidência da doença.", autoCuidado: "Completo", ingestao: "Normal", nivelConsciencia: "Completa" },
        { percentual: "90", deambulacao: "Completa", atividade: "Normal; alguma evidência da doença.", autoCuidado: "Completo", ingestao: "Normal", nivelConsciencia: "Completa" },
        { percentual: "80", deambulacao: "Completa", atividade: "Com esforço; alguma evidência da doença.", autoCuidado: "Completo", ingestao: "Normal", nivelConsciencia: "Completa" },
        { percentual: "70", deambulacao: "Reduzida", atividade: "Incapaz para o trabalho; alguma evidência da doença.", autoCuidado: "Completo", ingestao: "Normal ou reduzida", nivelConsciencia: "Completa" },
        { percentual: "60", deambulacao: "Reduzida", atividade: "Incapaz de realizar hobbies; doença significativa.", autoCuidado: "Assistência ocasional", ingestao: "Normal ou reduzida", nivelConsciencia: "Períodos de confusão ou completa" },
        { percentual: "50", deambulacao: "Sentado ou deitado", atividade: "Incapacitado para qualquer trabalho; doença extensa.", autoCuidado: "Assistência considerável", ingestao: "Normal ou reduzida", nivelConsciencia: "Períodos de confusão ou completa" },
        { percentual: "40", deambulacao: "Acamado", atividade: "Incapacitado para qualquer trabalho; doença extensa.", autoCuidado: "Assistência quase completa", ingestao: "Normal ou reduzida", nivelConsciencia: "Períodos de confusão ou completa" },
        { percentual: "30", deambulacao: "Acamado", atividade: "Incapacitado para qualquer trabalho; doença extensa.", autoCuidado: "Dependência completa", ingestao: "Reduzida", nivelConsciencia: "Períodos de confusão ou completa" },
        { percentual: "20", deambulacao: "Acamado", atividade: "Incapacitado para qualquer trabalho; doença extensa.", autoCuidado: "Dependência completa", ingestao: "Limitada a colheradas", nivelConsciencia: "Períodos de confusão ou completa" },
        { percentual: "10", deambulacao: "Acamado", atividade: "Incapacitado para qualquer trabalho; doença extensa.", autoCuidado: "Dependência completa", ingestao: "Cuidados com a boca", nivelConsciencia: "Confuso ou em coma" },
        { percentual: "0", deambulacao: "Morte", atividade: "", autoCuidado: "", ingestao: "", nivelConsciencia: "" },
    ];

    // Desenhar as linhas da tabela com altura padrão
    ppsData.forEach((row) => {
        drawContentRow(row, yPosition);
        yPosition += rowHeight;
    });

    doc.setFontSize(7);
      doc.setFont("helvetica", "italic");

      // Adicionar as linhas de observação
      doc.text("Referência: Harlos M, WoelK C. Guideline for estimating length of survival in Palliative Patients. Em http://www.palliative.info. Traduzido e adaptado por Neto, 2006.", 10, yPosition + 4);
      yPosition += rowHeight +3; // Espaço para a próxima linha

      // Adicionar imagem do carimbo e nome do profissional ao lado das observações
      const profissional = this.pps[0].profissional;
      const especialidade = this.pps[0].especialidade;
      if (profissional) {
          const xCarimboPosition = pageWidth - 40;  // Posição X para a imagem e texto do carimbo
          let carimboImageURL = profissional.carimbo;
          const params = profissional.carimbo.split('://')
            if (params[0] === 'http' && params[1] !== 'localhost') {
              carimboImageURL = carimboImageURL.replace('http', 'https')
            }

          if (carimboImageURL) {
              // Adiciona a imagem do carimbo se disponível
              doc.addImage(carimboImageURL, 'JPEG', xCarimboPosition, yPosition +2, 25, 10); // Tamanho e posição ajustados
          }
          // Adiciona o nome do profissional abaixo da imagem do carimbo
          doc.setFontSize(8);
          doc.text(`${profissional.apelido} - ${especialidade.nome}`, xCarimboPosition + 12, yPosition + 18, { align: 'center' });
      }
    UtilsPDF.addPageNumbers(doc);
    this.doc = doc;
}

,
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.orcamento = data[0];
          this.paciente = this.orcamento.paciente
          this.paciente_id = this.orcamento.paciente_id
          // console.log('orcamento ---> ', this.orcamento)
        } catch (error) {
          this.$toast.error("Erro ao buscar os dados do Orçamento (L-323):", error);
        }
      }
    },
    async getPps() {
      try {
        const { data } = await api.get(`atendimentos/app-evolucoes-nead_abemid/?sessao_id=${this.selectedBudgets[0]}&pps=true`);
        this.pps = data;
        // console.log(this.pps)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Procedimento (L-332):", error);
      }
    },
  },
};
</script>